
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { useRoute } from 'vue-router';
import { $api } from '@/services';
import dayjs from 'dayjs';
import { useStore } from 'vuex';

export default defineComponent({
	name: 'MakeReportStep3',
	components: { DaIcon },
	props: {
		reportOrder: {
			type: String,
			default: '',
		},
		reportId: {
			type: String,
			default: '',
		},
		insertedAt: {
			type: String,
			default: '',
		},
	},
	setup(props: any, { emit }: any) {
		const route = useRoute();
		const store = useStore();
		const applyNow = ref(null as any);
		const dateNow = ref('' as any);
		const applyDate = ref(null as any);
		const isEdit = ref(true);
		const nameReport = ref('');
		const goals = ref([]);
		const continued_goals_count = ref(0);
		const goals_count = ref(0);
		const skills_count = ref(0);

		const monthStart = computed(() => route.query.month_start);

		dateNow.value = 'Hôm nay ' + dayjs(new Date()).format('DD/MM/YYYY');

		const id = computed(() =>
			route.params?.id ? route.params?.id + '' : ''
		);

		nameReport.value =
			'Tháng ' +
			dayjs(String(monthStart.value || new Date()), 'YYYY-MM-DD')
				.add(1, 'month')
				.format('MM/YYYY');

		async function fetchPlan() {
			try {
				const {
					data: { data, stats },
				} = await $api.monthlyReport.monthlyReportPlan(
					props.reportId,
					id.value
				);
				goals.value = data ?? [];
				continued_goals_count.value = stats.continued_goals_count;
				goals_count.value = stats.goals_count;
				skills_count.value = stats.skills_count;
			} catch (e) {
				console.log(e);
			}
		}

		function submitReport() {
			emit('submit', {
				apply_now: applyNow.value == 1,
				name: nameReport.value,
				apply_at:
					applyNow.value == 2
						? dayjs(applyDate.value).format('YYYY-MM-DD')
						: new Date(),
			});
		}

		fetchPlan();

		function done() {
			isEdit.value = false;
			emit('isDone');
		}
		function firstDayOfNextMonth() {
			let today = new Date();
			let year = today.getFullYear();
			let month = today.getMonth();
			let nextMonth = month + 1;
			if (nextMonth === 12) {
				nextMonth = 0;
				year += 1;
			}
			let firstDayOfNextMonth = new Date(year, nextMonth, 1);
			return firstDayOfNextMonth;
		}
		watch(applyNow, () => {
			if (applyNow.value === 2) applyDate.value = firstDayOfNextMonth();
		});
		onMounted(() => emit('isNoteDone'));

		return {
			done,
			isEdit,
			goals,
			goals_count,
			skills_count,
			continued_goals_count,
			nameReport,
			applyNow,
			applyDate,
			applyDateText: computed(() =>
				dayjs(applyDate.value).format('DD/MM/YYYY')
			),
			dateNow,
			submitReport,
			org: computed(() => store.state.profile.current_org),
		};
	},
});
