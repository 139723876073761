
import { Vue, Options, setup } from 'vue-class-component';
import { Prop, PropSync, Watch } from 'vue-property-decorator';
import { CITY } from '@/data/city';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { isEmail, isNumber } from '@/helper/DAValidate';
import UploadAvatar from '@/components/elements/uploadAvatar/UploadAvatar.vue';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import { $api } from '@/services';
import store from '@/store';
import Timesheet from '@/views/setupCenter/features/timesheet/Timesheet.vue';
import { useGetBoardings } from '@/views/setupCenter/features/boarding/services/getBoardings';
import heic2any from 'heic2any';

@Options({
	name: 'SetupInformation',
	components: { Timesheet, DaModalFrame, UploadAvatar, DaIcon },
})
export default class SetupInformation extends Vue {
	//----------------------- 🤍 Props 🤍 -----------------------//
	@Prop() org!: any;
	@PropSync('updated') updateSuccessful!: boolean;

	//----------------------- 🤍 Data 🤍 -----------------------//
	orgProfile: any = {
		address: '',
		city: '',
		contact_email: '',
		hotline: '',
		name: '',
		official_name: '',
		profile_photo: null,
	};

	editMode = false;

	error = {
		contact_email: '',
		hotline: '',
	};

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get city() {
		return CITY;
	}

	//----------------------- 🤍 Watch 🤍 -----------------------//
	@Watch('org', { deep: true, immediate: true })
	onOrgChange(org: any) {
		if (org) {
			this.orgProfile = {
				address: org?.address,
				city: org?.city,
				contact_email: org?.contact_email,
				hotline: org?.hotline,
				name: org?.name,
				official_name: org?.official_name,
				profile_photo: org?.profile_photo,
			};
		}
	}

	@Watch('updateSuccessful')
	onUpdateSuccessful(value: boolean) {
		if (value) {
			this.editMode = false;
		}
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	updateProfile() {
		this.error = {
			contact_email: '',
			hotline: '',
		};
		if (!isNumber(this.orgProfile?.hotline)) {
			return (this.error['hotline'] = 'Số điện thoại không hợp lệ');
		}
		if (!isEmail(this.orgProfile?.contact_email)) {
			return (this.error['contact_email'] = 'Địa chỉ email không hợp lệ');
		}
		this.$emit('updateOrg', {
			address: this.orgProfile?.address,
			city: this.orgProfile?.city,
			contact_email: this.orgProfile?.contact_email,
			hotline: this.orgProfile?.hotline,
			name: this.orgProfile?.name,
			official_name: this.orgProfile?.official_name,
		});
	}
	modalIsOpen = false;
	avatar: any = '';

	openModelUpload() {
		const target = document.getElementById('upload-avatar-1');
		target ? target.click() : '';
	}

	uploadChange(event: any) {
		this.avatar = event.target.files[0];

		if (
			/image\/hei(c|f)/.test(event.target.files[0].type) ||
			event.target.files[0].name.endsWith('.heic') ||
			event.target.files[0].name.endsWith('.heif')
		) {
			heic2any({
				blob: event.target.files[0],
				toType: 'image/jpeg',
				quality: 1,
			}).then(async (jpgBlob) => {
				//Change the name of the file according to the new format
				let newName = event.target.files[0].name.replace(
					/\.[^/.]+$/,
					'.jpg'
				);

				//Convert blob back to file
				this.avatar = await this.blobToFile(jpgBlob, newName);

				this.modalIsOpen = true;
			});
		} else {
			this.modalIsOpen = true;
		}
	}

	blobToFile(theBlob: any, fileName: any) {
		//A Blob() is almost a File() - it's just missing the two properties below which we will add

		theBlob.name = fileName;

		//Cast to a File() type
		return new File([theBlob], fileName, {
			lastModified: new Date().getTime(),
			type: theBlob.type,
		});
	}

	close() {
		this.avatar = '';
		const target: any = document.getElementById('upload-avatar-1');
		target ? (target.value = null) : '';
		this.modalIsOpen = false;
	}

	upload(canvas: any) {
		canvas.toBlob(async (blob: any) => {
			try {
				let file = new File([blob], this.avatar.name, {
					type: blob.type,
				});
				let data: any = new FormData();
				data.append('profile_photo', file);
				const res = await $api.org.uploadProfilePicture(data);
				if (res) {
					await store.dispatch('setAlertMessage', {
						message: 'Cập nhật ảnh đại diện thành công',
					});
					this.$emit('updated');
					this.close();
				}
			} catch (e) {
				await store.dispatch('setAlertMessage', {
					message: 'Cõ lỗi xảy ra, vui lòng thử lại.',
					type: 'danger',
				});
			}
		});
	}

	edit() {
		this.editMode = true;
		this.updateSuccessful = false;
		this.error = {
			contact_email: '',
			hotline: '',
		};
	}

	updateOrg(data: any) {
		this.$emit('updateOrg', data);
	}

	totalBoardings = 0;

	async fetchBoardings() {
		const res = await this.setup.getBoardings();
		if (res && res?.data && res?.count) {
			this.totalBoardings = res?.count;
		}
	}

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const { getBoardings } = useGetBoardings();
		return {
			getBoardings,
		};
	});

	created() {
		this.fetchBoardings();
	}

	get total() {
		return this.totalBoardings < 10
			? '0' + this.totalBoardings
			: this.totalBoardings;
	}
}
