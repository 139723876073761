/* eslint-disable */
import { ref } from 'vue';
import { $api } from '@/services';

const error = ref(null);
const loading = ref(false);

export async function join(data: { token: string | null }) {
	error.value = null;
	try {
		const res = await $api.staff.join(data);
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export function useJoin() {
	return { error, loading, join };
}
