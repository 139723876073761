
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'SummaryView',
	components: { DaIcon },
	data() {
		return {
			series: [70],
			series2: [44, 55, 41, 17, 15],
			chartOptions2: {
				dataLabels: {
					enabled: false,
				},
				legend: {
					show: false,
				},
				fill: {
					type: 'gradient',
					gradient: {
						shade: 'dark',
						type: 'vertical',
						gradientToColors: ['#A855F7'],
						stops: [0, 100],
					},
				},
				stroke: {
					lineCap: 'round',
				},
			},
			chartOptions: {
				chart: {
					type: 'radialBar',
				},
				plotOptions: {
					radialBar: {
						hollow: {
							size: '54%',
						},
						dataLabels: {
							enabled: false,
							name: {
								offsetY: -10,
								show: true,
								color: '#65758B',
								fontWeight: 500,
								fontSize: '13px',
							},
							value: {
								color: '#48566A',
								fontSize: '24px',
								show: true,
								fontWeight: 'bold',
							},
						},
					},
				},
				fill: {
					type: 'gradient',
					gradient: {
						shade: 'dark',
						type: 'vertical',
						gradientToColors: ['#A855F7'],
						stops: [0, 100],
					},
				},
				stroke: {
					lineCap: 'round',
				},
				labels: ['Cảm xúc'],
			},
			series3: [42, 47, 52, 58, 65],
			chartOptions3: {
				chart: {
					width: 380,
					type: 'polarArea',
				},
				labels: ['Rose A', 'Rose B', 'Rose C', 'Rose D', 'Rose E'],
				fill: {
					opacity: 1,
				},
				stroke: {
					width: 1,
					colors: undefined,
				},
				yaxis: {
					show: false,
				},
				legend: {
					show: false,
				},
				plotOptions: {
					polarArea: {
						rings: {
							strokeWidth: 0,
						},
						spokes: {
							strokeWidth: 0,
						},
					},
				},
				theme: {
					monochrome: {
						enabled: true,
						shadeTo: 'light',
						shadeIntensity: 0.6,
					},
				},
			},
			series4: [
				{
					name: 'Website Blog',
					type: 'column',
					data: [
						440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257,
						160,
					],
				},
				{
					name: 'Social Media',
					type: 'line',
					data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
				},
			],
			chartOptions4: {
				chart: {
					height: 350,
					type: 'line',
				},
				stroke: {
					width: [0, 4],
				},
				title: {
					show: false,
				},
				dataLabels: {
					enabled: true,
					enabledOnSeries: [1],
				},
				legend: {
					show: false,
				},
				labels: [
					'T2 8:00',
					'T2 13:00',
					'T2 8:00',
					'T3 13:00',
					'T3 8:00',
					'T4 13:00',
					'T4 8:00',
					'T4 13:00',
					'T5 8:00',
					'T5 13:00',
					'T6 8:00',
					'T6 13:00',
				],
				xaxis: {
					show: false,
				},
				yaxis: [
					{
						title: {
							show: false,
						},
					},
					{
						opposite: true,
						title: {
							show: false,
						},
					},
				],
			},
			series5: [
				{
					name: 'Marine Sprite',
					data: [44, 55, 41, 37, 22, 43, 21],
				},
				{
					name: 'Striking Calf',
					data: [53, 32, 33, 52, 13, 43, 32],
				},
			],
			chartOptions5: {
				chart: {
					type: 'bar',
					height: 350,
					stacked: true,
				},
				plotOptions: {
					bar: {
						horizontal: true,
					},
				},
				stroke: {
					width: 1,
					colors: ['#fff'],
				},
				title: {
					show: false,
				},
				xaxis: {
					categories: [
						'1. Hành vi & giác quan',
						'2. Vận động thô',
						'3. Vận động tinh',
						'4. Giao tiếp',
						'5. Toán',
						'6. Viết',
						'7. Đọc',
					],
				},
				yaxis: {
					title: {
						show: false,
					},
					labels: {
						style: {
							colors: [],
							fontSize: '16px',
							fontWeight: 600,
							cssClass: 'apexcharts-yaxis-label',
						},
					},
				},
				tooltip: {
					y: {},
				},
				fill: {
					opacity: 1,
					colors: ['#EACECE', '#9BBEB6'],
				},
				legend: {
					show: false,
				},
			},
		};
	},
});
