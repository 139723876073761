
import { Options, Vue } from 'vue-class-component';
import { PropSync } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { _GET_DATE } from '@/helper/formatDate';

@Options({
	name: 'ConfirmModal',
})
export default class ConfirmModal extends Vue {
	//----------------------- 🤍 Props 🤍 -----------------------//
	@PropSync('success', { default: false, type: Boolean })
	successData!: boolean;
	@PropSync('data', {
		default: {
			student: '',
			week_start: '',
			report_date: '',
			staff: '',
			reportName: '',
			report_name_next: '',
		},
		type: Object,
	})
	reportData!: {
		student: '';
		week_start: '';
		report_date: '';
		staff: '';
		reportName: '';
	};

	get DAYJS() {
		return dayjs;
	}

	get applyDateText() {
		return _GET_DATE(this.reportData.report_date);
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	submit() {
		this.$emit('submit');
	}
}
