<template>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 w-screen md:w-2/5">
		<div class="h-full w-full flex flex-col bg-white shadow-xl">
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center" @click="$emit('close')">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM8.03033 6.96967C7.73744 6.67678 7.26256 6.67678 6.96967 6.96967C6.67678 7.26256 6.67678 7.73744 6.96967 8.03033L10.9393 12L6.96967 15.9697C6.67678 16.2626 6.67678 16.7374 6.96967 17.0303C7.26256 17.3232 7.73744 17.3232 8.03033 17.0303L12 13.0607L15.9697 17.0303C16.2626 17.3232 16.7374 17.3232 17.0303 17.0303C17.3232 16.7374 17.3232 16.2626 17.0303 15.9697L13.0607 12L17.0303 8.03033C17.3232 7.73744 17.3232 7.26256 17.0303 6.96967C16.7374 6.67678 16.2626 6.67678 15.9697 6.96967L12 10.9393L8.03033 6.96967Z"
								fill="#94A3B8"
							/>
						</svg>

						<div
							class="
								text-2xl
								font-bold
								text-neutral-700
								ml-2
								mt-1
							"
						>
							Báo nghỉ
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							type="button"
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>

			<div
				class="
					sideover-content
					pt-6
					relative
					flex-1
					px-4
					sm:px-6
					overflow-y-auto
				"
			>
				<div
					class="
						mt-4
						space-y-4
						sm:flex sm:items-center sm:space-y-0 sm:space-x-10
					"
				>
					<div class="flex items-center">
						<input
							name="notification-method"
							type="radio"
							class="
								h-4
								w-4
								text-yellow-600
								border-neutral-400 border-2
								focus:ring-yellow-600
							"
							:value="2"
							v-model="cancelType"
						/>
						<label
							@click="cancelType = 2"
							class="pl-3 block font-medium text-neutral-700"
						>
							Cả lớp nghỉ
						</label>
					</div>
					<div
						class="flex items-center"
						:class="{ 'opacity-50': [1, 2].includes(+type) }"
					>
						<input
							name="notification-method"
							type="radio"
							class="
								h-4
								w-4
								text-yellow-600
								border-neutral-400 border-2
								focus:ring-yellow-600
							"
							:value="3"
							v-model="cancelType"
							:disabled="[1, 2].includes(+type)"
						/>
						<label
							@click="
								() => {
									if ([1, 2].includes(+type)) {
										return;
									}
									cancelType = 3;
								}
							"
							class="pl-3 block font-medium text-neutral-700"
						>
							Giáo viên nghỉ
						</label>
					</div>
					<div
						class="flex items-center"
						:class="{ 'opacity-50': [2, 3].includes(+type) }"
					>
						<input
							name="notification-method"
							type="radio"
							class="
								h-4
								w-4
								text-yellow-600
								border-neutral-400 border-2
								focus:ring-yellow-600
							"
							:value="1"
							v-model="cancelType"
							:disabled="[2, 3].includes(+type)"
						/>
						<label
							@click="
								() => {
									if ([2, 3].includes(+type)) {
										return;
									}
									cancelType = 1;
								}
							"
							class="pl-3 block font-medium text-neutral-700"
						>
							Học sinh nghỉ
						</label>
					</div>
				</div>
				<div class="mt-8">
					<div>
						<div class="font-semibold text-neutral-600">
							Ghi chú*
						</div>
						<div class="w-full mt-2">
							<div class="input-group flex items-center">
								<textarea
									v-model="note"
									placeholder="Ghi chú về sự thay đổi này"
								>
								</textarea>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-6" :id="`list-dropdown-1`">
					<div
						class="
							bg-neutral-50
							flex
							items-center
							justify-between
							p-3
							border
							rounded-t
							cursor-pointer
						"
						@click="openList(1)"
					>
						<div>Số buổi đã chọn</div>
						<div class="relative">
							<div class="pr-10">{{ data.length }} buổi</div>
							<div
								class="
									absolute
									top-1/2
									right-0
									transform
									-translate-y-1/2
									transition-all
									duration-200
								"
								:id="`list-dropdown-1-icon`"
							>
								<svg
									width="12"
									height="6"
									viewBox="0 0 12 6"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10.5 5.25L6 0.75L1.5 5.25"
										stroke="#48566A"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div
						class="px-4 border border-t-0 rounded-b"
						:id="`list-dropdown-1-content`"
						v-if="data"
					>
						<div
							v-for="(item, index) in data"
							:key="index"
							class="py-4"
						>
							<div class="flex items-center justify-between">
								<div class="text-gray-600 w-40">
									{{ $filters.formatDate(item?.date) }}
								</div>
								<div
									v-if="item?.student && cancelType === 1"
									class="flex items-center"
								>
									<div>
										<img
											class="
												shadow-sm
												inline-block
												h-9
												w-9
												rounded-full
											"
											:src="
												item?.student.profile_photo
													?.w200
											"
											alt=""
											v-if="
												item?.student?.profile_photo
													?.w200
											"
										/>
										<span
											v-else
											class="
												inline-flex
												items-center
												justify-center
												h-9
												w-9
												rounded-full
											"
											:style="{
												background:
													item?.student?.profile_photo
														?.default?.color,
											}"
										>
											<span
												class="
													font-medium
													leading-none
													text-white text-xs
												"
												>{{
													item?.student?.profile_photo
														?.default?.initial
												}}</span
											>
										</span>
									</div>
									<div
										class="
											ml-4
											text-yellow-600
											font-semibold
											truncate
										"
									>
										{{ item?.student.name }}
									</div>
								</div>
								<div
									v-if="item?.staff && cancelType === 3"
									class="flex items-center"
								>
									<div>
										<img
											class="
												shadow-sm
												inline-block
												h-9
												w-9
												rounded-full
											"
											:src="
												item?.staff?.profile_photo?.w200
											"
											alt=""
											v-if="
												item?.staff?.profile_photo?.w200
											"
										/>
										<span
											v-else
											class="
												inline-flex
												items-center
												justify-center
												h-9
												w-9
												rounded-full
											"
											:style="{
												background:
													item?.staff?.profile_photo
														?.default?.color,
											}"
										>
											<span
												class="
													font-medium
													leading-none
													text-white text-xs
												"
												>{{
													item?.staff?.profile_photo
														?.default?.initial
												}}</span
											>
										</span>
									</div>
									<div
										class="ml-4 text-blue-600 font-semibold"
									>
										{{ item?.staff.name }}
									</div>
								</div>
								<div class="text-gray-600 w-40 text-right">
									{{ item?.day_care_class?.name }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="px-6 py-4 border-t border-yellow-50">
				<div class="flex justify-end">
					<div>
						<button
							type="button"
							class="button-p text-center px-6"
							@click="submit"
							:disabled="!isValidate || inProgress"
						>
							Xác nhận
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import { $api } from '@/services';
import dayjs from 'dayjs';
import { toEnglish } from '@/helper/toEnglish';

export default defineComponent({
	name: 'takeLeaveFormDaycare',

	props: {
		open: {
			type: Boolean,
			default: false,
		},

		data: {
			type: Array,
			required: true,
		},

		type: {
			type: Number,
			default: 1,
		},
	},

	data() {
		return {
			date: new Date(),
			note: '',
			inProgress: false,
			cancelType: 1,
		};
	},

	watch: {
		open: {
			handler(value) {
				if (!value) {
					this.note = '';
				}
			},
		},

		type: {
			immediate: true,
			deep: true,
			handler(value) {
				this.cancelType = value ?? 1;
			},
		},
	},

	computed: {
		isValidate() {
			return !!this.note.length;
		},
	},

	methods: {
		toEnglish,

		async cancelUnScheduleDaycareStaffAllClass() {
			const payload = this.data.map((item) => {
				return {
					date: dayjs(item.date).format('YYYY-MM-DD'),
					day_care_class_id: item?.day_care_class?.id || null,
					id: item?.id || undefined,
				};
			});
			await $api.schedule.cancelUnScheduleDaycare({
				class_sessions: payload,
				note: this.note,
			});
		},

		async cancelUnScheduleDaycareByStaff() {
			const payload = this.data.map((item) => {
				return {
					date: dayjs(item.date).format('YYYY-MM-DD'),
					day_care_class_id: item?.day_care_class?.id || null,
					id: item?.id || undefined,
					staff_id: item?.staff?.id,
				};
			});
			await $api.schedule.cancelUnScheduleDaycareByStaff({
				staff_class_sessions: payload,
				note: this.note,
			});
		},

		async submit() {
			this.inProgress = true;
			try {
				if (this.cancelType === 1) {
					const payload = this.data.map((item) => {
						return {
							date: dayjs(item.date).format('YYYY-MM-DD'),
							day_care_class_id: item?.day_care_class?.id || null,
							student_id: item?.student?.id || null,
							note: this.note,
						};
					});
					await $api.schedule.cancelUnScheduleDaycareFuture({
						student_class_sessions: payload,
					});
				} else if (this.cancelType === 2) {
					await this.cancelUnScheduleDaycareStaffAllClass();
				} else if (this.cancelType === 3) {
					await this.cancelUnScheduleDaycareByStaff();
				}
				await this.$store.dispatch('setAlertMessage', {
					message: 'Báo nghỉ thành công',
				});
				this.cancelType = this.type;
				this.$emit('success');
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		openList(index) {
			const el = document.getElementById(
				`list-dropdown-${index}-content`
			);
			const icon = document.getElementById(`list-dropdown-${index}-icon`);
			if (el) {
				if (el.classList.contains('hidden')) {
					el.classList.remove('hidden');
					icon?.classList.add('rotate-180');
				} else {
					el.classList.add('hidden');
					icon?.classList.remove('rotate-180');
				}
			}
		},
	},
});
</script>
