import { ref } from 'vue';
import { $api } from '@/services';

const error = ref(null);
const loading = ref(false);

export async function deleteBoarding(id: string) {
	error.value = null;
	try {
		const res = await $api.boarding.deleteDayCareClass(id);
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors.join('</br>') || err.message;
	} finally {
		loading.value = false;
	}
}

export function useDeleteBoarding() {
	return { deleteBoarding__error: error, loading, deleteBoarding };
}
