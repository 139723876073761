<template>
	<DaModalFrame v-model:open="modalIsOpen">
		<DeleteHoliday
			v-if="deleteData"
			:data="deleteData"
			@close="close"
			@success="fetchHolidays"
		/>
		<CreateHoliday
			v-else
			:data="data"
			@close="close"
			@success="fetchHolidays"
		/>
	</DaModalFrame>
	<div class="__report-lesson px-12 md-down:px-4 pb-30">
		<div
			class="
				__report-lesson__header
				pt-10
				pb-10
				flex flex-nowrap
				justify-between
				items-center
			"
		>
			<div
				class="
					__record__title
					text-neutral-500
					font-semibold
					whitespace-nowrap
					flex flex-nowrap
					items-center
					text-4xl
				"
			>
				<svg
					class="mr-3 h-6 w-6"
					viewBox="0 0 28 28"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M7 0.875C7 0.391751 6.60825 0 6.125 0C5.64175 0 5.25 0.391751 5.25 0.875V1.75H3.5C1.567 1.75 0 3.317 0 5.25V7H28V5.25C28 3.317 26.433 1.75 24.5 1.75H22.75V0.875C22.75 0.391751 22.3582 0 21.875 0C21.3918 0 21 0.391751 21 0.875V1.75H7V0.875ZM28 24.5V8.75H0V24.5C0 26.433 1.567 28 3.5 28H24.5C26.433 28 28 26.433 28 24.5ZM11.9937 14.2563C11.652 13.9146 11.098 13.9146 10.7563 14.2563C10.4146 14.598 10.4146 15.152 10.7563 15.4937L12.7626 17.5L10.7563 19.5063C10.4146 19.848 10.4146 20.402 10.7563 20.7437C11.098 21.0854 11.652 21.0854 11.9937 20.7437L14 18.7374L16.0063 20.7437C16.348 21.0854 16.902 21.0854 17.2437 20.7437C17.5854 20.402 17.5854 19.848 17.2437 19.5063L15.2374 17.5L17.2437 15.4937C17.5854 15.152 17.5854 14.598 17.2437 14.2563C16.902 13.9146 16.348 13.9146 16.0063 14.2563L14 16.2626L11.9937 14.2563Z"
						fill="#65758B"
					/>
				</svg>
				Ngày nghỉ
			</div>
			<button
				@click="openModal(null)"
				type="button"
				class="button-p btn--ghost w-fit px-6 h-12"
			>
				Thêm ngày nghỉ
			</button>
		</div>
		<div v-if="!isLoading">
			<div
				class="__report-lesson__container"
				v-if="holidays && holidays.length > 0"
			>
				<div v-for="data in holidays" :key="data.year">
					<div class="font-semibold text-lg text-gray-600">
						Năm {{ data.year }}
					</div>
					<div
						class="
							p-6
							mt-6
							bg-white
							rounded-lg
							shadow-sm
							flex
							items-center
							flex-nowrap
						"
					>
						<svg
							width="32"
							height="32"
							viewBox="0 0 32 32"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M8 1C8 0.447715 7.55228 0 7 0C6.44772 0 6 0.447715 6 1V2H4C1.79086 2 0 3.79086 0 6V8H32V6C32 3.79086 30.2091 2 28 2H26V1C26 0.447715 25.5523 0 25 0C24.4477 0 24 0.447715 24 1V2H8V1ZM32 28V10H0V28C0 30.2091 1.79086 32 4 32H28C30.2091 32 32 30.2091 32 28ZM13.7071 16.2929C13.3166 15.9024 12.6834 15.9024 12.2929 16.2929C11.9024 16.6834 11.9024 17.3166 12.2929 17.7071L14.5858 20L12.2929 22.2929C11.9024 22.6834 11.9024 23.3166 12.2929 23.7071C12.6834 24.0976 13.3166 24.0976 13.7071 23.7071L16 21.4142L18.2929 23.7071C18.6834 24.0976 19.3166 24.0976 19.7071 23.7071C20.0976 23.3166 20.0976 22.6834 19.7071 22.2929L17.4142 20L19.7071 17.7071C20.0976 17.3166 20.0976 16.6834 19.7071 16.2929C19.3166 15.9024 18.6834 15.9024 18.2929 16.2929L16 18.5858L13.7071 16.2929Z"
								fill="#F1A267"
							/>
						</svg>

						<span
							class="
								font-semibold
								text-4xl text-neutral-600
								ml-4
								pt-1
							"
							>{{ data.total_holidays }}</span
						>
						<span class="text-neutral-400 ml-4"
							>Tổng số ngày nghỉ</span
						>
					</div>
					<div class="shadow-sm sm:rounded-2xl overflow-hidden my-8">
						<div class="__record__table overflow-x-auto scroll-bar">
							<div
								class="
									overflow-hidden
									sm:rounded-t-2xl
									w-fit
									min-w-full
								"
							>
								<table
									class="min-w-full divide-y text-neutral-200"
								>
									<thead class="bg-neutral-100 h-16">
										<tr>
											<th
												scope="col"
												class="
													px-3
													py-3
													text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
													text-left
												"
											>
												Số Thứ Tự
											</th>
											<th
												scope="col"
												class="
													px-3
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												Tên ngày nghỉ lễ
											</th>
											<th
												scope="col"
												class="
													px-3
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												NGÀY nghỉ
											</th>
											<th
												scope="col"
												class="
													px-3
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												tổng số ngày
											</th>
											<th
												scope="col"
												class="
													px-3
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												người tạo
											</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(
												item, index
											) in data.holidays"
											:key="item.id"
											:class="
												index % 2 === 0
													? 'bg-white'
													: 'bg-neutral-50'
											"
											class="text-neutral-700"
										>
											<td
												class="
													px-3
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div>
													{{ index + 1 }}
												</div>
											</td>
											<td
												class="
													px-3
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div>
													{{ item?.title }}
												</div>
											</td>
											<td
												class="
													px-3
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div>
													<span
														v-if="item?.start_date"
													>
														{{
															$filters.formatDate2(
																item.start_date
															)
														}}
													</span>
													<span v-if="item?.end_date">
														-
														{{
															$filters.formatDate2(
																item.end_date
															)
														}}
													</span>
												</div>
											</td>
											<td
												class="
													px-3
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div>
													{{ item?.number_of_days }}
												</div>
											</td>
											<td>
												<div
													class="flex items-center"
													v-if="item.create_by_staff"
												>
													<div>
														<img
															class="
																shadow-sm
																inline-block
																h-6
																w-6
																rounded-full
															"
															:src="
																item
																	.create_by_staff
																	?.profile_photo
																	?.w200
															"
															alt=""
															v-if="
																item
																	.create_by_staff
																	?.profile_photo
																	?.w200
															"
														/>
														<span
															v-else
															class="
																inline-flex
																items-center
																justify-center
																h-6
																w-6
																rounded-full
															"
															:style="{
																background:
																	item
																		.create_by_staff
																		?.profile_photo
																		?.default
																		?.color,
															}"
														>
															<span
																class="
																	font-medium
																	leading-none
																	text-white
																	text-xs
																"
																>{{
																	item
																		.create_by_staff
																		?.profile_photo
																		?.default
																		?.initial
																}}</span
															>
														</span>
													</div>
													<div
														class="
															ml-4
															text-blue-600
															font-semibold
															whitespace-pre-wrap
															w-40
														"
													>
														{{
															item.create_by_staff
																.name
														}}
													</div>
												</div>
											</td>
											<td>
												<div
													class="flex gap-4"
													v-if="item.editable"
												>
													<div
														@click="
															openModalDelete(
																item
															)
														"
														class="
															text-yellow-600
															hover:text-yellow-700
															active:text-yellow-500
															cursor-pointer
														"
													>
														<svg
															width="20"
															height="20"
															viewBox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M3.125 1.25002C2.43464 1.25002 1.875 1.80966 1.875 2.50002V3.75002C1.875 4.44037 2.43464 5.00002 3.125 5.00002H3.75V16.25C3.75 17.6307 4.86929 18.75 6.25 18.75H13.75C15.1307 18.75 16.25 17.6307 16.25 16.25V5.00002H16.875C17.5654 5.00002 18.125 4.44037 18.125 3.75002V2.50002C18.125 1.80966 17.5654 1.25002 16.875 1.25002H12.5C12.5 0.559659 11.9404 1.52588e-05 11.25 1.52588e-05H8.75C8.05964 1.52588e-05 7.5 0.559659 7.5 1.25002H3.125ZM6.875 6.25002C7.22018 6.25002 7.5 6.52984 7.5 6.87502V15.625C7.5 15.9702 7.22018 16.25 6.875 16.25C6.52982 16.25 6.25 15.9702 6.25 15.625L6.25 6.87502C6.25 6.52984 6.52982 6.25002 6.875 6.25002ZM10 6.25002C10.3452 6.25002 10.625 6.52984 10.625 6.87502V15.625C10.625 15.9702 10.3452 16.25 10 16.25C9.65482 16.25 9.375 15.9702 9.375 15.625V6.87502C9.375 6.52984 9.65482 6.25002 10 6.25002ZM13.75 6.87502C13.75 6.52984 13.4702 6.25002 13.125 6.25002C12.7798 6.25002 12.5 6.52984 12.5 6.87502V15.625C12.5 15.9702 12.7798 16.25 13.125 16.25C13.4702 16.25 13.75 15.9702 13.75 15.625V6.87502Z"
																fill="currentColor"
															/>
														</svg>
													</div>
													<div
														@click.stop="
															openModal(item)
														"
														class="
															text-yellow-600
															hover:text-yellow-700
															active:text-yellow-500
															cursor-pointer
														"
													>
														<svg
															width="20"
															height="20"
															viewBox="0 0 20 20"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M15.4042 8.49713L17.1378 6.76355L17.1378 6.76354L17.1378 6.76351C17.6369 6.26443 17.8864 6.01488 18.027 5.74971C18.3376 5.1638 18.3376 4.46201 18.027 3.8761C17.8864 3.61092 17.6369 3.36137 17.1378 2.86226C16.6387 2.36315 16.3891 2.11359 16.1239 1.97301C15.538 1.66238 14.8362 1.66238 14.2503 1.97301C13.9851 2.11359 13.7356 2.36315 13.2365 2.86226L11.4807 4.61798C12.4199 6.22854 13.7721 7.57038 15.4042 8.49713ZM10.0263 6.07245L3.36428 12.7344C2.93922 13.1595 2.72669 13.372 2.58696 13.6331C2.44722 13.8942 2.38828 14.189 2.27039 14.7784L1.67967 17.732C1.61314 18.0646 1.57988 18.2309 1.67449 18.3255C1.7691 18.4201 1.9354 18.3869 2.26801 18.3204L5.22161 17.7296C5.81107 17.6117 6.10579 17.5528 6.36689 17.4131C6.62799 17.2733 6.84052 17.0608 7.26558 16.6357L13.946 9.95537C12.3648 8.96412 11.0271 7.63556 10.0263 6.07245Z"
																fill="currentColor"
															/>
														</svg>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				v-else
				class="w-full h-full flex items-center justify-center flex-col"
			>
				<img
					src="@/assets/images/illustrations/not_found.png"
					alt=""
					height="250"
					width="250"
				/>
				<div
					class="text-neutral-500 text-lg text-center"
					style="max-width: 700px"
				>
					Không có ngày nghỉ lễ
				</div>
			</div>
		</div>
		<div v-else class="fixed top-1/2 left-1/2">
			<Loading />
		</div>
	</div>
</template>

<script>
import { $api } from '@/services';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame';
import CreateHoliday from '@/views/holidays/features/CreateHoliday';
import DeleteHoliday from '@/views/holidays/features/DeleteHoliday';
import Loading from '@/components/common/Loading';

export default {
	name: 'Holidays',
	components: { Loading, DeleteHoliday, CreateHoliday, DaModalFrame },
	data() {
		return {
			params: {},
			holidays: [],
			modalIsOpen: false,
			data: null,
			deleteData: null,
			isLoading: true,
		};
	},

	created() {
		this.fetchHolidays();
	},

	methods: {
		async fetchHolidays() {
			try {
				const {
					data: { data },
				} = await $api.schedule.getHolidays(this.params);
				this.holidays = data;
			} catch (e) {
				console.log(e);
			} finally {
				this.isLoading = false;
			}
		},

		openModal(data) {
			this.modalIsOpen = true;
			this.data = data;
		},

		openModalDelete(data) {
			this.modalIsOpen = true;
			this.deleteData = data;
		},

		close() {
			this.modalIsOpen = false;
			this.data = null;
			this.deleteData = null;
		},
	},
};
</script>
