<template>
	<div v-if="isClassed" :class="isOpenModal ? 'side-over--active' : ''">
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			v-if="isOpenModal"
			@click="isOpenModal = false"
		></div>
		<CreateTimeSlot
			@close="isOpenModal = false"
			@success="successCreateUnSchedule"
		/>
	</div>
	<div
		v-else-if="isGroupClassed"
		:class="isOpenModal ? 'side-over--active' : ''"
	>
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			v-if="isOpenModal"
			@click="isOpenModal = false"
		></div>
		<CreateTimeSlotGroupClass
			@close="isOpenModal = false"
			@success="successCreateUnSchedule"
		/>
	</div>
	<div v-else :class="isOpenModal ? 'side-over--active' : ''">
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			v-if="isOpenModal"
			@click="isOpenModal = false"
		></div>
		<CreateUnScheduleSession
			@success="successCreateUnSchedule"
			@close="isOpenModal = false"
			@submit="confirm"
			:is-classed="isClassed"
		/>
	</div>
	<div class="__record px-10 pb-30 md-down:px-4">
		<div
			class="
				__record__header
				pt-10
				pb-10
				flex flex-nowrap
				justify-between
				items-center
				gap-4
			"
		>
			<div
				class="
					__record__title
					text-neutral-500
					font-semibold
					whitespace-nowrap
					flex flex-nowrap
					items-center
					text-4xl
				"
			>
				<svg
					class="mr-3 h-6 w-6"
					viewBox="0 0 28 28"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M7 0.875C7 0.39175 6.60825 0 6.125 0C5.64175 0 5.25 0.39175 5.25 0.875V1.75H3.5C1.567 1.75 0 3.317 0 5.25V7H28V5.25C28 3.317 26.433 1.75 24.5 1.75H22.75V0.875C22.75 0.39175 22.3582 0 21.875 0C21.3918 0 21 0.39175 21 0.875V1.75H7V0.875ZM28 24.5V8.75H0V24.5C0 26.433 1.567 28 3.5 28H24.5C26.433 28 28 26.433 28 24.5ZM16.625 12.25C16.1418 12.25 15.75 12.6418 15.75 13.125V14.875C15.75 15.3582 16.1418 15.75 16.625 15.75H18.375C18.8582 15.75 19.25 15.3582 19.25 14.875V13.125C19.25 12.6418 18.8582 12.25 18.375 12.25H16.625ZM21.875 12.25C21.3918 12.25 21 12.6418 21 13.125V14.875C21 15.3582 21.3918 15.75 21.875 15.75H23.625C24.1082 15.75 24.5 15.3582 24.5 14.875V13.125C24.5 12.6418 24.1082 12.25 23.625 12.25H21.875ZM3.5 18.375C3.5 17.8918 3.89175 17.5 4.375 17.5H6.125C6.60825 17.5 7 17.8918 7 18.375V20.125C7 20.6082 6.60825 21 6.125 21H4.375C3.89175 21 3.5 20.6082 3.5 20.125V18.375ZM9.625 17.5C9.14175 17.5 8.75 17.8918 8.75 18.375V20.125C8.75 20.6082 9.14175 21 9.625 21H11.375C11.8582 21 12.25 20.6082 12.25 20.125V18.375C12.25 17.8918 11.8582 17.5 11.375 17.5H9.625Z"
						fill="#65758B"
					/>
				</svg>
				<span class="mt-1 ml-2">Quản lý chuyên cần</span>
			</div>
			<div class="md:hidden cursor-pointer" @click="isOpenModal = true">
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12.75 6.75C12.75 6.33579 12.4142 6 12 6C11.5858 6 11.25 6.33579 11.25 6.75V11.25H6.75C6.33579 11.25 6 11.5858 6 12C6 12.4142 6.33579 12.75 6.75 12.75H11.25V17.25C11.25 17.6642 11.5858 18 12 18C12.4142 18 12.75 17.6642 12.75 17.25V12.75H17.25C17.6642 12.75 18 12.4142 18 12C18 11.5858 17.6642 11.25 17.25 11.25H12.75V6.75Z"
						fill="#94A3B8"
					/>
				</svg>
			</div>
			<div class="flex items-center justify-end gap-4 md-down:hidden">
				<button
					type="button"
					class="
						button-p
						text-center
						whitespace-nowrap
						bg-white
						hover:bg-white
					"
					@click="isOpenModal = true"
				>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
							fill="#48566A"
						/>
					</svg>
					<span class="ml-2">Tạo buổi học phát sinh</span>
				</button>
			</div>
		</div>
		<div
			class="
				flex
				items-start
				justify-between
				gap-8
				md-down:flex-col-reverse
			"
		>
			<div class="w-full">
				<div class="border-b border-neutral-200 md-down:hidden">
					<nav class="-mb-px flex space-x-8 tabs" aria-label="Tabs">
						<router-link
							v-for="tab in tabsMenu"
							:key="tab.id"
							:to="tab.link"
							class="
								border-transparent
								text-neutral-500
								hover:text-yellow-700 hover:border-yellow-500
								whitespace-nowrap
								py-3
								px-1
								border-b-2
								font-semibold
							"
						>
							<div class="flex items-center gap-2">
								<div>
									<DaIcon :name="tab.icon" class="h-4 w-4" />
								</div>
								<div>{{ tab.name }}</div>
							</div>
						</router-link>
					</nav>
				</div>
				<div class="md:hidden">
					<div class="p-6 bg-white select-tabs">
						<Multiselect
							v-model="tab"
							deselect-label="Đã chọn"
							track-by="id"
							:options="tabsMenu"
							:searchable="true"
							label="nameSearch"
							:allow-empty="false"
							selectLabel=""
							@select="tabSelectEvent"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									<DaIcon
										:name="props.option.icon"
										class="h-3.5 w-3.5 mr-4"
									/>
									<div>
										{{ props.option.name }}
									</div>
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
										text-yellow-700
									"
								>
									<DaIcon
										:name="option.icon"
										class="h-3.5 w-3.5 mr-4"
									/>
									<div>
										{{ option.name }}
									</div>
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<div>
					<router-view :key="time" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DaIcon from '@/components/elements/daIcon/DaIcon';
import CreateUnScheduleSession from '@/views/sessionManagement/features/createUnScheduleSession';
import { _ROUTER_NAME } from '@/enums';
import CreateTimeSlot from '@/views/timeSlot/features/CreateTimeSlot';
import CreateTimeSlotGroupClass from '@/views/sessionManagement/features/CreateTimeSlotGroupClass.vue';
import Multiselect from '@suadelabs/vue3-multiselect';

export default {
	name: 'SessionManagement',
	components: {
		Multiselect,
		CreateTimeSlotGroupClass,
		CreateTimeSlot,
		CreateUnScheduleSession,
		DaIcon,
	},

	data() {
		return {
			isOpenModal: false,
			issConfirmCreateUnSchedule: false,
			time: 0,
			tab: null,
		};
	},

	created() {
		this.$router.push(this.tabsMenu[0].link);
		this.tab = this.tabsMenu[0];
	},

	computed: {
		isClassed() {
			return [
				_ROUTER_NAME.SCHEDULE_TIMESLOT,
				_ROUTER_NAME.SCHEDULE_TIMESLOT__CLASS,
				_ROUTER_NAME.SESSION_MANAGEMENT,
				_ROUTER_NAME.SESSION_MANAGEMENT__CLASS,
			].includes(this.$route.name);
		},

		isGroupClassed() {
			return [_ROUTER_NAME.SESSION_MANAGEMENT__GROUP_CLASS].includes(
				this.$route.name
			);
		},

		org() {
			return this.$store.state.profile.current_org;
		},

		tabsMenu() {
			const tabs = [];
			if (this.org.enable_personal_schedule) {
				tabs.push({
					id: 0,
					link: '/session-management',
					name: 'Học cá nhân',
					icon: 'das--person',
				});
			}
			if (
				this.org.has_day_care &&
				this.org.exists_day_care_class &&
				this.org.enable_day_care_class_schedule
			) {
				tabs.push({
					id: 1,
					link: '/session-management/daycare',
					name: 'Học bán trú',
					// eslint-disable-next-line no-mixed-spaces-and-tabs
					icon: 'das--spoon',
					// eslint-disable-next-line no-mixed-spaces-and-tabs
				});
			}
			tabs.push({
				id: 2,
				link: '/session-management/group-class',
				name: 'Lớp nhóm',
				// eslint-disable-next-line no-mixed-spaces-and-tabs
				icon: 'das--users',
				// eslint-disable-next-line no-mixed-spaces-and-tabs
			});
			return tabs;
		},
	},

	watch: {
		tab: {
			deep: true,
			handler(value) {
				if (value) {
					this.$router.push({
						path: value.link,
						query: { ...this.$route.query },
					});
				}
			},
		},

		$route: {
			immediate: true,
			deep: true,
			handler(value) {
				const path = value?.path;
				this.tab = this.tabsMenu.find((item) => item.link === path);
			},
		},
	},

	methods: {
		confirm() {
			this.issConfirmCreateUnSchedule = true;
		},

		successCreateUnSchedule() {
			this.issConfirmCreateUnSchedule = false;
			this.isOpenModal = false;
			this.time++;
		},

		tabSelectEvent(selectedOption) {
			this.$router.push(selectedOption.link);
		},
	},
};
</script>
<style scoped lang="scss">
.tabs {
	.router-link-exact-active {
		@apply text-yellow-700 border-yellow-500;
	}
}
</style>
