<template>
	<div class="_modal bg-white">
		<div
			class="
				_modal__content
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<img
					src="@/assets/images/illustrations/warning.png"
					alt="Luca Education"
					title="Luca Education"
					height="60"
					width="60"
				/>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
					text-center
				"
			>
				Bạn không thể làm báo cáo tháng
			</div>
			<div v-if="isGroup" class="text-center text-neutral-500 mt-2">
				Lớp nhóm này đang có
				{{ data?.length }} buổi học lớp nhóm đang lưu bản nháp. Bạn cần
				hoàn thành các báo cáo buổi học này để có thể bắt đầu làm Báo
				cáo tháng.
			</div>
			<div v-else class="text-center text-neutral-500 mt-2">
				Học sinh này đang có
				{{ data?.length }} buổi học cá nhân đang lưu bản nháp. Bạn cần
				hoàn thành các báo cáo buổi học này để có thể bắt đầu làm Báo
				cáo tháng.
			</div>
			<div class="w-full mt-5">
				<div class="text-neutral-500 w-full">
					<span class="font-bold"
						>Danh sách báo cáo đang lưu nháp gồm:</span
					>
					<div
						v-for="(item, index) in data"
						:key="index"
						class="flex items-start justify-between w-full mt-2"
					>
						<div>
							•Ngày
							<span v-if="item?.date">{{
								$filters.formatDate2(item?.date)
							}}</span>
						</div>
						<div class="flex items-center gap-2">
							<div>Giáo viên:</div>
							<div
								v-for="staff in item.staffs"
								:key="staff.id"
								class="font-bold text-blue-600"
								@click="
									$router.push(`/record/employee/${staff.id}`)
								"
							>
								{{ staff.name }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="$emit('close')"
			>
				Thoát
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Modal',

	props: {
		data: {
			type: Array,
			required: true,
		},

		isGroup: {
			type: Boolean,
			default: false,
		},
	},
};
</script>
