
import { Options, setup, Vue } from 'vue-class-component';
import SwitchButton from '@/components/elements/switchButton/SwitchButton.vue';
import { Prop, Watch } from 'vue-property-decorator';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import PopupConfirm from '@/views/setupCenter/features/schedule/PopupConfirm.vue';
import { useStore } from 'vuex';
import router from '@/router';

@Options({
	name: 'Schedule',
	methods: {
		router() {
			return router;
		},
	},
	components: { PopupConfirm, DaModalFrame, SwitchButton },
})
export default class Schedule extends Vue {
	@Prop() org!: any;
	@Prop() timetable: any;
	@Prop() student_count: any;

	enable_schedule = true;
	enable_personal_schedule = true;
	enable_day_care_class_schedule = true;
	enable_report_by_session = true;
	onChange = false;
	onWatchOrg = true;
	key = '';
	step = 1;

	modalIsOpen = false;
	setup = setup(() => {
		const store = useStore();
		return {
			store,
		};
	});

	get isOff(): boolean {
		switch (this.key) {
			case 'enable_personal_schedule':
				return this.enable_personal_schedule;
			case 'enable_day_care_class_schedule':
				return this.enable_day_care_class_schedule;
			case 'enable_report_by_session':
				return this.enable_report_by_session;
			default:
				return this.enable_schedule;
		}
	}

	changeStep(step: number) {
		this.$router.push({ query: { step } });
	}

	@Watch('$route.query', { immediate: true, deep: true })
	onRouteChange(value: any) {
		if (!value?.step || !this.timetable) {
			this.step = 1;
			this.$router.push({ query: { step: 1 } });
			return;
		}

		if (value?.step === '2') {
			this.step = 2;
		} else {
			this.step = 1;
		}
	}

	@Watch('org', { deep: true, immediate: true })
	onOrgChange(org: any) {
		if (org && this.onWatchOrg) {
			this.enable_personal_schedule = org?.enable_personal_schedule;
			this.enable_day_care_class_schedule =
				org?.enable_day_care_class_schedule;
			this.enable_report_by_session = org?.enable_report_by_session;
			this.enable_schedule =
				org?.enable_personal_schedule || org?.enable_report_by_session;

			setTimeout(() => {
				this.onChange = true;
			}, 1000);
			this.onWatchOrg = false;
		}
	}

	openConfirm(key: string) {
		this.key = key;
		this.modalIsOpen = true;
	}

	onSubmit() {
		switch (this.key) {
			case 'enable_personal_schedule':
				this.enable_personal_schedule = !this.enable_personal_schedule;
				this.$emit('updateOrg', {
					enable_personal_schedule: this.enable_personal_schedule,
				});
				break;
			case 'enable_day_care_class_schedule':
				this.enable_day_care_class_schedule =
					!this.enable_day_care_class_schedule;
				this.$emit('updateOrg', {
					enable_day_care_class_schedule:
						this.enable_day_care_class_schedule,
				});
				break;
			case 'enable_report_by_session':
				this.enable_report_by_session = !this.enable_report_by_session;
				this.$emit('updateOrg', {
					enable_report_by_session: this.enable_report_by_session,
				});
				break;
			default:
				this.enable_schedule = !this.enable_schedule;
				this.enable_personal_schedule = this.enable_schedule;
				this.enable_report_by_session = this.enable_schedule;
				this.$emit('updateOrg', {
					enable_personal_schedule: this.enable_personal_schedule,
					enable_day_care_class_schedule:
						this.enable_day_care_class_schedule,
					enable_report_by_session: this.enable_report_by_session,
				});
		}

		this.cancel();

		if (!this.enable_personal_schedule) {
			this.step = 1;
			this.$router.push({ query: { step: 1 } });
		}
	}

	cancel() {
		this.modalIsOpen = false;
	}
}
