<template>
	<div class="_modal bg-white">
		<div
			class="
				_modal__content
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<svg
					width="60"
					height="60"
					viewBox="0 0 60 60"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M29.8034 3.38292C15.2228 3.38292 3.36674 15.3238 3.36674 30.001C3.36674 44.6762 15.2228 56.6171 29.8034 56.6171C44.3839 56.6171 56.2452 44.6762 56.2452 30.001C56.2452 15.3238 44.3839 3.38292 29.8034 3.38292ZM29.8034 60C13.3737 60 0 46.5418 0 30.001C0 13.4582 13.3737 0 29.8034 0C46.2382 0 59.6068 13.4582 59.6068 30.001C59.6068 46.5418 46.2382 60 29.8034 60Z"
						fill="#F4C870"
					/>
					<path
						d="M52.5551 30.0008C52.5551 42.6491 42.3697 52.9023 29.8011 52.9023C17.2376 52.9023 7.05225 42.6491 7.05225 30.0008C7.05225 17.3515 17.2376 7.09775 29.8011 7.09775C42.3697 7.09775 52.5551 17.3515 52.5551 30.0008Z"
						fill="#F4C870"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M42.5165 30.0002C42.5165 37.0694 36.8237 42.8002 29.8013 42.8002C22.7789 42.8002 17.0861 37.0694 17.0861 30.0002C17.0861 22.931 22.7789 17.2002 29.8013 17.2002C36.8237 17.2002 42.5165 22.931 42.5165 30.0002ZM25.6771 26.8525C25.5407 26.8525 25.4287 26.7425 25.4325 26.6061C25.5093 23.7795 27.7597 22.8002 29.8099 22.8002C32.0294 22.8002 34.0573 23.9682 34.0573 26.3854C34.0573 28.1119 33.0484 28.9346 32.0799 29.676C30.9096 30.5697 30.4757 30.9049 30.4757 32.0525V32.3713C30.4757 32.5094 30.3638 32.6213 30.2257 32.6213H28.644C28.5073 32.6213 28.3959 32.5114 28.394 32.3747L28.3873 31.8799C28.3268 30.3971 29.1743 29.483 30.2437 28.701C31.182 27.99 31.7772 27.5229 31.7772 26.5072C31.7772 25.1869 30.7784 24.6385 29.6888 24.6385C28.3532 24.6385 27.6315 25.477 27.5398 26.6027C27.5285 26.7403 27.4178 26.8525 27.2797 26.8525H25.6771ZM29.5173 37.1611C28.5891 37.1611 27.9131 36.5314 27.9131 35.6783C27.9131 34.7947 28.5891 34.1752 29.5173 34.1752C30.4858 34.1752 31.1517 34.7947 31.1517 35.6783C31.1517 36.5314 30.4858 37.1611 29.5173 37.1611Z"
						fill="white"
					/>
				</svg>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
					text-center
				"
			>
				Khôi phục buổi học
			</div>
			<div class="w-full mt-5 text-center text-gray-500">
				Bạn chắc chắn muốn khôi phục lại buổi học này?
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="$emit('close')"
			>
				Quay lại
			</button>
			<button
				type="button"
				class="button-p text-center whitespace-nowrap"
				@click="restoreCancelSession"
				:disabled="inProgress"
			>
				Xác nhận
			</button>
		</div>
	</div>
</template>

<script>
import { $api } from '@/services';

export default {
	name: 'RestoreCancelSessionGroupClass',

	props: {
		data: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			inProgress: false,
		};
	},

	methods: {
		async restoreCancelSession() {
			this.inProgress = true;
			try {
				await $api.groupClasses.uncancelGroupSession(this.data.id);
				await this.$store.dispatch('setAlertMessage', {
					message: 'Khôi phục buổi học thành thành công',
				});
				this.$emit('success');
			} catch (e) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},
	},
};
</script>
