<template>
	<DaModalFrame v-model:open="modalIsOpen">
		<UploadAvatar
			v-model:data="avatar"
			@upload="upload"
			@close="close"
			v-if="modalType === 'UPLOAD'"
		/>
		<InviteParentPopup
			v-else-if="modalType === 'INVITING' && studentData.full_name"
			@close="close"
			@invited="invited"
			:student-name="studentData.full_name"
		/>
		<ChangeStatus
			v-else
			:active-mode="isActiveMode"
			:successfully="success"
			@close="close"
			:staff="studentData"
			@block="(data) => closeStudent(false, data)"
			@active="() => closeStudent(true)"
		/>
	</DaModalFrame>
	<input type="file" hidden id="upload-avatar-1" @change="uploadChange" />
	<div v-if="!isLoading" class="grid grid-cols-2 gap-10 lg-down:grid-cols-1">
		<div>
			<div>
				<div
					class="bg-white rounded-2xl shadow-sm overflow-hidden"
					v-if="!editInfoMode"
				>
					<div
						class="
							__data__header
							p-6
							flex
							items-center
							justify-between
						"
					>
						<div class="text-neutral-600 font-semibold">
							Thông tin cá nhân
						</div>
						<DaIcon
							v-if="
								isAdmin &&
								!is_old_data &&
								['pending', 'active'].includes(student.state)
							"
							name="das--pen"
							class="
								h-5
								w-5
								cursor-pointer
								text-blue-600
								hover:text-blue-900
								active:text-blue-500
							"
							@click="editInfoMode = true"
						/>
					</div>
					<div class="__data__content text-neutral-800">
						<div
							class="
								p-4
								flex
								items-center
								justify-center
								flex-col
							"
						>
							<img
								class="
									shadow-sm
									inline-block
									h-20
									w-20
									rounded-full
								"
								:src="studentData?.profile_photo?.w200"
								alt=""
								v-if="studentData?.profile_photo?.w200"
							/>
							<span
								v-else
								class="
									shadow-sm
									inline-flex
									items-center
									justify-center
									h-20
									w-20
									rounded-full
								"
								:style="{
									background:
										studentData.profile_photo?.default
											?.color,
								}"
							>
								<span
									class="
										font-medium
										leading-none
										text-white text-4xl
									"
									>{{
										studentData.profile_photo?.default
											?.initial
									}}</span
								>
							</span>
							<div
								v-if="
									!isTeacherAndAccountant &&
									!is_old_data &&
									student.state === 'active'
								"
								class="
									mt-6
									text-blue-700
									font-semibold
									text-sm
									cursor-pointer
								"
								@click="openModelUpload"
							>
								Thay ảnh đại diện
							</div>
						</div>
						<div class="grid grid-cols-2 px-6 py-4">
							<div
								class="
									text-neutral-400
									font-semibold
									uppercase
									text-xs
								"
							>
								Họ và tên
							</div>
							<div v-if="studentData?.full_name">
								{{ studentData?.full_name }}
							</div>
						</div>
						<div class="grid grid-cols-2 px-6 py-4" v-if="isAdmin">
							<div
								class="
									text-neutral-400
									font-semibold
									uppercase
									text-xs
								"
							>
								Ngày sinh
							</div>
							<div v-if="studentData.dob">
								{{ $filters.formatDate2(studentData.dob) }}
							</div>
						</div>
						<div class="grid grid-cols-2 px-6 py-4" v-if="isAdmin">
							<div
								class="
									text-neutral-400
									font-semibold
									uppercase
									text-xs
								"
							>
								Giới tính
							</div>
							<div v-if="studentData?.gender">
								{{
									studentData?.gender === 'male'
										? 'Nam'
										: 'Nữ'
								}}
							</div>
						</div>
						<div class="bg-neutral-50" v-if="isAdmin">
							<div
								class="
									py-6
									pl-8
									pr-6
									font-semibold
									text-gray-600
								"
							>
								Thông tin nội bộ
							</div>
							<div class="grid grid-cols-2 px-6 py-4">
								<div
									class="
										text-neutral-400
										font-semibold
										uppercase
										text-xs
									"
								>
									Tên hiển thị
								</div>
								<div v-if="studentData?.name">
									{{ studentData?.name }}
								</div>
							</div>
							<div class="grid grid-cols-2 px-6 py-4">
								<div
									class="
										text-neutral-400
										font-semibold
										uppercase
										text-xs
									"
								>
									Biệt danh
								</div>
								<div v-if="studentData?.nickname">
									{{ studentData?.nickname }}
								</div>
							</div>
							<div
								class="grid grid-cols-2 px-6 py-4 bg-neutral-50"
							>
								<div
									class="
										text-neutral-400
										font-semibold
										uppercase
										text-xs
									"
								>
									Tuổi
								</div>
								<div v-if="studentData?.age">
									{{ studentData?.age }}
								</div>
							</div>
							<div class="grid grid-cols-2 px-6 py-4">
								<div
									class="
										text-neutral-400
										font-semibold
										uppercase
										text-xs
									"
								>
									Ngày nhập học
								</div>
								<div v-if="studentData?.start_date">
									{{
										$filters.formatDate2(
											studentData?.start_date
										)
									}}
								</div>
							</div>
							<div class="grid grid-cols-2 px-6 py-4">
								<div
									class="
										text-neutral-400
										font-semibold
										uppercase
										text-xs
									"
								>
									Địa chỉ
								</div>
								<div v-if="studentData?.address">
									{{ studentData?.address }}
								</div>
							</div>
							<div class="px-6 py-4">
								<div
									class="
										text-neutral-400
										font-semibold
										uppercase
										text-xs
									"
								>
									Ghi chú
								</div>
								<div>
									<div class="text-neutral-400 text-sm">
										(Nội dung này sẽ được ẩn trên app phụ
										huynh)
									</div>
									<div class="mt-2">
										{{ studentData?.note || '-' }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					class="__data bg-white rounded-2xl shadow-sm"
					v-if="editInfoMode && isAdmin"
				>
					<div
						class="
							__data__header
							p-6
							flex
							items-center
							justify-between
						"
					>
						<div class="text-neutral-600 font-semibold">
							Thông tin cá nhân
						</div>
					</div>
					<div class="__data__content text-neutral-800">
						<div
							class="
								p-4
								flex
								items-center
								justify-center
								flex-col
							"
						>
							<img
								class="
									shadow-sm
									inline-block
									h-20
									w-20
									rounded-fullp-2
								"
								:src="studentData?.profile_photo?.w200"
								alt=""
								v-if="studentData?.profile_photo?.w200"
							/>
							<span
								v-else
								class="
									shadow-sm
									inline-flex
									items-center
									justify-center
									h-20
									w-20
									rounded-full
								"
								:style="{
									background:
										studentData.profile_photo?.default
											?.color,
								}"
							>
								<span
									class="
										font-medium
										leading-none
										text-white text-4xl
									"
									>{{
										studentData.profile_photo?.default
											?.initial
									}}</span
								>
							</span>
							<div
								class="
									mt-6
									text-blue-700
									font-semibold
									text-sm
									cursor-pointer
								"
								@click="openModelUpload"
							>
								Thay ảnh đại diện
							</div>
						</div>
						<div
							class="
								input-group
								flex-nowrap flex
								items-center
								px-6
								py-4
								md-down:flex-col
							"
						>
							<div
								class="
									input-group__label
									w-56
									md-down:w-full md-down:mb-4
									text-neutral-400
									font-semibold
									text-xs
									uppercase
								"
							>
								Họ và tên
							</div>
							<div class="w-full">
								<input
									type="text"
									placeholder="Nhập họ và tên"
									v-model="studentData.full_name"
									:class="
										error['full_name'] ? 'input--error' : ''
									"
								/>
							</div>
						</div>
						<div
							class="input-group flex items-center"
							v-if="error['full_name']"
						>
							<div class="px-6">
								<div class="error text-sienna-600 text-sm">
									{{ error['full_name'] }}
								</div>
							</div>
						</div>
						<div
							class="
								input-group
								flex-nowrap flex
								items-center
								px-6
								py-4
								md-down:flex-col
							"
						>
							<div
								class="
									input-group__label
									w-56
									text-neutral-400
									font-semibold
									text-xs
									uppercase
									md-down:w-full md-down:mb-4
								"
							>
								Tên hiển thị
							</div>
							<div class="w-full">
								<input
									type="text"
									placeholder="Nhập tên hiển thị"
									v-model="studentData.name"
									:class="error['name'] ? 'input--error' : ''"
								/>
							</div>
						</div>
						<div
							class="input-group flex items-center"
							v-if="error['name']"
						>
							<div class="px-6">
								<div class="error text-sienna-600 text-sm">
									{{ error['name'] }}
								</div>
							</div>
						</div>
						<div
							class="
								input-group
								flex
								items-center
								px-6
								py-4
								md-down:flex-col
							"
						>
							<div
								class="
									input-group__label
									text-neutral-400
									font-semibold
									text-xs
									uppercase
									w-56
									md-down:w-full md-down:mb-4
								"
							>
								Giới tính
							</div>
							<div class="input-group w-full">
								<div class="w-full">
									<select
										class="
											mt-1
											block
											w-full
											pl-3
											pr-10
											py-2
											text-base
											border-gray-300
											focus:outline-none
											focus:ring-indigo-500
											focus:border-indigo-500
											rounded-md
											input
											w-full
										"
										v-model="studentData.gender"
									>
										<option value="male">Nam</option>
										<option value="female">Nữ</option>
									</select>
								</div>
							</div>
						</div>
						<div
							class="
								input-group
								flex
								items-center
								px-6
								py-4
								md-down:flex-col
							"
						>
							<div
								class="
									input-group__label
									text-neutral-400
									font-semibold
									text-xs
									uppercase
									w-56
									md-down:w-full md-down:mb-4
								"
							>
								Ngày sinh
							</div>
							<div class="input-group w-full">
								<VueDatepicker
									class="input-date w-full"
									placeholder="Ngày sinh của học sinh"
									autoApply
									:enableTimePicker="false"
									uid="filter-by-date"
									format="dd/MM/yyyy"
									minutesIncrement="30"
									v-model="studentData.dob"
									:maxDate="new Date()"
								>
									<template #input-icon>
										<svg
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M5 0.625C5 0.279822 4.72018 0 4.375 0C4.02982 0 3.75 0.279822 3.75 0.625V1.25H2.5C1.11929 1.25 0 2.36929 0 3.75V5H20V3.75C20 2.36929 18.8807 1.25 17.5 1.25H16.25V0.625C16.25 0.279822 15.9702 0 15.625 0C15.2798 0 15 0.279822 15 0.625V1.25H5V0.625ZM20 17.5V6.25H0V17.5C0 18.8807 1.11929 20 2.5 20H17.5C18.8807 20 20 18.8807 20 17.5ZM11.875 8.75C11.5298 8.75 11.25 9.02982 11.25 9.375V10.625C11.25 10.9702 11.5298 11.25 11.875 11.25H13.125C13.4702 11.25 13.75 10.9702 13.75 10.625V9.375C13.75 9.02982 13.4702 8.75 13.125 8.75H11.875ZM15.625 8.75C15.2798 8.75 15 9.02982 15 9.375V10.625C15 10.9702 15.2798 11.25 15.625 11.25H16.875C17.2202 11.25 17.5 10.9702 17.5 10.625V9.375C17.5 9.02982 17.2202 8.75 16.875 8.75H15.625ZM2.5 13.125C2.5 12.7798 2.77982 12.5 3.125 12.5H4.375C4.72018 12.5 5 12.7798 5 13.125V14.375C5 14.7202 4.72018 15 4.375 15H3.125C2.77982 15 2.5 14.7202 2.5 14.375V13.125ZM6.875 12.5C6.52982 12.5 6.25 12.7798 6.25 13.125V14.375C6.25 14.7202 6.52982 15 6.875 15H8.125C8.47018 15 8.75 14.7202 8.75 14.375V13.125C8.75 12.7798 8.47018 12.5 8.125 12.5H6.875Z"
												fill="#94A3B8"
											/>
										</svg>
									</template>
								</VueDatepicker>
							</div>
						</div>
						<div
							class="
								input-group
								flex
								items-center
								px-6
								py-4
								md-down:flex-col
							"
						>
							<div
								class="
									input-group__label
									text-neutral-400
									font-semibold
									text-xs
									uppercase
									w-56
									md-down:w-full md-down:mb-4
								"
							>
								Ngày nhập học
							</div>
							<div class="input-group w-full">
								<VueDatepicker
									class="input-date w-full"
									placeholder="Ngày nhập học của học sinh"
									autoApply
									:enableTimePicker="false"
									uid="filter-by-date"
									format="dd/MM/yyyy"
									v-model="studentData.start_date"
								>
									<template #input-icon>
										<svg
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M5 0.625C5 0.279822 4.72018 0 4.375 0C4.02982 0 3.75 0.279822 3.75 0.625V1.25H2.5C1.11929 1.25 0 2.36929 0 3.75V5H20V3.75C20 2.36929 18.8807 1.25 17.5 1.25H16.25V0.625C16.25 0.279822 15.9702 0 15.625 0C15.2798 0 15 0.279822 15 0.625V1.25H5V0.625ZM20 17.5V6.25H0V17.5C0 18.8807 1.11929 20 2.5 20H17.5C18.8807 20 20 18.8807 20 17.5ZM11.875 8.75C11.5298 8.75 11.25 9.02982 11.25 9.375V10.625C11.25 10.9702 11.5298 11.25 11.875 11.25H13.125C13.4702 11.25 13.75 10.9702 13.75 10.625V9.375C13.75 9.02982 13.4702 8.75 13.125 8.75H11.875ZM15.625 8.75C15.2798 8.75 15 9.02982 15 9.375V10.625C15 10.9702 15.2798 11.25 15.625 11.25H16.875C17.2202 11.25 17.5 10.9702 17.5 10.625V9.375C17.5 9.02982 17.2202 8.75 16.875 8.75H15.625ZM2.5 13.125C2.5 12.7798 2.77982 12.5 3.125 12.5H4.375C4.72018 12.5 5 12.7798 5 13.125V14.375C5 14.7202 4.72018 15 4.375 15H3.125C2.77982 15 2.5 14.7202 2.5 14.375V13.125ZM6.875 12.5C6.52982 12.5 6.25 12.7798 6.25 13.125V14.375C6.25 14.7202 6.52982 15 6.875 15H8.125C8.47018 15 8.75 14.7202 8.75 14.375V13.125C8.75 12.7798 8.47018 12.5 8.125 12.5H6.875Z"
												fill="#94A3B8"
											/>
										</svg>
									</template>
								</VueDatepicker>
							</div>
						</div>
						<div
							class="
								input-group
								flex-nowrap flex
								items-center
								px-6
								py-4
								md-down:flex-col
							"
						>
							<div
								class="
									input-group__label
									w-56
									text-neutral-400
									font-semibold
									text-xs
									uppercase
									md-down:w-full md-down:mb-4
								"
							>
								Địa chỉ
							</div>
							<div class="w-full">
								<input
									type="text"
									placeholder="Địa chỉ"
									v-model="studentData.address"
									:class="
										error['address'] ? 'input--error' : ''
									"
								/>
							</div>
						</div>
						<div
							class="
								input-group
								flex-nowrap flex
								items-start
								px-6
								py-4
								md-down:flex-col
							"
						>
							<div
								class="
									input-group__label
									w-56
									md-down:w-full md-down:mb-4
								"
							>
								<div
									class="
										text-neutral-400
										font-semibold
										text-xs
										uppercase
									"
								>
									Ghi chú
								</div>
								<div
									class="
										text-neutral-400 text-sm
										font-normal
										w-36
										mt-1.5
									"
								>
									(Nội dung này sẽ <br />
									được ẩn trên app <br />
									phụ huynh)
								</div>
							</div>
							<div class="w-full">
								<textarea
									placeholder="Nhập ghi chú"
									v-model="studentData.note"
									:class="error['note'] ? 'input--error' : ''"
								/>
							</div>
						</div>
						<div
							class="
								p-6
								flex
								items-center
								justify-end
								border-t border-neutral-100
							"
						>
							<button
								type="button"
								class="button-p text-center w-32"
								@click="saveUpdateInfo"
							>
								Lưu chỉnh sửa
							</button>
						</div>
					</div>
				</div>
				<div
					v-if="
						student &&
						['active', 'pending'].includes(studentData.state) &&
						isAdmin
					"
					class="
						font-semibold
						text-yellow-700
						underline
						text-sm
						mt-6
						text-right
						cursor-pointer
					"
					@click="openModel()"
				>
					{{
						studentData.state === 'active'
							? 'Khoá tài khoản'
							: 'Thay Đổi Trạng Thái'
					}}
				</div>
			</div>
			<div
				class="shadow-sm sm:rounded-2xl overflow-hidden mt-8"
				v-if="dataTransfers.length"
			>
				<div class="p-6 bg-white font-semibold text-gray-600">
					Thông tin chuyển cơ sở
				</div>
				<div class="__record__table overflow-x-auto scroll-bar">
					<div class="overflow-hidden w-fit min-w-full">
						<table class="min-w-full divide-y text-neutral-200">
							<thead class="bg-neutral-100 h-16">
								<tr>
									<th
										scope="col"
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											w-16
											whitespace-nowrap
										"
									>
										STT
									</th>
									<th
										scope="col"
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Tên trung tâm
									</th>
									<th
										scope="col"
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Ngày bắt đầu
									</th>
									<th
										scope="col"
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Ngày kết thúc
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in dataTransfers"
									:key="index"
									class="
										text-neutral-700
										bg-white
										hover:bg-orange-50
										cursor-pointer
									"
								>
									<td>
										<div class="px-6 py-4 text-neutral-700">
											{{ index + 1 }}
										</div>
									</td>
									<td>
										<div
											class="
												px-6
												py-4
												whitespace-nowrap
												text-neutral-700
											"
										>
											{{ item?.org?.name }}
										</div>
									</td>
									<td>
										<div
											class="
												px-6
												py-4
												whitespace-nowrap
												text-neutral-700
											"
										>
											<span v-if="item.join_date">{{
												$filters.formatDate(
													item.join_date
												)
											}}</span
											><span v-else>-</span>
										</div>
									</td>
									<td>
										<div class="px-6 py-4 text-neutral-700">
											<span v-if="item.left_date">
												{{
													$filters.formatDate(
														item.left_date
													)
												}} </span
											><span v-else>-</span>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div>
			<div
				v-if="
					isAdmin && ['active', 'pending'].includes(studentData.state)
				"
			>
				<div
					v-if="
						parents && parents.length > 0 && studentData.full_name
					"
				>
					<ParentInfo
						v-for="(parent, index) in parents"
						:key="parent.id"
						:data="{
							...parent,
							studentName: studentData.full_name,
						}"
						:index="index"
						class="mb-8"
						@reNewCode="reNewCode"
						@closeParentInvitation="closeParentInvitation"
						@generateReport="generateReport"
					/>
					<div
						class="
							font-semibold
							text-yellow-700
							underline
							text-sm
							mt-6
							text-right
							cursor-pointer
						"
						@click="inviting"
					>
						Mời phụ huynh khác
					</div>
				</div>
				<InviteParent v-else @inviting="inviting" />
			</div>
			<div
				class="flex items-center justify-center p-6 h-full"
				v-if="!['active', 'pending'].includes(studentData.state)"
			>
				<div class="flex items-center justify-center flex-col">
					<DaIcon
						name="das--user-close"
						class="h-16 w-16 text-neutral-200"
					/>
					<div class="text-neutral-600 font-semibold mt-1">
						Tài khoản
						{{ studentStateShort(studentData.state).toLowerCase() }}
					</div>
					<div
						class="text-sm text-neutral-500 mt-1 text-center"
						v-if="isAdmin"
					>
						{{
							studentData.state !== 'transfered'
								? studentData.state === 'pending'
									? 'Bạn hãy kich hoạt tài khoản cho học sinh để đưa học sinh vào học'
									: studentData.state === 'graduated'
									? 'Học sinh này đã được đưa vào danh sách tốt nghiệp'
									: studentData.state === 'stopped'
									? 'Học sinh này đã xin nghỉ'
									: 'Bạn hãy kich hoạt tài khoản cho học sinh để xem thống kê nhé'
								: 'Học sinh này đã được chuyển tới cơ sở khác'
						}}
					</div>
					<button
						v-if="
							isAdmin &&
							[
								'closed',
								'transfered',
								'pending',
								'stopped',
								'graduated',
							].includes(studentData.state)
						"
						type="button"
						class="button-p w-fit mt-6"
						@click="openModel(true)"
					>
						Kích hoạt tài khoản
					</button>
				</div>
			</div>
		</div>
	</div>
	<div v-else class="fixed top-1/2 left-1/2">
		<Loading />
	</div>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import ChangeStatus from '@/views/record/employeeDetail/features/changeStatus/ChangeStatus.vue';
import { useStore } from 'vuex';
import { $api } from '@/services';
import UploadAvatar from '@/components/elements/uploadAvatar/UploadAvatar.vue';
import { useRoute } from 'vue-router';
import InviteParent from '@/views/record/studentDetail/features/inviteParent/InviteParent.vue';
import ParentInfo from '@/views/record/studentDetail/features/inviteParent/ParentInfo.vue';
import InviteParentPopup from '@/views/record/studentDetail/features/inviteParent/InviteParentPopup.vue';
import { generateReport } from '@/helper/generateReport';
import heic2any from 'heic2any';
import Loading from '@/components/common/Loading';
import { studentStateShort } from '@/helper/studentState';

export default defineComponent({
	name: 'StudentDetail_Info',
	props: {
		student: {
			type: Object,
			default() {
				return {
					id: '',
					dob: '',
					name: ' ',
					full_name: '',
					state: '',
					address: '',
					gender: '',
				};
			},
		},
		success: {
			type: Boolean,
			default() {
				return false;
			},
		},
		dataTransfers: {
			type: Array,
			required: true,
		},
	},
	components: {
		Loading,
		InviteParentPopup,
		ParentInfo,
		InviteParent,
		UploadAvatar,
		ChangeStatus,
		DaModalFrame,
		DaIcon,
	},

	data() {
		return {
			isGenerate: false,
		};
	},

	mounted() {
		this.isGenerate = true;
	},

	computed: {
		isTeacherAndAccountant() {
			return this.$store.getters.isTeacherAndAccountant;
		},
	},

	watch: {
		modalIsOpen(value) {
			if (!value) {
				this.modalType = '';
			}
		},
	},

	setup(props, { emit }) {
		let editInfoMode = ref(false);
		const store = useStore();
		let editMode = ref(false);
		let isLoading = ref(false);
		let studentData = ref({
			id: '',
			dob: '',
			name: ' ',
			full_name: '',
			state: '',
			gender: '',
			start_date: '',
			address: '',
		});
		const route = useRoute();

		const id = computed(() => String(route.params.id || ''));

		const modalIsOpen = ref(false);
		const modalType = ref('');
		const isActiveMode = ref(false);

		const error = ref({
			name: '',
			full_name: '',
			note: '',
			status: false,
		});

		const oldPayload = ref({
			'x-cross': route.query.org_id,
		});

		const is_old_data = ref(route.query.is_old_data === 'true');

		function openModel(isActive = false) {
			emit('openModal');
			isActiveMode.value = isActive;
			modalIsOpen.value = true;
		}

		function inviting() {
			modalIsOpen.value = true;
			modalType.value = 'INVITING';
		}

		function closeStudent(isActive = false, data) {
			emit('closeStudent', {
				isActive: isActive,
				id: studentData.value.id,
				state: data?.state?.id || undefined,
			});
			modalIsOpen.value = false;
		}

		function saveUpdateInfo() {
			error.value = {
				name: '',
				full_name: '',
				status: false,
			};
			if (studentData.value.full_name?.length <= 0) {
				error.value['full_name'] = 'Tên không được bỏ trống';
				error.value.status = true;
			}
			if (studentData.value.name?.length <= 0) {
				error.value['name'] = 'Tên hiển thị không được bỏ trống';
				error.value.status = true;
			}
			if (error.value.status) {
				return;
			}
			editInfoMode.value = false;
			emit('submit', studentData.value);
		}

		watch(
			() => props.student,
			(value) => {
				studentData.value = value;
			},
			{
				immediate: true,
				deep: true,
			}
		);

		let isAdmin = computed(() => store.getters.isAdmin);

		const avatar = ref('');

		function openModelUpload() {
			const target = document.getElementById('upload-avatar-1');
			target ? target.click() : '';
		}

		function uploadChange(event) {
			avatar.value = event.target.files[0];
			if (
				/image\/hei(c|f)/.test(event.target.files[0].type) ||
				event.target.files[0].name.endsWith('.heic') ||
				event.target.files[0].name.endsWith('.heif')
			) {
				heic2any({
					blob: event.target.files[0],
					toType: 'image/jpeg',
					quality: 1,
				}).then(async (jpgBlob) => {
					//Change the name of the file according to the new format
					let newName = event.target.files[0].name.replace(
						/\.[^/.]+$/,
						'.jpg'
					);

					//Convert blob back to file
					avatar.value = await blobToFile(jpgBlob, newName);

					modalIsOpen.value = true;
					modalType.value = 'UPLOAD';
				});
			} else {
				modalIsOpen.value = true;
				modalType.value = 'UPLOAD';
			}
		}

		function close() {
			avatar.value = '';
			const target = document.getElementById('upload-avatar-1');
			target ? (target.value = null) : '';
			modalIsOpen.value = false;
			modalType.value = '';
		}

		function upload(canvas) {
			canvas.toBlob(async (blob) => {
				try {
					let file = new File([blob], avatar.value.name, {
						type: blob.type,
					});
					let data = new FormData();
					data.append('profile_photo', file);
					const res = await $api.student.uploadProfilePicture(
						studentData.value.id || id.value,
						data
					);
					if (res) {
						await store.dispatch('setAlertMessage', {
							message: 'Cập nhật ảnh đại diện thành công',
						});
						emit('updated');
						close();
					}
				} catch (e) {
					await store.dispatch('setAlertMessage', {
						message: 'Cõ lỗi xảy ra, vui lòng thử lại.',
						type: 'danger',
					});
				}
			});
		}

		const parents = ref([]);

		async function fetchParents() {
			try {
				const { data } = await $api.student.parents(
					id.value,
					is_old_data.value ? oldPayload.value : null
				);
				parents.value = data.data;
			} catch (err) {
				console.log(err);
			}
		}

		async function reNewCode(value) {
			try {
				await $api.student.reNewInviteParent(
					value.student_id,
					value.id
				);
				await $api.student.sendEmailInviteParent(
					value.student_id,
					value.id
				);
				await store.dispatch('setAlertMessage', {
					message: 'Tạo mã liên kết mới thành công',
				});
				await fetchParents();
			} catch (err) {
				await store.dispatch('setAlertMessage', {
					message: 'Cõ lỗi xảy ra, vui lòng thử lại.',
					type: 'danger',
				});
			}
		}

		async function closeParentInvitation(value) {
			try {
				await $api.student.closeInviteParent(
					value.student_id,
					value.id
				);
				await store.dispatch('setAlertMessage', {
					message: 'Xoá thông tin Phụ huynh thành công',
				});
				await fetchParents();
			} catch (err) {
				await store.dispatch('setAlertMessage', {
					message: 'Cõ lỗi xảy ra, vui lòng thử lại.',
					type: 'danger',
				});
			}
		}

		async function invited() {
			await fetchParents();
		}

		function blobToFile(theBlob, fileName) {
			//A Blob() is almost a File() - it's just missing the two properties below which we will add

			theBlob.name = fileName;

			//Cast to a File() type
			return new File([theBlob], fileName, {
				lastModified: new Date().getTime(),
				lastModifiedDate: new Date(),
				type: theBlob.type,
			});
		}

		fetchParents();

		return {
			invited,
			openModelUpload,
			upload,
			close,
			uploadChange,
			reNewCode,
			closeParentInvitation,
			modalType,
			avatar,
			error,
			editMode,
			editInfoMode,
			studentData,
			modalIsOpen,
			isActiveMode,
			isAdmin,
			parents,
			saveUpdateInfo,
			openModel,
			closeStudent,
			inviting,
			isLoading,
			is_old_data,
		};
	},

	methods: {
		studentStateShort,
		generateReport,
	},
});
</script>

<style scoped></style>
