import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/images/illustrations/success.png'
import _imports_1 from '@/assets/images/illustrations/warning.png'


const _hoisted_1 = { class: "_create-boarding _modal bg-white" }
const _hoisted_2 = { class: "_modal__content md-down:px-4 px-12 py-6 flex flex-col items-center justify-center" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  alt: "Luca Education",
  title: "Luca Education",
  height: "60",
  width: "60"
}
const _hoisted_4 = {
  key: 1,
  src: _imports_1,
  alt: "Luca Education",
  title: "Luca Education",
  height: "60",
  width: "60"
}
const _hoisted_5 = { class: "_modal__content__title text-neutral-700 font-semibold text-2xl mt-5 text-center" }
const _hoisted_6 = { class: "text-center text-neutral-500 mt-2" }
const _hoisted_7 = { class: "w-full mt-5" }
const _hoisted_8 = { class: "input-group flex flex-nowrap items-center" }
const _hoisted_9 = { class: "input-group__label text-neutral-500 font-normal w-2/5" }
const _hoisted_10 = { class: "font-bold text-neutral-500 text-right" }
const _hoisted_11 = {
  key: 0,
  class: "px-12 mb-5 text-sienna-600 text-sm"
}
const _hoisted_12 = { class: "_modal__footer px-12 py-6 bg-neutral-50 flex flex-nowrap items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        (_ctx.createSuccessful)
          ? (_openBlock(), _createElementBlock("img", _hoisted_3))
          : (_openBlock(), _createElementBlock("img", _hoisted_4))
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.titleData), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.labelData), 1),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.textData), 1),
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.valueData), 1)
        ])
      ])
    ]),
    (_ctx.errorData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.errorData), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("button", {
        type: "button",
        class: "button-p text-center mr-3 bg-none shadow-none text-neutral-500 hover:text-neutral-700",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, _toDisplayString(_ctx.createSuccessful ? 'Đóng' : 'Thoát'), 1),
      (!_ctx.createSuccessful)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "button-p text-center whitespace-nowrap",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
          }, " Xác nhận "))
        : _createCommentVNode("", true)
    ])
  ]))
}