<template>
  <div class="_create-boarding _modal bg-white">
    <div
      class="
				_modal__content
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
    >
      <div>
        <svg fill="none" height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_29532_12533)">
            <path
              d="M29.8034 3.38292C15.2228 3.38292 3.36674 15.3238 3.36674 30.001C3.36674 44.6762 15.2228 56.6171 29.8034 56.6171C44.3839 56.6171 56.2452 44.6762 56.2452 30.001C56.2452 15.3238 44.3839 3.38292 29.8034 3.38292ZM29.8034 60C13.3737 60 0 46.5418 0 30.001C0 13.4582 13.3737 0 29.8034 0C46.2382 0 59.6068 13.4582 59.6068 30.001C59.6068 46.5418 46.2382 60 29.8034 60Z"
              fill="#E37056"/>
            <path
              d="M52.5551 30.0011C52.5551 42.6493 42.3697 52.9026 29.8011 52.9026C17.2376 52.9026 7.05225 42.6493 7.05225 30.0011C7.05225 17.3518 17.2376 7.09799 29.8011 7.09799C42.3697 7.09799 52.5551 17.3518 52.5551 30.0011Z"
              fill="#F4C870"/>
            <path clip-rule="evenodd"
                  d="M23.1788 27.4397V23.5997C23.1788 20.065 26.1437 17.2 29.8013 17.2C33.459 17.2 36.4239 20.0652 36.4239 23.5997V27.4397C38.6184 27.4397 40.3974 29.159 40.3974 31.2798V38.9599C40.3974 41.0809 38.6184 42.7999 36.4239 42.7999H23.1788C20.9843 42.7999 19.2053 41.0809 19.2053 38.9599V31.2798C19.2053 29.159 20.9843 27.4397 23.1788 27.4397ZM33.7748 27.4397V23.5997C33.7748 21.4789 31.9958 19.7596 29.8013 19.7596C27.6069 19.7596 25.8279 21.4789 25.8279 23.5997V27.4397H33.7748ZM29.8013 32.5599C30.5329 32.5599 31.1258 33.1329 31.1258 33.8399V36.4C31.1258 37.107 30.5329 37.6799 29.8013 37.6799C29.0698 37.6799 28.4769 37.107 28.4769 36.4V33.8399C28.4769 33.1329 29.0698 32.5599 29.8013 32.5599Z"
                  fill="white"
                  fill-rule="evenodd"/>
          </g>
          <defs>
            <clipPath id="clip0_29532_12533">
              <rect fill="white" height="60" width="60"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div
        class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
					text-center
				"
      >
        Bạn có muốn khoá lớp nhóm này?
      </div>
      <div class="text-center text-neutral-500 mt-2">
        Lớp nhóm này sẽ được khoá, để có thể sử dụng lại bạn phải mở lại lớp nhóm. Lớp nhóm đã khoá sẽ không tạo báo cáo
        hay đếm giờ học nhóm cho Trị liệu viên và Học sinh
      </div>
    </div>
    <div
      class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
    >
      <button
        class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
        type="button"
        @click="$emit('close')"
      >
        Huỷ
      </button>
      <button
        :disabled="inProgress"
        class="button-p text-center whitespace-nowrap"
        type="button"
        @click="$emit('submit')"
      >
        Xác nhận
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockGroupClass',

  props: {
    inProgress: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
