
import { Options, Vue } from 'vue-class-component';
import { Prop, PropSync, Watch } from 'vue-property-decorator';

@Options({
	name: 'UpdateGroupClass',
})
export default class UpdateGroupClass extends Vue {
	@PropSync('error', { default: '' })
	error!: any;

	@PropSync('inProgress', { default: false, type: Boolean })
	inProgress!: boolean;

	@Prop('data') data!: any;

	errorMes = {
		name: '',
		status: true,
	};

	groupData = {
		name: '',
	};

	@Watch('error')
	onErrorPropsChange(value: any) {
		if (value && value['name']) {
			this.errorMes['name'] =
				'Tên lớp nhóm mới không được trùng với tên lớp nhóm đã có trước đó.';
		}
	}

	@Watch('data', {
		immediate: true,
		deep: true,
	})
	onDataChange(value: any) {
		this.groupData.name = value?.name || '';
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	submit() {
		this.errorMes = {
			name: '',
			status: true,
		};
		if (this.groupData['name']?.length <= 0) {
			this.errorMes['name'] = 'Tên lớp nhóm không được bỏ trống';
			this.errorMes.status = true;
			setTimeout(() => {
				this.errorMes = {
					name: '',
					status: true,
				};
			}, 3000);
		}
		this.$emit('submit', this.groupData);
	}
}
