<template>
	<div v-if="!isLoading">
		<div
			v-if="goals && goals.length > 0"
			class="p-6 bg-white shadow-sm rounded-2xl mb-10"
		>
			<div>
				<div class="flex items-center justify-between mb-8">
					<div class="font-semibold text-neutral-600">
						Thống kê chung
					</div>
					<button
						type="button"
						class="
							button-p
							text-center
							bg-none
							shadow-none
							flex
							items-center
							justify-center
							btn--text
							w-fit
						"
						v-if="false"
					>
						<svg
							class="mr-2"
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M12.4702 6.65087L13.7102 5.41084C13.8461 5.27498 13.914 5.20705 13.9667 5.14509C14.6009 4.39841 14.6009 3.30223 13.9667 2.55555C13.914 2.49359 13.8461 2.42566 13.7102 2.2898C13.5744 2.15394 13.5064 2.08601 13.4445 2.03338C12.6978 1.39915 11.6016 1.39915 10.855 2.03338C10.793 2.086 10.7251 2.15391 10.5893 2.2897L10.5892 2.28978L10.5892 2.2898L9.33099 3.54801C10.0747 4.84221 11.1583 5.91748 12.4702 6.65087ZM7.87633 5.00267L2.86273 10.0163C2.43767 10.4413 2.22514 10.6539 2.0854 10.915C1.94567 11.1761 1.88672 11.4708 1.76883 12.0602L1.37318 14.0385C1.30666 14.3711 1.27339 14.5374 1.368 14.632C1.46261 14.7266 1.62892 14.6934 1.96153 14.6269L3.9398 14.2312L3.93982 14.2312L3.93984 14.2312C4.52927 14.1133 4.82399 14.0544 5.08508 13.9146C5.34617 13.7749 5.55869 13.5624 5.98373 13.1373L5.98376 13.1373L5.98377 13.1373L11.0118 8.10931C9.75111 7.3111 8.68202 6.24922 7.87633 5.00267Z"
								fill="currentColor"
							/>
						</svg>
						Chỉnh sửa
					</button>
				</div>
			</div>
			<div class="flex flex-nowrap w-full md-down:flex-col">
				<div
					class="
						p-6
						border
						rounded-l-lg
						border-neutral-200
						w-full
						md:border-r-0
						md-down:border-b-0
						md-down:rounded-t
						md-down:rounded-b-none
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							src="@/assets/images/icons/abc.png"
							alt=""
							width="32"
							height="32"
							class="mr-2"
							style="height: 32px"
						/>
						{{ skill_count }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Tổng số lĩnh vực
						</span>
					</div>
				</div>
				<div
					class="
						p-6
						border
						rounded-r-lg
						border-neutral-200
						w-full
						md-down:rounded-b md-down:rounded-t-none
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							src="@/assets/images/icons/light.png"
							alt=""
							width="32"
							height="32"
							class="mr-2"
							style="height: 32px"
						/>
						{{ goal_count }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Tổng số mục tiêu</span
						>
					</div>
				</div>
			</div>
		</div>
		<div v-if="goals && goals.length > 0">
			<div>
				<div class="mb-10" v-for="(goal, index) in goals" :key="index">
					<div class="font-semibold text-lg text-neutral-600 mb-4">
						{{ index + 1 }}. {{ goal.skill.name }}
					</div>
					<div class="shadow-sm sm:rounded-2xl overflow-hidden">
						<div class="__record__table overflow-x-auto scroll-bar">
							<div
								class="
									overflow-hidden
									sm:rounded-t-2xl
									w-fit
									min-w-full
								"
							>
								<table
									class="min-w-full divide-y text-neutral-200"
								>
									<thead class="bg-neutral-100 h-16">
										<tr>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													w-8
													whitespace-nowrap
												"
											></th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													w-16
													whitespace-nowrap
												"
											>
												STT
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
												style="min-width: 160px"
											>
												Nội dung
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												NGÀY TẠO
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												NGÀY TẠM DỪNG
											</th>
										</tr>
									</thead>
									<tbody>
										<template
											v-for="(g, i) in goal.goals"
											:key="g.id"
										>
											<tr
												@click="
													$router.push(
														`/goal/${id}/${g.id}`
													)
												"
												:class="
													i % 2 === 0
														? 'bg-white'
														: 'bg-neutral-50'
												"
												class="
													text-neutral-700
													hover:bg-orange-50
													cursor-pointer
												"
											>
												<td
													class="px-6 py-4 w-8"
													@click.stop="openSubGoal(g)"
												>
													<div
														v-if="
															g?.sub_goals
																.length > 0
														"
														class="w-4"
													>
														<img
															class="w-full"
															v-if="g?.open"
															src="@/assets/images/icons/arrow-up.png"
															alt=""
														/>
														<img
															class="w-full"
															v-else
															src="@/assets/images/icons/arrow-down.png"
															alt=""
														/>
													</div>
												</td>
												<td
													class="
														px-6
														py-4
														whitespace-nowrap
														text-neutral-700
														text-center
													"
												>
													{{ i + 1 }}
												</td>
												<td
													class="
														px-6
														py-4
														text-neutral-700
													"
												>
													<div
														class="
															flex
															items-center
														"
													>
														<div class="mr-2">
															{{ g.name }}
														</div>
														<TooltipGoalDetail
															:goal-id="
																g.goal_id ||
																g.id
															"
															:student-id="id"
														/>
													</div>
													<div
														class="
															flex
															gap-2
															flex-wrap
														"
														style="max-width: 360px"
													>
														<div
															class="
																mt-1
																py-0.5
																px-2.5
																rounded-full
																bg-neutral-200
																text-neutral-700
																h-fit
																w-fit
																whitespace-nowrap
																font-semibold
																shadow-input
															"
															style="
																font-size: 10px;
															"
															v-if="g?.raw_source"
														>
															{{
																g?.display_source
															}}
														</div>
														<div
															class="
																mt-1
																py-0.5
																px-2.5
																rounded-full
																bg-neutral-200
																text-neutral-700
																h-fit
																w-fit
																whitespace-nowrap
																font-semibold
																shadow-input
															"
															style="
																font-size: 10px;
															"
															v-if="g?.state"
														>
															{{
																$filters.sourceMap(
																	g?.state,
																	g.editable
																)
															}}
														</div>
														<template
															v-if="
																g?.assistance_levels &&
																org.enable_goal_assistance_level
															"
														>
															<div
																v-for="ass in g?.assistance_levels"
																:key="ass"
																class="
																	flex
																	gap-2
																"
															>
																<div
																	class="
																		mt-1
																		py-0.5
																		px-2.5
																		rounded-full
																		bg-pink-100
																		whitespace-nowrap
																		text-neutral-700
																		h-fit
																		w-fit
																		font-semibold
																		shadow-input
																	"
																	style="
																		font-size: 10px;
																	"
																>
																	{{ ass }}
																</div>
															</div>
														</template>
													</div>
													<div
														v-if="
															g?.sub_goals
																.length > 0
														"
														class="mt-2"
													>
														<div
															class="
																text-neutral-400
																text-xs
																font-semibold
															"
														>
															{{
																g?.sub_goals
																	.length
															}}
															MỤC TIÊU
														</div>
													</div>
													<div
														class="
															flex
															items-center
															gap-2
															mt-2
														"
													>
														<div
															v-if="g?.has_media"
														>
															<img
																src="@/assets/images/icons/has_media.png"
																alt=""
															/>
														</div>
														<div
															class="
																text-neutral-400
																text-xs
																font-semibold
															"
														>
															ĐÃ HỌC
															{{
																g.number_of_assessment
															}}
															NGÀY
														</div>
														<div
															v-if="
																g?.completed_at
															"
														>
															<img
																src="@/assets/images/icons/has_taught.png"
																alt=""
															/>
														</div>
													</div>
													<!-- <div
														class="
															flex
															items-center
															gap-2
															mt-2
														"
													>
														<div
															class="
																text-neutral-400
																text-xs
																font-semibold
															"
														>
															KẾT QUẢ
														</div>
														<div
															class="
																progress-bar
																overflow-hidden
															"
														>
															<div
																:style="{
																	background:
																		$filters.tagColor(
																			g?.complete_percent
																		),
																	width:
																		g?.complete_percent +
																		'%',
																}"
																class="h-full"
															></div>
														</div>
														<div
															class="
																text-neutral-400
																text-xs
																font-semibold
															"
														>
															{{
																g?.complete_percent
															}}%
														</div>
													</div> -->
													<div
														v-if="
															g.parent
																?.sub_goal_count >
															0
														"
														class="
															goal-block
															flex
															gap-3
															mt-2
														"
													>
														<img
															class="w-3 h-3 mt-1"
															src="@/assets/images/icons/goal-arrow.png"
															alt=""
														/>
														<div>
															<div
																class="
																	text-neutral-700
																"
															>
																{{
																	g.parent
																		?.name
																}}
															</div>
															<div
																class="
																	text-neutral-400
																	text-xs
																	font-semibold
																"
															>
																{{
																	g.parent
																		?.sub_goal_count
																}}
																MỤC TIÊU
															</div>
														</div>
													</div>
												</td>
												<td
													class="
														px-6
														py-4
														whitespace-nowrap
														text-neutral-700
													"
												>
													<div v-if="g?.inserted_at">
														{{
															$filters.formatDate(
																g?.inserted_at
															)
														}}
													</div>
													<div v-else>-</div>
												</td>
												<td
													class="
														px-6
														py-4
														whitespace-nowrap
														text-neutral-700
													"
												>
													<div v-if="g?.completed_at">
														{{
															$filters.formatDate(
																g?.completed_at
															)
														}}
													</div>
													<div v-else>-</div>
												</td>
											</tr>
											<tr
												class="
													text-neutral-700
													cursor-pointer
												"
												v-if="g?.open"
												:class="
													i % 2 === 0
														? 'bg-white'
														: 'bg-neutral-50'
												"
											>
												<td colspan="8" class="pl-16">
													<child-goal
														:data="g?.sub_goals"
														:id="id"
													/>
												</td>
											</tr>
										</template>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="w-full h-full flex items-center justify-center flex-col"
		>
			<img
				src="@/assets/images/illustrations/not_found.png"
				alt=""
				height="250"
				width="250"
			/>
			<div
				class="text-neutral-500 text-lg text-center"
				style="max-width: 700px"
			>
				Học sinh chưa có mục tiêu tạm dừng nào. Mục tiêu tạm dừng sẽ
				được tạo tự động sau khi hoàn thành Báo cáo kế hoạch
			</div>
		</div>
	</div>
	<div v-else class="fixed top-1/2 left-1/2">
		<Loading />
	</div>
</template>

<script setup>
import { computed, defineComponent, ref } from 'vue';
import { useStudent } from '@/features/student/useStudent';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail.vue';
import Loading from '@/components/common/Loading.vue';
import ChildGoal from './ChildGoal.vue';
import { TAG_COLOR } from '@/helper/tag';

const { goalStoppedByStudent } = useStudent();
const route = useRoute();
const store = useStore();
const goals = ref([]);
const skill_count = ref(0);
const goal_count = ref(0);

const id = computed(() => (route.params?.id ? route.params?.id + '' : ''));

const isLoading = ref(true);

const oldPayload = ref({
	'x-cross': route.query.org_id,
});

const is_old_data = ref(route.query.is_old_data === 'true');

const org = computed(() => store.state.profile.current_org);

async function fetchGoals() {
	const res = await goalStoppedByStudent(
		id.value,
		is_old_data.value ? oldPayload.value : null
	);
	if (res?.data) {
		goals.value = res?.data?.skills ?? [];
		skill_count.value = res?.data?.skills.length;
		goals.value.forEach((item) => {
			goal_count.value += item?.goals.length || 0;
		});
	}
	isLoading.value = false;
}

function openSubGoal(goal) {
	if (goal?.sub_goals.length > 0) goal.open = !goal.open;
	else return;
}

fetchGoals();
</script>
