<template>
	<div class="_change-status _modal bg-white">
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<svg
					width="60"
					height="60"
					viewBox="0 0 61 60"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g clip-path="url(#clip0_15771_6272)">
						<path
							d="M30.3034 3.38292C15.7228 3.38292 3.86674 15.3238 3.86674 30.001C3.86674 44.6762 15.7228 56.6171 30.3034 56.6171C44.8839 56.6171 56.7452 44.6762 56.7452 30.001C56.7452 15.3238 44.8839 3.38292 30.3034 3.38292ZM30.3034 60C13.8737 60 0.5 46.5418 0.5 30.001C0.5 13.4582 13.8737 0 30.3034 0C46.7382 0 60.1068 13.4582 60.1068 30.001C60.1068 46.5418 46.7382 60 30.3034 60Z"
							fill="#F4C870"
						/>
						<path
							d="M53.0546 30.0008C53.0546 42.6491 42.8692 52.9023 30.3006 52.9023C17.7371 52.9023 7.55176 42.6491 7.55176 30.0008C7.55176 17.3515 17.7371 7.09775 30.3006 7.09775C42.8692 7.09775 53.0546 17.3515 53.0546 30.0008Z"
							fill="#F4C870"
						/>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M43.0164 29.9992C43.0164 37.0685 37.3236 42.7992 30.3012 42.7992C23.2787 42.7992 17.5859 37.0685 17.5859 29.9992C17.5859 22.93 23.2787 17.1992 30.3012 17.1992C37.3236 17.1992 43.0164 22.93 43.0164 29.9992ZM26.177 26.8516C26.0405 26.8516 25.9286 26.7415 25.9323 26.6051C26.0092 23.7785 28.2596 22.7992 30.3097 22.7992C32.5293 22.7992 34.5572 23.9672 34.5572 26.3844C34.5572 28.1109 33.5483 28.9336 32.5798 29.675C31.4094 30.5687 30.9756 30.9039 30.9756 32.0516V32.3703C30.9756 32.5084 30.8637 32.6203 30.7256 32.6203H29.1439C29.0072 32.6203 28.8958 32.5104 28.8939 32.3737L28.8872 31.8789C28.8267 30.3961 29.6741 29.482 30.7436 28.7C31.6818 27.9891 32.2771 27.5219 32.2771 26.5062C32.2771 25.1859 31.2783 24.6375 30.1887 24.6375C28.8531 24.6375 28.1313 25.476 28.0396 26.6017C28.0284 26.7393 27.9177 26.8516 27.7796 26.8516H26.177ZM30.0172 37.1602C29.089 37.1602 28.413 36.5305 28.413 35.6773C28.413 34.7937 29.089 34.1742 30.0172 34.1742C30.9857 34.1742 31.6516 34.7937 31.6516 35.6773C31.6516 36.5305 30.9857 37.1602 30.0172 37.1602Z"
							fill="white"
						/>
					</g>
					<defs>
						<clipPath id="clip0_15771_6272">
							<rect
								width="60"
								height="60"
								fill="white"
								transform="translate(0.5)"
							/>
						</clipPath>
					</defs>
				</svg>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
				"
			>
				Chỉnh sửa thời khoá biểu
			</div>
			<div class="text-center text-neutral-500 mt-2">
				Chọn lớp nhóm bạn muốn chỉnh sửa thời khoá biểu
			</div>
			<div class="w-full mt-5">
				<div class="flex flex-col gap-3">
					<div
						v-for="c in classes"
						:key="c.id"
						class="flex items-center w-full justify-between"
						@click="selected = c.id"
					>
						<label class="text-neutral-700"> {{ c.name }} </label>
						<input
							name="notification-method"
							type="radio"
							class="
								h-4
								w-4
								text-yellow-600
								border-neutral-400 border-2
								focus:ring-yellow-600
							"
							:value="c.id"
							v-model="selected"
						/>
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="close"
			>
				Hủy
			</button>
			<button
				type="button"
				class="button-p text-center whitespace-nowrap"
				@click="$emit('submit', selected)"
			>
				Tiếp tục
			</button>
		</div>
	</div>
</template>

<script>
import { $api } from '@/services';

export default {
	name: 'ChooseGroupClass',

	created() {
		this.fetchGroupClasses();
	},

	data() {
		return {
			selected: null,
			classes: [],
		};
	},

	methods: {
		close() {
			this.$emit('close');
		},

		async fetchGroupClasses() {
			try {
				const {
					data: { data },
				} = await $api.groupClasses.list({
					only_group_class_has_staffs_and_students: true,
				});
				this.classes = data.filter((i) => i.enabled);
			} catch (e) {
				// TODO:
			}
		},
	},
};
</script>
