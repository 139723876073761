<template>
	<div>
		<DaModalFrame v-model:open="modalIsOpen">
			<CreateGroupClass
				v-if="action === 'creating' && !groupDeleted"
				:error="error"
				:in-progress="inProgress"
				@close="modalIsOpen = false"
				@submit="createGroupClass"
			/>
			<DeleteGroupClass
				v-if="action === 'deleting' && groupDeleted"
				:in-progress="inProgress"
				@close="closeModal"
				@submit="deleteGroupClass(groupDeleted.id)"
			/>
			<BlockGroupClass
				v-if="action === 'block' && groupDeleted"
				:in-progress="inProgress"
				@close="closeModal"
				@submit="blockGroupClass(groupDeleted.id)"
			/>
			<UpdateGroupClass
				v-if="action === 'updating' && groupUpdated"
				:data="groupUpdated"
				:error="error"
				:in-progress="inProgress"
				@close="modalIsOpen = false"
				@submit="updateGroupClass"
			/>
		</DaModalFrame>
		<div class="__report-lesson px-12 md-down:px-4 pb-30">
			<div
				class="
					__report-lesson__header
					pt-10
					flex flex-wrap
					gap-6
					md:flex-nowrap
					justify-between
					items-center
				"
			>
				<div
					class="
						__record__title
						text-neutral-500
						font-semibold
						whitespace-nowrap
						flex flex-nowrap
						items-center
						text-4xl
						gap-4
					"
				>
					<svg
						fill="none"
						height="28"
						viewBox="0 0 28 28"
						width="28"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							clip-rule="evenodd"
							d="M12.25 24.5C12.25 24.5 10.5 24.5 10.5 22.75C10.5 21 12.25 15.75 19.25 15.75C26.25 15.75 28 21 28 22.75C28 24.5 26.25 24.5 26.25 24.5H12.25Z"
							fill="#65758B"
							fill-rule="evenodd"
						/>
						<path
							clip-rule="evenodd"
							d="M19.25 14C22.1495 14 24.5 11.6495 24.5 8.75C24.5 5.85051 22.1495 3.5 19.25 3.5C16.3505 3.5 14 5.85051 14 8.75C14 11.6495 16.3505 14 19.25 14Z"
							fill="#65758B"
							fill-rule="evenodd"
						/>
						<path
							clip-rule="evenodd"
							d="M9.12863 24.5C8.88223 24.0029 8.75 23.4135 8.75 22.75C8.75 20.378 9.93834 17.9385 12.1379 16.2399C11.1757 15.9322 10.0539 15.75 8.75 15.75C1.75 15.75 0 21 0 22.75C0 24.5 1.75 24.5 1.75 24.5H9.12863Z"
							fill="#65758B"
							fill-rule="evenodd"
						/>
						<path
							clip-rule="evenodd"
							d="M7.875 14C10.2912 14 12.25 12.0412 12.25 9.625C12.25 7.20875 10.2912 5.25 7.875 5.25C5.45875 5.25 3.5 7.20875 3.5 9.625C3.5 12.0412 5.45875 14 7.875 14Z"
							fill="#65758B"
							fill-rule="evenodd"
						/>
					</svg>
					Lớp nhóm
				</div>
				<div class="w-full flex items-center justify-end">
					<div
						class="search-input max-w-lg w-full"
						style="min-width: 160px"
					>
						<div class="input-group input--lg">
							<input
								v-model="searchQuery"
								class="bg-white input-with-icon"
								placeholder="Tìm theo tên lớp nhóm"
								type="text"
								@keyup="searching"
								@keypress.enter="search"
							/>
							<div class="input-icon absolute top-1/2">
								<DaIcon
									class="h-4 w-4 text-neutral-400"
									name="das--search"
								/>
							</div>
						</div>
					</div>
					<Menu as="div" class="ml-3 relative">
						<div>
							<MenuButton
								class="
									rounded-full
									flex
									text-sm
									focus:outline-none
									focus:ring-2
									focus:ring-offset-2
									focus:ring-indigo-500
								"
							>
								<span class="sr-only">Open user menu</span>
								<svg
									fill="none"
									height="20"
									viewBox="0 0 20 20"
									width="20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										clip-rule="evenodd"
										d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
										fill="#94A3B8"
										fill-rule="evenodd"
									/>
								</svg>
							</MenuButton>
						</div>
						<transition
							enter-active-class="transition ease-out duration-200"
							enter-from-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-from-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95"
						>
							<MenuItems
								class="
									origin-top-right
									absolute
									right-0
									mt-2
									w-60
									rounded-md
									py-1
									bg-white
									focus:outline-none
									z-10
									shadow-sm
									border border-yellow-100
								"
							>
								<MenuItem @click="openModalCreated">
									<div
										class="
											p-2
											px-4
											bg-white
											z-10
											text-gray-600
											hover:bg-neutral-50
											flex
											items-center
											gap-4
											cursor-pointer
										"
									>
										<svg
											fill="none"
											height="16"
											viewBox="0 0 16 16"
											width="16"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												clip-rule="evenodd"
												d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
												fill="#94A3B8"
												fill-rule="evenodd"
											/>
										</svg>
										<span>Tạo lớp nhóm mới</span>
									</div>
								</MenuItem>
							</MenuItems>
						</transition>
					</Menu>
				</div>
			</div>
			<div class="w-full my-10">
				<div class="border-b border-neutral-200">
					<nav aria-label="Tabs" class="-mb-px flex space-x-8 tabs">
						<div
							v-for="tab in tabs"
							:key="tab.id"
							:class="
								tabSelected?.id === tab.id
									? 'border-b-2 text-yellow-700 border-yellow-500'
									: 'text-neutral-500'
							"
							class="
								border-transparent
								hover:text-yellow-700 hover:border-yellow-500
								whitespace-nowrap
								py-3
								px-1
								border-b-2
								font-semibold
								cursor-pointer
							"
							@click="tabSelected = tab"
						>
							<div class="flex items-center gap-2">
								<div>{{ tab.name }}</div>
							</div>
						</div>
					</nav>
				</div>
			</div>
			<div v-if="!isLoading" class="mt-10 lg-down:overflow-x-auto">
				<div>
					<div v-if="groups.length">
						<div class="mb-10">
							<div class="shadow-sm sm:rounded-2xl">
								<div class="__record__table scroll-bar">
									<div
										class="
											sm:rounded-t-2xl
											w-fit
											min-w-full
										"
									>
										<table
											class="
												min-w-full
												divide-y
												text-neutral-200
												rounded-2xl
											"
										>
											<thead
												class="
													bg-neutral-100
													h-16
													rounded-2xl
												"
											>
												<tr>
													<th
														class="
															px-6
															py-3
															text-left text-xs
															font-semibold
															text-neutral-600
															uppercase
															tracking-wider
															w-16
															whitespace-nowrap
														"
														scope="col"
													>
														STT
													</th>
													<th
														class="
															px-6
															py-3
															text-left text-xs
															font-semibold
															text-neutral-600
															uppercase
															tracking-wider
															whitespace-nowrap
														"
														scope="col"
														style="width: 400px"
														@click="
															sortBy =
																sortBy !==
																'name_asc'
																	? 'name_asc'
																	: 'name_desc'
														"
													>
														<div
															class="
																flex
																items-center
																gap-1
																justify-between
																cursor-pointer
															"
														>
															tên lớp nhóm
															<svg
																fill="none"
																height="16"
																viewBox="0 0 16 16"
																width="16"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	clip-rule="evenodd"
																	d="M7.99999 2.39999C8.21216 2.39999 8.41564 2.48428 8.56567 2.63431L10.9657 5.03431C11.2781 5.34673 11.2781 5.85326 10.9657 6.16568C10.6533 6.4781 10.1467 6.4781 9.8343 6.16568L7.99999 4.33136L6.16567 6.16568C5.85325 6.4781 5.34672 6.4781 5.0343 6.16568C4.72188 5.85326 4.72188 5.34673 5.0343 5.03431L7.4343 2.63431C7.58433 2.48428 7.78782 2.39999 7.99999 2.39999ZM5.0343 9.83431C5.34672 9.52189 5.85325 9.52189 6.16567 9.83431L7.99999 11.6686L9.8343 9.83431C10.1467 9.52189 10.6533 9.52189 10.9657 9.83431C11.2781 10.1467 11.2781 10.6533 10.9657 10.9657L8.56567 13.3657C8.25325 13.6781 7.74672 13.6781 7.4343 13.3657L5.0343 10.9657C4.72188 10.6533 4.72188 10.1467 5.0343 9.83431Z"
																	fill="#94A3B8"
																	fill-rule="evenodd"
																/>
															</svg>
														</div>
													</th>
													<th
														class="
															px-6
															py-3
															text-left text-xs
															font-semibold
															text-neutral-600
															uppercase
															tracking-wider
															whitespace-nowrap
														"
														scope="col"
														@click="
															sortBy =
																sortBy !==
																'students_count_asc'
																	? 'students_count_asc'
																	: 'students_count_desc'
														"
													>
														<div
															class="
																flex
																items-center
																gap-1
																justify-between
																cursor-pointer
															"
														>
															học sinh
															<svg
																fill="none"
																height="16"
																viewBox="0 0 16 16"
																width="16"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	clip-rule="evenodd"
																	d="M7.99999 2.39999C8.21216 2.39999 8.41564 2.48428 8.56567 2.63431L10.9657 5.03431C11.2781 5.34673 11.2781 5.85326 10.9657 6.16568C10.6533 6.4781 10.1467 6.4781 9.8343 6.16568L7.99999 4.33136L6.16567 6.16568C5.85325 6.4781 5.34672 6.4781 5.0343 6.16568C4.72188 5.85326 4.72188 5.34673 5.0343 5.03431L7.4343 2.63431C7.58433 2.48428 7.78782 2.39999 7.99999 2.39999ZM5.0343 9.83431C5.34672 9.52189 5.85325 9.52189 6.16567 9.83431L7.99999 11.6686L9.8343 9.83431C10.1467 9.52189 10.6533 9.52189 10.9657 9.83431C11.2781 10.1467 11.2781 10.6533 10.9657 10.9657L8.56567 13.3657C8.25325 13.6781 7.74672 13.6781 7.4343 13.3657L5.0343 10.9657C4.72188 10.6533 4.72188 10.1467 5.0343 9.83431Z"
																	fill="#94A3B8"
																	fill-rule="evenodd"
																/>
															</svg>
														</div>
													</th>
													<th
														class="
															px-6
															py-3
															text-left text-xs
															font-semibold
															text-neutral-600
															uppercase
															tracking-wider
															whitespace-nowrap
														"
														scope="col"
														@click="
															sortBy =
																sortBy !==
																'staffs_count_asc'
																	? 'staffs_count_asc'
																	: 'staffs_count_desc'
														"
													>
														<div
															class="
																flex
																items-center
																gap-1
																justify-between
																cursor-pointer
															"
														>
															trị liệu viên
															<svg
																fill="none"
																height="16"
																viewBox="0 0 16 16"
																width="16"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	clip-rule="evenodd"
																	d="M7.99999 2.39999C8.21216 2.39999 8.41564 2.48428 8.56567 2.63431L10.9657 5.03431C11.2781 5.34673 11.2781 5.85326 10.9657 6.16568C10.6533 6.4781 10.1467 6.4781 9.8343 6.16568L7.99999 4.33136L6.16567 6.16568C5.85325 6.4781 5.34672 6.4781 5.0343 6.16568C4.72188 5.85326 4.72188 5.34673 5.0343 5.03431L7.4343 2.63431C7.58433 2.48428 7.78782 2.39999 7.99999 2.39999ZM5.0343 9.83431C5.34672 9.52189 5.85325 9.52189 6.16567 9.83431L7.99999 11.6686L9.8343 9.83431C10.1467 9.52189 10.6533 9.52189 10.9657 9.83431C11.2781 10.1467 11.2781 10.6533 10.9657 10.9657L8.56567 13.3657C8.25325 13.6781 7.74672 13.6781 7.4343 13.3657L5.0343 10.9657C4.72188 10.6533 4.72188 10.1467 5.0343 9.83431Z"
																	fill="#94A3B8"
																	fill-rule="evenodd"
																/>
															</svg>
														</div>
													</th>
													<th
														class="
															px-6
															py-3
															text-left text-xs
															font-semibold
															text-neutral-600
															uppercase
															tracking-wider
															whitespace-nowrap
														"
														scope="col"
													>
														<div
															class="
																flex
																items-center
																gap-1
																justify-between
																cursor-pointer
															"
														>
															ngày học
														</div>
													</th>
													<th
														style="width: 100px"
													></th>
												</tr>
											</thead>
											<tbody>
												<tr
													v-for="(group, i) in groups"
													:key="group.id"
													:class="[
														i % 2 === 0
															? 'bg-white'
															: 'bg-neutral-50',
														group.enabled
															? 'hover:bg-yellow-50 cursor-pointer'
															: '',
													]"
													class="text-neutral-700"
													@click.prevent="
														group.enabled &&
															$router.push(
																`/group-classes/${group.id}`
															)
													"
												>
													<td
														:class="{
															'opacity-50':
																!group.enabled,
														}"
														class="
															px-6
															py-4
															whitespace-nowrap
															text-neutral-700
														"
													>
														<div class="ml-2">
															{{ i + 1 }}
														</div>
													</td>
													<td
														:class="{
															'opacity-50':
																!group.enabled,
														}"
														class="
															px-6
															py-4
															text-neutral-700
														"
														style="width: 300px"
													>
														<div
															class="
																flex
																items-center
																gap-2
															"
														>
															<svg
																v-if="
																	group.enabled
																"
																fill="none"
																height="20"
																viewBox="0 0 20 20"
																width="20"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	clip-rule="evenodd"
																	d="M8.75 17.5001C8.75 17.5001 7.5 17.5001 7.5 16.2501C7.5 15.0001 8.75 11.2501 13.75 11.2501C18.75 11.2501 20 15.0001 20 16.2501C20 17.5001 18.75 17.5001 18.75 17.5001H8.75Z"
																	fill="#94A3B8"
																	fill-rule="evenodd"
																/>
																<path
																	clip-rule="evenodd"
																	d="M13.75 10.0001C15.8211 10.0001 17.5 8.32113 17.5 6.25006C17.5 4.17899 15.8211 2.50006 13.75 2.50006C11.6789 2.50006 10 4.17899 10 6.25006C10 8.32113 11.6789 10.0001 13.75 10.0001Z"
																	fill="#94A3B8"
																	fill-rule="evenodd"
																/>
																<path
																	clip-rule="evenodd"
																	d="M6.52045 17.5001C6.34445 17.145 6.25 16.724 6.25 16.2501C6.25 14.5557 7.09882 12.8133 8.66991 11.6C7.98262 11.3802 7.18137 11.2501 6.25 11.2501C1.25 11.2501 0 15.0001 0 16.2501C0 17.5001 1.25 17.5001 1.25 17.5001H6.52045Z"
																	fill="#94A3B8"
																	fill-rule="evenodd"
																/>
																<path
																	clip-rule="evenodd"
																	d="M5.625 10.0001C7.35089 10.0001 8.75 8.60095 8.75 6.87506C8.75 5.14917 7.35089 3.75006 5.625 3.75006C3.89911 3.75006 2.5 5.14917 2.5 6.87506C2.5 8.60095 3.89911 10.0001 5.625 10.0001Z"
																	fill="#94A3B8"
																	fill-rule="evenodd"
																/>
															</svg>
															<svg
																v-else
																fill="none"
																height="20"
																viewBox="0 0 20 20"
																width="20"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	clip-rule="evenodd"
																	d="M4.79169 7.99983V4.99979C4.79169 2.23829 7.12353 0 10.0001 0C12.8766 0 15.2085 2.23846 15.2085 4.99979V7.99983C16.9343 7.99983 18.3334 9.34299 18.3334 10.9999V17C18.3334 18.657 16.9343 20 15.2085 20H4.79169C3.06583 20 1.66675 18.657 1.66675 17V10.9999C1.66675 9.34299 3.06583 7.99983 4.79169 7.99983ZM13.125 7.99983V4.99979C13.125 3.3429 11.7259 1.99974 10.0001 1.99974C8.27422 1.99974 6.87514 3.3429 6.87514 4.99979V7.99983H13.125ZM10.0001 12C10.5754 12 11.0417 12.4476 11.0417 13V15C11.0417 15.5523 10.5754 16 10.0001 16C9.42478 16 8.95849 15.5523 8.95849 15V13C8.95849 12.4476 9.42478 12 10.0001 12Z"
																	fill="#94A3B8"
																	fill-rule="evenodd"
																/>
															</svg>

															{{ group?.name }}
														</div>
													</td>
													<td
														:class="{
															'opacity-50':
																!group.enabled,
														}"
														class="
															px-6
															py-4
															text-neutral-700
														"
														style="min-width: 200px"
													>
														<UserGroup
															v-if="
																group?.students
																	.length > 0
															"
															:data="
																group?.students
															"
															placement="bottom-start"
														/>
														<div v-else>
															<button
																:class="{
																	'cursor-not-allowed':
																		!group.enabled,
																}"
																class="
																	button-p
																	text-center
																	bg-none
																	shadow-none
																	flex
																	items-center
																	justify-center
																	btn--text
																	w-fit
																"
																type="button"
																@click.stop="
																	group.enabled &&
																		$router.push(
																			`/group-classes/${group?.id}/add-student`
																		)
																"
															>
																Thêm học sinh
															</button>
														</div>
													</td>
													<td
														:class="{
															'opacity-50':
																!group.enabled,
														}"
														class="
															px-6
															py-4
															whitespace-nowrap
															text-sm
															text-neutral-700
														"
													>
														<div
															v-if="
																group?.staffs
																	.length > 0
															"
														>
															<div
																v-for="staff in group.staffs"
																:key="staff.id"
																class="
																	flex
																	items-center
																	mb-2
																"
																@click.stop="
																	$router.push(
																		`/record/employee/${staff?.id}`
																	)
																"
															>
																<img
																	v-if="
																		staff
																			?.profile_photo
																			?.w200
																	"
																	:src="
																		staff
																			?.profile_photo
																			?.w200
																	"
																	alt=""
																	class="
																		shadow-sm
																		inline-block
																		h-6
																		w-6
																		rounded-full
																	"
																/>
																<span
																	v-else
																	:style="{
																		background:
																			staff
																				?.profile_photo
																				?.default
																				?.color,
																	}"
																	class="
																		inline-flex
																		items-center
																		justify-center
																		h-6
																		w-6
																		rounded-full
																	"
																>
																	<span
																		class="
																			font-medium
																			leading-none
																			text-white
																			text-xs
																		"
																		>{{
																			staff
																				?.profile_photo
																				?.default
																				?.initial
																		}}</span
																	>
																</span>
																<div
																	class="
																		ml-4
																		text-blue-600
																		font-semibold
																	"
																>
																	{{
																		staff?.name
																	}}
																</div>
															</div>
														</div>
														<div v-else>
															<button
																:class="{
																	'cursor-not-allowed':
																		!group.enabled,
																}"
																class="
																	button-p
																	text-center
																	bg-none
																	shadow-none
																	flex
																	items-center
																	justify-center
																	btn--text
																	w-fit
																"
																type="button"
																@click.stop="
																	group.enabled &&
																		$router.push(
																			`/group-classes/${group?.id}/add-staff`
																		)
																"
															>
																Thêm Trị liệu
																viên
															</button>
														</div>
													</td>
													<td
														:class="{
															'opacity-50':
																!group.enabled,
														}"
														style="min-width: 200px"
													>
														<div
															class="
																flex
																items-center
																gap-1
															"
														>
															<span
																v-for="(
																	i, index
																) in group?.days_of_week"
																:key="index"
															>
																T{{ i + 1 }}
																{{
																	index +
																		1 !==
																	group
																		?.days_of_week
																		.length
																		? ','
																		: ''
																}}
															</span>
														</div>
													</td>
													<td>
														<Menu
															as="div"
															class="
																ml-3
																relative
																w-full
															"
														>
															<div>
																<MenuButton
																	class="
																		rounded-full
																		flex
																		text-sm
																		focus:outline-none
																		focus:ring-2
																		focus:ring-offset-2
																		focus:ring-indigo-500
																		p-3
																	"
																	@click.stop="
																		() => {}
																	"
																>
																	<span
																		class="
																			sr-only
																		"
																		>Open
																		user
																		menu</span
																	>
																	<svg
																		fill="none"
																		height="20"
																		viewBox="0 0 20 20"
																		width="20"
																		xmlns="http://www.w3.org/2000/svg"
																	>
																		<path
																			clip-rule="evenodd"
																			d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
																			fill="#94A3B8"
																			fill-rule="evenodd"
																		/>
																	</svg>
																</MenuButton>
															</div>
															<transition
																enter-active-class="transition ease-out duration-200"
																enter-from-class="transform opacity-0 scale-95"
																enter-to-class="transform opacity-100 scale-100"
																leave-active-class="transition ease-in duration-75"
																leave-from-class="transform opacity-100 scale-100"
																leave-to-class="transform opacity-0 scale-95"
															>
																<MenuItems
																	class="
																		origin-top-right
																		absolute
																		right-0
																		mt-2
																		w-60
																		rounded-md
																		py-1
																		bg-white
																		focus:outline-none
																		z-10
																		shadow-sm
																		border
																		border-yellow-100
																	"
																>
																	<MenuItem
																		v-if="
																			group.enabled
																		"
																		@click.stop="
																			openModalUpdated(
																				group
																			)
																		"
																	>
																		<div
																			class="
																				p-2
																				px-4
																				bg-white
																				z-10
																				text-gray-600
																				hover:bg-neutral-50
																				flex
																				items-center
																				gap-4
																				cursor-pointer
																			"
																		>
																			<svg
																				fill="none"
																				height="16"
																				viewBox="0 0 16 16"
																				width="16"
																				xmlns="http://www.w3.org/2000/svg"
																			>
																				<path
																					clip-rule="evenodd"
																					d="M12.4702 6.65087L13.7102 5.41084C13.8461 5.27498 13.914 5.20705 13.9666 5.14509C14.6009 4.39841 14.6009 3.30223 13.9666 2.55555C13.914 2.49359 13.8461 2.42566 13.7102 2.2898C13.5744 2.15394 13.5064 2.08601 13.4445 2.03338C12.6978 1.39915 11.6016 1.39915 10.8549 2.03338C10.793 2.086 10.7251 2.15391 10.5893 2.2897L10.5892 2.28978L10.5892 2.2898L9.33097 3.54801C10.0746 4.84221 11.1583 5.91748 12.4702 6.65087ZM7.87631 5.00267L2.86271 10.0163C2.43765 10.4413 2.22512 10.6539 2.08538 10.915C1.94565 11.1761 1.8867 11.4708 1.76881 12.0602L1.37316 14.0385C1.30663 14.3711 1.27337 14.5374 1.36798 14.632C1.46259 14.7266 1.62889 14.6934 1.9615 14.6269L3.93978 14.2312L3.9398 14.2312L3.93982 14.2312C4.52925 14.1133 4.82397 14.0544 5.08506 13.9146C5.34615 13.7749 5.55867 13.5624 5.98371 13.1373L5.98374 13.1373L5.98374 13.1373L11.0117 8.10931C9.75108 7.3111 8.682 6.24922 7.87631 5.00267Z"
																					fill="#94A3B8"
																					fill-rule="evenodd"
																				/>
																			</svg>

																			<span
																				>Chỉnh
																				sửa
																				lớp
																				nhóm
																			</span>
																		</div>
																	</MenuItem>
																	<MenuItem
																		v-if="
																			group.enabled
																		"
																		@click.stop="
																			$router.push(
																				`/group-classes/${group?.id}/add-student`
																			)
																		"
																	>
																		<div
																			class="
																				p-2
																				px-4
																				bg-white
																				z-10
																				text-gray-600
																				hover:bg-neutral-50
																				flex
																				items-center
																				gap-4
																				cursor-pointer
																			"
																		>
																			<svg
																				fill="none"
																				height="16"
																				viewBox="0 0 16 16"
																				width="16"
																				xmlns="http://www.w3.org/2000/svg"
																			>
																				<path
																					clip-rule="evenodd"
																					d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
																					fill="#94A3B8"
																					fill-rule="evenodd"
																				/>
																			</svg>
																			<span
																				>Thêm
																				học
																				sinh</span
																			>
																		</div>
																	</MenuItem>
																	<MenuItem
																		v-if="
																			group.enabled
																		"
																		@click.stop="
																			$router.push(
																				`/group-classes/${group?.id}/add-staff`
																			)
																		"
																	>
																		<div
																			class="
																				p-2
																				px-4
																				bg-white
																				z-10
																				text-gray-600
																				hover:bg-neutral-50
																				flex
																				items-center
																				gap-4
																				cursor-pointer
																			"
																		>
																			<svg
																				fill="none"
																				height="16"
																				viewBox="0 0 16 16"
																				width="16"
																				xmlns="http://www.w3.org/2000/svg"
																			>
																				<path
																					clip-rule="evenodd"
																					d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
																					fill="#94A3B8"
																					fill-rule="evenodd"
																				/>
																			</svg>
																			<span
																				>Thêm
																				Trị
																				liệu
																				viên</span
																			>
																		</div>
																	</MenuItem>
																	<MenuItem
																		v-if="
																			group.enabled
																		"
																		@click.stop="
																			openModalBlock(
																				group
																			)
																		"
																	>
																		<div
																			class="
																				p-2
																				px-4
																				bg-white
																				z-10
																				text-gray-600
																				hover:bg-neutral-50
																				flex
																				items-center
																				gap-4
																				cursor-pointer
																			"
																		>
																			<svg
																				fill="none"
																				height="16"
																				viewBox="0 0 16 16"
																				width="16"
																				xmlns="http://www.w3.org/2000/svg"
																			>
																				<path
																					clip-rule="evenodd"
																					d="M3.8333 6.39986V3.99983C3.8333 1.79063 5.69877 0 8.00001 0C10.3013 0 12.1667 1.79077 12.1667 3.99983V6.39986C13.5474 6.39986 14.6667 7.47439 14.6667 8.7999V13.6C14.6667 14.9256 13.5474 16 12.1667 16H3.8333C2.45261 16 1.33334 14.9256 1.33334 13.6V8.7999C1.33334 7.47439 2.45261 6.39986 3.8333 6.39986ZM10.5 6.39986V3.99983C10.5 2.67432 9.3807 1.59979 8.00001 1.59979C6.61932 1.59979 5.50006 2.67432 5.50006 3.99983V6.39986H10.5ZM8.00001 9.6C8.46025 9.6 8.83328 9.95812 8.83328 10.4V12C8.83328 12.4419 8.46025 12.8 8.00001 12.8C7.53977 12.8 7.16674 12.4419 7.16674 12V10.4C7.16674 9.95812 7.53977 9.6 8.00001 9.6Z"
																					fill="#94A3B8"
																					fill-rule="evenodd"
																				/>
																			</svg>
																			<span
																				>Khoá
																				lớp
																				nhóm</span
																			>
																		</div>
																	</MenuItem>
																	<MenuItem
																		v-else
																		@click.stop="
																			enableGroupClass(
																				group.id
																			)
																		"
																	>
																		<div
																			class="
																				p-2
																				px-4
																				bg-white
																				z-10
																				text-gray-600
																				hover:bg-neutral-50
																				flex
																				items-center
																				gap-4
																				cursor-pointer
																			"
																		>
																			<svg
																				fill="none"
																				height="16"
																				viewBox="0 0 16 16"
																				width="16"
																				xmlns="http://www.w3.org/2000/svg"
																			>
																				<path
																					d="M11.5337 7H15.4662C15.6781 7 15.7939 7.24721 15.6582 7.41005L13.692 9.76953C13.592 9.88947 13.4078 9.88947 13.3079 9.76953L11.3416 7.41005C11.2059 7.24721 11.3217 7 11.5337 7Z"
																					fill="#94A3B8"
																				/>
																				<path
																					d="M0.533681 9H4.46616C4.67812 9 4.79391 8.75279 4.65822 8.58995L2.69198 6.23047C2.59203 6.11053 2.40781 6.11053 2.30787 6.23047L0.341625 8.58995C0.205932 8.75279 0.321722 9 0.533681 9Z"
																					fill="#94A3B8"
																				/>
																				<path
																					clip-rule="evenodd"
																					d="M7.99992 3C6.44743 3 5.06049 3.70697 4.14261 4.81839C3.96677 5.03131 3.65161 5.06137 3.43869 4.88552C3.22578 4.70968 3.19572 4.39453 3.37156 4.18161C4.47126 2.85003 6.13654 2 7.99992 2C10.9416 2 13.3879 4.1165 13.901 6.90967C13.9065 6.9397 13.9118 6.96981 13.9169 7H12.9C12.437 4.71778 10.4186 3 7.99992 3ZM3.09984 9C3.56285 11.2822 5.58129 13 7.99992 13C9.55241 13 10.9394 12.293 11.8572 11.1816C12.0331 10.9687 12.3482 10.9386 12.5611 11.1145C12.7741 11.2903 12.8041 11.6055 12.6283 11.8184C11.5286 13.15 9.86331 14 7.99992 14C5.05822 14 2.6119 11.8835 2.09884 9.09033C2.09332 9.0603 2.08803 9.03019 2.08296 9H3.09984Z"
																					fill="#94A3B8"
																					fill-rule="evenodd"
																				/>
																			</svg>
																			<span
																				>Khôi
																				phục
																				lớp
																				nhóm</span
																			>
																		</div>
																	</MenuItem>
																	<MenuItem
																		@click.stop="
																			openModalDeleted(
																				group
																			)
																		"
																	>
																		<div
																			class="
																				p-2
																				px-4
																				bg-white
																				z-10
																				text-gray-600
																				hover:bg-neutral-50
																				flex
																				items-center
																				gap-4
																				cursor-pointer
																			"
																		>
																			<svg
																				fill="none"
																				height="16"
																				viewBox="0 0 16 16"
																				width="16"
																				xmlns="http://www.w3.org/2000/svg"
																			>
																				<path
																					clip-rule="evenodd"
																					d="M2.5 1C1.94772 1 1.5 1.44772 1.5 2V3C1.5 3.55228 1.94772 4 2.5 4H3V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V4H13.5C14.0523 4 14.5 3.55228 14.5 3V2C14.5 1.44772 14.0523 1 13.5 1H10C10 0.447715 9.55229 0 9 0H7C6.44772 0 6 0.447715 6 1H2.5ZM5.5 5C5.77614 5 6 5.22386 6 5.5V12.5C6 12.7761 5.77614 13 5.5 13C5.22386 13 5 12.7761 5 12.5L5 5.5C5 5.22386 5.22386 5 5.5 5ZM8 5C8.27614 5 8.5 5.22386 8.5 5.5V12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5V5.5C7.5 5.22386 7.72386 5 8 5ZM11 5.5C11 5.22386 10.7761 5 10.5 5C10.2239 5 10 5.22386 10 5.5V12.5C10 12.7761 10.2239 13 10.5 13C10.7761 13 11 12.7761 11 12.5V5.5Z"
																					fill="#94A3B8"
																					fill-rule="evenodd"
																				/>
																			</svg>
																			<span
																				>Xoá
																				lớp
																				nhóm</span
																			>
																		</div>
																	</MenuItem>
																</MenuItems>
															</transition>
														</Menu>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div
						v-else
						class="
							w-full
							h-full
							flex
							items-center
							justify-center
							flex-col
						"
					>
						<img
							alt=""
							height="250"
							src="@/assets/images/illustrations/not_found.png"
							width="250"
						/>
						<div
							class="text-neutral-400 text-lg text-center"
							style="max-width: 900px"
						>
							{{
								tabSelected.id === 0
									? 'Không có lớp nhóm nào đang hoạt động'
									: 'Trung tâm không có lớp nhóm nào bị khoá.'
							}}
						</div>
						<div
							v-if="isAdmin && tabSelected.id === 0"
							class="mt-8"
						>
							<button
								class="
									button-p
									text-center
									shadow-none
									flex
									items-center
									justify-center
									w-fit
									px-6
									bg-none
									btn--ghost
								"
								type="button"
								@click="openModalCreated"
							>
								Tạo mới
							</button>
						</div>
					</div>
				</div>
			</div>
			<div v-else class="fixed top-1/2 left-1/2">
				<Loading />
			</div>
		</div>
	</div>
</template>

<script>
import Loading from '@/components/common/Loading';
import { $api } from '@/services';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import DaIcon from '@/components/elements/daIcon/DaIcon';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import DeleteGroupClass from '@/views/groupClasses/features/DeleteGroupClass.vue';
import UpdateGroupClass from '@/views/groupClasses/features/UpdateGroupClass.vue';
import CreateGroupClass from '@/views/groupClasses/features/CreateGroupClass.vue';
import UserGroup from '@/components/elements/UserGroup.vue';
import BlockGroupClass from '@/views/groupClasses/features/BlockGroupClass.vue';

export default {
	name: 'GroupClasses',

	components: {
		BlockGroupClass,
		UpdateGroupClass,
		DeleteGroupClass,
		DaModalFrame,
		CreateGroupClass,
		DaIcon,
		Loading,
		Menu,
		MenuItem,
		MenuItems,
		MenuButton,
		UserGroup,
	},

	data() {
		return {
			isLoading: true,
			modalIsOpen: false,
			groups: [],
			skills: [],
			groupDeleted: null,
			groupUpdated: null,
			inProgress: false,
			error: '',
			action: '',
			sortBy: '',
			typing: '',
			searchQuery: '',
			isSearching: false,
			tabSelected: null,
			tabs: [
				{
					id: 0,
					name: 'Đang hoạt động',
				},
				{
					id: 1,
					name: 'Tạm dừng',
				},
			],
		};
	},

	created() {
		this.tabSelected = this.tabs[0];
	},

	computed: {
		isAdmin() {
			return this.$store.getters.isAdmin;
		},
	},

	watch: {
		sortBy() {
			this.fetchGroupClasses();
		},

		sortSearchBy() {
			this.fetchGroupClasses();
		},

		typing(value) {
			this.isLoading = !!value;
		},

		tabSelected: {
			immediate: true,
			deep: true,
			handler() {
				this.fetchGroupClasses();
			},
		},
	},

	methods: {
		async fetchGroupClasses() {
			try {
				const {
					data: { data },
				} = await $api.groupClasses.list({
					order_by: this.sortBy,
					query: this.searchQuery,
					enabled: !this.tabSelected || this.tabSelected?.id === 0,
				});
				this.groups = data;
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.isLoading = false;
			}
		},

		async createGroupClass(data) {
			this.inProgress = true;
			try {
				await $api.groupClasses.create(data);
				this.closeModal();
				this.$store.dispatch('setAlertMessage', {
					message: 'Tạo lớp nhóm thành công',
				});
				await this.fetchGroupClasses();
			} catch (e) {
				this.error = e?.response?.data?.errors_by_field;
			} finally {
				this.inProgress = false;
			}
		},

		async updateGroupClass(data) {
			this.inProgress = true;
			try {
				await $api.groupClasses.update(this.groupUpdated.id, data);
				this.closeModal();
				this.$store.dispatch('setAlertMessage', {
					message: 'Chỉnh sửa lớp nhóm thành công',
				});
				await this.fetchGroupClasses();
			} catch (e) {
				this.error = e?.response?.data?.errors_by_field;
			} finally {
				this.inProgress = false;
			}
		},

		async blockGroupClass(id) {
			this.inProgress = true;
			try {
				await $api.groupClasses.block(id);
				this.closeModal();
				this.$store.dispatch('setAlertMessage', {
					message: 'Đã khoá lớp nhóm',
				});
				await this.fetchGroupClasses();
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		async enableGroupClass(id) {
			this.inProgress = true;
			try {
				await $api.groupClasses.enable(id);
				this.closeModal();
				this.$store.dispatch('setAlertMessage', {
					message: 'Khôi phục lớp nhóm thành công',
				});
				await this.fetchGroupClasses();
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		async deleteGroupClass(id) {
			this.inProgress = true;
			try {
				await $api.groupClasses.delete(id);
				this.closeModal();
				this.$store.dispatch('setAlertMessage', {
					message: 'Đã xoá lớp nhóm',
				});
				await this.fetchGroupClasses();
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		openModalDeleted(group) {
			this.action = 'deleting';
			this.groupDeleted = group;
			this.modalIsOpen = true;
		},

		openModalBlock(group) {
			this.action = 'block';
			this.groupDeleted = group;
			this.modalIsOpen = true;
		},

		openModalCreated() {
			this.action = 'creating';
			this.groupDeleted = null;
			this.groupUpdated = null;
			this.modalIsOpen = true;
		},

		openModalUpdated(group) {
			this.action = 'updating';
			this.groupDeleted = null;
			this.groupUpdated = group;
			this.modalIsOpen = true;
		},

		closeModal() {
			this.action = '';
			this.modalIsOpen = false;
			this.groupDeleted = null;
			this.groupUpdated = null;
		},

		searching() {
			clearInterval(this.typing);
			this.typing = setTimeout(() => {
				this.search();
			}, 0);
		},

		async search() {
			this.isLoading = true;
			await this.fetchGroupClasses();
			this.isLoading = false;
		},
	},
};
</script>
