<template>
	<div class="_create-boarding _modal bg-white">
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<svg
					width="60"
					height="60"
					viewBox="0 0 60 60"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M29.8034 3.38292C15.2228 3.38292 3.36674 15.3238 3.36674 30.001C3.36674 44.6762 15.2228 56.6171 29.8034 56.6171C44.3839 56.6171 56.2452 44.6762 56.2452 30.001C56.2452 15.3238 44.3839 3.38292 29.8034 3.38292ZM29.8034 60C13.3737 60 0 46.5418 0 30.001C0 13.4582 13.3737 0 29.8034 0C46.2382 0 59.6068 13.4582 59.6068 30.001C59.6068 46.5418 46.2382 60 29.8034 60Z"
						fill="#F4C870"
					/>
					<path
						d="M52.5551 30.0009C52.5551 42.6492 42.3697 52.9025 29.8011 52.9025C17.2376 52.9025 7.05225 42.6492 7.05225 30.0009C7.05225 17.3516 17.2376 7.09787 29.8011 7.09787C42.3697 7.09787 52.5551 17.3516 52.5551 30.0009Z"
						fill="#F4C870"
					/>
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M42.5164 30C42.5164 37.0693 36.8236 42.8 29.8012 42.8C22.7787 42.8 17.0859 37.0693 17.0859 30C17.0859 22.9308 22.7787 17.2 29.8012 17.2C36.8236 17.2 42.5164 22.9308 42.5164 30ZM25.677 26.8524C25.5405 26.8524 25.4286 26.7423 25.4323 26.6059C25.5092 23.7793 27.7596 22.8 29.8097 22.8C32.0293 22.8 34.0572 23.968 34.0572 26.3852C34.0572 28.1117 33.0483 28.9344 32.0798 29.6758C30.9094 30.5695 30.4756 30.9047 30.4756 32.0524V32.3711C30.4756 32.5092 30.3637 32.6211 30.2256 32.6211H28.6439C28.5072 32.6211 28.3958 32.5112 28.3939 32.3745L28.3872 31.8797C28.3267 30.3969 29.1741 29.4828 30.2436 28.7008C31.1818 27.9899 31.7771 27.5227 31.7771 26.507C31.7771 25.1867 30.7783 24.6383 29.6887 24.6383C28.3531 24.6383 27.6313 25.4768 27.5396 26.6025C27.5284 26.7401 27.4177 26.8524 27.2796 26.8524H25.677ZM29.5172 37.1609C28.589 37.1609 27.913 36.5313 27.913 35.6781C27.913 34.7945 28.589 34.175 29.5172 34.175C30.4857 34.175 31.1516 34.7945 31.1516 35.6781C31.1516 36.5313 30.4857 37.1609 29.5172 37.1609Z"
						fill="white"
					/>
				</svg>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
					text-center
				"
			>
				Thông báo này sẽ được GỬI LẠI đến phụ huynh
			</div>
			<div
				class="
					text-center text-neutral-400
					mt-2
					font-semibold
					tracking-wide
				"
			>
				Bạn có chắc chắn muốn gửi lại?
			</div>
			<div class="w-full mt-5">
				<div
					class="input-group grid grid-cols-2 items-center mt-10"
					v-if="times"
				>
					<div class="text-neutral-500 uppercase font-medium text-sm">
						lần cuối gửi thông báo
					</div>
					<div class="font-bold text-neutral-500 truncate">
						{{ times }}
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="$emit('close')"
			>
				Huỷ
			</button>
			<button
				type="button"
				class="button-p text-center whitespace-nowrap"
				@click="$emit('submit')"
			>
				Chắc chắn
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ConfirmResend',

	props: {
		times: {
			required: true,
			type: String,
		},
	},
};
</script>
