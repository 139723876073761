<script setup>
import { ref, defineEmits, onMounted, defineProps } from 'vue';
import { $api } from '@/services';
import { useStore } from 'vuex';

const props = defineProps(['data']);
const emit = defineEmits(['success', 'close']);
const store = useStore();

const id = ref(0);
const name = ref('');
const error = ref('');
const submit = async () => {
	try {
		if (!name.value) {
			error.value = 'Tên thư mục không được để trống!';
			setTimeout(() => (error.value = ''), 3000);
			return;
		}
		let res;
		if (id.value)
			res = await $api.org.changeFolderName(id.value, {
				name: name.value,
			});
		else res = await $api.org.createDocumentsFolder({ name: name.value });
		if (res.data) {
			emit('success');
			await store.dispatch('setAlertMessage', {
				message: id.value
					? 'Đổi tên thư mục thành công'
					: 'Tạo thư mục thành công',
			});
		}
	} catch (e) {
		if (e?.response?.data?.errors_by_field['name']) {
			error.value =
				'Tên thư mục mới không được trùng với tên thư mục đã có trước đó.';
			setTimeout(() => (error.value = ''), 3000);
		}
		console.log(e);
	}
};
onMounted(() => {
	if (props.data) {
		id.value = props.data.id;
		name.value = props.data.name;
	}
});
</script>

<template>
	<div class="_create-boarding _modal bg-white">
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<img
					src="@/assets/images/illustrations/spoon.png"
					alt="Luca Education"
					title="Luca Education"
					height="60"
					width="60"
				/>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
				"
			>
				{{ name ? 'Đổi tên thư mục' : 'Tạo thư mục' }}
			</div>
			<div class="text-center text-neutral-500 mt-2">
				{{
					name
						? 'Tên thư mục mới không được trùng với tên thư mục đã có trước đó.'
						: 'Bạn hãy điền tên thư mục và ấn “Xác nhận” để tạo thư mục.'
				}}
			</div>
			<div class="w-full mt-8">
				<div class="w-full input-group flex flex-nowrap items-start">
					<div
						class="
							input-group__label
							text-neutral-400 text-xs
							font-semibold
							uppercase
							w-2/5
							whitespace-nowrap
							mt-3.5
						"
					>
						Tên thư mục
					</div>
					<div class="w-full">
						<input
							class="w-full"
							type="text"
							placeholder="Gõ tên thư mục"
							v-model="name"
							:class="error ? 'input--error' : ''"
							v-on:keydown.enter="submit"
						/>
						<div
							class="error text-sienna-600 text-sm mt-4 te"
							v-if="error"
						>
							{{ error }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="$emit('close')"
			>
				Thoát
			</button>
			<button
				type="button"
				class="button-p text-center whitespace-nowrap"
				@click="submit"
			>
				Xác nhận
			</button>
		</div>
	</div>
</template>
