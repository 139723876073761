import { ref } from 'vue';
import { $api } from '@/services';

const error = ref(null);
const loading = ref(false);

export async function listWeeklyReportOldData(
	id: string,
	oldData: any,
	params: any
) {
	error.value = null;
	try {
		const res = await $api.weeklyReport.weeklyReportsListOldData(
			id,
			oldData,
			params
		);
		if (!res) throw new Error('Lỗi');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export function useListWeeklyReportOldData() {
	return { error, loading, listWeeklyReportOldData };
}
