
import { Vue, Options } from 'vue-class-component';
import SwitchButton from '@/components/elements/switchButton/SwitchButton.vue';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
	name: 'TargetSmart',
	components: {
		SwitchButton,
	},
})
export default class TargetSmart extends Vue {
	//----------------------- 🤍 Props 🤍 -----------------------//
	@Prop() org!: any;

	//----------------------- 🤍 Data 🤍 -----------------------//
	enable_goal_process = false;
	enable_goal_reason = false;
	enable_goal_strategy = false;
	enable_goal_assistance_level = false;
	share_goal_library = false;
	onChange = false;
	onWatchOrg = true;

	//----------------------- 🤍 Watch 🤍 -----------------------//
	@Watch('org', { deep: true, immediate: true })
	onOrgChange(org: any) {
		if (org && this.onWatchOrg) {
			this.share_goal_library = org?.share_goal_library;
			this.enable_goal_process = org?.enable_goal_process;
			this.enable_goal_reason = org?.enable_goal_reason;
			this.enable_goal_strategy = org?.enable_goal_strategy;
			this.enable_goal_assistance_level =
				org?.enable_goal_assistance_level;
			setTimeout(() => {
				this.onChange = true;
			}, 1000);
			this.onWatchOrg = false;
		}
	}

	@Watch('share_goal_library')
	onShareGoalLibrary(value: any) {
		if (this.onChange) {
			this.$emit('updateOrg', {
				share_goal_library: value,
			});
		}
	}

	@Watch('enable_goal_process')
	onGoalProcess(value: any) {
		if (this.onChange) {
			this.$emit('updateOrg', {
				enable_goal_process: value,
			});
		}
	}

	@Watch('enable_goal_reason')
	onGoalReason(value: any) {
		if (this.onChange) {
			this.$emit('updateOrg', {
				enable_goal_reason: value,
			});
		}
	}

	@Watch('enable_goal_strategy')
	onGoalStrategy(value: any) {
		if (this.onChange) {
			this.$emit('updateOrg', {
				enable_goal_strategy: value,
			});
		}
	}

	@Watch('enable_goal_assistance_level')
	onGoalAssistance(value: any) {
		if (this.onChange) {
			this.$emit('updateOrg', {
				enable_goal_assistance_level: value,
			});
		}
	}
}
