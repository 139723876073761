<template>
	<Popper :arrow="true" :placement="placement" hover>
		<div class="cursor-pointer">
			<div class="isolate flex -space-x-2 overflow-hidden">
				<div
					v-for="(item, index) in data.slice(0, 4)"
					:key="item?.id"
					:style="{ zIndex: data?.length - index }"
					class="ring-2 ring-white rounded-full"
				>
					<img
						v-if="item?.profile_photo?.w200"
						:class="`h-${size} w-${size}`"
						:src="item?.profile_photo?.w200"
						:style="{
							minHeight: `${size * 4}px`,
							minWidth: `${size * 4}px`,
						}"
						alt=""
						class="shadow-sm inline-block rounded-full"
					/>
					<span
						v-else
						:class="`h-${size} w-${size}`"
						:style="{
							background: item?.profile_photo?.default?.color,
						}"
						class="
							inline-flex
							items-center
							justify-center
							rounded-full
						"
					>
						<span
							class="font-medium leading-none text-white text-xs"
							>{{ item?.profile_photo?.default?.initial }}</span
						>
					</span>
				</div>
				<span
					v-if="data?.length <= 0"
					:class="`h-${size} w-${size}`"
					class="
						inline-flex
						items-center
						justify-center
						rounded-full
						bg-neutral-400
					"
				>
					<span class="font-medium leading-none text-white">#</span>
				</span>
				<span
					v-if="data?.length > 4"
					:class="`h-${size} w-${size}`"
					class="
						inline-flex
						items-center
						justify-center
						rounded-full
						bg-neutral-400
					"
				>
					<span class="font-medium leading-none text-white"
						>+{{ data?.length - 4 }}</span
					>
				</span>
			</div>
		</div>
		<template #content>
			<div class="flex flex-wrap gap-3" style="max-width: 360px">
				<div
					v-for="(item, index) in [...data].reverse()"
					:key="item?.id"
					class="flex flex-nowrap gap-3 items-center"
					style="width: 160px"
				>
					<div
						:style="{ zIndex: data?.length - index }"
						class="rounded-full"
					>
						<img
							v-if="item?.profile_photo?.w200"
							:class="`h-${size - 1} w-${size - 1}`"
							:src="item?.profile_photo?.w200"
							alt=""
							class="shadow-sm inline-block rounded-full"
						/>
						<span
							v-else
							:class="`h-${size - 1} w-${size - 1}`"
							:style="{
								background: item?.profile_photo?.default?.color,
							}"
							class="
								inline-flex
								items-center
								justify-center
								rounded-full
							"
						>
							<span
								class="
									font-medium
									leading-none
									text-white text-xs
								"
								>{{
									item?.profile_photo?.default?.initial
								}}</span
							>
						</span>
					</div>
					<div
						:style="{ color: item?.profile_photo?.default?.color }"
						class="
							font-semibold
							cursor-pointer
							hover:underline
							text-sm
							py-1
						"
						@click.stop="$router.push(`/record/student/${item.id}`)"
					>
						{{ item?.name }}
					</div>
				</div>
			</div>
		</template>
	</Popper>
</template>
<script>
import Popper from 'vue3-popper';

export default {
	name: 'UserGroup',
	components: { Popper },

	props: {
		data: {
			type: Array,
			required: true,
		},

		size: {
			type: Number,
			default: 9,
		},

		placement: {
			type: String,
			default: 'bottom',
		},
	},
};
</script>
