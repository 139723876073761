
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { staff } from '@/views/record/employeeDetail/@types/staff.interface';
import Multiselect from '@suadelabs/vue3-multiselect';

@Options({
	name: 'ChangeStatus',
	components: { Multiselect },
})
export default class ChangeStatus extends Vue {
	@Prop({ default: false }) activeMode?: boolean;
	@Prop({ default: true }) isStudent?: boolean;
	@Prop() staff!: staff;

	state =
		this.staff.state !== 'active'
			? {
					id: 'active',
					name: 'Đang học',
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  }
			: null;

	get states() {
		const result = [];
		if (this.staff.state !== 'active') {
			result.push({
				id: 'active',
				name: 'Đang học',
			});
		}
		return [
			...result,
			{ id: 'pending', name: 'Chưa nhập học' },
			{
				id: 'graduated',
				name: 'Tốt nghiệp',
			},
			{
				id: 'closed',
				name: 'Tạm dừng',
			},
			{
				id: 'stopped',
				name: 'Xin nghỉ',
			},
		];
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	close() {
		this.$emit('close');
	}

	submit() {
		if (['active'].includes(String(this.state?.id))) {
			this.$emit('active');
		} else {
			this.$emit('block', { state: this.state });
		}
	}
}
