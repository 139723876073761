
import { Vue, Options, setup } from 'vue-class-component';
import { useRegister } from './services/register';
import { useCheckToken } from './services/checkToken';
import { _ROUTER_NAME } from '@/enums';
import { _var } from '@/enums/Common';
import ChooseCenterCard from '@/components/elements/cards/chooseCenterCard/ChooseCenterCard.vue';
import { useStore } from 'vuex';

@Options({
	name: 'Register',
	components: { ChooseCenterCard },
})
export default class Register extends Vue {
	//----------------------- 🤍 Data 🤍 -----------------------//
	chooseCenterStage = false;
	typePassword = 'password';
	orgs = [];
	registerData = {
		name: '',
		username: '',
		password: '',
		password_confirmation: '',
	};

	token: string | null = '';

	errorData = {
		name: '',
		username: '',
		password: '',
		password_confirmation: '',
		status: false,
	};

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const store = useStore();
		const { error, loading, register } = useRegister();
		const { checkToken__error, checkToken } = useCheckToken();
		return {
			store,
			error,
			loading,
			register,
			checkToken,
			checkToken__error,
		};
	});

	//----------------------- 🤍 Created 🤍 -----------------------//
	async created() {
		localStorage.clear();
		this.token = this.$route?.query?.token
			? this.$route?.query?.token + ''
			: null;
		if (!this.token) {
			return this.$router.push({
				name: _ROUTER_NAME.LOGIN,
			});
		}
		const res = await this.setup.checkToken({
			token: this.$route?.query?.token
				? this.$route?.query?.token + ''
				: null,
		});
		if (res?.data) {
			this.registerData.username = res?.data?.email;
		}

		//@ts-ignore
		if (this.setup.checkToken__error?.response?.data?.errors) {
			await this.$router.push({
				name: _ROUTER_NAME.INVALID_TOKEN,
			});
		}
	}

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get Error() {
		const error: any = this.setup.error;
		if (error) {
			return error.join('<br/>');
		}
		return error;
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	async onSubmit() {
		this.errorData = {
			name: '',
			username: '',
			password: '',
			password_confirmation: '',
			status: false,
		};
		if (this.registerData.name.length < 1) {
			this.errorData.name = 'Tên không được để trống';
			this.errorData.status = true;
		}
		if (this.registerData.password.length < 8) {
			this.errorData.password =
				'Phải có ít nhất 8 ký tự (bao gồm chữ và số)';
			this.errorData.status = true;
		} else if (
			!/[0-9]/.test(this.registerData.password) ||
			!/[a-zA-Z]/.test(this.registerData.password)
		) {
			this.errorData.password = 'Mật khẩu bao gồm cả chữ và số';
			this.errorData.status = true;
		}
		if (
			this.registerData.password !=
			this.registerData.password_confirmation
		) {
			this.errorData.password_confirmation =
				'Xác nhận mật khẩu không khớp';
			this.errorData.status = true;
		}
		if (this.errorData.status) {
			return;
		}
		try {
			const { data } = await this.setup.register({
				account: {
					name: this.registerData.name,
					password: this.registerData.password,
					password_confirmation:
						this.registerData.password_confirmation,
				},
				token: this.token,
			});

			if (data) {
				localStorage.setItem(_var.TOKEN, data?.access_token);
				if (data?.orgs) {
					if (data?.orgs.length === 1) {
						localStorage.setItem(
							_var.SUBDOMAIN,
							data?.orgs[0].subdomain
						);
						return (window.location.href = '/');
					} else if (data?.orgs.length <= 0) {
						await this.$router.push('/signin');
					}
					this.chooseCenterStage = true;
					this.orgs = data?.orgs;
				}
			}
		} catch (e) {
			if (e?.response?.data?.errors) {
				await this.$router.push({
					name: _ROUTER_NAME.INVALID_TOKEN,
				});
			} else if (e?.response?.data?.errors_by_field) {
				this.errorData.password = e?.response?.data?.errors_by_field;
				this.errorData.status = true;
			} else {
				await this.setup.store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		}
	}

	selectOrg(org: any) {
		localStorage.setItem(_var.SUBDOMAIN, org.subdomain);
		return (window.location.href = '/');
	}
}
