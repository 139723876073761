<template>
	<div class="_change-status _modal bg-white">
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<svg fill="none" height="60" viewBox="0 0 60 60" width="60">
					<path
						d="M29.8034 3.38292C15.2228 3.38292 3.36674 15.3238 3.36674 30.001C3.36674 44.6762 15.2228 56.6171 29.8034 56.6171C44.3839 56.6171 56.2452 44.6762 56.2452 30.001C56.2452 15.3238 44.3839 3.38292 29.8034 3.38292ZM29.8034 60C13.3737 60 0 46.5418 0 30.001C0 13.4582 13.3737 0 29.8034 0C46.2382 0 59.6068 13.4582 59.6068 30.001C59.6068 46.5418 46.2382 60 29.8034 60Z"
						fill="#E37056"
					/>
					<path
						d="M52.5556 30.0008C52.5556 42.6491 42.3702 52.9023 29.8016 52.9023C17.2381 52.9023 7.05273 42.6491 7.05273 30.0008C7.05273 17.3515 17.2381 7.09775 29.8016 7.09775C42.3702 7.09775 52.5556 17.3515 52.5556 30.0008Z"
						fill="#F4C870"
					/>
					<path
						d="M29.7892 36.6177C29.0372 36.6177 28.3904 36.8826 27.869 37.408C27.3527 37.9313 27.0869 38.5748 27.0869 39.3217C27.0869 40.1721 27.3677 40.8489 27.9242 41.3324C28.4606 41.8028 29.0973 42.041 29.8192 42.041C30.5311 42.041 31.1628 41.7993 31.6943 41.3228C32.2457 40.8323 32.5215 40.159 32.5215 39.3217C32.5215 38.5733 32.2508 37.9278 31.7193 37.4045C31.1879 36.8811 30.5362 36.6177 29.7892 36.6177Z"
						fill="white"
					/>
					<path
						d="M27.7136 31.5484V31.56C27.8088 32.5688 27.9743 33.3092 28.2049 33.8225C28.4907 34.4377 29.0171 34.7627 29.729 34.7627C30.4259 34.7627 30.9523 34.4326 31.2581 33.8073C31.5188 33.2739 31.6792 32.5471 31.7544 31.5882L32.4112 24.024C32.4864 23.3154 32.5215 22.6084 32.5215 21.923C32.5215 20.7012 32.361 19.7806 32.0352 19.1074C31.7845 18.5835 31.208 17.9602 29.9095 17.9602C29.0723 17.9602 28.3804 18.2454 27.859 18.8056C27.3476 19.3592 27.0869 20.1243 27.0869 21.0797C27.0869 21.71 27.1321 22.7567 27.2273 24.1941L27.7136 31.5484Z"
						fill="white"
					/>
				</svg>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
				"
			>
				Bạn có muốn thay đổi trạng thái của tài khoản này?
			</div>

			<div class="text-center text-neutral-500 mt-2">
				Bạn vui lòng chọn trạng thái mới cho tài khoản học sinh này.
			</div>
			<div class="w-full mt-5">
				<div class="input-group grid grid-cols-2 items-center mt-5">
					<div
						class="input-group__label text-neutral-500 font-normal"
					>
						Tên đầy đủ
					</div>
					<div class="font-bold text-neutral-500 text-right">
						{{ props.student.full_name }}
					</div>
				</div>
				<div class="input-group grid grid-cols-2 items-center mt-5">
					<div
						class="input-group__label text-neutral-500 font-normal"
					>
						Tên hiển thị
					</div>
					<div class="font-bold text-neutral-500 text-right">
						{{ props.student.name }}
					</div>
				</div>
				<div class="input-group grid grid-cols-2 items-center mt-5">
					<div
						class="
							input-group__label
							text-neutral-500
							font-normal
							w-2/5
						"
					>
						Trạng thái
					</div>
					<div class="w-full">
						<div class="bg-white select-tabs">
							<Multiselect
								v-model="state"
								:allow-empty="false"
								:hideSelected="true"
								:options="states"
								:searchable="false"
								placeholder="Chọn trạng thái cho học sinh"
								selectLabel=""
								track-by="id"
							>
								<template #noResult>
									Không tìm thấy kết quả
								</template>
								<template v-slot:option="props">
									<div class="flex flex-nowrap items-center">
										{{ props.option.name }}
									</div>
								</template>
								<template v-slot:singleLabel="{ option }">
									<div
										class="
											flex flex-nowrap
											items-center
											font-medium
										"
									>
										{{ option.name }}
									</div>
								</template>
							</Multiselect>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				type="button"
				@click="close"
			>
				Thoát
			</button>
			<button
				class="button-p text-center whitespace-nowrap"
				type="button"
				@click="submit"
			>
				Xác nhận
			</button>
		</div>
	</div>
</template>

<script setup>
import Multiselect from '@suadelabs/vue3-multiselect';
import { ref, defineProps, defineEmits } from 'vue';

const props = defineProps(['student']);
const emit = defineEmits(['close', 'block', 'active']);
const state = ref({});

const states = ref([
	{ id: 'active', name: 'Đang học' },
	{ id: 'pending', name: 'Chưa nhập học' },
	{
		id: 'graduated',
		name: 'Tốt nghiệp',
	},
	{
		id: 'closed',
		name: 'Tạm dừng',
	},
	{
		id: 'stopped',
		name: 'Xin nghỉ',
	},
]);

const close = () => {
	emit('close');
};

const submit = () => {
	if (['active', 'pending'].includes(String(state.value.id))) {
		emit('active', state.value.id == 'pending' ? 'pending' : {});
	} else {
		emit('block', state.value.id);
	}
};
</script>
