<template>
	<DaModalFrame v-model:open="modalIsOpen">
		<ErrorUploading
			v-if="list_student_have_no_assigment.length"
			:list_student_have_no_assigment="list_student_have_no_assigment"
			@close="modalIsOpen = false"
			@submit="submit"
		/>
	</DaModalFrame>
	<DaModalFrame v-model:open="modalIsOpenOverlap">
		<ErrorUploadingOverlap
			v-if="
				staff_time_slots_overlap.length ||
				student_time_slots_overlap.length
			"
			:data-staffs="staff_time_slots_overlap"
			:data-students="student_time_slots_overlap"
			:is-student-mode="isStudentMode"
			@close="modalIsOpenOverlap = false"
			@submit="submit"
		/>
	</DaModalFrame>
	<DaModalFrame v-model:open="modalIsOpenNotFound">
		<ErrorUploadingNotFound
			v-if="list_deleted.length"
			:data="list_deleted"
			@close="modalIsOpenNotFound = false"
			@submit="submit"
		/>
	</DaModalFrame>
	<input
		id="upload-file-timetable"
		hidden
		type="file"
		@change="uploadChange"
	/>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 w-screen md:w-2/5">
		<div
			class="
				h-full
				w-full
				flex flex-col
				bg-white
				shadow-xl
				overflow-y-auto
			"
		>
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center" @click="$emit('close')">
						<svg
							fill="none"
							height="24"
							viewBox="0 0 24 24"
							width="24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M6 0.75C6 0.335786 5.66421 0 5.25 0C4.83579 0 4.5 0.335786 4.5 0.75V1.5H3C1.34315 1.5 0 2.84315 0 4.5V6H24V4.5C24 2.84315 22.6569 1.5 21 1.5H19.5V0.75C19.5 0.335786 19.1642 0 18.75 0C18.3358 0 18 0.335786 18 0.75V1.5H6V0.75ZM24 21V7.5H0V21C0 22.6569 1.34315 24 3 24H21C22.6569 24 24 22.6569 24 21ZM12.75 12.75C12.75 12.3358 12.4142 12 12 12C11.5858 12 11.25 12.3358 11.25 12.75V15H9C8.58579 15 8.25 15.3358 8.25 15.75C8.25 16.1642 8.58579 16.5 9 16.5H11.25V18.75C11.25 19.1642 11.5858 19.5 12 19.5C12.4142 19.5 12.75 19.1642 12.75 18.75V16.5H15C15.4142 16.5 15.75 16.1642 15.75 15.75C15.75 15.3358 15.4142 15 15 15H12.75V12.75Z"
								fill="#94A3B8"
								fill-rule="evenodd"
							/>
						</svg>
						<div
							class="
								text-2xl
								font-bold
								text-neutral-700
								ml-2
								mt-1
							"
						>
							<div v-if="step === 0">
								{{
									isEdit
										? 'Chỉnh sửa thời khoá biểu'
										: 'Tạo thời khoá biểu'
								}}
							</div>
							<div v-else>Tạo thời khoá biểu can thiệp</div>
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							type="button"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								aria-hidden="true"
								class="h-6 w-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6 18L18 6M6 6l12 12"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div class="sideover-content mt-6 relative flex-1 px-4 sm:px-6">
				<div>
					<div class="font-semibold text-neutral-600">
						<div v-if="step === 0">
							Bước 1: Chọn ngày áp dụng THỜI KHOÁ BIỂU
						</div>
						<div v-else>
							Bước 2: Tải lên Lịch can thiệp cá nhân của cả cơ sở
						</div>
					</div>
					<div class="text-gray-500 mt-4">
						<div v-if="step === 0">
							Ngày áp dụng là ngày TKB (gồm Lịch can thiệp cá nhân
							và bán trú) sẽ có hiệu lực, các báo cáo ngày (cá
							nhân) sẽ được tạo ra dựa vào buổi học trong Lịch can
							thiệp cá nhân mà bạn vừa tải lên.
						</div>
						<div v-else>
							Luca cung cấp tệp Lịch can thiệp cá nhân mẫu để bạn
							điền và nhập liệu nhanh chóng khi thiếp lập cho toàn
							cơ sở.
						</div>
					</div>
					<div v-if="step === 0">
						<div class="font-semibold text-neutral-600 mt-10">
							Chọn ngày
						</div>
						<div class="w-full mt-2">
							<div class="input-group flex items-center">
								<VueDatepicker
									v-model="applyDate"
									:enableTimePicker="false"
									:min-date="new Date()"
									autoApply
									class="input-date w-full"
									format="dd/MM/yyyy"
									placeholder="Chọn ngày áp dụng"
									uid="upload-timetable-id"
								>
									<template #input-icon>
										<DaIcon
											class="h-4 w-4 text-neutral-400"
											name="das--lock"
										/>
									</template>
								</VueDatepicker>
							</div>
						</div>
						<div
							v-if="applyDate"
							class="rounded-md bg-orange-50 p-4 mt-10"
						>
							<div class="flex">
								<div class="flex-shrink-0">
									<svg
										fill="none"
										height="20"
										viewBox="0 0 20 20"
										width="20"
										xmlns="http://www.w3.org/2000/svg"
									>
										<g clip-path="url(#clip0_19328_29044)">
											<path
												d="M10.0003 18.3327C14.6027 18.3327 18.3337 14.6017 18.3337 9.99935C18.3337 5.39698 14.6027 1.66602 10.0003 1.66602C5.39795 1.66602 1.66699 5.39698 1.66699 9.99935C1.66699 14.6017 5.39795 18.3327 10.0003 18.3327Z"
												stroke="#F59F0A"
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
											/>
											<path
												d="M10 13.3333V10"
												stroke="#F59F0A"
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
											/>
											<path
												d="M10 6.66602H10.0083"
												stroke="#F59F0A"
												stroke-linecap="round"
												stroke-linejoin="round"
												stroke-width="2"
											/>
										</g>
										<defs>
											<clipPath id="clip0_19328_29044">
												<rect
													fill="white"
													height="20"
													width="20"
												/>
											</clipPath>
										</defs>
									</svg>
								</div>
								<div class="ml-3">
									<h3 class="font-semibold text-orange-600">
										Áp dụng từ
										{{ timeNow }} ngày
										{{ $filters.formatDate(applyDate) }}
									</h3>
									<div class="mt-1 text-sm text-orange-600">
										<p>
											Vì TKB sẽ được áp dụng từ thời điểm
											tải lên, nên các buổi học và báo cáo
											trước thời điểm này sẽ không được áp
											dụng. Bạn có thể thêm "Buổi học phát
											sinh" để bổ sung cho các buổi học bị
											thiếu của ngày hôm nay.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-else>
						<div
							v-if="file"
							class="
								text-neutral-500
								flex
								items-center
								justify-between
								gap-2
								mt-6
							"
						>
							<div class="flex items-center gap-2">
								<svg
									fill="none"
									height="32"
									viewBox="0 0 32 32"
									width="32"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										clip-rule="evenodd"
										d="M4 4C4 1.79086 5.79086 0 8 0H18.5858C19.1162 0 19.6249 0.210714 20 0.585786L27.4142 8C27.7893 8.37507 28 8.88378 28 9.41421V28C28 30.2091 26.2091 32 24 32H8C5.79086 32 4 30.2091 4 28V4ZM19 7V3L25 9H21C19.8954 9 19 8.10457 19 7ZM21.7071 15.7071C22.0976 15.3166 22.0976 14.6834 21.7071 14.2929C21.3166 13.9024 20.6834 13.9024 20.2929 14.2929L15 19.5858L12.7071 17.2929C12.3166 16.9024 11.6834 16.9024 11.2929 17.2929C10.9024 17.6834 10.9024 18.3166 11.2929 18.7071L14.2929 21.7071C14.4804 21.8946 14.7348 22 15 22C15.2652 22 15.5196 21.8946 15.7071 21.7071L21.7071 15.7071Z"
										fill="#E5A42B"
										fill-rule="evenodd"
									/>
								</svg>
								<div class="text-sm">{{ file.name || '' }}</div>
							</div>
							<div
								class="flex items-center gap-2 cursor-pointer"
								@click="openModelUpload"
							>
								<svg
									fill="none"
									height="16"
									viewBox="0 0 16 16"
									width="16"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										clip-rule="evenodd"
										d="M8 0C6.57359 0 5.31176 0.560456 4.40576 1.34182C3.63985 2.00237 3.08479 2.8617 2.94223 3.725C1.26586 4.09512 0 5.55511 0 7.31818C0 9.36599 1.70754 11 3.78125 11H7.5V5.70711L5.35355 7.85355C5.15829 8.04882 4.84171 8.04882 4.64645 7.85355C4.45118 7.65829 4.45118 7.34171 4.64645 7.14645L7.64645 4.14645C7.84171 3.95118 8.15829 3.95118 8.35355 4.14645L11.3536 7.14645C11.5488 7.34171 11.5488 7.65829 11.3536 7.85355C11.1583 8.04882 10.8417 8.04882 10.6464 7.85355L8.5 5.70711V11H12.6875C14.5023 11 16 9.56949 16 7.77273C16 6.13656 14.7581 4.80411 13.1657 4.57898C12.9233 1.99944 10.6902 0 8 0Z"
										fill="#E5A42B"
										fill-rule="evenodd"
									/>
									<path
										clip-rule="evenodd"
										d="M7.5 14.5V11H8.5V14.5C8.5 14.7761 8.27614 15 8 15C7.72386 15 7.5 14.7761 7.5 14.5Z"
										fill="#E5A42B"
										fill-rule="evenodd"
									/>
								</svg>

								<span
									class="
										text-sm text-yellow-700
										font-semibold
									"
									>Tải lên tệp khác</span
								>
							</div>
						</div>
						<div
							v-else
							class="
								text-neutral-500
								flex flex-col
								items-center
								justify-senter
								text-center
								gap-2
								rounded-lg
								mt-6
								p-8
								cursor-pointer
								border-2 border-dashed
								hover:border-yellow-500
							"
							@click="openModelUpload"
						>
							<svg
								fill="none"
								height="48"
								viewBox="0 0 48 48"
								width="48"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									clip-rule="evenodd"
									d="M24 0C19.7208 0 15.9353 1.68137 13.2173 4.02545C10.9196 6.0071 9.25438 8.5851 8.82668 11.175C3.79759 12.2853 0 16.6653 0 21.9545C0 28.098 5.12261 33 11.3438 33H22.5V17.1213L16.0607 23.5607C15.4749 24.1464 14.5251 24.1464 13.9393 23.5607C13.3536 22.9749 13.3536 22.0251 13.9393 21.4393L22.9393 12.4393C23.5251 11.8536 24.4749 11.8536 25.0607 12.4393L34.0607 21.4393C34.6464 22.0251 34.6464 22.9749 34.0607 23.5607C33.4749 24.1464 32.5251 24.1464 31.9393 23.5607L25.5 17.1213V33H38.0625C43.507 33 48 28.7085 48 23.3182C48 18.4097 44.2743 14.4123 39.4971 13.7369C38.7699 5.99831 32.0707 0 24 0Z"
									fill="#F0BB4F"
									fill-rule="evenodd"
								/>
								<path
									clip-rule="evenodd"
									d="M22.5 43.5V33H25.5V43.5C25.5 44.3284 24.8284 45 24 45C23.1716 45 22.5 44.3284 22.5 43.5Z"
									fill="#F0BB4F"
									fill-rule="evenodd"
								/>
							</svg>
							<div class="font-semibold">
								Tải lên Lịch can thiệp cá nhân của cơ sở
							</div>
							<div class="text-sm">
								Lưu ý cần sử dụng đúng định dạng Lịch can thiệp
								cá nhân mà Luca cung cấp
							</div>
						</div>
					</div>
				</div>
				<div
					v-if="step === 1 && isEdit && !file"
					class="flex items-center justify-center my-10"
				>
					<div
						class="
							text-yellow-600
							hover:text-yellow-700
							cursor-pointer
							flex
							items-center
							flex-nowrap
							font-semibold
							gap-2
						"
						@click="downloadFileCurrent"
					>
						Tải xuống Lịch can thiệp cá nhân hiện có của cơ sở
						<svg
							fill="none"
							height="24"
							viewBox="0 0 25 24"
							width="25"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M12.5 0C10.3604 0 8.46764 0.840683 7.10864 2.01273C5.95978 3.00355 5.12719 4.29255 4.91334 5.5875C2.39879 6.14267 0.5 8.33267 0.5 10.9773C0.5 14.049 3.06131 16.5 6.17188 16.5H11.75V8.25C11.75 7.83579 12.0858 7.5 12.5 7.5C12.9142 7.5 13.25 7.83579 13.25 8.25V16.5H19.5312C22.2535 16.5 24.5 14.3542 24.5 11.6591C24.5 9.20484 22.6371 7.20616 20.2485 6.86847C19.8849 2.99916 16.5354 0 12.5 0Z"
								fill="currentColor"
								fill-rule="evenodd"
							/>
							<path
								clip-rule="evenodd"
								d="M11.9697 23.7803C12.2626 24.0732 12.7374 24.0732 13.0303 23.7803L17.5303 19.2803C17.8232 18.9874 17.8232 18.5126 17.5303 18.2197C17.2374 17.9268 16.7626 17.9268 16.4697 18.2197L13.25 21.4393V16.5H11.75V21.4393L8.53033 18.2197C8.23744 17.9268 7.76256 17.9268 7.46967 18.2197C7.17678 18.5126 7.17678 18.9874 7.46967 19.2803L11.9697 23.7803Z"
								fill="currentColor"
								fill-rule="evenodd"
							/>
						</svg>
					</div>
				</div>
				<div v-if="isAlertShowNotFound && step === 1" class="mt-6">
					<div class="rounded p-4 bg-sienna-50 text-sienna-600">
						<div class="flex">
							<div class="flex-shrink-0">
								<svg
									fill="none"
									height="20"
									viewBox="0 0 20 20"
									width="20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g clip-path="url(#clip0_15931_30659)">
										<path
											d="M10.0003 18.3327C14.6027 18.3327 18.3337 14.6017 18.3337 9.99935C18.3337 5.39698 14.6027 1.66602 10.0003 1.66602C5.39795 1.66602 1.66699 5.39698 1.66699 9.99935C1.66699 14.6017 5.39795 18.3327 10.0003 18.3327Z"
											stroke="#B83E27"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<path
											d="M10 13.3333V10"
											stroke="#B83E27"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<path
											d="M10 6.66602H10.0083"
											stroke="#B83E27"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
									</g>
									<defs>
										<clipPath id="clip0_15931_30659">
											<rect
												fill="white"
												height="20"
												width="20"
											/>
										</clipPath>
									</defs>
								</svg>
							</div>
							<div class="ml-3">
								<span
									class="text-sm font-medium text-sienna-600"
								>
									Tải mẫu tệp không thành công.
								</span>
								<span
									class="
										text-sm text-sienna-600
										underline
										cursor-pointer
									"
									@click="modalIsOpenNotFound = true"
									>Xem chi tiết.</span
								>
							</div>
						</div>
					</div>
				</div>
				<div v-if="isAlertShowOverlap && step === 1" class="mt-6">
					<div class="rounded p-4 bg-orange-50 text-orange-600">
						<div class="flex">
							<div class="flex-shrink-0">
								<svg
									fill="none"
									height="20"
									viewBox="0 0 20 20"
									width="20"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g clip-path="url(#clip0_15931_30667)">
										<path
											d="M10.0003 18.3327C14.6027 18.3327 18.3337 14.6017 18.3337 9.99935C18.3337 5.39698 14.6027 1.66602 10.0003 1.66602C5.39795 1.66602 1.66699 5.39698 1.66699 9.99935C1.66699 14.6017 5.39795 18.3327 10.0003 18.3327Z"
											stroke="#F59F0A"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<path
											d="M10 13.3333V10"
											stroke="#F59F0A"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
										<path
											d="M10 6.66602H10.0083"
											stroke="#F59F0A"
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
										/>
									</g>
									<defs>
										<clipPath id="clip0_15931_30667">
											<rect
												fill="white"
												height="20"
												width="20"
											/>
										</clipPath>
									</defs>
								</svg>
							</div>
							<div class="ml-3">
								<div v-if="student_time_slots_overlap.length">
									<span
										class="
											text-sm
											font-medium
											text-orange-600
										"
									>
										Học sinh bị trùng buổi học.
									</span>
									<span
										class="
											text-sm text-orange-600
											underline
											cursor-pointer
										"
										@click="openModalErrorOverlap(true)"
										>Xem chi tiết.</span
									>
								</div>
								<div v-if="staff_time_slots_overlap.length">
									<span
										class="
											text-sm
											font-medium
											text-orange-600
										"
									>
										Giáo viên bị trùng buổi dạy.
									</span>
									<span
										class="
											text-sm text-orange-600
											underline
											cursor-pointer
										"
										@click="openModalErrorOverlap(false)"
										>Xem chi tiết.</span
									>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="isAlertShow && step === 1">
				<AlertUpload
					:message="message"
					:type="type"
					@close="closeAlert"
				/>
			</div>
			<div
				v-if="step === 1"
				class="flex items-center justify-center my-10 md:hidden"
			>
				<div
					class="
						text-yellow-600
						hover:text-yellow-700
						cursor-pointer
						flex
						items-center
						flex-nowrap
						underline
						gap-2
						text-sm
					"
					@click="downloadFileNew"
				>
					<svg
						fill="none"
						height="16"
						viewBox="0 0 16 16"
						width="16"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							clip-rule="evenodd"
							d="M8 0C6.57359 0 5.31176 0.560456 4.40576 1.34182C3.63985 2.00237 3.08479 2.8617 2.94223 3.725C1.26586 4.09512 0 5.55511 0 7.31818C0 9.36599 1.70754 11 3.78125 11H7.5V5.5C7.5 5.22386 7.72386 5 8 5C8.27614 5 8.5 5.22386 8.5 5.5V11H12.6875C14.5023 11 16 9.56949 16 7.77273C16 6.13656 14.7581 4.80411 13.1657 4.57898C12.9233 1.99944 10.6902 0 8 0Z"
							fill="currentColor"
							fill-rule="evenodd"
						/>
						<path
							clip-rule="evenodd"
							d="M7.64645 15.8536C7.84171 16.0488 8.15829 16.0488 8.35355 15.8536L11.3536 12.8536C11.5488 12.6583 11.5488 12.3417 11.3536 12.1464C11.1583 11.9512 10.8417 11.9512 10.6464 12.1464L8.5 14.2929V11H7.5V14.2929L5.35355 12.1464C5.15829 11.9512 4.84171 11.9512 4.64645 12.1464C4.45118 12.3417 4.45118 12.6583 4.64645 12.8536L7.64645 15.8536Z"
							fill="currentColor"
							fill-rule="evenodd"
						/>
					</svg>

					Tải xuống Lịch can thiệp cá nhân mẫu
				</div>
			</div>
			<div
				class="
					px-6
					py-4
					border-t border-yellow-50
					flex
					justify-end
					md:justify-between
					items-center
					gap-6
				"
			>
				<div
					v-if="step === 1"
					class="
						flex
						items-center
						justify-center
						my-10
						md-down:hidden
					"
				>
					<div
						class="
							text-yellow-600
							hover:text-yellow-700
							cursor-pointer
							flex
							items-center
							flex-nowrap
							underline
							gap-2
							text-sm
						"
						@click="downloadFileNew"
					>
						<svg
							fill="none"
							height="16"
							viewBox="0 0 16 16"
							width="16"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								clip-rule="evenodd"
								d="M8 0C6.57359 0 5.31176 0.560456 4.40576 1.34182C3.63985 2.00237 3.08479 2.8617 2.94223 3.725C1.26586 4.09512 0 5.55511 0 7.31818C0 9.36599 1.70754 11 3.78125 11H7.5V5.5C7.5 5.22386 7.72386 5 8 5C8.27614 5 8.5 5.22386 8.5 5.5V11H12.6875C14.5023 11 16 9.56949 16 7.77273C16 6.13656 14.7581 4.80411 13.1657 4.57898C12.9233 1.99944 10.6902 0 8 0Z"
								fill="currentColor"
								fill-rule="evenodd"
							/>
							<path
								clip-rule="evenodd"
								d="M7.64645 15.8536C7.84171 16.0488 8.15829 16.0488 8.35355 15.8536L11.3536 12.8536C11.5488 12.6583 11.5488 12.3417 11.3536 12.1464C11.1583 11.9512 10.8417 11.9512 10.6464 12.1464L8.5 14.2929V11H7.5V14.2929L5.35355 12.1464C5.15829 11.9512 4.84171 11.9512 4.64645 12.1464C4.45118 12.3417 4.45118 12.6583 4.64645 12.8536L7.64645 15.8536Z"
								fill="currentColor"
								fill-rule="evenodd"
							/>
						</svg>

						Tải xuống Lịch can thiệp cá nhân mẫu
					</div>
				</div>
				<div v-else></div>
				<div class="flex justify-end">
					<div>
						<button
							v-if="step === 0"
							:disabled="!applyDate"
							class="button-p text-center w-32"
							type="button"
							@click="step = 1"
						>
							Tiếp tục
						</button>
						<button
							v-else
							:disabled="isAlertShowNotFound || isAlertShow"
							class="button-p text-center w-32"
							type="button"
							@click="submit"
						>
							Hoàn thành
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import AlertUpload from '@/components/elements/alert/AlertUpload';
import DaIcon from '@/components/elements/daIcon/DaIcon';
import { $api } from '@/services';
import dayjs from 'dayjs';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame';
import ErrorUploading from '@/views/timetable/features/ErrorUploading';
import ErrorUploadingNotFound from '@/views/timetable/features/ErrorUploadingNotFound';
import ErrorUploadingOverlap from '@/views/timetable/features/ErrorUploadingOverlap';

export default defineComponent({
	name: 'UploadTimeTable',

	components: {
		ErrorUploadingOverlap,
		ErrorUploadingNotFound,
		ErrorUploading,
		DaModalFrame,
		AlertUpload,
		DaIcon,
	},

	props: {
		isEdit: {
			type: Boolean,
			required: true,
		},

		isShow: {
			type: Boolean,
			required: true,
		},
	},

	data() {
		return {
			file: null,
			isAlertShow: false,
			isAlertShowNotFound: false,
			isAlertShowOverlap: false,
			message: '',
			type: '',
			step: 0,
			applyDate: new Date(),
			modalIsOpen: false,
			modalIsOpenNotFound: false,
			modalIsOpenOverlap: false,
			list_student_have_no_assigment: [],
			list_deleted: [[], []],
			staff_time_slots_overlap: [],
			student_time_slots_overlap: [],
			dataUpload: null,
			isStudentMode: false,
		};
	},

	mounted() {
		this.applyDate = new Date();
	},

	computed: {
		timeNow() {
			const newDate = new Date(Date());
			const isNext = dayjs(this.applyDate).isAfter(newDate, 'day');
			return isNext ? '00:00' : dayjs(newDate).format('HH:mm');
		},
	},

	watch: {
		isShow: {
			immediate: true,
			handler(value) {
				if (!value) {
					this.isAlertShow = false;
					this.isAlertShowNotFound = false;
					this.isAlertShowOverlap = false;
					this.list_student_have_no_assigment = [];
					this.list_deleted = [[], []];
					this.staff_time_slots_overlap = [];
					this.student_time_slots_overlap = [];
					this.close();
				}
			},
		},
	},

	methods: {
		async downloadFileNew() {
			try {
				const res = await $api.schedule.checkDownloadNewFile();
				if (res?.data?.can_download) {
					window.location.href =
						process.env.VUE_APP_URL +
						'excel/new_timetable?secure_token=' +
						res?.data?.token;
				} else {
					this.list_student_have_no_assigment =
						res?.data?.list_student_have_no_assigment;
					this.modalIsOpen = true;
				}
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},

		async downloadFileCurrent() {
			try {
				const res = await $api.schedule.checkDownloadCurrentFile();
				if (res?.data?.token) {
					window.location.href =
						process.env.VUE_APP_URL +
						'excel/export_timetable?secure_token=' +
						res?.data?.token;
				} else {
					this.list_student_have_no_assigment =
						res?.data?.list_student_have_no_assigment;
					this.modalIsOpen = true;
				}
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},

		deleteFile() {
			this.file = null;
		},

		isFile(icon) {
			const ext = ['.xlsx'];
			return ext.some((el) => icon.endsWith(el));
		},

		openModelUpload() {
			const target = document.getElementById('upload-file-timetable');
			target ? target.click() : '';
		},

		async uploadChange(event) {
			let file = event.target.files[0];

			if (!file) {
				return;
			}

			if (!this.isFile(file.name)) {
				this.isAlertShow = true;
				this.message = 'Định dạng tệp không hỗ trợ';
				this.type = 'danger';
				setTimeout(() => {
					this.closeAlert();
				}, 3000);
				return;
			}

			this.file = file;
			await this.upload();
		},

		async upload() {
			this.isAlertShow = false;
			this.isAlertShowNotFound = false;
			this.isAlertShowOverlap = false;
			try {
				let data = new FormData();
				data.append('file', this.file);
				data.append(
					'activated_date',
					this.applyDate
						? dayjs(this.applyDate).format('YYYY-MM-DD')
						: null
				);
				const {
					data: { data: res_data },
				} = await $api.schedule.uploadTimeTableFile(data);
				if (
					res_data &&
					(res_data.list_staffs_deleted.length ||
						res_data.list_students_deleted.length)
				) {
					this.isAlertShowNotFound = true;
					this.list_deleted = [
						res_data.list_students_deleted,
						res_data.list_staffs_deleted,
					];
				}
				if (
					res_data &&
					(res_data.student_time_slots_overlap.length ||
						res_data.staff_time_slots_overlap.length)
				) {
					this.student_time_slots_overlap =
						res_data.student_time_slots_overlap || [];
					this.staff_time_slots_overlap =
						res_data.staff_time_slots_overlap || [];
					this.isAlertShowOverlap = true;
				}
				this.dataUpload = res_data;
			} catch (e) {
				this.isAlertShow = true;
				this.message = e.response?.data?.errors
					? e.response?.data.errors.join('<br/>')
					: 'Có lỗi xảy ra, tải lên tài liệu không thành công';
				this.type = 'danger';
			}
		},

		async submit() {
			try {
				await $api.schedule.createTimeTable({
					excel_data: {
						...this.dataUpload,
					},
				});
				this.isAlertShow = true;
				this.message = 'Tải lên tài liệu thành công';
				this.type = '';
				this.$emit('success');
				if (!this.isEdit) {
					this.$emit('close');
				} else {
					this.$emit('success');
				}
				setTimeout(() => {
					this.closeAlert();
				}, 3000);
			} catch (e) {
				this.isAlertShow = true;
				this.message = e.response?.data?.errors
					? e.response?.data.errors.join('<br/>')
					: 'Có lỗi xảy ra, tải lên tài liệu không thành công';
				this.type = 'danger';
				setTimeout(() => {
					this.closeAlert();
				}, 3000);
			}
		},

		closeAlert() {
			this.isAlertShow = false;
			this.message = '';
			this.type = '';
		},

		close() {
			this.file = null;
			this.isAlertShow = false;
			this.message = '';
			this.type = '';
			this.step = 0;
			this.applyDate = new Date();
			const target = document.getElementById('upload-file-timetable');
			target ? (target.value = null) : '';
		},

		openModalErrorOverlap(isStudentMode = false) {
			this.modalIsOpenOverlap = true;
			this.isStudentMode = isStudentMode;
		},
	},
});
</script>
