import { ref } from 'vue';
import { $api } from '@/services';

const error = ref(null);
const loading = ref(false);

export async function getBoardings() {
	error.value = null;
	try {
		const res = await $api.boarding.getDayCareClasses();
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export function useGetBoardings() {
	return { error, loading, getBoardings };
}
