
import { computed, defineComponent, ref } from 'vue';
import { useStudent } from '@/features/student/useStudent';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
	name: 'StopTarget',
	setup() {
		const { goalStoppedByStudent } = useStudent();
		const route = useRoute();
		const store = useStore();
		const goals = ref([]);
		const skill_count = ref(0);
		const goal_count = ref(0);

		const id = computed(() =>
			route.params?.id ? route.params?.id + '' : ''
		);

		async function fetchGoals() {
			const res = await goalStoppedByStudent(id.value);
			if (res?.data) {
				goals.value = res?.data?.skills ?? [];
				skill_count.value = res?.data?.skills.length;
				console.log('length', res?.data?.skills)
				goals.value.forEach((item: any) => {
					goal_count.value += item?.goals.length || 0;
				});
			}
		}

		fetchGoals();

		return {
			id,
			goal_count,
			skill_count,
			isAdmin: computed(() => store.getters.isAdmin),
			org: computed(() => store.state.profile.current_org),
			goals,
		};
	},
});
