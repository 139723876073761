import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "__setup-center px-10 md-down:px-4 pb-30" }
const _hoisted_2 = { class: "__setup-center__header pt-10 pb-4" }
const _hoisted_3 = { class: "__setup-center__title text-neutral-500 font-semibold whitespace-nowrap flex flex-nowrap items-center" }
const _hoisted_4 = { class: "md:hidden" }
const _hoisted_5 = { class: "p-6 bg-white select-tabs" }
const _hoisted_6 = { class: "flex flex-nowrap items-center" }
const _hoisted_7 = { class: "flex flex-nowrap items-center font-medium text-yellow-700" }
const _hoisted_8 = { class: "border-b border-neutral-200 md-down:hidden" }
const _hoisted_9 = {
  "aria-label": "Tabs",
  class: "-mb-px flex space-x-8 tabs"
}
const _hoisted_10 = { class: "__setup-center__content h-full w-full mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DaIcon = _resolveComponent("DaIcon")!
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_DaIcon, {
          class: "mr-3 h-6 w-6",
          name: "das--gear"
        }),
        _createTextVNode(" Thiết lập cơ sở ")
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Multiselect, {
            modelValue: _ctx.selected,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
            "allow-empty": false,
            options: _ctx.tabsMenu,
            searchable: true,
            "deselect-label": "Đã chọn",
            label: "nameSearch",
            selectLabel: "",
            "track-by": "id",
            onSelect: _ctx.tabSelectEvent
          }, {
            noResult: _withCtx(() => [
              _createTextVNode(" Không tìm thấy kết quả")
            ]),
            option: _withCtx((props) => [
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_DaIcon, {
                  name: props.option.icon,
                  class: "h-3.5 w-3.5 mr-4"
                }, null, 8, ["name"]),
                _createElementVNode("div", null, _toDisplayString(props.option.name), 1)
              ])
            ]),
            singleLabel: _withCtx(({ option }) => [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_DaIcon, {
                  name: option.icon,
                  class: "h-3.5 w-3.5 mr-4"
                }, null, 8, ["name"]),
                _createElementVNode("div", null, _toDisplayString(option.name), 1)
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "options", "onSelect"])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("nav", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabsMenu, (tab) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              key: tab.id,
              to: tab.link,
              class: "border-transparent text-neutral-500 hover:text-yellow-700 hover:border-yellow-500 whitespace-nowrap py-3 px-1 border-b-2 font-semibold"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(tab.name), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        (_openBlock(), _createBlock(_component_router_view, {
          key: _ctx.org,
          updated: _ctx.updateSuccessful,
          "onUpdate:updated": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.updateSuccessful) = $event)),
          loading: _ctx.loading,
          org: _ctx.org,
          student_count: _ctx.student_count,
          timetable: _ctx.timetable,
          onUpdated: _ctx.onUpdated,
          onUpdateOrg: _ctx.updateOrg
        }, null, 8, ["updated", "loading", "org", "student_count", "timetable", "onUpdated", "onUpdateOrg"]))
      ])
    ])
  ]))
}