<template>
	<div v-if="!isLoading">
		<div class="p-6 bg-white shadow-sm rounded-2xl mb-10">
			<div>
				<div class="flex items-center justify-between mb-8">
					<div class="font-semibold text-neutral-600">
						Thống kê chung
					</div>
				</div>
			</div>
			<div class="flex flex-nowrap w-full md-down:flex-col">
				<div
					class="
						p-6
						border
						rounded-l-lg
						border-neutral-200
						w-full
						md:border-r-0
						md-down:border-b-0
						md-down:rounded-t
						md-down:rounded-b-none
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							src="@/assets/images/icons/abc.png"
							alt=""
							width="32"
							height="32"
							class="mr-2"
							style="height: 32px"
						/>
						{{ stats.skills_count }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Tổng số lĩnh vực
						</span>
					</div>
				</div>
				<div
					class="
						p-6
						border
						rounded-r-lg
						border-neutral-200
						w-full
						md-down:rounded-b md-down:rounded-t-none
					"
				>
					<div
						class="
							text-33
							font-bold
							text-neutral-600
							mb-6
							flex flex-nowrap
							items-center
						"
					>
						<img
							src="@/assets/images/icons/light.png"
							alt=""
							width="32"
							height="32"
							class="mr-2"
							style="height: 32px"
						/>
						{{ stats.goals_count }}
					</div>
					<div class="flex flex-nowrap items-center">
						<span class="text-neutral-500 text-sm"
							>Tổng số mục tiêu</span
						>
					</div>
				</div>
			</div>
		</div>
		<div v-if="goals && goals.length > 0">
			<div>
				<div class="mb-10" v-for="(goal, index) in goals" :key="index">
					<div class="font-semibold text-lg text-neutral-600 mb-4">
						{{ index + 1 }}. {{ goal.skill.name }}
					</div>
					<div class="shadow-sm sm:rounded-2xl overflow-hidden">
						<div class="__record__table overflow-x-auto scroll-bar">
							<div
								class="
									overflow-hidden
									sm:rounded-t-2xl
									w-fit
									min-w-full
								"
							>
								<table
									class="min-w-full divide-y text-neutral-200"
								>
									<thead class="bg-neutral-100 h-16">
										<tr>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													w-16
													whitespace-nowrap
												"
											>
												STT
											</th>
											<th
												scope="col"
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
												style="min-width: 160px"
											>
												Nội dung
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											@click="
												$router.push(
													`/goal/${id}/${g.id}`
												)
											"
											v-for="(g, i) in goal.goals"
											:key="g.id"
											:class="
												i % 2 === 0
													? 'bg-white'
													: 'bg-neutral-50'
											"
											class="
												text-neutral-700
												hover:bg-orange-50
												cursor-pointer
											"
										>
											<td
												class="
													px-6
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div class="ml-2">
													{{ i + 1 }}
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													text-neutral-700
												"
											>
												<div class="flex items-center">
													<div class="mr-2">
														{{ g.name }}
													</div>
													<TooltipGoalDetail
														:goal-id="
															g.goal_id || g.id
														"
														:student-id="id"
													/>
												</div>
												<div
													class="flex gap-2 flex-wrap"
													style="max-width: 360px"
												>
													<div
														class="
															mt-1
															py-0.5
															px-2.5
															rounded-full
															bg-neutral-200
															text-neutral-700
															h-fit
															w-fit
															whitespace-nowrap
															font-semibold
															shadow-input
														"
														style="font-size: 10px"
														v-if="g?.raw_source"
													>
														{{ g?.display_source }}
													</div>
													<div
														class="
															mt-1
															py-0.5
															px-2.5
															rounded-full
															bg-neutral-200
															text-neutral-700
															h-fit
															w-fit
															font-semibold
															shadow-input
															whitespace-nowrap
														"
														style="font-size: 10px"
														v-if="g?.state"
													>
														{{
															$filters.sourceMap(
																g?.state,
																g.editable
															)
														}}
													</div>
													<template
														v-if="
															g?.assistance_levels &&
															org.enable_goal_assistance_level
														"
														class="flex gap-2"
													>
														<div
															v-for="ass in g?.assistance_levels"
															:key="ass"
														>
															<div
																class="
																	mt-1
																	py-0.5
																	px-2.5
																	rounded-full
																	bg-pink-100
																	whitespace-nowrap
																	text-neutral-700
																	h-fit
																	w-fit
																	font-semibold
																	shadow-input
																"
																style="
																	font-size: 10px;
																"
															>
																{{ ass }}
															</div>
														</div>
													</template>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="w-full h-full flex items-center justify-center flex-col"
		>
			<img
				src="@/assets/images/illustrations/not_found.png"
				alt=""
				height="250"
				width="250"
			/>
			<div class="text-neutral-600 text-center text-lg">
				Lớp nhóm chưa có mục tiêu tạm dừng
			</div>
		</div>
	</div>
	<div v-else class="fixed top-1/2 left-1/2">
		<Loading />
	</div>
</template>

<script>
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail.vue';
import Loading from '@/components/common/Loading.vue';
import { $api } from '@/services';

export default {
	name: 'GoalsStopped',

	components: { Loading, TooltipGoalDetail },

	data() {
		return {
			goals: [],
			stats: {
				goals_count: 0,
				skills_count: 0,
			},
			isLoading: false,
		};
	},

	computed: {
		id() {
			return this.$route.params?.id;
		},

		org() {
			return this.$store.state.profile.current_org;
		},
	},

	created() {
		this.fetchGoals();
	},

	methods: {
		async fetchGoals() {
			const {
				data: { data, stats },
			} = await $api.groupClasses.goalsStoppedByGroupClass(this.id);
			if (data) {
				this.goals = data?.skills ?? [];
			}
			this.stats = stats;
			this.isLoading = false;
		},
	},
};
</script>
