<template>
	<div v-if="load || isLoading || loaded" class="fixed top-1/2 left-1/2">
		<Loading />
	</div>
	<div v-else class="px-20 py-10" id="plans_pdf">
		<div>
			<div class="flex justify-between items-center w-full mb-10">
				<div class="flex items-center">
					<img
						alt=""
						style="min-width: 56px"
						class="shadow-sm inline-block h-14 w-14 rounded-full"
						:src="student?.profile_photo?.w200"
						v-if="student?.profile_photo?.w200"
					/>
					<span
						style="min-width: 56px"
						v-else
						class="
							inline-flex
							items-center
							justify-center
							h-14
							w-14
							rounded-full
						"
						:style="{
							background: student?.profile_photo?.default?.color,
						}"
					>
						<span
							class="font-medium leading-none text-white text-4xl"
							>{{
								student?.profile_photo?.default?.initial
							}}</span
						>
					</span>
					<div class="ml-4">
						<div
							class="
								font-bold
								text-neutral-700 text-4xl
								md-down:text-2xl
							"
						>
							{{ student?.name }}
						</div>
						<div
							v-if="student?.age"
							class="
								text-green-500
								font-semibold
								text-xs
								uppercase
							"
						>
							<span>
								{{ student?.age }}
								{{
									student?.learning_time
										? `| ${student?.learning_time}`
										: ''
								}}
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="p-6 bg-white shadow-sm rounded-2xl mb-10">
				<div>
					<div class="flex items-center justify-between mb-6">
						<div class="font-semibold text-neutral-600">
							Kế hoạch {{ plan?.order }}
						</div>
						<div class="flex items-center">
							<div
								v-if="plan?.apply_at"
								class="font-semibold text-neutral-600 mr-8"
							>
								Áp dụng:
								{{ $filters.formatDate(plan?.apply_at) }}
							</div>
							<Menu as="div" class="ml-3 relative">
								<div>
									<MenuButton
										class="
											rounded-full
											flex
											text-sm
											focus:outline-none
											focus:ring-2
											focus:ring-offset-2
											focus:ring-indigo-500
										"
									>
										<span class="sr-only"
											>Open user menu</span
										>
										<svg
											fill="none"
											height="20"
											viewBox="0 0 20 20"
											width="20"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												clip-rule="evenodd"
												d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
												fill="#94A3B8"
												fill-rule="evenodd"
											/>
										</svg>
									</MenuButton>
								</div>
							</Menu>
						</div>
					</div>
				</div>
				<div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6">
					<div
						class="
							p-6
							border
							lg:rounded-l-lg
							md:rounded-tl-lg
							border-neutral-200
							w-full
							md-down:rounded-t md-down:rounded-b-none
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/abc.png"
								style="height: 32px"
								width="32"
							/>
							{{ skills_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Tổng số lĩnh vực
							</span>
						</div>
					</div>
					<div
						class="
							p-6
							border border-neutral-200
							w-full
							md:border-l-0
							md-down:border-t-0
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/light.png"
								style="height: 32px"
								width="32"
							/>
							{{ goals_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Tổng số mục tiêu</span
							>
						</div>
					</div>
					<div
						class="
							p-6
							border border-neutral-200
							w-full
							md:rounded-tr-lg
							lg:rounded-tr-none
							md:border-l-0
							md-down:border-t-0
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/light.png"
								style="height: 32px"
								width="32"
							/>
							{{ continued_goals_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Mục tiêu lặp lại</span
							>
						</div>
					</div>
					<div
						class="
							p-6
							border border-neutral-200
							w-full
							lg:border-l-0
							lg-down:border-t-0
							md:rounded-bl-lg
							lg:rounded-bl-none
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/light.png"
								style="height: 32px"
								width="32"
							/>
							{{ new_goals_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Mục tiêu mới</span
							>
						</div>
					</div>
					<div
						class="
							p-6
							border border-neutral-200
							w-full
							md:border-l-0
							lg-down:border-t-0
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/light.png"
								style="height: 32px"
								width="32"
							/>
							{{ goals_from_stopped_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Từ MT tạm dừng</span
							>
						</div>
					</div>
					<div
						class="
							p-6
							border
							md:border-l-0
							lg:rounded-r-lg
							md:rounded-br-lg
							border-neutral-200
							w-full
							lg-down:border-t-0
							md-down:rounded-b md-down:rounded-t-none
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/light.png"
								style="height: 32px"
								width="32"
							/>
							{{ goals_from_lib_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Từ Ngân hàng MT</span
							>
						</div>
					</div>
				</div>
				<div class="mt-4 flex justify-end items-center text-gray-700">
					<div
						v-if="data && data.sent_at && data.sent_by_staff"
						class="flex gap-2"
					>
						<router-link
							:to="`/record/employee/${data.sent_by_staff?.id}`"
						>
							<div class="flex items-center">
								<div>
									<img
										v-if="
											data.sent_by_staff?.profile_photo
												?.w200
										"
										:src="
											data.sent_by_staff?.profile_photo
												?.w200
										"
										alt=""
										class="
											shadow-sm
											inline-block
											h-6
											w-6
											rounded-full
										"
									/>
									<span
										v-else
										:style="{
											background:
												data.sent_by_staff
													?.profile_photo?.default
													?.color,
										}"
										class="
											inline-flex
											items-center
											justify-center
											h-6
											w-6
											rounded-full
										"
									>
										<span
											class="
												font-medium
												leading-none
												text-white text-xs
											"
											>{{
												data.sent_by_staff
													?.profile_photo?.default
													?.initial
											}}</span
										>
									</span>
								</div>
								<div class="ml-4 text-blue-600 font-semibold">
									{{ data.sent_by_staff.name }}
								</div>
							</div>
						</router-link>
						đã gửi KH tới PH
						<span>{{ $filters.formatDateTime(data.sent_at) }}</span>
					</div>
					<div v-else></div>
				</div>
			</div>
			<div v-if="groups && groups.length > 0">
				<div
					v-if="
						groups.length > 1 || groups?.[0]?.id !== 'no_have_group'
					"
				>
					<div
						v-for="(group, index) in groups"
						:key="group.id"
						class="mb-8"
					>
						<GroupDetail
							:data="group"
							:index="index"
							@refresh="listGroup"
							@sortList="sortList"
							:hasPendingPlan="hasPendingPlan"
						/>
					</div>
				</div>
				<div v-else>
					<div
						v-for="(group, index) in groups?.[0]
							?.goals_group_by_skill"
						:key="group.id"
					>
						<GoalList
							:data="group"
							:index="index"
							:student-id="Number(id)"
							class="mb-6"
							:hasPendingPlan="hasPendingPlan"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import Loading from '@/components/common/Loading.vue';
import GroupDetail from '@/components/modules/groups/GroupDetail.vue';
import GoalList from '@/components/elements/GoalList.vue';

const route = useRoute();
const store = useStore();
const goals = ref([]);
const skill_count = ref(0);
const goal_count = ref(0);
const plan = ref({});
const student = ref({});
const isLoading = ref(false);
const load = ref(false);
const loaded = ref(false);
const order_by = ref('');
const goals_count = ref(0);
const skills_count = ref(0);
const continued_goals_count = ref(0);
const goals_from_lib_count = ref(0);
const goals_from_stopped_count = ref(0);
const new_goals_count = ref(0);
const groups = ref([]);
const hasPendingPlan = ref(false);
const id = computed(() => (route.params?.id ? route.params?.id + '' : ''));

async function fetchStudent() {
	try {
		loaded.value = true;
		const response = await fetch(
			process.env.VUE_APP_BASE_URL + `v1/staff/students/${id.value}`,
			{
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'x-access-token': route?.query?.token,
					'x-subdomain': route?.query?.subdomain,
				},
			}
		);
		const data = await response.json();
		student.value = data.data;
		loaded.value = false;
	} catch (e) {
		await store.dispatch('setAlertMessage', {
			message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
			type: 'danger',
		});
	}
}

async function planActive() {
	try {
		load.value = true;
		const response = await fetch(
			process.env.VUE_APP_BASE_URL +
				`v1/staff/students/${id.value}/plans/active`,
			{
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'x-access-token': route?.query?.token,
					'x-subdomain': route?.query?.subdomain,
				},
			}
		);
		const data = await response.json();
		plan.value = data.data;
		console.log('data.value', plan.value);
		console.log(plan.value.id);
		console.log(load.value);
		load.value = false;
	} catch (e) {
		await store.dispatch('setAlertMessage', {
			message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
			type: 'danger',
		});
	}
}

const sortList = (value) => {
	order_by.value = value;
	listGroup();
};

async function listGroup() {
	try {
		isLoading.value = true;
		const response = await fetch(
			process.env.VUE_APP_BASE_URL +
				`v1/staff/students/${id.value}/groups_with_goals?order_by=`,
			{
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'x-access-token': route?.query?.token,
					'x-subdomain': route?.query?.subdomain,
				},
			}
		);
		const data = await response.json();
		groups.value = data.data;
		if (data.has_pending_plan) hasPendingPlan.value = data.has_pending_plan;
		if (data.stats) {
			goals_count.value = data.stats.goals_count;
			skills_count.value = data.stats.skills_count;
			continued_goals_count.value = data.stats.continued_goals_count;
			goals_from_lib_count.value = data.stats.goals_from_lib_count;
			goals_from_stopped_count.value =
				data.stats.goals_from_stopped_count;
			new_goals_count.value = data.stats.new_goals_count;
		}
		isLoading.value = false;
	} catch (err) {
		await store.dispatch('setAlertMessage', {
			message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
			type: 'danger',
		});
	}
}
onMounted(() => {
	fetchStudent();
	planActive();
	listGroup();
});
</script>
<style scoped>
.tab {
	padding: 8px 12px 8px 12px;
	color: #48566a;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

.tab-selected {
	border-radius: 8px;
	background: #e5a42b;
	box-shadow: 0px 3px 8px -1px #3232470d;
	color: #fff;
}
</style>
