<template>
	<div class="pt-10 md-down:pt-4 md:-mb-60">
		<div
			class="__report-lesson__container"
			v-if="class_sessions && class_sessions.length > 0"
		>
			<div
				v-for="classItem in class_sessions"
				:key="classItem.id"
				class="mb-10"
			>
				<div
					class="flex items-center justify-between"
					v-if="classItem.student_class_sessions.length"
				>
					<div class="mb-3 text-neutral-600 font-bold text-lg">
						{{
							classItem.student_class_sessions[0].day_care_class
								?.name
						}}
					</div>
					<div v-if="classItem.state === 'cancelled'">
						<div
							class="
								text-sm
								w-fit
								font-semibold
								text-sienna-600
								p-1
								px-3
								rounded-full
								flex
								items-center
								justify-center
								text-sm
								bg-sienna-50
							"
						>
							<svg
								width="14"
								height="14"
								viewBox="0 0 14 14"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M4.35731 4.35739C4.52817 4.18653 4.80518 4.18653 4.97603 4.35739L7.29167 6.67303L9.60731 4.35739C9.77817 4.18653 10.0552 4.18653 10.226 4.35739C10.3969 4.52824 10.3969 4.80525 10.226 4.97611L7.91039 7.29175L10.226 9.60739C10.3969 9.77824 10.3969 10.0553 10.226 10.2261C10.0552 10.397 9.77817 10.397 9.60731 10.2261L7.29167 7.91047L4.97603 10.2261C4.80518 10.397 4.52817 10.397 4.35731 10.2261C4.18646 10.0553 4.18646 9.77824 4.35731 9.60739L6.67295 7.29175L4.35731 4.97611C4.18646 4.80525 4.18646 4.52824 4.35731 4.35739Z"
									fill="#DB5A41"
								/>
							</svg>
							<div class="mt-1 ml-1">HS nghỉ</div>
						</div>
					</div>
				</div>
				<div
					class="shadow-sm sm:rounded-2xl overflow-hidden mt-4"
					v-if="classItem.state !== 'cancelled'"
				>
					<div class="__record__table overflow-x-auto scroll-bar">
						<div
							class="
								overflow-hidden
								sm:rounded-t-2xl
								w-fit
								min-w-full
							"
						>
							<table class="min-w-full divide-y text-neutral-200">
								<thead class="bg-neutral-100 h-16">
									<tr>
										<th
											style="width: 70px"
											scope="col"
											class="
												px-3
												py-3
												text-center text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												w-16
												whitespace-nowrap
											"
										>
											STT
										</th>
										<th
											style="width: 400px"
											scope="col"
											class="
												px-3
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
											"
										>
											Tên học sinh
										</th>
										<th
											style="width: 260px"
											scope="col"
											class="
												px-3
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
											"
										>
											Trạng thái
										</th>
										<th
											scope="col"
											class="
												px-3
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
											"
										>
											Phân loại
										</th>
									</tr>
								</thead>
								<tbody>
									<tr
										@click.stop="
											$emit('selectedStudent', item)
										"
										v-for="(
											item, index
										) in classItem.student_class_sessions"
										:key="index"
										:class="
											index % 2 === 0
												? 'bg-white'
												: 'bg-neutral-50'
										"
										class="
											text-neutral-700
											cursor-pointer
											hover:bg-orange-50
										"
									>
										<td>
											<div
												class="
													px-3
													py-4
													text-center text-neutral-700
												"
											>
												{{ index + 1 }}
											</div>
										</td>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700
												hover:text-yellow-700
												hover:underline
												cursor-pointer
											"
										>
											<div
												class="flex items-center"
												v-if="item.student"
											>
												<div>
													<img
														class="
															shadow-sm
															inline-block
															h-6
															w-6
															rounded-full
														"
														:src="
															item.student
																?.profile_photo
																?.w200
														"
														alt=""
														v-if="
															item.student
																?.profile_photo
																?.w200
														"
													/>
													<span
														v-else
														class="
															inline-flex
															items-center
															justify-center
															h-6
															w-6
															rounded-full
														"
														:style="{
															background:
																item.student
																	?.profile_photo
																	?.default
																	?.color,
														}"
													>
														<span
															class="
																font-medium
																leading-none
																text-white
																text-xs
															"
															>{{
																item.student
																	?.profile_photo
																	?.default
																	?.initial
															}}</span
														>
													</span>
												</div>
												<div
													class="
														ml-4
														text-yellow-600
														font-semibold
														whitespace-pre-wrap
														w-40
													"
												>
													{{ item.student.name }}
												</div>
											</div>
										</td>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700
											"
										>
											<div>
												<div
													v-if="
														item.state !==
															'created' &&
														item.state !== 'past' &&
														item.state !==
															'cancelled' &&
														item.state !== 'ongoing'
													"
													class="
														text-xs
														w-fit
														font-semibold
														bg-neutral-100
														text-gray-500
														p-1
														pb-0.5
														px-3
														rounded-full
														flex
														items-center
														justify-center
														gap-2
													"
												>
													<div>
														<svg
															width="15"
															height="14"
															viewBox="0 0 15 14"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M7.5 14C11.366 14 14.5 10.866 14.5 7C14.5 3.13401 11.366 0 7.5 0C3.63401 0 0.5 3.13401 0.5 7C0.5 10.866 3.63401 14 7.5 14ZM11.0491 6.63407C10.9689 6.50148 10.846 6.41667 10.7083 6.41667H4.58333C4.44564 6.41667 4.3228 6.50148 4.2426 6.63407C4.14461 6.72535 4.08333 6.85552 4.08333 7C4.08333 7.14448 4.14461 7.27465 4.2426 7.36593C4.3228 7.49852 4.44564 7.58333 4.58333 7.58333H10.7083C10.846 7.58333 10.9689 7.49852 11.0491 7.36593C11.1471 7.27465 11.2083 7.14448 11.2083 7C11.2083 6.85552 11.1471 6.72535 11.0491 6.63407Z"
																fill="#CBD5E1"
															/>
														</svg>
													</div>
													<div>Chưa học</div>
												</div>
												<div
													v-if="
														item.state ===
														'cancelled'
													"
													class="
														text-sm
														w-fit
														font-semibold
														bg-sienna-100
														text-sienna-500
														p-1
														pb-0.5
														px-3
														rounded-full
														flex
														items-center
														justify-center
														gap-2
													"
												>
													<div>
														<svg
															width="15"
															height="14"
															viewBox="0 0 15 14"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M5.19063 4.35739C5.36148 4.18653 5.63849 4.18653 5.80934 4.35739L8.12498 6.67303L10.4406 4.35739C10.6115 4.18653 10.8885 4.18653 11.0593 4.35739C11.2302 4.52824 11.2302 4.80525 11.0593 4.97611L8.7437 7.29175L11.0593 9.60739C11.2302 9.77824 11.2302 10.0553 11.0593 10.2261C10.8885 10.397 10.6115 10.397 10.4406 10.2261L8.12498 7.91047L5.80934 10.2261C5.63849 10.397 5.36148 10.397 5.19063 10.2261C5.01977 10.0553 5.01977 9.77824 5.19063 9.60739L7.50627 7.29175L5.19063 4.97611C5.01977 4.80525 5.01977 4.52824 5.19063 4.35739Z"
																fill="#DB5A41"
															/>
														</svg>
													</div>
													<div>HS nghỉ</div>
												</div>
												<div
													v-if="
														item.state === 'ongoing'
													"
													class="
														rounded-full
														flex
														items-center
														justify-center
														bg-blue-100
														text-xs text-blue-600
														font-semibold
														w-fit
														px-3
														py-1
														whitespace-nowrap
													"
												>
													Đang học
												</div>
												<div
													v-if="
														item.state === 'past' ||
														item.state === 'created'
													"
													class="
														text-xs
														w-fit
														font-semibold
														bg-green-50
														text-green-500
														p-1
														pb-0.5
														px-3
														rounded-full
														flex
														items-center
														justify-center
														gap-2
													"
												>
													<div>
														<svg
															width="15"
															height="14"
															viewBox="0 0 15 14"
															fill="none"
															xmlns="http://www.w3.org/2000/svg"
														>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M8.68176 4.34846C8.93804 4.09218 9.35355 4.09218 9.60983 4.34846C9.86297 4.6016 9.86608 5.01009 9.61915 5.26704L6.12597 9.63351C6.12093 9.63981 6.11554 9.64583 6.10983 9.65154C5.85355 9.90782 5.43804 9.90782 5.18176 9.65154L2.86612 7.3359C2.60983 7.07962 2.60983 6.6641 2.86612 6.40782C3.1224 6.15154 3.53791 6.15154 3.79419 6.40782L5.62602 8.23965L8.66435 4.36809C8.66975 4.36121 8.67557 4.35465 8.68176 4.34846Z"
																fill="#339588"
															/>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M7.87639 8.84617L8.68176 9.65154C8.93804 9.90782 9.35355 9.90782 9.60984 9.65154C9.61554 9.64583 9.62093 9.63981 9.62597 9.63351L13.1191 5.26704C13.3661 5.01009 13.363 4.6016 13.1098 4.34846C12.8536 4.09218 12.438 4.09218 12.1818 4.34846C12.1756 4.35465 12.1698 4.36121 12.1644 4.36809L9.12602 8.23965L8.70135 7.81497L7.87639 8.84617Z"
																fill="#339588"
															/>
														</svg>
													</div>
													<div>Đã học</div>
												</div>
											</div>
										</td>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700
											"
										>
											<div>
												<div
													v-if="!item.scheduled"
													class="
														text-xs
														w-fit
														font-semibold
														text-sienna-600
														p-1
														pb-0.5
														px-3
														rounded-full
														border border-sienna-200
														flex
														items-center
														justify-center
													"
												>
													Phát sinh
												</div>
												<div
													v-else
													class="
														text-xs
														w-fit
														font-semibold
														text-neutral-600
														p-1
														pb-0.5
														px-3
														rounded-full
														border
														border-neutral-300
														flex
														items-center
														justify-center
													"
												>
													Cố định
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="w-full h-full flex items-center justify-center flex-col"
		>
			<img
				src="@/assets/images/illustrations/not_found.png"
				alt=""
				height="250"
				width="250"
			/>
			<div
				class="text-neutral-500 text-lg text-center"
				style="max-width: 700px"
			>
				Không có lịch ngày lớp bán trú
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'TimeSlotDaycare',

	props: {
		class_sessions: {
			type: Array,
			required: true,
		},
	},
});
</script>
