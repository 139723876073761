
import { Options, Vue } from 'vue-class-component';
import { PropSync, Watch } from 'vue-property-decorator';
import Multiselect from '@suadelabs/vue3-multiselect';

@Options({
	components: { Multiselect },
})
export default class TargetDetail extends Vue {
	@PropSync('data') dataProp!: any;
	@PropSync('isEdit') _isEdit!: any;

	_data: any = {
		next_plan: 'Mục tiêu đã hoàn thành',
	};
	step = 1;

	_stopped_reason: any = null;

	options = [
		{
			value: 'too_hard',
			name: 'Mục tiêu quá khó',
		},
		{
			value: 'no_cooperation',
			name: 'Con không hợp tác',
		},
		{
			value: 'no_opportunity',
			name: 'Con không có cơ hội',
		},
		{
			value: 'other',
			name: 'Lý do khác',
		},
	];

	@Watch('dataProp', { immediate: true, deep: true })
	onDataChange(value: any) {
		if (value) {
			this._data = value;
		}
	}

	get isEditMode() {
		return !this._data.id || this._isEdit;
	}

	submit() {
		this._data.stopped_reason = this._stopped_reason?.value;
		this.$emit(this._data.isCompleted ? 'update' : 'submit', this._data);
	}

	reason(as: any) {
		const stopped_reason = as?.stopped_reason || '';
		const stopped_reason_other = as?.stopped_reason_other || '';
		const other =
			stopped_reason !== 'Khác' && stopped_reason !== 'other'
				? stopped_reason.toLowerCase()
				: stopped_reason_other.toLowerCase();
		return as?.next_plan !== 'Tạm dừng mục tiêu'
			? as?.next_plan
			: `${as?.next_plan} ${other ? ' vì ' + other : ''}`;
	}
}
