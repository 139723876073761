<template>
	<div class="grid md:grid-cols-2 min-h-screen">
		<div class="__setup-center px-12 md-down:px-4 pb-30">
			<div
				class="
					__setup-center__header
					pt-10
					pb-4
					flex
					justify-between
					gap-8
					items-center
					flex-wrap
				"
			>
				<div
					class="
						__record__title
						text-neutral-500
						font-semibold
						whitespace-nowrap
						flex flex-nowrap
						items-center
						text-4xl
						cursor-pointer
					"
					@click="$router.push(`/fee-notices`)"
				>
					<svg
						class="mr-2"
						fill="none"
						height="28"
						viewBox="0 0 28 28"
						width="28"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M17.5 7L10.5 14L17.5 21"
							stroke="#94A3B8"
							stroke-linecap="round"
							stroke-linejoin="round"
							stroke-width="2"
						/>
					</svg>
					Đã đóng học phí
				</div>
				<div
					class="search-input w-full md:w-80 lg-down:ml-auto"
					style="min-width: 120px"
				>
					<div class="input-group input--xl">
						<input
							v-model="searchQuery"
							class="bg-white input-with-icon"
							placeholder="Tìm theo học sinh"
							type="text"
							@keyup="searching"
							@keypress.enter="search"
						/>
						<div class="input-icon absolute top-1/2">
							<DaIcon
								class="h-4 w-4 text-neutral-400"
								name="das--search"
							/>
						</div>
					</div>
				</div>
				<div class="md:hidden w-full flex items-center justify-end">
					<button
						class="button-p text-center w-32"
						type="button"
						@click="isShow = true"
					>
						Bước 2: Xác nhận
					</button>
				</div>
			</div>
			<div class="mt-6">
				<div>
					<div
						v-if="students.length"
						class="font-semibold text-neutral-500"
					>
						Chọn học sinh đã đóng học phí tháng
						<span v-if="month_start">{{
							$filters.formatDateMMYYYY(month_start)
						}}</span>
					</div>
					<div
						v-if="students.length"
						class="shadow-sm sm:rounded-2xl mt-4"
					>
						<div class="__record__table overflow-x-auto scroll-bar">
							<div
								class="
									overflow-hidden
									sm:rounded-t-2xl
									w-fit
									min-w-full
								"
							>
								<table
									class="min-w-full divide-y text-neutral-200"
								>
									<thead class="bg-neutral-100 h-16">
										<tr>
											<th
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													w-16
													whitespace-nowrap
												"
												scope="col"
											>
												STT
											</th>
											<th
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
													w-32
												"
												scope="col"
											>
												Tên học sinh
											</th>
											<th style="width: 40px"></th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(
												person, index
											) in students.filter(
												(item) =>
													!this.checkBoxSelected.includes(
														item.tuition_id
													)
											)"
											:key="person.id"
											:class="
												index % 2 === 0
													? 'bg-white'
													: 'bg-neutral-50'
											"
											class="text-neutral-700"
										>
											<td>
												<div
													class="
														px-6
														py-4
														text-neutral-700
													"
												>
													{{ index + 1 }}
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													whitespace-nowrap
													text-neutral-700
												"
												style="width: 250px"
											>
												<div class="flex items-center">
													<div>
														<img
															v-if="
																person.student
																	?.profile_photo
																	?.w200
															"
															:src="
																person.student
																	?.profile_photo
																	?.w200
															"
															alt=""
															class="
																shadow-sm
																inline-block
																h-6
																w-6
																rounded-full
															"
														/>
														<span
															v-else
															:style="{
																background:
																	person
																		.student
																		?.profile_photo
																		?.default
																		?.color,
															}"
															class="
																inline-flex
																items-center
																justify-center
																h-6
																w-6
																rounded-full
															"
														>
															<span
																class="
																	font-medium
																	leading-none
																	text-white
																	text-xs
																"
																>{{
																	person
																		.student
																		?.profile_photo
																		?.default
																		?.initial
																}}</span
															>
														</span>
													</div>
													<div
														class="
															ml-4
															text-orange-600
															font-semibold
															whitespace-pre-wrap
															w-40
														"
													>
														{{
															person.student.name
														}}
													</div>
												</div>
											</td>
											<td>
												<Checkbox
													:value="
														checkBoxSelected.find(
															(id) =>
																id ===
																person.tuition_id
														)
													"
													@click.stop="
														checkChanged(
															person.tuition_id
														)
													"
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div
						v-else
						class="
							w-full
							h-full
							mt-20
							flex
							items-center
							justify-center
							flex-col
						"
					>
						<img
							alt=""
							height="250"
							src="@/assets/images/illustrations/not_found.png"
							width="250"
						/>
						<div
							class="text-neutral-500 text-lg text-center"
							style="max-width: 700px"
						>
							Rất tiếc, chúng tôi không thể tìm thấy kết quả cho
							tìm kiếm của bạn.
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			:class="{ 'md-down:hidden': !isShow }"
			class="
				bg-white
				overflow-y-auto
				md-down:px-8 md-down:py-16
				p-12
				md-down:fixed md-down:h-screen md-down:w-screen
				relative
			"
		>
			<div class="flex items-center justify-between items-center">
				<div class="font-semibold text-neutral-600">
					Danh sách học sinh đã chọn
				</div>
				<div class="mb-1.5 md:hidden">
					<svg
						fill="none"
						height="28"
						viewBox="0 0 28 28"
						width="28"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							clip-rule="evenodd"
							d="M8.71463 8.71453C9.05633 8.37282 9.61035 8.37282 9.95206 8.71453L14.5833 13.3458L19.2146 8.71453C19.5563 8.37282 20.1104 8.37282 20.4521 8.71453C20.7938 9.05624 20.7938 9.61026 20.4521 9.95197L15.8208 14.5833L20.4521 19.2145C20.7938 19.5562 20.7938 20.1103 20.4521 20.452C20.1104 20.7937 19.5563 20.7937 19.2146 20.452L14.5833 15.8207L9.95206 20.452C9.61035 20.7937 9.05633 20.7937 8.71463 20.452C8.37292 20.1103 8.37292 19.5562 8.71463 19.2145L13.3459 14.5833L8.71463 9.95197C8.37292 9.61026 8.37292 9.05624 8.71463 8.71453Z"
							fill="#94A3B8"
							fill-rule="evenodd"
						/>
					</svg>
				</div>
			</div>
			<div class="text-neutral-400 mt-1">
				Danh sách học sinh đã chọn sẽ được đánh dấu đã đóng học phí
				tháng
				<span v-if="month_start">{{
					$filters.formatDateMMYYYY(month_start)
				}}</span>
			</div>
			<div>
				<div class="flex flex-col">
					<div
						v-if="students.length"
						class="shadow-sm sm:rounded-2xl mt-6"
					>
						<div class="__record__table overflow-x-auto scroll-bar">
							<div
								class="
									overflow-hidden
									sm:rounded-t-2xl
									w-fit
									min-w-full
								"
							>
								<table
									class="min-w-full divide-y text-neutral-200"
								>
									<thead class="bg-neutral-100 h-16">
										<tr>
											<th
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													w-16
													whitespace-nowrap
												"
												scope="col"
											>
												STT
											</th>
											<th
												class="
													px-6
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
													w-32
												"
												scope="col"
											>
												Tên học sinh
											</th>
											<th style="width: 40px"></th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(
												person, index
											) in studentsInClass"
											:key="person.student.id"
											:class="
												index % 2 === 0
													? 'bg-white'
													: 'bg-neutral-50'
											"
											class="
												text-neutral-700
												hover:bg-orange-50
												cursor-pointer
											"
											@click="
												$router.push(
													isAdmin
														? `/record/student/${person.student.id}`
														: `/record/student/${person.student.id}/info`
												)
											"
										>
											<td>
												<div
													class="
														px-6
														py-4
														text-neutral-700
													"
												>
													{{ index + 1 }}
												</div>
											</td>
											<td
												class="
													px-6
													py-4
													whitespace-nowrap
													text-neutral-700
												"
												style="width: 250px"
											>
												<div class="flex items-center">
													<div>
														<img
															v-if="
																person.student
																	?.profile_photo
																	?.w200
															"
															:src="
																person.student
																	?.profile_photo
																	?.w200
															"
															alt=""
															class="
																shadow-sm
																inline-block
																h-6
																w-6
																rounded-full
															"
														/>
														<span
															v-else
															:style="{
																background:
																	person
																		.student
																		?.profile_photo
																		?.default
																		?.color,
															}"
															class="
																inline-flex
																items-center
																justify-center
																h-6
																w-6
																rounded-full
															"
														>
															<span
																class="
																	font-medium
																	leading-none
																	text-white
																	text-xs
																"
																>{{
																	person
																		.student
																		?.profile_photo
																		?.default
																		?.initial
																}}</span
															>
														</span>
													</div>
													<div
														class="
															ml-4
															text-orange-600
															font-semibold
															whitespace-pre-wrap
															w-40
														"
													>
														{{
															person.student.name
														}}
													</div>
												</div>
											</td>
											<td>
												<Checkbox
													:value="
														checkBoxSelected.find(
															(id) =>
																id ===
																person.tuition_id
														)
													"
													@click.stop="
														checkChanged(
															person.tuition_id
														)
													"
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-6 flex justify-end">
					<button
						class="button-p text-center w-32"
						type="button"
						@click="submit"
					>
						Xác nhận
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DaIcon from '@/components/elements/daIcon/DaIcon';
import { $api } from '@/services';
import Checkbox from '@/components/elements/checkbox/Checkbox';
import { toEnglish } from '@/helper/toEnglish';

export default {
	name: 'MarkPaid',

	components: { Checkbox, DaIcon },

	data() {
		return {
			searchQuery: '',
			students: [],
			allStudents: [],
			params: {},
			checkBoxSelected: [],
			typing: null,
			isShow: false,
		};
	},

	created() {
		this.fetchStudents();
	},

	computed: {
		id() {
			return String(this.$route.params.id);
		},

		month_start() {
			return String(this.$route.query.month_start);
		},

		isAdmin() {
			return this.$store.getters.isAdmin;
		},

		studentsInClass() {
			return this.allStudents.filter((s) =>
				this.checkBoxSelected.includes(s.tuition_id)
			);
		},
	},

	methods: {
		checkChanged(id) {
			const result = this.checkBoxSelected.find((item) => item === id);

			if (result) {
				this.checkBoxSelected = this.checkBoxSelected.filter((item) => {
					return item !== id;
				});
			} else {
				this.checkBoxSelected.push(id);
			}
		},

		async search() {
			this.students = this.allStudents.filter((s) => {
				return (
					this.formatTextSearch(s.nameSearch).includes(
						this.formatTextSearch(this.searchQuery)
					) ||
					this.formatTextSearch(s.full_name).includes(
						this.formatTextSearch(this.searchQuery)
					) ||
					this.formatTextSearch(s.name).includes(
						this.formatTextSearch(this.searchQuery)
					) ||
					this.formatTextSearch(s.unaccent_full_name).includes(
						this.formatTextSearch(this.searchQuery)
					) ||
					this.formatTextSearch(s.unaccent_name).includes(
						this.formatTextSearch(this.searchQuery)
					)
				);
			});
		},

		formatTextSearch(text) {
			if (!text) {
				return '';
			}
			return text.toLowerCase();
		},

		searching() {
			clearInterval(this.typing);
			this.typing = setTimeout(() => {
				this.search();
			}, 1000);
		},

		async fetchStudents() {
			try {
				const {
					data: { data },
				} = await $api.notification.getFeeNotificationsByYear({
					tuition_state: '',
					month_start: this.month_start,
				});
				this.allStudents =
					data && data.length
						? data[0]?.tuition_notices_by_month.map((item) => {
								return {
									...item,
									nameSearch: toEnglish(item?.student.name),
								};
						  })
						: [];

				this.students =
					data && data.length
						? data[0]?.tuition_notices_by_month.map((item) => {
								return {
									...item,
									nameSearch: toEnglish(item?.student.name),
								};
						  })
						: [];
				this.allStudents.forEach((s) => {
					if (s.paid == true) {
						this.checkBoxSelected.push(s.tuition_id);
					}
				});
			} catch (e) {
				console.log(e);
			}
		},

		async submit() {
			try {
				const paid_tuition_ids = this.studentsInClass.map(
					(s) => s.tuition_id
				);
				await $api.notification.markPaid({
					month_start: this.month_start,
					paid_tuition_ids: paid_tuition_ids,
					unpaid_tuition_ids: this.allStudents
						.filter(
							(item) =>
								!paid_tuition_ids.includes(item.tuition_id)
						)
						.map((item) => item.tuition_id),
				});
				this.$store.dispatch('setAlertMessage', {
					message: 'Đánh dấu thành công',
				});
				await this.$router.push(`/fee-notices`);
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},
	},
};
</script>
