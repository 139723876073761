
import { Options, Vue } from 'vue-class-component';
import { PropSync } from 'vue-property-decorator';
import Multiselect from '@suadelabs/vue3-multiselect';
import { toEnglish } from '@/helper/toEnglish';

@Options({
	name: 'CreateStaffModal',
	components: { Multiselect },
})
export default class CreateStaffModal extends Vue {
	//----------------------- 🤍 Props 🤍 -----------------------//

	@PropSync('data')
	staffs!: any[];

	staffSelected = null;

	//----------------------- 🤍 Methods 🤍 -----------------------//
	submit() {
		// @ts-ignore
		let id = this.staffSelected ? this.staffSelected.id + '' : '';
		this.$emit('submit', { staff_id: id, main: false });
	}

	toEnglish(string = '') {
		return toEnglish(string);
	}
}
