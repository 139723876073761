<script>
import { $http } from '@/plugins/axios';

export default {
	name: 'DownloadCSV',
	methods: {
		downloadFileAsZip(files) {
			const fileTmp = Object.assign({}, files);
			const fileTmpArray = Object.values(fileTmp);

			const interval = setInterval(() => {
				if (fileTmpArray.length <= 0) {
					clearInterval(interval);
				} else {
					const file = fileTmpArray.pop();
					this.download(file);
				}
			}, 2000);
		},

		download(file) {
			let link = document.createElement('a');
			link.download = file.name;
			link.href = file?.file?.original;
			link.click();
		},

		async downloadCSV(student_id, plan_id) {
			try {
				const { data } = await $http.get(
					`/v1/staff/students/${student_id}/plans/${plan_id}/secure_token`
				);
				window.location.href =
					process.env.VUE_APP_URL +
					`csv/plans?secure_token=${data.data.token}`;
			} catch (e) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},

		async downloadCSVPlanReport(student_id, report_id) {
			try {
				const { data } = await $http.get(
					`/v1/staff/students/${student_id}/monthly_reports/${report_id}/secure_token`
				);
				window.location.href =
					process.env.VUE_APP_URL +
					`csv/monthly_reports?secure_token=${data.data.token}`;
			} catch (e) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},

		async downloadCSVScheduleService(payload) {
			try {
				const { data } = await $http.post(
					`v1/org_admin/schedule_sessions/export_token`,
					payload
				);
				window.location.href =
					process.env.VUE_APP_URL +
					`excel/export_schedule_sessions?secure_token=${data.token}`;
			} catch (e) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},
	},
};
</script>
