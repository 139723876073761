
import { defineComponent } from 'vue';

export default defineComponent({
	props: {
		data: {
			type: Array,
			required: true,
		},
	},
	methods: {
		close() {
			this.$emit('close');
		},
	},
});
