<template>
	<div class="_modal bg-white">
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<img
					src="@/assets/images/icons/sent.png"
					alt="Luca Education"
					title="Luca Education"
					height="60"
					width="60"
				/>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
					text-center
				"
			>
				Thông báo này sẽ được GỬI LẠI đến những phụ huynh chưa xem
			</div>
			<div class="text-center text-neutral-500 mt-2">
				Bạn có chắc chắn muốn gửi lại?
			</div>
			<div class="w-full mt-5">
				<div
					class="input-group grid grid-cols-2 items-center mb-10"
					v-if="notification.last_resent_at"
				>
					<div
						class="
							input-group__label
							text-neutral-500
							uppercase
							font-medium
						"
					>
						Lần cuối gửi thông báo
					</div>
					<div class="font-bold text-neutral-500 truncate">
						{{ timeSince(notification.last_resent_at) }}
					</div>
				</div>
				<div class="input-group grid grid-cols-2 items-center">
					<div
						class="
							input-group__label
							text-neutral-500
							font-medium
							uppercase
						"
					>
						Số phụ huynh chưa xem
					</div>
					<div class="font-bold text-neutral-500 truncate">
						{{ notification.unread_count }} phụ huynh
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="$emit('close')"
			>
				Huỷ
			</button>
			<button
				type="button"
				class="button-p text-center whitespace-nowrap"
				@click="$emit('submit')"
			>
				Chắc chắn
			</button>
		</div>
	</div>
</template>

<script>
import { timeSince } from '@/helper/formatDate';
export default {
	name: 'ReSend',
	methods: { timeSince },
	props: {
		notification: {
			type: Object,
			required: true,
		},
	},
};
</script>
