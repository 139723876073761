<template>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 w-screen md:w-3/5">
		<div class="h-full w-full flex flex-col bg-white shadow-xl">
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center" @click="$emit('close')">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M17.3005 10.5H23.1992C23.5172 10.5 23.6909 10.8708 23.4873 11.1151L20.538 14.6543C20.388 14.8342 20.1117 14.8342 19.9618 14.6543L17.0124 11.1151C16.8089 10.8708 16.9826 10.5 17.3005 10.5Z"
								fill="#94A3B8"
							/>
							<path
								d="M0.800522 13.5H6.69924C7.01718 13.5 7.19086 13.1292 6.98732 12.8849L4.03796 9.3457C3.88804 9.16579 3.61172 9.16579 3.4618 9.3457L0.512438 12.8849C0.308899 13.1292 0.482583 13.5 0.800522 13.5Z"
								fill="#94A3B8"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M11.9999 4.5C9.67114 4.5 7.59073 5.56045 6.21391 7.22758C5.95015 7.54696 5.47742 7.59205 5.15804 7.32829C4.83866 7.06452 4.79358 6.59179 5.05734 6.27242C6.70689 4.27504 9.20481 3 11.9999 3C16.4124 3 20.0819 6.17476 20.8515 10.3645C20.8598 10.4096 20.8677 10.4547 20.8753 10.5H19.35C18.6555 7.07667 15.6278 4.5 11.9999 4.5ZM4.64976 13.5C5.34428 16.9233 8.37194 19.5 11.9999 19.5C14.3286 19.5 16.409 18.4396 17.7859 16.7724C18.0496 16.453 18.5223 16.408 18.8417 16.6717C19.1611 16.9355 19.2062 17.4082 18.9424 17.7276C17.2929 19.725 14.795 21 11.9999 21C7.58733 21 3.91785 17.8252 3.14826 13.6355C3.13998 13.5904 3.13204 13.5453 3.12444 13.5H4.64976Z"
								fill="#94A3B8"
							/>
						</svg>
						<div
							class="
								text-2xl
								font-bold
								text-neutral-700
								ml-2
								mt-1
							"
						>
							<span v-if="type === 'students'">
								Khôi phục báo nghỉ cho từng học sinh</span
							>
							<span v-else>Khôi phục buổi học</span>
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							type="button"
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div
				class="
					sideover-content
					pt-6
					relative
					flex-1
					px-4
					sm:px-6
					overflow-y-auto
				"
			>
				<div v-if="type === 'students'">
					<div
						class="
							space-y-2
							mt-8
							rounded-xl
							border border-neutral-100
						"
					>
						<div
							v-for="(student, index) in students"
							:key="student.id"
							class="flex items-center justify-between p-4"
							:class="{
								'border-t border-neutral-100': index !== 0,
							}"
						>
							<div
								@click.stop="
									$router.push(
										`/record/student/${student.id}`
									)
								"
								class="flex items-center"
								v-if="student"
							>
								<div>
									<img
										class="
											shadow-sm
											inline-block
											h-6
											w-6
											rounded-full
										"
										:src="student?.profile_photo?.w200"
										alt=""
										v-if="student?.profile_photo?.w200"
									/>
									<span
										v-else
										class="
											inline-flex
											items-center
											justify-center
											h-9
											w-9
											rounded-full
										"
										:style="{
											background:
												student?.profile_photo?.default
													?.color,
										}"
									>
										<span
											class="
												font-medium
												leading-none
												text-white text-xs
											"
											>{{
												student?.profile_photo?.default
													?.initial
											}}</span
										>
									</span>
								</div>
								<div
									class="
										ml-4
										text-yellow-600
										font-semibold
										hover:text-yellow-700 hover:underline
										cursor-pointer
										whitespace-pre-wrap
									"
								>
									{{ student.name }}
								</div>
							</div>
							<Checkbox
								@click="onSelect(student?.id)"
								:value="selected.includes(student?.id)"
							/>
						</div>
					</div>
				</div>
				<div v-if="type === 'students'" class="w-full mt-6">
					<div class="font-semibold text-neutral-600 mb-2">
						Ghi chú
					</div>
					<div class="h-40">
						<textarea
							placeholder="Ghi chú khôi phục buổi học"
							v-model="leave_note"
							style="height: 100%; resize: none"
						/>
					</div>
				</div>
				<div class="mt-6" :id="`list-dropdown-1`">
					<div
						class="
							bg-neutral-50
							flex
							items-center
							justify-between
							p-3
							border
							rounded-t
							cursor-pointer
						"
						@click="openList(1)"
					>
						<div>Số buổi đã chọn</div>
						<div class="relative">
							<div class="pr-10">{{ data.length }} buổi</div>
							<div
								class="
									absolute
									top-1/2
									right-0
									transform
									-translate-y-1/2
									transition-all
									duration-200
								"
								:id="`list-dropdown-1-icon`"
							>
								<svg
									width="12"
									height="6"
									viewBox="0 0 12 6"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10.5 5.25L6 0.75L1.5 5.25"
										stroke="#48566A"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div
						class="px-4 border border-t-0 rounded-b"
						:id="`list-dropdown-1-content`"
						v-if="data"
					>
						<div
							v-for="(item, index) in data"
							:key="index"
							class="py-4"
						>
							<div class="flex items-center justify-between">
								<div
									class="flex items-center w-1/3"
									v-if="
										item.group_class_id &&
										getClass(item.group_class_id)
									"
								>
									<div
										@click.stop="
											$router.push(
												`/group-classes/${item.group_class_id}`
											)
										"
										class="flex items-center gap-4"
										v-if="
											item.group_class_id &&
											getClass(item.group_class_id)
										"
									>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<g
												clip-path="url(#clip0_21795_4077)"
											>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M7.34111 8.43768C7.34111 9.90561 6.15158 11.1204 4.65835 11.1204C3.16511 11.1204 1.97559 9.8803 1.97559 8.41238C1.97559 6.94445 3.16511 5.72961 4.65835 5.72961C6.12627 5.72961 7.34111 6.96976 7.34111 8.43768ZM6.60714 11.3989C7.46765 11.7279 8.1763 12.3606 8.60656 13.1958C7.8979 13.7526 7.3158 14.4866 7.01209 15.3724C6.86023 15.8026 6.73369 16.2835 6.63245 16.7391H0.634203C0.279876 16.7391 -0.0238327 16.4354 0.00147638 16.0557C0.0520945 15.2458 0.229258 14.436 0.507658 13.6767C0.887294 12.6137 1.69718 11.8038 2.70955 11.3989C3.26635 11.7785 3.94969 11.981 4.65834 11.981C5.367 11.981 6.05034 11.7785 6.60714 11.3989ZM23.5136 13.6767C23.134 12.6137 22.3241 11.8038 21.3117 11.3989C20.7296 11.7785 20.0716 11.981 19.3376 11.981C18.6037 11.981 17.9456 11.7785 17.3888 11.3989C16.5283 11.7279 15.8197 12.3606 15.3894 13.1958C16.0981 13.7526 16.6802 14.4866 16.9839 15.3724C17.1357 15.828 17.2623 16.2835 17.3635 16.7391H23.3618C23.7414 16.7391 24.0451 16.4354 23.9945 16.0557C23.9692 15.2458 23.792 14.436 23.5136 13.6767ZM22.0204 8.43768C22.0204 9.90561 20.8309 11.1204 19.3377 11.1204C17.8697 11.1204 16.6549 9.8803 16.6549 8.41238C16.6549 6.94445 17.8444 5.72961 19.3377 5.72961C20.8309 5.72961 22.0204 6.96976 22.0204 8.43768ZM13.9468 13.373C13.39 13.7526 12.7066 13.9551 11.998 13.9551C11.2893 13.9551 10.606 13.7526 10.0492 13.373C9.03682 13.7779 8.20162 14.5878 7.82199 15.6508C7.54359 16.4101 7.39173 17.22 7.31581 18.0299C7.2905 18.4095 7.59421 18.7132 7.97384 18.7132H16.0221C16.4018 18.7132 16.7055 18.4095 16.6802 18.0299C16.6295 17.22 16.4524 16.4101 16.174 15.6508C15.7943 14.5878 14.9591 13.7779 13.9468 13.373ZM14.6808 10.4118C14.6808 11.8798 13.4913 13.0946 11.9981 13.0946C10.5048 13.0946 9.31531 11.9051 9.31531 10.4118C9.31531 8.9439 10.5301 7.72906 11.9981 7.72906C13.466 7.72906 14.6808 8.91859 14.6808 10.4118Z"
													fill="#94A3B8"
												/>
											</g>
											<defs>
												<clipPath id="clip0_21795_4077">
													<rect
														width="24"
														height="24"
														fill="white"
													/>
												</clipPath>
											</defs>
										</svg>
										<div>
											{{
												getClass(item.group_class_id)
													?.name
											}}
										</div>
									</div>
								</div>
								<div class="w-1/3">
									<div
										v-if="item.staff_ids"
										class="flex flex-col gap-2"
									>
										<div
											@click.stop="
												$router.push(
													`/record/employee/${staff_id}`
												)
											"
											class="flex items-center"
											v-for="staff_id in item.staff_ids"
											:key="staff_id"
										>
											<div v-if="getStaff(staff_id)">
												<img
													class="
														shadow-sm
														inline-block
														h-9
														w-9
														rounded-full
													"
													:src="
														getStaff(staff_id)
															?.profile_photo
															?.w200
													"
													alt=""
													v-if="
														getStaff(staff_id)
															?.profile_photo
															?.w200
													"
												/>
												<span
													v-else
													class="
														inline-flex
														items-center
														justify-center
														h-9
														w-9
														rounded-full
													"
													:style="{
														background:
															getStaff(staff_id)
																?.profile_photo
																?.default
																?.color,
													}"
												>
													<span
														class="
															font-medium
															leading-none
															text-white text-xs
														"
														>{{
															getStaff(staff_id)
																?.profile_photo
																?.default
																?.initial
														}}</span
													>
												</span>
											</div>
											<div
												v-if="getStaff(staff_id)"
												class="
													ml-4
													text-blue-600
													font-semibold
												"
											>
												{{ getStaff(staff_id).name }}
											</div>
										</div>
									</div>
								</div>
								<div class="text-gray-600">
									<span v-if="item.date">
										{{ $filters.formatDate2(item.date) }}
									</span>
									<span>
										- {{ item.display_start_slot }}</span
									>
									<span> - {{ item.display_end_slot }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="px-6 py-4 border-t border-yellow-50">
				<div class="flex justify-end">
					<div>
						<button
							type="button"
							class="button-p text-center px-6"
							@click="submit"
							:disabled="
								inProgress ||
								(type === 'students' && !leave_note?.length)
							"
						>
							Xác nhận
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import { $api } from '@/services';
import Checkbox from '@/components/elements/checkbox/Checkbox.vue';

export default defineComponent({
	name: 'RestoreFormGroupClass',
	components: { Checkbox },

	props: {
		data: {
			type: Array,
			required: true,
		},

		staffs: {
			type: Array,
			required: true,
		},

		group_classes: {
			type: Array,
			required: true,
		},

		type: {
			type: String,
			default: 'students',
		},
	},

	created() {
		this.fetchStudents();
	},

	data() {
		return {
			date: new Date(),
			inProgress: false,
			leave_note: '',
			students: [],
			selected: [],
		};
	},

	methods: {
		async submit() {
			if (this.type === 'classes') {
				await this.restoreCancelSessions();
				return;
			}
			this.inProgress = true;
			try {
				await $api.groupClasses.uncancelGroupSessionForStudent(
					{
						group_session_ids: this.data.map((g) => g.id),
						leave_note: this.leave_note,
						student_ids: this.selected?.map((s) => s),
						// eslint-disable-next-line no-mixed-spaces-and-tabs
					}
					// eslint-disable-next-line no-mixed-spaces-and-tabs
				);
				await this.$store.dispatch('setAlertMessage', {
					message: 'Khôi phục buổi học thành công',
				});
				this.$emit('success');
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		async restoreCancelSessions() {
			this.inProgress = true;
			try {
				const promise = [];
				this.data.forEach((s) => {
					promise.push($api.groupClasses.uncancelGroupSession(s.id));
				});

				await Promise.all(promise);

				await this.$store.dispatch('setAlertMessage', {
					message: 'Khôi phục buổi học thành thành công',
				});
				this.$emit('success');
			} catch (e) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		openList(index) {
			const el = document.getElementById(
				`list-dropdown-${index}-content`
			);
			const icon = document.getElementById(`list-dropdown-${index}-icon`);
			if (el) {
				if (el.classList.contains('hidden')) {
					el.classList.remove('hidden');
					icon?.classList.add('rotate-180');
				} else {
					el.classList.add('hidden');
					icon?.classList.remove('rotate-180');
				}
			}
		},

		getStaff(staff_id) {
			return this.staffs?.find((s) => s.id === staff_id);
		},

		getClass(class_id) {
			return this.group_classes?.find((s) => s.id === class_id);
		},

		onSelect(id) {
			const index = this.selected.indexOf(id);

			if (index === -1) {
				this.selected.push(id);
			} else {
				this.selected = this.selected.filter((item) => item !== id);
			}
		},

		async fetchStudents() {
			const ids = this.data?.map((item) => item?.id).filter((i) => !!i);
			try {
				const {
					data: { data },
				} = await $api.groupClasses.studentInGroupWithState(
					this.data[0].group_class_id,
					{ group_session_ids: ids }
				);
				this.students = data.filter(
					(s) => s.group_session_state === 'cancelled'
				);
			} catch (err) {
				console.log(err);
			}
		},
	},
});
</script>
