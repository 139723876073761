import { ref } from 'vue';
import { $api } from '@/services';

const error = ref(null);
const loading = ref(false);

export async function listLessonReport(filter: any, before?: string | null) {
	error.value = null;
	try {
		const res = await $api.lessonReport.listLessonReport({
			...filter,
			date: undefined,
			before: before || undefined,
			include_new: 1,
		});
		if (!res) throw new Error('Lỗi');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export function useListLessonReport() {
	return { error, loading, listLessonReport };
}
