
import { Options, Vue } from 'vue-class-component';
import { _ROUTER_NAME } from '@/enums';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';

@Options({
	name: 'ReportWeek',
	components: { DaIcon },
})
export default class ReportWeek extends Vue {
	sideOverShow = false;
	tabs: any = [
		{
			id: 0,
			name: 'Tài liệu của Trung tâm',
			link: _ROUTER_NAME.DOCUMENTS,
		},
		{
			id: 1,
			name: 'Tài liệu của Luca',
			link: _ROUTER_NAME.LUCA_DOCUMENTS,
		},
	];
	searchQuery = '';
	typing: any = null;
	onCreateFlag = 0;

	get canCreateDocument() {
		return this.$route.name === _ROUTER_NAME.DOCUMENTS;
	}

	searching() {
		clearInterval(this.typing);
		this.typing = setTimeout(() => {
			this.search();
		}, 1000);
	}

	search() {
		this.$router.push({
			query: { query: this.searchQuery },
		});
	}
}
