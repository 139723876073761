
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { useWeeklyReport } from '@/features/weeklyReport/weeklyReport';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import ConfirmModal from '@/views/report/features/weekDetail/features/confirmModal/ConfirmModal.vue';
import { _MODAL_TYPE, _ROUTER_NAME } from '@/enums';
import ExitModal from '@/views/report/features/weekDetail/features/exitConfirm/ExitModal.vue';
import { _GET_DATE_2 } from '@/helper/formatDate';
import router from '@/router';
import { $api } from '@/services';
import CopyModal from '@/views/report/features/copyModal/CopyModal.vue';
import Loading from '@/components/common/Loading.vue';
import { _MONTH_DIFF } from '@/helper/monthDiff';

export default defineComponent({
	name: 'ReportWeekDetail',
	components: {
		Loading,
		CopyModal,
		ExitModal,
		ConfirmModal,
		DaModalFrame,
		DaIcon,
	},
	setup() {
		const store = useStore();
		const { getWeeklyReport, submitReport } = useWeeklyReport();
		const route = useRoute();
		const id = route.params.id ? route.params.id + '' : '';
		const week_start = route.query.week_start
			? route.query.week_start + ''
			: '';
		const editQuery = route.query.action === 'edit' ? true : '';
		const query = week_start ? `?week_start=${week_start}` : '';
		let weeklyReport = ref({});
		let student_id = ref('');
		let token = ref('');
		let org = ref({
			value: {
				note: '',
			},
		} as any);
		let assessment_result = ref([]);
		let series = ref([] as number[]);
		let series2_1 = ref([] as number[]);
		let series2_2 = ref([] as number[]);
		let series2_3 = ref([] as number[]);
		let series3 = ref([] as any[]);
		let emotion_scale = ref([] as any[]);
		let assessments_by_skill = ref([] as any[]);
		let emotion_cooperation_result_by_day = ref([] as any[]);
		let emotion_cooperation_result_by_day_emotion = ref([] as any[]);
		let emotion_cooperation_result_by_day_rating = ref([] as any[]);
		let emotion_cooperation_result_by_day_result = ref([] as any[]);
		let emotion_cooperation_result_by_day_labels = ref([] as any[]);
		let emotion_cooperation_result_by_day_data = ref([
			{
				name: 'Cảm xúc',
				type: 'column',
				data: [0, 0, 0, 0, 0],
			},
			{
				name: 'Sự tham gia',
				type: 'column',
				data: [0, 0, 0, 0, 0],
			},
			{
				name: 'Kết quả buổi học',
				type: 'line',
				data: [0, 0, 0, 0, 0],
			},
		] as any[]);
		let series3Total = ref(0);
		let isLoading = ref(true);

		const mixChart = ref(null as any);
		const compare_with_last = ref({
			average_cooperation_scale: '0.0',
			average_emotion_scale: '0.0',
			average_result: '0.0',
		});

		const attendance = ref({
			attendance_compare_with_last_percent: '0.0',
			attendance_percent: '100.0',
			completed: 2,
			total: 2,
		});
		const is_old_data = computed(() => route.query.is_old_data);
		let radarData = ref([] as any[]);
		let categories = ref([] as string[]);
		const radarChartOptions = ref<any>({
			chart: {
				id: 'radarChart',
				type: 'radar',
			},
			title: {
				show: false,
			},
			stroke: {
				width: 0,
			},
			fill: {
				opacity: 0.2,
				colors: ['#65A30D'],
			},
			markers: {
				size: 5,
				hover: {
					size: 10,
				},
				colors: ['#65A30D'],
			},
			dataLabels: {
				enabled: true,
				formatter: function (val: any) {
					return val + '%';
				},
				style: {
					fontFamily: 'Gilrory',
					fontSize: '20px',
					fontWeight: 'bold',
					colors: ['#344256'],
				},
				background: {
					enabled: false,
				},
			},
			yaxis: {
				show: false,
				logBase: 100,
				tickAmount: 10,
				min: 0,
				max: 100,
				labels: {
					show: false,
				},
			},
			xaxis: {
				categories: [],
				labels: {
					show: true,
					style: {
						fontFamily: 'Gilrory',
						fontSize: '24px',
						fontWeight: 'bold',
						colors: Array(1000).fill('#344256'),
					},
					formatter: function (val: any, index: any) {
						return index?.dataPointIndex + 1;
					},
				},
			},
			tooltip: {
				x: {
					show: true,
					formatter: function (val: any, object: any) {
						return `${object?.dataPointIndex + 1}: ${val}`;
					},
					title: {
						formatter: (seriesName: string) => seriesName,
					},
				},
				y: {
					show: true,
					formatter: function (val: any) {
						return val + '%';
					},
					title: {
						formatter: (seriesName: string) => seriesName + ':',
					},
				},
			},
		});

		async function fetchReport() {
			emotion_cooperation_result_by_day.value = [];
			emotion_cooperation_result_by_day_emotion.value = [];
			emotion_cooperation_result_by_day_rating.value = [];
			emotion_cooperation_result_by_day_result.value = [];
			emotion_cooperation_result_by_day_labels.value = [];
			emotion_cooperation_result_by_day_data.value = [
				{
					name: 'Kết quả buổi học',
					type: 'line',
					data: [0, 0, 0, 0, 0],
				},
				{
					name: 'Sự tham gia',
					type: 'column',
					data: [0, 0, 0, 0, 0],
				},
				{
					name: 'Cảm xúc',
					type: 'column',
					data: [0, 0, 0, 0, 0],
				},
			];

			const oldPayload = ref({
				'x-cross': route.query.org_id,
			});

			const res = await getWeeklyReport(
				id,
				query,
				route.query.is_old_data ? oldPayload.value : null
			);
			if (res?.data) {
				weeklyReport.value = res?.data;
				student_id.value = res?.data.student_id;
				series2_1.value = [res?.data?.average_cooperation_scale || 0];
				series2_2.value = [res?.data?.average_result_in_percent || 0];
				series2_3.value = [res?.data?.average_emotion_scale || 0];
				if (!is_old_data.value) {
					await getToken();
				}
			}
			if (res?.org) {
				org.value = res?.org;
			}
			if (res?.stats) {
				if (res.stats.compare_with_last) {
					compare_with_last.value = res.stats.compare_with_last;
				}

				if (res.stats.attendance) {
					attendance.value = res.stats.attendance;
				}
			}
			if (res?.stats?.assessment_results) {
				assessment_result.value = res?.stats?.result;
				emotion_cooperation_result_by_day.value =
					res?.stats?.emotion_cooperation_result_by_day;
				assessments_by_skill.value = res?.stats?.assessment_results;
				emotion_scale.value = res?.stats?.emotion_scale;
				emotion_scale.value.forEach((item) => {
					return (series3Total.value += item.count);
				});
				series3.value = emotion_scale.value.map((item) => {
					const r = (item.count * 100) / series3Total.value;
					return Math.round(r * 10) / 10 || 0;
				});
				let a: any = [];
				let count = 0;
				await assessment_result.value.forEach(
					(item: { result: string; count: number }) => {
						if (
							item.result === 'Từ chối' ||
							item.result === 'Không có cơ hội' ||
							item.result === 'Không hợp tác'
						) {
							count += item.count;
						} else {
							a.push(item);
						}
					}
				);
				a.push({
					result: 'X',
					count: count,
				});
				series.value = a.map((item: { count: number }) => {
					return item.count;
				});
				emotion_cooperation_result_by_day.value.forEach((item) => {
					emotion_cooperation_result_by_day_emotion.value.push(
						+(+item.emotion_scale / 5) * 100 || null
					);
					emotion_cooperation_result_by_day_rating.value.push(
						+(+item.cooperation_scale / 5) * 100 || null
					);
					emotion_cooperation_result_by_day_result.value.push(
						+item.complete_percent || null
					);
					//@ts-ignore
					emotion_cooperation_result_by_day_labels.value.push(
						_GET_DATE_2(item.date)
					);
				});
				emotion_cooperation_result_by_day_data.value[1] = {
					name: 'Cảm xúc',
					type: 'column',
					data: emotion_cooperation_result_by_day_emotion.value,
				};
				emotion_cooperation_result_by_day_data.value[0] = {
					name: 'Sự tham gia',
					type: 'column',
					data: emotion_cooperation_result_by_day_rating.value,
				};
				emotion_cooperation_result_by_day_data.value[2] = {
					name: 'Kết quả buổi học',
					type: 'line',
					data: emotion_cooperation_result_by_day_result.value,
				};

				const optimalColumnWidthPercent =
					5 +
					15 /
						(1 +
							30 *
								Math.exp(
									-emotion_cooperation_result_by_day_labels
										.value?.length / 3
								));

				setTimeout(() => {
					mixChart.value?.updateOptions({
						labels: emotion_cooperation_result_by_day_labels.value,
						plotOptions: {
							bar: {
								columnWidth: optimalColumnWidthPercent + '%',
							},
						},
					});
				}, 1000);
			}

			if (res?.skills) {
				radarData.value = [
					{
						name: 'Kết quả',
						data: res?.skills.map((skill: any) => {
							return skill?.complete_percent;
						}),
					},
				];
				radarChartOptions.value.xaxis.categories = res?.skills.map(
					(skill: any) => {
						return skill?.name;
					}
				);
				categories.value = res?.skills.map((skill: any) => {
					return skill?.name;
				});
			}
			isLoading.value = false;
		}

		// this gives an object with dates as keys
		function groups(array: any) {
			return array.reduce((groups: any, item: any) => {
				const skill = item.skill;
				if (!groups[skill]) {
					groups[skill] = [];
				}
				groups[skill].push(item);
				return groups;
			}, {});
		}

		const assessments_by_skill_group = computed(() => {
			const g: any[] = groups(assessments_by_skill.value);
			const rs: any = Object.keys(g).map((skill: any) => {
				return {
					skill,
					data: g[skill],
				};
			});
			return rs;
		});

		let editReport = ref(editQuery);

		watch(
			editReport,
			(value) => {
				if (value) {
					const queryRoute = Object.assign({}, route.query);
					queryRoute.action = 'edit';
					router.push({
						query: queryRoute,
					});
				}
			},
			{ immediate: true }
		);

		watch(route, (value: any) => {
			editReport.value = value.query.action === 'edit' ? true : '';
		});

		function edit() {
			editReport.value = true;
			//@ts-ignore
			noteContent.value = weeklyReport.value?.note;
		}

		//@ts-ignore
		const alertShow = ref(!weeklyReport.value?.note);
		let noteContent = ref('');
		let noteUpdateError = ref('');
		let modalIsOpen = ref(false);
		let modalType = ref('');
		let successData = ref(false);

		fetchReport();

		let reportData = ref({
			//@ts-ignore
			student: computed(() => weeklyReport.value?.student?.full_name),
			week_start: computed(() => week_start),
			//@ts-ignore
			report_date: computed(() => weeklyReport.value?.inserted_at),
			staff: computed(
				//@ts-ignore
				() => weeklyReport.value?.submitted_by_staff?.full_name
			),
		});

		function cancel() {
			editReport.value = false;
			noteContent.value = '';
			modalIsOpen.value = false;
			modalType.value = '';
			path.value = ' ';
			router.push({
				name: _ROUTER_NAME.REPORT_WEEK,
			});
		}

		function submit() {
			successData.value = false;
			if (noteContent.value?.length <= 0) {
				return (noteUpdateError.value =
					'Bạn cần hoàn tất nhận xét chung của cả tuần để hoàn thành báo cáo');
			}
			modalIsOpen.value = true;
			modalType.value = _MODAL_TYPE.CONFIRM;
		}

		function shareReport() {
			window.open(`/w/${token.value}`, '_blank');
		}

		const path = ref('');

		function openShare() {
			modalIsOpen.value = true;
			modalType.value = _MODAL_TYPE.COPY;
			path.value = `${window.location.origin}/w/${token.value}`;
		}

		function closeShare() {
			modalIsOpen.value = false;
			path.value = '';
		}

		async function getToken() {
			try {
				// @ts-ignore
				if (!weeklyReport.value.id) {
					return;
				}
				const res = await $api.weeklyReport.getToken(
					// @ts-ignore
					// @ts-ignore
					weeklyReport.value.id,
					student_id.value
				);
				if (res?.data) {
					token.value = res?.data?.data.token;
				}
			} catch (e) {
				console.log(e);
			}
		}

		function exit() {
			modalIsOpen.value = true;
			modalType.value = _MODAL_TYPE.EXIT;
		}

		async function confirmSubmit() {
			const res = await submitReport(id, {
				note: noteContent.value,
				week_start: week_start,
			});
			if (res?.data) {
				editReport.value = false;
				noteContent.value = '';
				path.value = ' ';
				successData.value = true;
				await router.push(
					`/report/week/${id}?week_start=${week_start}`
				);
				await fetchReport();
			}
		}

		function cancelConfirm() {
			modalIsOpen.value = false;
		}

		onMounted(() => {
			window.scrollTo(0, 0);
		});

		const dateData = computed(() => {
			const join_date = route.query.join_date;
			const left_date = route.query.left_date;
			if (!join_date) {
				return '';
			}
			return `${_GET_DATE_2(join_date)} - ${
				left_date ? _GET_DATE_2(left_date) : 'Hiện tại'
			} | Đã học ${_MONTH_DIFF(join_date, left_date)} tháng`;
		});

		function exitOldData() {
			const new_id = route.query.new_id;
			const r = router.resolve({
				path: `/record/student/${new_id}/staff-list`,
			});
			window.location.assign(r.href);
		}

		return {
			id,
			dateData,
			exitOldData,
			openShare,
			closeShare,
			path,
			emotion_cooperation_result_by_day_data: computed(
				() => emotion_cooperation_result_by_day_data.value
			),
			weeklyReport,
			reportData,
			modalIsOpen,
			editReport,
			alertShow,
			mixChart,
			noteUpdateError,
			noteContent,
			successData,
			submit,
			confirmSubmit,
			cancelConfirm,
			shareReport,
			edit,
			cancel,
			exit,
			modalType,
			modalTypes: computed(() => _MODAL_TYPE),
			isAdmin: computed(() => store.getters.isAdmin),
			org,
			store,
			assessment_result,
			assessments_by_skill_group,
			series2_1,
			series2_2,
			series2_3,
			series,
			series3Total,
			mixChartOptions: {
				chart: {
					type: 'line',
					stacked: false,
				},
				dataLabels: {
					enabled: true,
					enabledOnSeries: [0, 1],
					offsetY: -10,
					background: {
						enabled: true,
						foreColor: '#334155',
					},
					formatter: function (value: any) {
						if (!value || value == 'null') {
							return 'Không thực hiện';
						}
						return value + '%';
					},
				},
				stroke: {
					width: [0.5, 0.5, 4],
				},
				yaxis: [
					{
						opposite: false,
						axisTicks: {
							show: false,
						},
						axisBorder: {
							show: false,
						},
						labels: {
							show: false,
							formatter: (value: any) => {
								if (!value || value == 'null') {
									return 'Không thực hiện';
								}
								return value + '%';
							},
						},
						title: {
							show: false,
						},
						max: 100,
					},
					{
						opposite: false,
						axisTicks: {
							show: false,
						},
						axisBorder: {
							show: false,
						},
						labels: {
							show: false,
							formatter: (value: any) => {
								if (!value || value == 'null') {
									return 'Không thực hiện';
								}
								return value + '%';
							},
						},
						title: {
							show: false,
						},
						max: 100,
					},
					{
						opposite: false,
						axisTicks: {
							show: false,
						},
						axisBorder: {
							show: false,
						},
						labels: {
							show: false,
							formatter: (value: any) => {
								if (!value || value == 'null') {
									return 'Không thực hiện';
								}
								return value + '%';
							},
						},
						title: {
							show: false,
						},
						max: 100,
					},
				],
				tooltip: {
					fixed: {
						enabled: true,
						position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
						offsetY: 30,
						offsetX: 60,
					},
				},
				legend: {
					horizontalAlign: 'left',
					offsetX: 40,
				},
				plotOptions: {
					bar: {
						horizontal: false,
						columnWidth: '20%',
						borderRadius: 4,
					},
				},
				labels: [],
				colors: ['#FFD270', '#DA8F8F', '#6ED0B9'],
				markers: {
					size: 8,
					strokeColors: '#fff',
					strokeWidth: 2,
				},
			},
			emotion_scale,
			chartOptions: {
				dataLabels: {
					enabled: false,
				},
				colors: ['#7AD2C6', '#93BF56', '#F8BB54', '#F47C7C', '#E5E7EB'],
				labels: [
					'Đạt 80 - 100%',
					'Đạt 55 - 75%',
					'Đạt 30 - 50%',
					'Đạt 5 - 25%',
					'X',
				],
				legend: {
					show: false,
				},
				tooltip: {
					fillSeriesColor: true,
					y: {
						formatter: function (value: any) {
							return value + ' mục tiêu';
						},
					},
				},
				stroke: {
					lineCap: 'round',
				},
			},
			emotion_cooperation_result_by_day_labels,
			chartOptions2_1: {
				chart: {
					type: 'radialBar',
				},
				plotOptions: {
					radialBar: {
						hollow: {
							size: '54%',
						},
						dataLabels: {
							name: {
								offsetY: -10,
								show: true,
								color: '#65758B',
								fontWeight: 'normal',
								fontSize: '13px',
							},
							value: {
								color: '#48566A',
								fontSize: '24px',
								show: true,
								fontWeight: 'bold',
							},
						},
					},
				},
				fill: {
					colors: '#6ED0B9',
				},
				stroke: {
					lineCap: 'round',
				},
				labels: ['Kết quả'],
			},
			chartOptions2_2: {
				chart: {
					type: 'radialBar',
				},
				plotOptions: {
					radialBar: {
						hollow: {
							size: '54%',
						},
						dataLabels: {
							name: {
								offsetY: -10,
								show: true,
								color: '#65758B',
								fontWeight: 'normal',
								fontSize: '13px',
							},
							value: {
								color: '#48566A',
								fontSize: '24px',
								show: true,
								fontWeight: 'bold',
							},
						},
					},
				},
				fill: {
					colors: '#FFD270',
				},
				stroke: {
					lineCap: 'round',
				},
				labels: ['Sự tham gia'],
			},
			chartOptions2_3: {
				chart: {
					type: 'radialBar',
				},
				plotOptions: {
					radialBar: {
						hollow: {
							size: '54%',
						},
						dataLabels: {
							name: {
								offsetY: -10,
								show: true,
								color: '#65758B',
								fontWeight: 'normal',
								fontSize: '13px',
							},
							value: {
								color: '#48566A',
								fontSize: '24px',
								show: true,
								fontWeight: 'bold',
							},
						},
					},
				},
				fill: {
					colors: '#DA8F8F',
				},
				stroke: {
					lineCap: 'round',
				},
				labels: ['Cảm xúc'],
			},
			series3,
			chartOptions3: {
				colors: ['#9EDF93', '#D0DB54', '#FFE68C', '#FF9D6D', '#FF6967'],
				chart: {
					width: 380,
					type: 'polarArea',
				},
				labels: [
					'Vui vẻ',
					'Bình thường',
					'Khó chịu',
					'Tức giận',
					'Bùng nổ',
				],
				tooltip: {
					fillSeriesColor: true,
					y: {
						formatter: function (value: any) {
							if (!value || value == 'null') {
								return 'Không thực hiện';
							}
							return value + '%';
						},
					},
				},
				fill: {
					opacity: 1,
					colors: [
						'#9EDF93',
						'#D0DB54',
						'#FFE68C',
						'#FF9D6D',
						'#FF6967',
					],
				},
				stroke: {
					lineCap: 'round',
				},
				yaxis: {
					show: false,
				},
				legend: {
					show: false,
				},
				dataLabels: {
					enabled: false,
				},
				plotOptions: {
					polarArea: {
						rings: {
							strokeWidth: 0,
						},
						spokes: {
							strokeWidth: 0,
						},
					},
				},
			},
			attendance,
			compare_with_last,
			week_start,
			student_id,
			isLoading,
			is_old_data,
			radarData,
			radarChartOptions,
			categories,
		};
	},
});
