
import { computed, defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { $api } from '@/services';
import { useStore } from 'vuex';
import Popper from 'vue3-popper';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import DeleteConfirm from '@/components/modules/reportTargetFuture/DeleteConfirm.vue';
import EditGoalForGroup from '@/views/createGoalByLibForGroup/EditGoalForGroup.vue';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail.vue';

export default defineComponent({
	name: 'DataSlideOverBank',
	components: {
		Popper,
		TooltipGoalDetail,
		EditGoalForGroup,
		DeleteConfirm,
		DaModalFrame,
	},
	props: {
		isCompleted: {
			type: Boolean,
			default: false,
		},
		isUpdated: {
			type: Boolean,
			default: false,
		},
	},
	setup(props: any, { emit }: any) {
		const route = useRoute();
		const store = useStore();

		const id = computed(() =>
			route.params?.id ? route.params?.id + '' : ''
		);

		const type = computed(() =>
			route.query?.type ? route.query?.type + '' : ''
		);

		//
		const isShow = ref(true);
		const report = ref(null as any);
		const goals = ref(null as any);
		const stats = ref(null as any);
		const goals_count = ref(0);
		const skills_count = ref(0);
		const continued_goals_count = ref(0);
		const goals_from_lib_count = ref(0);
		const goals_from_stopped_count = ref(0);
		const new_goals_count = ref(0);

		async function fetchGoalsLib() {
			const {
				data: { data, stats },
			} = await $api.groupClasses.goalsInBank(id.value);
			if (data) {
				goals.value = data?.skills ?? [];
			}

			if (stats) {
				skills_count.value = stats?.skills_count || 0;
				goals_count.value = stats?.goals_count || 0;
				continued_goals_count.value = stats?.continued_goals_count || 0;
				goals_from_lib_count.value = stats?.goals_from_lib_count || 0;
				goals_from_stopped_count.value =
					stats?.goals_from_stopped_count || 0;
				new_goals_count.value = stats?.new_goals_count || 0;
			}
		}

		async function activePlans() {
			try {
				const {
					data: { data, stats: s, skills: sk },
				} = await $api.groupClasses.activeReports(id.value);
				report.value = data;
				stats.value = s;
				goals.value = sk;

				if (s) {
					skills_count.value = s?.skills_count || 0;
					goals_count.value = s?.goals_count || 0;
					continued_goals_count.value = s?.continued_goals_count || 0;
					goals_from_lib_count.value = s?.goals_from_lib_count || 0;
					goals_from_stopped_count.value =
						s?.goals_from_stopped_count || 0;
					new_goals_count.value = s?.new_goals_count || 0;
				}
			} catch (e) {
				console.log(e);
			}
		}

		async function deleteGoal(goal: any) {
			try {
				await $api.groupClasses.deleteGoalLib(
					goal.id || goal.goal_id,
					id.value
				);
				await fetchGoalsInPlan();
				await store.dispatch('setAlertMessage', {
					message: 'Đã xoá mục tiêu thành công',
				});
				isSuccess.value = true;
				await store.dispatch('removeGoal', id);
				closeModal();
			} catch (e) {
				await store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		}

		watch(
			() => props.isUpdated,
			(value: boolean) => {
				if (value) {
					fetchGoalsInPlan();
					emit('updated');
				}
			}
		);

		const goals__completed = ref([]);
		const skills_count__completed = ref(0);
		const goals_count__completed = ref(0);

		//
		async function fetchGoals() {
			const {
				data: { data, stats },
			} = await $api.groupClasses.goalsCompletedByGroupClass(id.value);
			if (data) {
				goals__completed.value = data?.skills ?? [];
			}

			if (stats) {
				skills_count__completed.value = stats?.skills_count || 0;
				goals_count__completed.value = stats?.goals_count || 0;
			}
		}

		const modalIsOpen = ref(false);
		const goalSelected = ref(null);
		const isSuccess = ref(false);
		const isNoRepeat = ref(false);
		const isReteach = ref(false);
		const isNoRepeatReTeach = ref(false);
		const isEditModal = ref(false);

		function deleting(goal: any, reteach = false) {
			if (reteach && isNoRepeatReTeach.value) {
				deleteGoal(goal);
				closeModal();
				return;
			}
			if (!reteach && isNoRepeat.value) {
				deleteGoal(goal);
				closeModal();
				return;
			}
			goalSelected.value = goal;
			modalIsOpen.value = true;
			isSuccess.value = false;
			isEditModal.value = false;
			isReteach.value = reteach;
		}

		let editData = ref(null as any);
		const tabActive = ref(null as any);

		async function editGoalModal(data: any) {
			editData.value = {
				id: data?.id || data?.goal_id,
				name: data?.name || data?.goal_name,
			};
			isEditModal.value = true;
			if (!data?.editable) {
				tabActive.value = 1;
			} else {
				tabActive.value = null;
			}
		}

		function closeModal() {
			modalIsOpen.value = false;
			goalSelected.value = null;
			isSuccess.value = false;
			isEditModal.value = false;
		}

		function closeModelParent() {
			if (isReteach.value) {
				isNoRepeatReTeach.value = false;
			} else {
				isNoRepeat.value = false;
			}
			closeModal();
		}

		const errorEdit = ref('');

		async function editSubmit(data: any) {
			try {
				const res = await $api.groupClasses.updateGoal(
					id.value,
					data.id || data.goal_id,
					{
						group_goal: data,
					}
				);
				if (res?.data) {
					closeModal();
					await fetchGoalsInPlan();
					await store.dispatch('setAlertMessage', {
						message: 'Chỉnh sửa mục tiêu thành công',
					});
					emit('re-fetch');
				}
			} catch (e) {
				errorEdit.value = e?.response?.data?.errors_by_field || '';
			}
		}

		const skills = ref([]);

		async function fetchSkill() {
			try {
				const res = await $api.goal.allSkills();
				if (res?.data) {
					skills.value = res?.data?.data;
				}
			} catch (e) {
				console.log(e);
			}
		}

		async function fetchGoalsInPlan() {
			switch (type.value) {
				case 'library':
					await fetchGoalsLib();
					break;
				default:
					await activePlans();
			}
		}

		fetchGoals();
		fetchGoalsInPlan();
		fetchSkill();

		return {
			id,
			isReteach,
			isNoRepeat,
			isNoRepeatReTeach,
			modalIsOpen,
			goalSelected,
			isSuccess,
			errorEdit,
			editData,
			isEditModal,
			skills,
			deleting,
			closeModal,
			closeModelParent,
			editSubmit,
			editGoalModal,
			report,
			goals,
			goals_count,
			skills_count,
			continued_goals_count,
			goals_from_lib_count,
			goals_from_stopped_count,
			new_goals_count,
			goals__completed,
			skills_count__completed,
			goals_count__completed,
			stop,
			deleteGoal,
			tabActive,
			org: computed(() => store.state.profile.current_org),
			type,
			isShow,
		};
	},
});
