
import { Options, Vue } from 'vue-class-component';
import { Prop, PropSync, Watch } from 'vue-property-decorator';
import SwitchButton from '@/components/elements/switchButton/SwitchButton.vue';

@Options({
	name: 'UpdateGoalLib',
	components: { SwitchButton },
})
export default class CreateGoalLib extends Vue {
	@PropSync('error', { default: '' })
	error!: any;

	@PropSync('inProgress', { default: false, type: Boolean })
	inProgress!: boolean;

	@Prop('data') data!: any;

	errorMes = {
		name: '',
		status: true,
	};

	goalData = {
		name: '',
    is_published: false,
	};

	@Watch('error')
	onErrorPropsChange(value: any) {
		if (value && value['name']) {
			this.errorMes['name'] =
				'Tên thư viện mới không được trùng với tên thư viện đã có trước đó.';
		}
	}

	@Watch('data', {
		immediate: true,
		deep: true,
	})
	onDataChange(value: any) {
		this.goalData.name = value?.name || '';
		this.goalData.is_published = !!value?.is_published;
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	submit() {
		this.errorMes = {
			name: '',
			status: true,
		};
		if (this.goalData['name']?.length <= 0) {
			this.errorMes['name'] = 'Tên thư viện không được bỏ trống';
			this.errorMes.status = true;
			setTimeout(() => {
				this.errorMes = {
					name: '',
					status: true,
				};
			}, 3000);
		}
		this.$emit('submit', this.goalData);
	}
}
