import { ref } from 'vue';
import { $api } from '@/services';

const error = ref(null);
const loading = ref(false);

export async function updatePassword(data: {
	account: { password: string; password_confirmation: string };
	token: string | null;
}) {
	error.value = null;
	try {
		const res = await $api.auth.updatePassword(data);
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export function useUpdatePassword() {
	return { updatePassword__error: error, loading, updatePassword };
}
