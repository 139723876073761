<template>
	<ViewPhotoV2
		:key="photosIndex"
		v-if="imageShow && images.length"
		:images="
			images.map((item) => {
				return { asset_url: getUrl(item), asset_key: getUrl(item) };
			})
		"
		:index="photosIndex"
		@close="imageShow = false"
	/>
	<input
		type="file"
		hidden
		id="upload-images-notification"
		@change="uploadChange"
		multiple
		accept="application/pdf,image/png, image/jpeg, image/jpg"
	/>
	<DaModalFrame v-model:open="modalIsOpen">
		<CloseConfirm
			@close="exitNotSave"
			:in-progress="inProgress"
			@submit="exit()"
		/>
	</DaModalFrame>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 w-screen md:w-2/5">
		<div class="h-full w-full flex flex-col bg-white shadow-xl">
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center gap-2">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12 5.25C12 4.83579 11.6642 4.5 11.25 4.5C10.8358 4.5 10.5 4.83579 10.5 5.25V13.5C10.5 13.7691 10.6442 14.0177 10.8779 14.1512L16.1279 17.1512C16.4875 17.3567 16.9457 17.2317 17.1512 16.8721C17.3567 16.5125 17.2317 16.0543 16.8721 15.8488L12 13.0648V5.25Z"
								fill="#94A3B8"
							/>
						</svg>
						<div class="text-2xl font-bold text-neutral-700">
							{{
								idFolder
									? 'Tạo Tài liệu tham khảo'
									: 'Chỉnh sửa Tài liệu tham khảo'
							}}
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							type="button"
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							@click="openExit"
						>
							<span class="sr-only">Close panel</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div
				class="
					sideover-content
					pt-6
					relative
					flex-1
					px-4
					sm:px-6
					overflow-y-auto
				"
			>
				<div>
					<div class="input-group">
						<div
							class="
								input-group__label
								text-neutral-700
								font-semibold
								mb-4
							"
						>
							Tiêu đề
						</div>
						<input
							type="text"
							placeholder="Tiêu đề mẫu"
							v-model="title"
						/>
					</div>
					<div
						class="input-group flex items-center"
						v-if="errors?.title"
					>
						<div class="error text-sienna-600 text-sm w-full">
							{{ errors?.title }}
						</div>
					</div>
				</div>
				<div class="mt-10">
					<div class="input-group flex items-center gap-10">
						<div class="text-neutral-700 font-semibold">
							Chế độ công khai
						</div>
						<SwitchButton v-model:value="state" />
					</div>
				</div>
				<div>
					<div class="input-group mt-10">
						<div
							class="
								input-group__label
								text-neutral-700
								font-semibold
								mb-4
							"
						>
							Tải lên tài liệu
						</div>
						<div class="w-full flex gap-8">
							<div
								v-if="
									images?.length ||
									files?.length ||
									filesDetail?.length ||
									imagesDetail?.length
								"
							>
								<div
									v-if="imagesDetail?.length"
									class="grid grid-cols-2 gap-4"
								>
									<div
										v-for="(image, index) in imagesDetail"
										:key="index"
										class="rounded-lg relative"
									>
										<div
											class="
												absolute
												top-2
												right-2
												cursor-pointer
											"
											@click="removeImageDetail(index)"
										>
											<svg
												width="24"
												height="24"
												viewBox="0 0 12 12"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<g opacity="0.5">
													<rect
														width="12"
														height="12"
														rx="6"
														fill="black"
													/>
												</g>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M3.73483 3.73483C3.88128 3.58839 4.11872 3.58839 4.26517 3.73483L6.25 5.71967L8.23484 3.73483C8.38128 3.58839 8.61872 3.58839 8.76516 3.73483C8.91161 3.88128 8.91161 4.11872 8.76516 4.26517L6.78033 6.25L8.76516 8.23484C8.91161 8.38128 8.91161 8.61872 8.76516 8.76516C8.61872 8.91161 8.38128 8.91161 8.23484 8.76516L6.25 6.78033L4.26517 8.76516C4.11872 8.91161 3.88128 8.91161 3.73483 8.76516C3.58839 8.61872 3.58839 8.38128 3.73483 8.23484L5.71967 6.25L3.73483 4.26517C3.58839 4.11872 3.58839 3.88128 3.73483 3.73483Z"
													fill="white"
												/>
											</svg>
										</div>
										<img
											:src="image?.asset_url"
											alt=""
											class="
												rounded-lg
												object-fill
												h-full
												w-full
											"
											style="aspect-ratio: 4/3"
											@click="openMediaPopup(index)"
										/>
									</div>
								</div>
								<div
									v-if="images?.length"
									class="grid grid-cols-2 gap-4 mt-4"
								>
									<div
										v-for="(image, index) in images"
										:key="index"
										class="rounded-lg relative"
									>
										<div
											class="
												absolute
												top-2
												right-2
												cursor-pointer
											"
											@click="removeImage(index)"
										>
											<svg
												width="24"
												height="24"
												viewBox="0 0 12 12"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<g opacity="0.5">
													<rect
														width="12"
														height="12"
														rx="6"
														fill="black"
													/>
												</g>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M3.73483 3.73483C3.88128 3.58839 4.11872 3.58839 4.26517 3.73483L6.25 5.71967L8.23484 3.73483C8.38128 3.58839 8.61872 3.58839 8.76516 3.73483C8.91161 3.88128 8.91161 4.11872 8.76516 4.26517L6.78033 6.25L8.76516 8.23484C8.91161 8.38128 8.91161 8.61872 8.76516 8.76516C8.61872 8.91161 8.38128 8.91161 8.23484 8.76516L6.25 6.78033L4.26517 8.76516C4.11872 8.91161 3.88128 8.91161 3.73483 8.76516C3.58839 8.61872 3.58839 8.38128 3.73483 8.23484L5.71967 6.25L3.73483 4.26517C3.58839 4.11872 3.58839 3.88128 3.73483 3.73483Z"
													fill="white"
												/>
											</svg>
										</div>
										<img
											:src="getUrl(image)"
											alt=""
											class="
												rounded-lg
												object-fill
												h-full
												w-full
											"
											style="aspect-ratio: 4/3"
											@click="openMediaPopup(index)"
										/>
									</div>
								</div>
								<div
									v-if="filesDetail?.length"
									class="mt-4 flex flex-col gap-2"
								>
									<div
										v-for="(file, index) in filesDetail"
										:key="index"
										class="
											text-neutral-400
											flex
											items-center
											gap-2
										"
									>
										<svg
											width="24"
											height="32"
											viewBox="0 0 24 32"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M0 4C0 1.79086 1.79086 0 4 0H14.5858C15.1162 0 15.6249 0.210714 16 0.585786L23.4142 8C23.7893 8.37507 24 8.88378 24 9.41421V28C24 30.2091 22.2091 32 20 32H4C1.79086 32 0 30.2091 0 28V4ZM15 7V3L21 9H17C15.8954 9 15 8.10457 15 7ZM17.7071 15.7071C18.0976 15.3166 18.0976 14.6834 17.7071 14.2929C17.3166 13.9024 16.6834 13.9024 16.2929 14.2929L11 19.5858L8.70711 17.2929C8.31658 16.9024 7.68342 16.9024 7.29289 17.2929C6.90237 17.6834 6.90237 18.3166 7.29289 18.7071L10.2929 21.7071C10.4804 21.8946 10.7348 22 11 22C11.2652 22 11.5196 21.8946 11.7071 21.7071L17.7071 15.7071Z"
												fill="#E5A42B"
											/>
										</svg>
										<span> {{ file.asset_key }}</span>
										<a
											:href="file.asset_url"
											download
											target="_blank"
											class="
												flex
												items-center
												font-semibold
												text-sm text-blue-700
												hover:text-blue-800
												cursor-pointer
												ml-6
												whitespace-nowrap
											"
										>
											<svg
												class="mr-2"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M10 0C8.21698 0 6.6397 0.70057 5.5072 1.67727C4.54981 2.50296 3.85599 3.57713 3.67778 4.65625C1.58233 5.1189 0 6.94389 0 9.14773C0 11.7075 2.13442 13.75 4.72656 13.75H9.375V6.875C9.375 6.52982 9.65482 6.25 10 6.25C10.3452 6.25 10.625 6.52982 10.625 6.875V13.75H15.8594C18.1279 13.75 20 11.9619 20 9.71591C20 7.6707 18.4476 6.00513 16.4571 5.72373C16.1541 2.4993 13.3628 0 10 0Z"
													fill="currentColor"
												/>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M9.55806 19.8169C9.80214 20.061 10.1979 20.061 10.4419 19.8169L14.1919 16.0669C14.436 15.8229 14.436 15.4271 14.1919 15.1831C13.9479 14.939 13.5521 14.939 13.3081 15.1831L10.625 17.8661V13.75H9.375V17.8661L6.69194 15.1831C6.44786 14.939 6.05214 14.939 5.80806 15.1831C5.56398 15.4271 5.56398 15.8229 5.80806 16.0669L9.55806 19.8169Z"
													fill="currentColor"
												/>
											</svg>
											Tải xuống
										</a>
										<span
											@click="removeFileDetail(index)"
											class="
												flex
												items-center
												font-semibold
												text-sm text-blue-700
												hover:text-blue-800
												cursor-pointer
												ml-6
												whitespace-nowrap
											"
										>
											<svg
												class="mr-2"
												width="16"
												height="16"
												viewBox="0 0 16 16"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M2.5 1C1.94772 1 1.5 1.44772 1.5 2V3C1.5 3.55228 1.94772 4 2.5 4H3V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V4H13.5C14.0523 4 14.5 3.55228 14.5 3V2C14.5 1.44772 14.0523 1 13.5 1H10C10 0.447715 9.55229 0 9 0H7C6.44772 0 6 0.447715 6 1H2.5ZM5.5 5C5.77614 5 6 5.22386 6 5.5V12.5C6 12.7761 5.77614 13 5.5 13C5.22386 13 5 12.7761 5 12.5L5 5.5C5 5.22386 5.22386 5 5.5 5ZM8 5C8.27614 5 8.5 5.22386 8.5 5.5V12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5V5.5C7.5 5.22386 7.72386 5 8 5ZM11 5.5C11 5.22386 10.7761 5 10.5 5C10.2239 5 10 5.22386 10 5.5V12.5C10 12.7761 10.2239 13 10.5 13C10.7761 13 11 12.7761 11 12.5V5.5Z"
													fill="currentColor"
												/>
											</svg>
											Xoá
										</span>
									</div>
								</div>
								<div
									v-if="files?.length"
									class="mt-4 flex flex-col gap-2"
								>
									<div
										v-for="(file, index) in files"
										:key="index"
										class="
											text-neutral-400
											flex
											items-center
											gap-2
										"
									>
										<svg
											width="24"
											height="32"
											viewBox="0 0 24 32"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M0 4C0 1.79086 1.79086 0 4 0H14.5858C15.1162 0 15.6249 0.210714 16 0.585786L23.4142 8C23.7893 8.37507 24 8.88378 24 9.41421V28C24 30.2091 22.2091 32 20 32H4C1.79086 32 0 30.2091 0 28V4ZM15 7V3L21 9H17C15.8954 9 15 8.10457 15 7ZM17.7071 15.7071C18.0976 15.3166 18.0976 14.6834 17.7071 14.2929C17.3166 13.9024 16.6834 13.9024 16.2929 14.2929L11 19.5858L8.70711 17.2929C8.31658 16.9024 7.68342 16.9024 7.29289 17.2929C6.90237 17.6834 6.90237 18.3166 7.29289 18.7071L10.2929 21.7071C10.4804 21.8946 10.7348 22 11 22C11.2652 22 11.5196 21.8946 11.7071 21.7071L17.7071 15.7071Z"
												fill="#E5A42B"
											/>
										</svg>
										<span> {{ file.name }}</span>
										<span
											@click="removeFileDetail(index)"
											class="
												flex
												items-center
												font-semibold
												text-sm text-blue-700
												hover:text-blue-800
												cursor-pointer
												ml-6
												whitespace-nowrap
											"
										>
											<svg
												class="mr-2"
												width="16"
												height="16"
												viewBox="0 0 16 16"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M2.5 1C1.94772 1 1.5 1.44772 1.5 2V3C1.5 3.55228 1.94772 4 2.5 4H3V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V4H13.5C14.0523 4 14.5 3.55228 14.5 3V2C14.5 1.44772 14.0523 1 13.5 1H10C10 0.447715 9.55229 0 9 0H7C6.44772 0 6 0.447715 6 1H2.5ZM5.5 5C5.77614 5 6 5.22386 6 5.5V12.5C6 12.7761 5.77614 13 5.5 13C5.22386 13 5 12.7761 5 12.5L5 5.5C5 5.22386 5.22386 5 5.5 5ZM8 5C8.27614 5 8.5 5.22386 8.5 5.5V12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5V5.5C7.5 5.22386 7.72386 5 8 5ZM11 5.5C11 5.22386 10.7761 5 10.5 5C10.2239 5 10 5.22386 10 5.5V12.5C10 12.7761 10.2239 13 10.5 13C10.7761 13 11 12.7761 11 12.5V5.5Z"
													fill="currentColor"
												/>
											</svg>
											Xoá
										</span>
									</div>
								</div>
								<div class="w-full flex items-end gap-8 mt-4">
									<button
										@click="openModelUpload"
										type="button"
										class="
											button-p
											text-center
											bg-none
											shadow-none
											flex
											items-center
											justify-center
											btn--text
											w-fit
										"
									>
										<svg
											class="mr-2"
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M8 0C6.57359 0 5.31176 0.560456 4.40576 1.34182C3.63985 2.00237 3.08479 2.8617 2.94223 3.725C1.26586 4.09512 0 5.55511 0 7.31818C0 9.36599 1.70754 11 3.78125 11H7.5V5.70711L5.35355 7.85355C5.15829 8.04882 4.84171 8.04882 4.64645 7.85355C4.45118 7.65829 4.45118 7.34171 4.64645 7.14645L7.64645 4.14645C7.84171 3.95118 8.15829 3.95118 8.35355 4.14645L11.3536 7.14645C11.5488 7.34171 11.5488 7.65829 11.3536 7.85355C11.1583 8.04882 10.8417 8.04882 10.6464 7.85355L8.5 5.70711V11H12.6875C14.5023 11 16 9.56949 16 7.77273C16 6.13656 14.7581 4.80411 13.1657 4.57898C12.9233 1.99944 10.6902 0 8 0Z"
												fill="currentColor"
											/>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M7.5 14.5V11H8.5V14.5C8.5 14.7761 8.27614 15 8 15C7.72386 15 7.5 14.7761 7.5 14.5Z"
												fill="currentColor"
											/>
										</svg>
										Thêm tài liệu
									</button>
								</div>
							</div>
							<div
								v-else
								class="
									w-full
									text-neutral-500
									flex flex-col
									items-center
									justify-senter
									text-center
									gap-2
									rounded-lg
									p-8
									cursor-pointer
									border-2 border-dashed
									hover:border-yellow-500
								"
								@click="openModelUpload"
							>
								<svg
									class="text-yellow-500"
									width="48"
									height="48"
									viewBox="0 0 48 48"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M24 0C19.7208 0 15.9353 1.68137 13.2173 4.02545C10.9196 6.0071 9.25438 8.5851 8.82668 11.175C3.79759 12.2853 0 16.6653 0 21.9545C0 28.098 5.12261 33 11.3438 33H22.5V17.1213L16.0607 23.5607C15.4749 24.1464 14.5251 24.1464 13.9393 23.5607C13.3536 22.9749 13.3536 22.0251 13.9393 21.4393L22.9393 12.4393C23.5251 11.8536 24.4749 11.8536 25.0607 12.4393L34.0607 21.4393C34.6464 22.0251 34.6464 22.9749 34.0607 23.5607C33.4749 24.1464 32.5251 24.1464 31.9393 23.5607L25.5 17.1213V33H38.0625C43.507 33 48 28.7085 48 23.3182C48 18.4097 44.2743 14.4123 39.4971 13.7369C38.7699 5.99831 32.0707 0 24 0Z"
										fill="currentColor"
									/>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M22.5 43.5V33H25.5V43.5C25.5 44.3284 24.8284 45 24 45C23.1716 45 22.5 44.3284 22.5 43.5Z"
										fill="currentColor"
									/>
								</svg>
								<div class="font-semibold">
									Tải lên tài liệu tham khảo
								</div>
								<div class="text-sm">PNG, JPG & PDF</div>
							</div>
						</div>
					</div>
					<div
						class="input-group flex items-center"
						v-if="errors?.document"
					>
						<div class="error text-sienna-600 text-sm w-full">
							{{ errors?.document }}
						</div>
					</div>
				</div>
			</div>
			<div class="px-6 py-4 border-t border-yellow-50">
				<div class="flex justify-end">
					<div>
						<button
							:disabled="!isSubmit || inProgress"
							type="button"
							class="button-p text-center w-32"
							@click="submit(!state)"
						>
							Tải lên
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import { $api } from '@/services';
import ViewPhotoV2 from '@/components/common/ViewPhotoV2.vue';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import CloseConfirm from '@/views/documents/CloseConfirm.vue';
import SwitchButton from '@/components/elements/switchButton/SwitchButton.vue';

export default defineComponent({
	name: 'CreateDocument',

	components: { DaModalFrame, ViewPhotoV2, CloseConfirm, SwitchButton },

	props: {
		id: {
			type: String,
			default: '',
		},

		show: {
			type: Boolean,
			required: true,
		},

		idFolder: {
			type: Number,
			default: null,
		},
	},

	watch: {
		id: {
			immediate: true,
			handler() {
				this.fetchDocumentDetail();
			},
		},

		show: {
			handler(value) {
				if (!value) {
					this.resetForm();
				}
			},
		},
	},

	data() {
		return {
			inProgress: false,
			title: '',
			errors: {
				title: '',
				document: '',
			},
			images: [],
			files: [],
			imagesDetail: [],
			filesDetail: [],
			imageShow: false,
			photosIndex: 0,
			keys: [],
			modalIsOpen: false,
			state: false,
		};
	},

	computed: {
		isSubmit() {
			return this.title;
		},
	},

	methods: {
		resetForm() {
			this.title = '';
			this.errors = {
				title: '',
				document: '',
			};
			this.images = [];
			this.files = [];
			this.imageShow = false;
			this.photosIndex = 0;
			this.keys = [];
		},

		async fetchDocumentDetail() {
			try {
				const {
					data: { data },
				} = await $api.org.documentDetail(this.id);
				this.title = data?.title || '';
				if (data.state == 'published') this.state = true;
				this.imagesDetail = data?.reference_document_items?.filter(
					(i) => i.asset_type.includes('image')
				);
				this.filesDetail = data?.reference_document_items?.filter(
					(i) => !i.asset_type.includes('image')
				);
			} catch (e) {
				console.log(e);
				// TODO:
			}
		},

		async submit(isDraft = false) {
			this.inProgress = true;
			try {
				if (this.images?.length || this.files?.length) {
					await this.getSignatures();
				}
				const payload = {
					title: this.title,
					state: isDraft ? 'draft' : 'published',
					reference_document_items: [
						...this.keys,
						...(this.imagesDetail || []),
						...(this.filesDetail || []),
					].map((d) => {
						return {
							asset_key: d.asset_key,
							asset_type: d.asset_type.includes('image')
								? 'image'
								: 'file',
							name: d.name,
						};
					}),
				};
				if (this.idFolder)
					payload.reference_document_folder_id = this.idFolder;
				if (this.id) {
					await $api.org.updateDocuments(this.id, payload);
				} else {
					await $api.org.createDocuments(payload);
				}
				this.$emit('success');
				this.resetForm();
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		removeImage(index) {
			this.images.splice(index, 1);
		},

		removeFile(index) {
			this.files.splice(index, 1);
		},

		removeImageDetail(index) {
			this.imagesDetail.splice(index, 1);
		},

		removeFileDetail(index) {
			this.filesDetail.splice(index, 1);
		},

		getUrl(file) {
			if (!file || file?.asset_url) {
				return file?.asset_url ?? '';
			}

			return URL.createObjectURL(file);
		},

		async getSignatures() {
			const fileList = [...this.images, ...this.files];
			const {
				data: { data },
			} = await this.$api.file.getSignatures({
				mime_types: fileList.map((f) => f.type),
				prefix: 'notice_attachment',
			});

			if (data && data.length) {
				for (let i = 0; i < data.length; i++) {
					let formData = new FormData();
					formData.append(
						'AWSAccessKeyId',
						data[0]['AWSAccessKeyId'] || ''
					);
					formData.append('key', data[i].key || '');
					formData.append('acl', data[i].acl || '');
					formData.append(
						'Content-Type',
						data[i]['Content-Type'] || ''
					);
					formData.append('x-amz-signature', data[i].signature || '');
					formData.append('Signature', data[i].signature || '');
					formData.append('Policy', data[i].policy || '');
					formData.append(
						'success_action_status',
						data[i].success_action_status || ''
					);
					formData.append('file', fileList[i]);
					await this.uploadToS3(
						data[i].action,
						formData,
						fileList[i]
					);
				}
			}
		},

		async uploadToS3(url, payload, file) {
			try {
				const { data } = await this.$api.file.uploadToS3(url, payload);
				const keyData = data.toString().match('<Key>(.*)</Key>');
				const key = keyData && keyData.length ? keyData[1] : '';
				if (key) {
					this.keys.push({
						asset_key: key,
						asset_type: file.type,
						name: file.name,
					});
				}
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},

		openMediaPopup(index) {
			this.imageShow = true;
			this.photosIndex = index;
		},

		openModelUpload() {
			const target = document.getElementById(
				'upload-images-notification'
			);
			target ? target.click() : '';
		},

		isImage(icon) {
			const ext = ['.jpg', '.jpeg', '.png', '.svg'];
			return ext.some((el) => icon.toLowerCase().endsWith(el));
		},

		uploadChange(event) {
			let fileUploads = Array.from(event?.target?.files || []);
			if (!fileUploads || !fileUploads?.length) {
				return;
			}

			if (
				(this.images?.length && !this.isImage(fileUploads[0]?.name)) ||
				(this.files?.length && this.isImage(fileUploads[0]?.name))
			) {
				this.$store.dispatch('setAlertMessage', {
					message:
						'Tài liệu tham khảo không thể có 2 loại file khác nhau',
					type: 'danger',
				});
				return;
			}

			fileUploads.forEach((file) => {
				if (this.isImage(file.name)) {
					this.images.push(file);
				} else {
					this.files.push(file);
				}
			});

			if (this.images?.length && this.files?.length) {
				this.$store.dispatch('setAlertMessage', {
					message:
						'Tài liệu tham khảo không thể có 2 loại file khác nhau',
					type: 'danger',
				});
				return;
			}

			this.closeUpload();
		},

		openExit() {
			if (!this.id) {
				this.modalIsOpen = true;
			} else this.exit();
		},

		async exitNotSave() {
			this.modalIsOpen = false;
			this.$emit('close');
		},

		async exit() {
			this.modalIsOpen = false;
			if (!this.id) {
				await this.submit(true);
			}
			this.$emit('close');
		},

		closeUpload() {
			const target = document.getElementById(
				'upload-images-notification'
			);
			target ? (target.value = null) : '';
		},
	},
});
</script>
