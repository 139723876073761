<template>
	<div :class="isShow ? 'side-over--active' : ''">
		<div
			v-if="isShow"
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			@click="isShow = false"
		></div>
		<UpdateResultSideOver
			v-model:data="result"
			:is-data="true"
			:is-edit="true"
			@close="isShow = false"
			@success="success"
		/>
	</div>
	<DaModalFrame v-model:open="isShowDeleted" @close="isShowDeleted = false">
		<DeleteResult @close="isShowDeleted = false" @submit="deleteResult" />
	</DaModalFrame>
	<div
		v-if="$route.query.start_date && $route.query.end_date"
		class="bg-white shadow-sm rounded-2xl overflow-hidden pb-4"
	>
		<div
			class="
				bg-orange-50
				p-4
				px-6
				flex
				md:items-center
				justify-between
				text-gray-700
				md-down:flex-col
			"
		>
			<div class="font-semibold text-lg">
				Báo cáo
				<span class="text-green-500">{{ result.name }}</span>
			</div>
			<div
				v-if="is_viewed_by_parent"
				class="flex items-center justify-end gap-2"
			>
				Phụ huynh xác nhận đã xem
				<svg
					fill="none"
					height="20"
					viewBox="0 0 20 20"
					width="20"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g clip-path="url(#clip0_29861_3992)">
						<path
							d="M9.26745 5.21165H2.62886C1.64009 5.21165 0.833008 6.0187 0.833008 7.0075V14.7761C0.833008 15.7648 1.64006 16.5719 2.62886 16.5719H5.05019L5.05033 19.2959C5.05033 19.9013 5.75664 20.2241 6.20055 19.8205L10.0344 16.5517H13.3235C14.3122 16.5517 15.1193 15.7446 15.1193 14.7558L15.1192 11.0834C11.8907 11.0834 9.26752 8.44011 9.26752 5.21158L9.26745 5.21165Z"
							fill="#339588"
						/>
						<path
							d="M15.1393 0.833008C12.7179 0.833008 10.7606 2.79028 10.7606 5.21165C10.7606 7.63302 12.7179 9.5903 15.1393 9.5903C17.5606 9.5903 19.5179 7.63302 19.5179 5.21165C19.5179 2.79028 17.5606 0.833008 15.1393 0.833008ZM17.2781 4.60631L15.0989 6.78554C14.9778 6.90659 14.7963 6.98738 14.6146 6.98738C14.433 6.98738 14.2514 6.90673 14.1303 6.78554L12.98 5.63532C12.7177 5.37296 12.7177 4.92901 12.98 4.66681C13.2424 4.40445 13.6863 4.40445 13.9485 4.66681L14.5943 5.31258L16.2892 3.61766C16.5516 3.3553 16.9955 3.3553 17.2578 3.61766C17.5606 3.90014 17.5606 4.34407 17.2782 4.60631H17.2781Z"
							fill="#339588"
						/>
						<path
							d="M8.78697 5.71165C9.02947 8.81816 11.5111 11.3196 14.6192 11.5638L14.6193 14.7558C14.6193 14.7558 14.6193 14.7558 14.6193 14.7558C14.6193 15.4685 14.0361 16.0517 13.3235 16.0517H10.0344H9.85017L9.70999 16.1712L5.87615 19.44L5.87008 19.4452L5.86417 19.4506C5.75578 19.5491 5.55033 19.4867 5.55033 19.2959L5.55019 16.5719L5.55017 16.0719H5.05019H2.62886C1.91621 16.0719 1.33301 15.4887 1.33301 14.7761V7.0075C1.33301 6.29485 1.91623 5.71165 2.62886 5.71165H8.78697ZM15.4524 7.1391L17.6251 4.96641C18.1223 4.4978 18.1008 3.72652 17.6051 3.25789C17.1468 2.8064 16.3911 2.80865 15.9357 3.2641L14.5943 4.60547L14.3022 4.31336C14.3022 4.31332 14.3021 4.31329 14.3021 4.31325C13.8446 3.85558 13.0841 3.85572 12.6266 4.31315C12.1688 4.7707 12.1689 5.53131 12.6265 5.98887L13.7767 7.13908C13.7767 7.13909 13.7767 7.13909 13.7767 7.1391C13.9873 7.34967 14.2943 7.48738 14.6146 7.48738C14.9351 7.48738 15.2421 7.34942 15.4524 7.1391ZM11.2606 5.21165C11.2606 3.06643 12.9941 1.33301 15.1393 1.33301C17.2845 1.33301 19.0179 3.06642 19.0179 5.21165C19.0179 7.35688 17.2845 9.0903 15.1393 9.0903C12.994 9.0903 11.2606 7.35688 11.2606 5.21165Z"
							stroke="#339588"
						/>
					</g>
					<defs>
						<clipPath id="clip0_29861_3992">
							<rect fill="white" height="20" width="20" />
						</clipPath>
					</defs>
				</svg>
			</div>
		</div>
		<div class="flex items-end justify-between p-6 pb-1 flex-wrap">
			<div class="flex-col gap-4">
				<div>Ngày bắt đầu: {{ startDate }}</div>
				<div>Ngày kết thúc: {{ endDate }}</div>
			</div>
			<div class="flex-col gap-4">
				<div class="flex items-center flex-wrap gap-2">
					<div>Giáo viên hỗ trợ</div>
					<div class="flex items-center flex-wrap gap-1">
						<router-link
							v-for="(staff, index) in staffs"
							:key="staff.id"
							:to="`/record/employee/${staff?.id}`"
						>
							<div>
								<span class="font-semibold text-blue-700">{{
									staff?.name
								}}</span
								><span v-if="index < staffs?.length - 1"
									>,</span
								>
							</div>
						</router-link>
					</div>
				</div>
				<div
					v-if="result?.created_by_staff"
					class="flex items-center flex-wrap gap-2"
				>
					<div>
						<router-link
							:to="`/record/employee/${result?.created_by_staff?.id}`"
							class="font-semibold text-blue-700 mr-1"
							>{{ result?.created_by_staff?.name }}
						</router-link>
						đã gửi tới PH {{ sendDate }}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="mt-10 bg-white rounded-2xl shadow-sm overflow-hidden relative">
		<div class="__data__header p-6 flex items-center justify-between">
			<div class="text-neutral-600 font-semibold">
				{{ result.name }}
			</div>
			<div
				v-if="!isTeacherAndAccountant"
				class="flex items-center justify-end gap-6"
			>
				<div
					class="hover:text-yellow-700 text-yellow-600 cursor-pointer"
					@click="isShow = true"
				>
					<svg
						fill="none"
						height="18"
						viewBox="0 0 18 18"
						width="18"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							clip-rule="evenodd"
							d="M14.4041 7.49709L16.1377 5.76351L16.1377 5.76349L16.1377 5.76347C16.6368 5.26438 16.8863 5.01484 17.0269 4.74967C17.3376 4.16375 17.3376 3.46197 17.0269 2.87605C16.8863 2.61088 16.6368 2.36132 16.1377 1.86221C15.6386 1.3631 15.389 1.11355 15.1238 0.972961C14.5379 0.662333 13.8361 0.662333 13.2502 0.972961C12.985 1.11355 12.7355 1.3631 12.2364 1.86221L10.4807 3.61793C11.4198 5.2285 12.772 6.57033 14.4041 7.49709ZM9.02618 5.07241L2.36419 11.7344C1.93913 12.1595 1.7266 12.372 1.58687 12.6331C1.44713 12.8942 1.38819 13.1889 1.27029 13.7784L0.679575 16.732C0.613052 17.0646 0.579791 17.2309 0.674399 17.3255C0.769007 17.4201 0.935313 17.3868 1.26792 17.3203L4.22152 16.7296C4.81098 16.6117 5.1057 16.5528 5.3668 16.413C5.6279 16.2733 5.84043 16.0608 6.26549 15.6357L12.9459 8.95532C11.3647 7.96407 10.027 6.63552 9.02618 5.07241Z"
							fill="currentColor"
							fill-rule="evenodd"
						/>
					</svg>
				</div>
				<div
					class="hover:text-yellow-700 text-yellow-600 cursor-pointer"
					@click="isShowDeleted = true"
				>
					<svg
						fill="none"
						height="20"
						viewBox="0 0 20 20"
						width="20"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							clip-rule="evenodd"
							d="M3.125 1.25C2.43464 1.25 1.875 1.80964 1.875 2.5V3.75C1.875 4.44036 2.43464 5 3.125 5H3.75V16.25C3.75 17.6307 4.86929 18.75 6.25 18.75H13.75C15.1307 18.75 16.25 17.6307 16.25 16.25V5H16.875C17.5654 5 18.125 4.44036 18.125 3.75V2.5C18.125 1.80964 17.5654 1.25 16.875 1.25H12.5C12.5 0.559644 11.9404 0 11.25 0H8.75C8.05964 0 7.5 0.559644 7.5 1.25H3.125ZM6.875 6.25C7.22018 6.25 7.5 6.52982 7.5 6.875V15.625C7.5 15.9702 7.22018 16.25 6.875 16.25C6.52982 16.25 6.25 15.9702 6.25 15.625L6.25 6.875C6.25 6.52982 6.52982 6.25 6.875 6.25ZM10 6.25C10.3452 6.25 10.625 6.52982 10.625 6.875V15.625C10.625 15.9702 10.3452 16.25 10 16.25C9.65482 16.25 9.375 15.9702 9.375 15.625V6.875C9.375 6.52982 9.65482 6.25 10 6.25ZM13.75 6.875C13.75 6.52982 13.4702 6.25 13.125 6.25C12.7798 6.25 12.5 6.52982 12.5 6.875V15.625C12.5 15.9702 12.7798 16.25 13.125 16.25C13.4702 16.25 13.75 15.9702 13.75 15.625V6.875Z"
							fill="currentColor"
							fill-rule="evenodd"
						/>
					</svg>
				</div>
			</div>
		</div>
		<div class="__data__content text-neutral-800">
			<div class="grid grid-cols-2 px-6 py-4 bg-neutral-50">
				<div class="text-neutral-500">Ngày thực hiện</div>
				<div>
					{{
						result.assessment_date
							? $filters.formatDate2(result.assessment_date)
							: ''
					}}
				</div>
			</div>
			<div class="grid grid-cols-2 px-6 py-4">
				<div class="text-neutral-500">Tuổi thực</div>
				<div>{{ result.age }}</div>
			</div>
			<div class="grid grid-cols-2 px-6 py-4 bg-neutral-50">
				<div class="text-neutral-500">Tuổi phát triển</div>
				<div>
					<div
						v-for="(item, index) in result.development_ages"
						:key="index"
					>
						{{ item }}
					</div>
				</div>
			</div>
			<div class="grid grid-cols-2 px-6 py-4">
				<div class="text-neutral-500">Kết luận</div>
				<div>
					<div
						v-for="(item, index) in result.assessment_conclusions"
						:key="index"
					>
						{{ item }}
					</div>
				</div>
			</div>
			<div class="grid grid-cols-2 px-6 py-4 bg-neutral-50">
				<div class="text-neutral-500">Đơn thuốc</div>
				<div>
					<div
						v-for="(item, index) in result.prescription"
						:key="index"
					>
						{{ item }}
					</div>
				</div>
			</div>
			<div class="grid grid-cols-2 px-6 py-4">
				<div class="text-neutral-500">Tải lên</div>
				<div class="flex items-center">
					<div v-if="result.created_by_staff">
						<span class="font-semibold text-blue-700">{{
							result?.created_by_staff?.name
						}}</span>
						<span class="mx-2">-</span>
					</div>
					{{
						result.assessment_date
							? $filters.formatDate2(result.assessment_date)
							: ''
					}}
				</div>
			</div>
			<div class="grid grid-cols-2 px-6 py-4 bg-neutral-50">
				<div class="text-neutral-500">Chỉnh sửa gần nhất</div>
				<div class="flex items-center">
					<div v-if="result.edited_by_staff">
						<span class="font-semibold text-blue-700">{{
							result?.created_by_staff?.name
						}}</span>
						<span class="mx-2">-</span>
					</div>
					{{
						result.updated_at
							? $filters.formatDateTime(result.updated_at)
							: ''
					}}
				</div>
			</div>
			<div class="grid grid-cols-2 px-6 py-4">
				<div class="text-neutral-500">Tài liệu</div>
				<div
					v-if="result.files.length"
					class="
						flex
						items-center
						font-medium
						text-sm text-blue-700
						hover:text-blue-800
						cursor-pointer
					"
					@click.stop="downloadFileResult(result.files)"
				>
					<svg
						class="mr-2"
						fill="none"
						height="20"
						viewBox="0 0 20 20"
						width="20"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							clip-rule="evenodd"
							d="M10 0C8.21698 0 6.6397 0.70057 5.5072 1.67727C4.54981 2.50296 3.85599 3.57713 3.67778 4.65625C1.58233 5.1189 0 6.94389 0 9.14773C0 11.7075 2.13442 13.75 4.72656 13.75H9.375V6.875C9.375 6.52982 9.65482 6.25 10 6.25C10.3452 6.25 10.625 6.52982 10.625 6.875V13.75H15.8594C18.1279 13.75 20 11.9619 20 9.71591C20 7.6707 18.4476 6.00513 16.4571 5.72373C16.1541 2.4993 13.3628 0 10 0Z"
							fill="currentColor"
							fill-rule="evenodd"
						/>
						<path
							clip-rule="evenodd"
							d="M9.55806 19.8169C9.80214 20.061 10.1979 20.061 10.4419 19.8169L14.1919 16.0669C14.436 15.8229 14.436 15.4271 14.1919 15.1831C13.9479 14.939 13.5521 14.939 13.3081 15.1831L10.625 17.8661V13.75H9.375V17.8661L6.69194 15.1831C6.44786 14.939 6.05214 14.939 5.80806 15.1831C5.56398 15.4271 5.56398 15.8229 5.80806 16.0669L9.55806 19.8169Z"
							fill="currentColor"
							fill-rule="evenodd"
						/>
					</svg>
					Tải xuống
				</div>
			</div>
		</div>
	</div>
	<ChartComponent v-if="$route.query.start_date && $route.query.end_date" />
</template>

<script>
import { defineComponent } from 'vue';
import { $api } from '@/services';
import UpdateResultSideOver from './UpdateResultSideOver';
import downloadFile from '@/mixins/downloadCSV.vue';
import DeleteResult from '@/views/record/studentDetail/features/result/DeleteReult';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame';
import ChartComponent from '@/views/record/studentDetail/features/result/ChartComponent.vue';

export default defineComponent({
	name: 'ResultDetail',
	components: {
		ChartComponent,
		DaModalFrame,
		DeleteResult,
		UpdateResultSideOver,
	},
	data() {
		return {
			result: {
				age: null,
				assessment_conclusions: [],
				assessment_date: '',
				development_ages: [],
				prescription: [],
				files: [],
				id: '',
				name: '',
			},
			staffs: [],
			is_viewed_by_parent: false,
			isShow: false,
			isShowDeleted: false,
		};
	},

	mixins: [downloadFile],

	computed: {
		id() {
			return Number(this.$route.params.id);
		},
		resultId() {
			return Number(this.$route.params.result_id);
		},
		isTeacherAndAccountant() {
			return this.$store.getters.isTeacherAndAccountant;
		},
		startDate() {
			return this.$route.query.start_date
				? this.$filters.formatDate2(this.$route.query.start_date)
				: '';
		},
		endDate() {
			return this.$route.query.end_date
				? this.$filters.formatDate2(this.$route.query.end_date)
				: '';
		},

		sendDate() {
			return this.result.inserted_at
				? this.$filters.formatDateTime(this.result.inserted_at)
				: '';
		},
	},

	created() {
		this.fetchResultDetail();
	},

	methods: {
		downloadFileResult(files) {
			files.forEach((i) => {
				if (i.asset_key) window.open(i?.file, '_blank').focus();
				else window.open(i?.file?.original, '_blank').focus();
			});
		},

		async fetchResultDetail() {
			try {
				const { data } = await $api.student.assessmentDocumentDetail(
					this.id,
					this.resultId
				);
				this.result = data.data;
				this.staffs = data.staffs;
				this.is_viewed_by_parent = data.is_viewed_by_parent;
			} catch (e) {
				await this.$store.dispatch('setAlertMessage', {
					message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},

		async deleteResult() {
			try {
				await $api.student.deleteAssessmentDocuments(
					this.id,
					this.resultId
				);
				return this.$router.push(`/record/student/${this.id}/result`);
			} catch (e) {
				await this.$store.dispatch('setAlertMessage', {
					message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},

		success() {
			this.fetchResultDetail();
		},
	},
});
</script>
