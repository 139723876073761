
import { Options, Vue } from 'vue-class-component';
import { PropSync } from 'vue-property-decorator';

@Options({
	name: 'DeleteConfirmModal',
})
export default class DeleteConfirmModal extends Vue {
	//----------------------- 🤍 Props 🤍 -----------------------//

	@PropSync('data')
	popupData!: any;

	//----------------------- 🤍 Methods 🤍 -----------------------//
	submit() {
		this.$emit('submit', {
			...this.popupData,
		});
	}
}
