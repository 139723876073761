<script setup>
import { defineProps, computed } from 'vue';
import { useStore } from 'vuex';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail';

const props = defineProps(['data', 'id', 'completed']);
const store = useStore();
const org = computed(() => store.state.profile.current_org);
</script>
<template>
	<table class="min-w-full divide-y text-neutral-200 mb-5">
		<thead class="bg-neutral-100 h-16">
			<tr>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						w-8
						whitespace-nowrap
					"
				></th>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						w-16
						whitespace-nowrap
					"
				>
					STT
				</th>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						whitespace-nowrap
					"
				>
					MỤC TIÊU CỤ THỂ
				</th>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						whitespace-nowrap
					"
				>
					NGÀY TẠO
				</th>
				<th
					scope="col"
					class="
						px-6
						py-3
						text-left text-xs
						font-semibold
						text-neutral-600
						uppercase
						tracking-wider
						whitespace-nowrap
					"
				>
					{{ props.completed ? 'NGÀY HOÀN THÀNH' : 'NGÀY TẠM DỪNG' }}
				</th>
			</tr>
		</thead>
		<tbody style="border-top: none">
			<template v-for="(g, i) in props.data" :key="i">
				<tr
					@click.stop="
						$router.push(`/goal/${props.id}/${g.goal_id || g.id}`)
					"
					:class="i % 2 === 0 ? 'bg-white' : 'bg-neutral-50'"
					class="text-neutral-700 hover:bg-orange-50 cursor-pointer"
				>
					<td class="px-6 py-4 w-8 divTableCell">
						<div class="w-4">
							<img
								class="w-full"
								src="@/assets/images/icons/child-goal.png"
								alt=""
							/>
						</div>
					</td>
					<td class="px-6 py-4 whitespace-nowrap text-neutral-700">
						<div class="ml-2">
							{{ i + 1 }}
						</div>
					</td>
					<td class="px-6 py-4 text-neutral-700">
						<div class="flex items-center gap-2">
							<div
								class="hover:text-yellow-700 hover:underline"
								v-html="g?.name"
							></div>
							<TooltipGoalDetail
								:goal-id="g.goal_id || g.id"
								:student-id="id"
							/>
						</div>
						<div
							class="flex gap-2 flex-wrap"
							style="max-width: 360px"
						>
							<template
								v-if="
									g?.goal?.assistance_levels &&
									org.enable_goal_assistance_level
								"
							>
								<div
									v-for="ass in g?.goal?.assistance_levels"
									:key="ass"
									class="flex gap-2"
								>
									<div
										class="
											mt-1
											py-0.5
											px-2.5
											rounded-full
											bg-pink-100
											whitespace-nowrap
											text-neutral-700
											h-fit
											w-fit
											font-semibold
											shadow-input
										"
										style="font-size: 10px"
									>
										{{ ass }}
									</div>
								</div>
							</template>
							<div
								class="
									mt-1
									py-0.5
									px-2.5
									rounded-full
									bg-yellow-tag
									text-neutral-700
									h-fit
									w-fit
									font-semibold
									shadow-input
									whitespace-nowrap
								"
								style="font-size: 10px"
								v-if="g?.age"
							>
								{{ g?.age }}
							</div>
						</div>
						<div class="flex items-center gap-2 mt-2">
							<div v-if="g?.has_media">
								<img
									src="@/assets/images/icons/has_media.png"
									alt=""
								/>
							</div>
							<div class="text-neutral-400 text-xs font-semibold">
								ĐÃ HỌC
								{{ g.number_of_assessment }}
								NGÀY
							</div>
							<div v-if="g?.completed_at">
								<img
									src="@/assets/images/icons/has_taught.png"
									alt=""
								/>
							</div>
						</div>
						<div class="flex items-center gap-2 mt-2">
							<div class="text-neutral-400 text-xs font-semibold">
								KẾT QUẢ
							</div>
							<div class="progress-bar overflow-hidden">
								<div
									:style="{
										background: $filters.TAG_COLOR(
											g?.complete_percent
										),
										width: g?.complete_percent + '%',
									}"
									class="h-full"
								></div>
							</div>
							<div class="text-neutral-400 text-xs font-semibold">
								{{ g?.complete_percent }}%
							</div>
						</div>
					</td>
					<td class="px-6 py-4 whitespace-nowrap text-neutral-700">
						<div v-if="g?.inserted_at">
							{{ $filters.formatDate(g?.inserted_at) }}
						</div>
						<div v-else>-</div>
					</td>
					<td
						class="px-6 py-4 whitespace-nowrap text-neutral-700"
						v-if="props.completed"
					>
						<div v-if="g?.completed_at">
							{{ $filters.formatDate(g?.completed_at) }}
						</div>
						<div v-else>-</div>
					</td>
				</tr>
			</template>
		</tbody>
	</table>
</template>
