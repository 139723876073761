import { ref } from 'vue';
import { $api } from '@/services';

const error = ref(null);
const loading = ref(false);

export async function dayCare(
	id: string,
	student_id: string,
	oldPayload?: any
) {
	error.value = null;
	try {
		const res = await $api.lessonReport.dayCare(id, student_id, oldPayload);
		if (!res) throw new Error('Lỗi');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export function useDayCare() {
	return { error, loading, dayCare };
}
