<template>
	<div
		class="
			_modal
			choose-popup
			bg-white
			p-8
			rounded-lg
			border border-neutral-300
			text-neutral-700
			scroll-bar
		"
	>
		<div
			class="
				flex
				md-down:flex-col md-down:items-center
				justify-between
				items-start
			"
			v-for="(org, index) in orgs.filter((o) => o.is_active_org)"
			:key="org.id"
			:class="index !== 0 ? 'mt-8' : ''"
		>
			<div
				class="
					flex
					md-down:mr-0
					mr-10
					md-down:flex-col
					items-start
					md-down:mb-4
				"
			>
				<div
					:class="{ 'opacity-30': org.org.state === 'closed' }"
					class="mr-6 md-down:mr-0 md-down:mb-2"
				>
					<img
						class="shadow-sm inline-block h-16 w-16 rounded-2xl"
						:src="org.org?.profile_photo?.w200"
						alt=""
						v-if="org.org?.profile_photo?.w200"
					/>
					<span
						v-else
						class="
							inline-flex
							items-center
							justify-center
							h-16
							w-16
							rounded-2xl
						"
						:style="{
							background: org.org?.profile_photo?.default?.color,
						}"
					>
						<span
							class="font-medium leading-none text-white text-4xl"
							>{{
								org.org?.profile_photo?.default?.initial
							}}</span
						>
					</span>
				</div>
				<div style="max-width: 200px" class="md-down:text-center">
					<div
						:class="{
							'opacity-30': org.org.state === 'closed',
						}"
						class="font-semibold md-down:mb-2"
					>
						{{ org.org.name }}
					</div>
					<div
						:class="{
							'opacity-30': org.org.state === 'closed',
						}"
						class="md-down:mb-2"
					>
						{{ org.org?.org_profile.address }}
					</div>
					<div
						:class="{
							'opacity-30': org.org.state === 'closed',
						}"
						class="font-semibold"
					>
						{{ org.org?.org_profile.city }}
					</div>
					<div
						:class="{
							'opacity-30': org.org.state === 'closed',
						}"
					>
						<span v-if="org.join_date">{{
							$filters.formatDate(org.join_date)
						}}</span>
						<span v-if="org.left_date"
							>-
							{{ $filters.formatDate(org.left_date) }}
						</span>
					</div>
					<div
						v-if="org.org.state === 'closed'"
						class="flex items-center gap-2 mt-2"
					>
						<svg
							width="12"
							height="13"
							viewBox="0 0 12 13"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M2.87497 4.94589V3.14587C2.87497 1.48897 4.27407 0.145996 6 0.145996C7.72593 0.145996 9.12503 1.48907 9.12503 3.14587V4.94589C10.1606 4.94589 11 5.75179 11 6.74592V10.346C11 11.3402 10.1606 12.146 9.12503 12.146H2.87497C1.83945 12.146 1 11.3402 1 10.346V6.74592C1 5.75179 1.83945 4.94589 2.87497 4.94589ZM7.87497 4.94589V3.14587C7.87497 2.15174 7.03552 1.34584 6 1.34584C4.96448 1.34584 4.12503 2.15174 4.12503 3.14587V4.94589H7.87497ZM6 7.34599C6.34518 7.34599 6.62495 7.61458 6.62495 7.94597V9.14602C6.62495 9.4774 6.34518 9.746 6 9.746C5.65482 9.746 5.37505 9.4774 5.37505 9.14602V7.94597C5.37505 7.61458 5.65482 7.34599 6 7.34599Z"
								fill="#E37056"
							/>
						</svg>
						<span class="text-sm text-neutral-700"
							>Cơ sở đang khoá</span
						>
					</div>
				</div>
			</div>
			<div>
				<button
					type="button"
					class="button-p btn--ghost w-fit px-4 btn--sm"
					@click="select(org)"
				>
					Chọn cơ sở
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SelectCenter',

	props: {
		orgs: {
			type: Array,
			required: true,
		},
	},

	methods: {
		select(org) {
			this.$emit('selected', org);
		},
	},
};
</script>

<style lang="scss" scoped>
._modal {
	max-height: 90vh;
	overflow: auto;
	border-radius: 16px;

	&::-webkit-scrollbar {
		display: none !important;
	}
}
</style>
