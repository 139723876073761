<template>
	<div
		class="p-4"
		:class="
			type !== 'danger'
				? 'bg-green-50 text-green-700'
				: 'bg-sienna-50 text-sienna-700'
		"
	>
		<div class="flex">
			<div class="flex-shrink-0">
				<svg
					v-if="type !== 'danger'"
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M16.6461 5L7.49075 14.1667L3.32922 10"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
				<svg
					v-else
					width="20"
					height="20"
					viewBox="0 0 20 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g clip-path="url(#clip0_6702_2514)">
						<path
							d="M8.56445 3.21659L1.51482 14.9999C1.36947 15.2519 1.29257 15.5377 1.29175 15.8287C1.29094 16.1197 1.36625 16.4059 1.51018 16.6587C1.65412 16.9115 1.86166 17.1222 2.11217 17.2698C2.36268 17.4174 2.64742 17.4967 2.93806 17.4999H17.0373C17.328 17.4967 17.6127 17.4174 17.8632 17.2698C18.1137 17.1222 18.3213 16.9115 18.4652 16.6587C18.6091 16.4059 18.6845 16.1197 18.6836 15.8287C18.6828 15.5377 18.6059 15.2519 18.4606 14.9999L11.4109 3.21659C11.2626 2.97168 11.0536 2.76919 10.8044 2.62866C10.5551 2.48813 10.2738 2.41431 9.9877 2.41431C9.7016 2.41431 9.42034 2.48813 9.17104 2.62866C8.92174 2.76919 8.71283 2.97168 8.56445 3.21659V3.21659Z"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M9.98767 7.5V10.8333"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M9.98767 14.1667H9.99599"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</g>
					<defs>
						<clipPath id="clip0_6702_2514">
							<rect width="19.9753" height="20" fill="white" />
						</clipPath>
					</defs>
				</svg>
			</div>
			<div class="ml-3">
				<p
					class="text-sm font-medium"
					:class="
						type !== 'danger'
							? 'text-text-green-700'
							: 'text-sienna-700'
					"
				>
					{{ message }}
				</p>
			</div>
			<div class="ml-auto pl-3" v-if="false">
				<div @click="$emit('close')">
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M15 5L5 15"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<path
							d="M5 5L15 15"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AlertUpload',

	props: {
		message: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			required: true,
		},
	},
};
</script>
