<template>
	<input
		type="file"
		hidden
		id="upload-file"
		@change="uploadChange"
		multiple
		accept="application/pdf,image/png, image/jpeg, image/jpg"
	/>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 side-over">
		<div class="h-full w-full flex flex-col bg-white shadow-xl">
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center" @click="$emit('close')">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M3 3C3 1.34315 4.34315 0 6 0H13.9393C14.3372 0 14.7187 0.158035 15 0.43934L20.5607 6C20.842 6.28131 21 6.66284 21 7.06066V21C21 22.6569 19.6569 24 18 24H6C4.34315 24 3 22.6569 3 21V3ZM14.25 5.25V2.25L18.75 6.75H15.75C14.9216 6.75 14.25 6.07843 14.25 5.25ZM12.75 10.5C12.75 10.0858 12.4142 9.75 12 9.75C11.5858 9.75 11.25 10.0858 11.25 10.5V12.75H9C8.58579 12.75 8.25 13.0858 8.25 13.5C8.25 13.9142 8.58579 14.25 9 14.25H11.25V16.5C11.25 16.9142 11.5858 17.25 12 17.25C12.4142 17.25 12.75 16.9142 12.75 16.5V14.25H15C15.4142 14.25 15.75 13.9142 15.75 13.5C15.75 13.0858 15.4142 12.75 15 12.75H12.75V10.5Z"
								fill="#94A3B8"
							/>
						</svg>

						<div class="text-2xl font-bold text-neutral-700 ml-2">
							Tạo Kết quả đánh giá
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							type="button"
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div class="px-4 sm:px-6">
				<div class="border-b border-neutral-200">
					<nav class="flex space-x-8 tabs" aria-label="Tabs">
						<div
							@click="tab = 0"
							class="
								order-transparent
								hover:text-yellow-700 hover:border-yellow-500
								whitespace-nowrap
								pb-6
								pt-8
								px-1
								font-semibold
								cursor-pointer
							"
							:class="
								tab === 0
									? 'border-b-2 text-yellow-700 border-yellow-500'
									: 'text-neutral-500'
							"
						>
							Nội dung
						</div>
						<div
							v-if="_isData"
							@click="tab = 1"
							class="
								order-transparent
								hover:text-yellow-700 hover:border-yellow-500
								whitespace-nowrap
								pb-6
								pt-8
								px-1
								font-semibold
								cursor-pointer
							"
							:class="
								tab === 1
									? 'border-b-2 text-yellow-700 border-yellow-500'
									: 'text-neutral-500'
							"
						>
							Tài liệu
						</div>
					</nav>
				</div>
			</div>
			<div class="sideover-content mt-6 relative flex-1 px-4 sm:px-6">
				<div v-show="tab === 0">
					<div class="text-neutral-600">
						Bạn hãy điền các thông tin dưới đây để tạo kết quả sau
						thăm khám
					</div>
					<div class="w-full mt-5">
						<div class="font-semibold text-neutral-600 mb-2">
							Tên bản đánh giá*
						</div>
						<div class="select-tabs mb-6">
							<div class="input-group flex items-center">
								<input
									v-model="result.name"
									maxlength="100"
									type="text"
									placeholder="vd: đánh giá đầu vào, lượng giá...."
									:class="errorName ? 'text-error' : ''"
								/>
							</div>
							<div
								class="text-sienna-600 text-sm mt-2"
								v-if="errorName"
							>
								Tên {{ errorName }}
							</div>
						</div>
					</div>

					<div class="w-full mt-5">
						<div class="font-semibold text-neutral-600 mb-2">
							Ngày thực hiện*
						</div>
						<div class="select-tabs mb-6">
							<div class="input-group w-full">
								<VueDatepicker
									class="input-date w-full"
									placeholder="Ngày thực hiện đánh giá"
									autoApply
									:enableTimePicker="false"
									uid="filter-by-date"
									format="dd/MM/yyyy"
									v-model="result.assessment_date"
									:class="
										errorCreatedTime ? 'text-error' : ''
									"
									:maxDate="new Date()"
								>
									<template #input-icon>
										<svg
											width="20"
											height="20"
											viewBox="0 0 20 20"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M5 0.625C5 0.279822 4.72018 0 4.375 0C4.02982 0 3.75 0.279822 3.75 0.625V1.25H2.5C1.11929 1.25 0 2.36929 0 3.75V5H20V3.75C20 2.36929 18.8807 1.25 17.5 1.25H16.25V0.625C16.25 0.279822 15.9702 0 15.625 0C15.2798 0 15 0.279822 15 0.625V1.25H5V0.625ZM20 17.5V6.25H0V17.5C0 18.8807 1.11929 20 2.5 20H17.5C18.8807 20 20 18.8807 20 17.5ZM11.875 8.75C11.5298 8.75 11.25 9.02982 11.25 9.375V10.625C11.25 10.9702 11.5298 11.25 11.875 11.25H13.125C13.4702 11.25 13.75 10.9702 13.75 10.625V9.375C13.75 9.02982 13.4702 8.75 13.125 8.75H11.875ZM15.625 8.75C15.2798 8.75 15 9.02982 15 9.375V10.625C15 10.9702 15.2798 11.25 15.625 11.25H16.875C17.2202 11.25 17.5 10.9702 17.5 10.625V9.375C17.5 9.02982 17.2202 8.75 16.875 8.75H15.625ZM2.5 13.125C2.5 12.7798 2.77982 12.5 3.125 12.5H4.375C4.72018 12.5 5 12.7798 5 13.125V14.375C5 14.7202 4.72018 15 4.375 15H3.125C2.77982 15 2.5 14.7202 2.5 14.375V13.125ZM6.875 12.5C6.52982 12.5 6.25 12.7798 6.25 13.125V14.375C6.25 14.7202 6.52982 15 6.875 15H8.125C8.47018 15 8.75 14.7202 8.75 14.375V13.125C8.75 12.7798 8.47018 12.5 8.125 12.5H6.875Z"
												fill="#94A3B8"
											/>
										</svg>
									</template>
								</VueDatepicker>
							</div>
							<div
								class="text-sienna-600 text-sm mt-2"
								v-if="errorCreatedTime"
							>
								Ngày thực hiện
								<span v-html="errorCreatedTime"></span>
							</div>
						</div>
					</div>
					<div class="w-full mt-5">
						<div class="font-semibold text-neutral-600 mb-2">
							Kết luận
						</div>
						<div
							class="select-tabs mb-6 flex items-center gap-6"
							v-for="(a, index) in assessment_conclusions.length"
							:key="index"
						>
							<Multiselect
								v-if="optionResult.length > 0"
								v-model="assessment_conclusions[index]"
								:options="
									optionResult.filter(
										(s) =>
											!assessment_conclusions.includes(s)
									)
								"
								:searchable="true"
								selectLabel=""
								placeholder="Kết luận về tình trạng của h.s"
								selectedLabel=""
								deselectLabel="Ấn đễ bỏ chọn"
							>
								<template #noResult>
									Không tìm thấy kết quả
								</template>
							</Multiselect>
							<DaIcon
								@click="
									assessment_conclusions.length > 1 &&
										index !==
											assessment_conclusions.length &&
										removeConclusions(index)
								"
								name="das--delete"
								class="h-4 w-4"
								:class="
									assessment_conclusions.length > 1 &&
									index !== assessment_conclusions.length
										? 'text-yellow-600 hover:text-yellow-700 active:text-yellow-500 cursor-pointer'
										: 'text-neutral-300'
								"
							/>
						</div>
						<div class="flex justify-end gap-6">
							<div
								@click="addConclusions"
								class="
									font-semibold
									text-yellow-700
									mb-2
									text-sm text-right
									cursor-pointer
								"
							>
								Thêm chẩn đoán
							</div>
							<div class="h-4 w-4" style="min-width: 8px"></div>
						</div>
					</div>
					<div class="w-full mt-5">
						<div class="font-semibold text-neutral-600 mb-2">
							Tuổi phát triển
						</div>
						<div class="select-tabs mb-6">
							<div
								class="
									input-group
									flex
									items-center
									mb-4
									relative
									gap-6
								"
								v-for="(d, index) in development_ages.length"
								:key="index"
							>
								<input
									v-model="development_ages[index]"
									type="text"
									placeholder="Tuổi phát triển của h.s ở thời điểm đánh giá"
									style="padding-right: 30px"
								/>
								<DaIcon
									@click="
										development_ages.length > 1 &&
											index !== development_ages.length &&
											removeAge(index)
									"
									:class="
										development_ages.length > 1 &&
										index !== development_ages.length
											? 'text-yellow-600 hover:text-yellow-700 active:text-yellow-500 cursor-pointer'
											: 'text-neutral-300'
									"
									name="das--delete"
									class="h-4 w-4"
								/>
							</div>
						</div>
						<div class="flex justify-end gap-6">
							<div
								@click="addAge"
								class="
									font-semibold
									text-yellow-700
									mb-2
									text-sm text-right
									cursor-pointer
								"
							>
								Thêm tuổi phát triển
							</div>
							<div class="h-4 w-4" style="min-width: 8px"></div>
						</div>
					</div>
					<div class="w-full mt-5">
						<div class="font-semibold text-neutral-600 mb-2">
							Đơn thuốc
						</div>
						<div class="select-tabs mb-6">
							<div
								class="
									input-group
									flex
									items-center
									mb-4
									relative
									gap-6
								"
								v-for="(p, index) in prescription.length"
								:key="index"
							>
								<input
									v-model="prescription[index]"
									type="text"
									placeholder="Đơn thuốc của h.s ở thời điểm đánh giá"
									style="padding-right: 30px"
								/>
								<DaIcon
									@click="
										prescription.length > 1 &&
											index !== prescription.length;
										removePescription(index);
									"
									:class="
										prescription.length > 1 &&
										index !== prescription.length
											? 'text-yellow-600 hover:text-yellow-700 active:text-yellow-500 cursor-pointer'
											: 'text-neutral-300'
									"
									name="das--delete"
									class="h-4 w-4"
								/>
							</div>
						</div>
						<div class="flex justify-end gap-6">
							<div
								@click="addPescription"
								class="
									font-semibold
									text-yellow-700
									mb-2
									text-sm text-right
									cursor-pointer
								"
							>
								Thêm đơn thuốc
							</div>
							<div class="h-4 w-4" style="min-width: 8px"></div>
						</div>
					</div>
				</div>
				<div v-show="tab === 1">
					<div v-if="result.files.length">
						<div
							v-for="(file, index) in result.files"
							:key="file.id"
							class="flex items-center justify-between mb-6"
						>
							<div class="text-neutral-600 w-11/12 truncate">
								{{ index + 1 }}. {{ file.name }}
							</div>
							<div
								@click="deleteFile(file.id)"
								class="
									flex
									items-center
									text-blue-700
									hover:text-blue-800
									font-semibold
									cursor-pointer
									text-sm
								"
							>
								<svg
									class="mr-2"
									width="16"
									height="16"
									viewBox="0 0 16 16"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M2.5 1C1.94772 1 1.5 1.44772 1.5 2V3C1.5 3.55228 1.94772 4 2.5 4H3V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V4H13.5C14.0523 4 14.5 3.55228 14.5 3V2C14.5 1.44772 14.0523 1 13.5 1H10C10 0.447715 9.55229 0 9 0H7C6.44772 0 6 0.447715 6 1H2.5ZM5.5 5C5.77614 5 6 5.22386 6 5.5V12.5C6 12.7761 5.77614 13 5.5 13C5.22386 13 5 12.7761 5 12.5L5 5.5C5 5.22386 5.22386 5 5.5 5ZM8 5C8.27614 5 8.5 5.22386 8.5 5.5V12.5C8.5 12.7761 8.27614 13 8 13C7.72386 13 7.5 12.7761 7.5 12.5V5.5C7.5 5.22386 7.72386 5 8 5ZM11 5.5C11 5.22386 10.7761 5 10.5 5C10.2239 5 10 5.22386 10 5.5V12.5C10 12.7761 10.2239 13 10.5 13C10.7761 13 11 12.7761 11 12.5V5.5Z"
										fill="currentColor"
									/>
								</svg>
							</div>
						</div>
						<div
							class="flex items-center justify-end"
							v-if="result.files.length < 10"
						>
							<div class="mt-4 ml-auto" @click="openModelUpload">
								<button
									type="button"
									class="
										button-p
										text-center
										bg-none
										shadow-none
										flex
										items-center
										justify-center
										btn--text
										w-fit
									"
								>
									<svg
										class="mr-2"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M12 0C9.86038 0 7.96764 0.840683 6.60864 2.01273C5.45978 3.00355 4.62719 4.29255 4.41334 5.5875C1.89879 6.14267 0 8.33267 0 10.9773C0 14.049 2.56131 16.5 5.67188 16.5H11.25V8.56066L8.03033 11.7803C7.73744 12.0732 7.26256 12.0732 6.96967 11.7803C6.67678 11.4874 6.67678 11.0126 6.96967 10.7197L11.4697 6.21967C11.7626 5.92678 12.2374 5.92678 12.5303 6.21967L17.0303 10.7197C17.3232 11.0126 17.3232 11.4874 17.0303 11.7803C16.7374 12.0732 16.2626 12.0732 15.9697 11.7803L12.75 8.56066V16.5H19.0312C21.7535 16.5 24 14.3542 24 11.6591C24 9.20484 22.1371 7.20616 19.7485 6.86847C19.3849 2.99916 16.0354 0 12 0Z"
											fill="currentColor"
										/>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M11.25 21.75V16.5H12.75V21.75C12.75 22.1642 12.4142 22.5 12 22.5C11.5858 22.5 11.25 22.1642 11.25 21.75Z"
											fill="currentColor"
										/>
									</svg>
									Tải lên tài liệu đánh giá
								</button>
							</div>
						</div>
					</div>
					<div v-else>
						<div class="text-neutral-500">
							Bạn hãy tải lên các tài liệu về kết quả thăm khám
							của trẻ (nếu có)
						</div>
						<div
							class="
								text-neutral-500
								flex flex-col
								items-center
								justify-senter
								text-center
								gap-2
								rounded-lg
								mt-6
								p-8
								cursor-pointer
								border-2 border-dashed
								hover:border-yellow-500
							"
							@click="openModelUpload"
						>
							<svg
								width="48"
								height="48"
								viewBox="0 0 48 48"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M24 0C19.7208 0 15.9353 1.68137 13.2173 4.02545C10.9196 6.0071 9.25438 8.5851 8.82668 11.175C3.79759 12.2853 0 16.6653 0 21.9545C0 28.098 5.12261 33 11.3438 33H22.5V17.1213L16.0607 23.5607C15.4749 24.1464 14.5251 24.1464 13.9393 23.5607C13.3536 22.9749 13.3536 22.0251 13.9393 21.4393L22.9393 12.4393C23.5251 11.8536 24.4749 11.8536 25.0607 12.4393L34.0607 21.4393C34.6464 22.0251 34.6464 22.9749 34.0607 23.5607C33.4749 24.1464 32.5251 24.1464 31.9393 23.5607L25.5 17.1213V33H38.0625C43.507 33 48 28.7085 48 23.3182C48 18.4097 44.2743 14.4123 39.4971 13.7369C38.7699 5.99831 32.0707 0 24 0Z"
									fill="#F0BB4F"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M22.5 43.5V33H25.5V43.5C25.5 44.3284 24.8284 45 24 45C23.1716 45 22.5 44.3284 22.5 43.5Z"
									fill="#F0BB4F"
								/>
							</svg>
							<div class="font-semibold">
								Tải lên tài liệu đánh giá
							</div>
							<div class="text-sm">
								PNG, JPG, PDF, DOCX, XLXS <br />
								Tối đa 10 tài liệu/ học sinh
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="isAlertShow">
				<AlertUpload
					:message="message"
					:type="type"
					@close="closeAlert"
				/>
			</div>
			<div class="px-6 py-4 border-t border-yellow-50">
				<div class="flex justify-end" v-if="tab === 0">
					<div>
						<button
							type="button"
							class="button-p text-center w-32"
							@click="submit"
						>
							TIếp tục
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import Multiselect from '@suadelabs/vue3-multiselect';
import { $api } from '@/services';
import { PropSync, Watch, Prop } from 'vue-property-decorator';
import AlertUpload from '../../../../../components/elements/alert/AlertUpload';
import heic2any from 'heic2any';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';

@Options({
	name: 'UpdateResultSideOver',
	components: { AlertUpload, Multiselect, DaIcon },
})
export default class UpdateResultSideOver extends Vue {
	@PropSync('data')
	resultData;

	@PropSync('isData')
	_isData;

	@PropSync('refresh')
	refresh;

	@Prop() isEdit;

	tab = 0;

	isAlertShow = false;
	message = '';
	type = '';

	result = {
		id: '',
		name: '',
		assessment_date: null,
		development_age: '',
		files: [],
	};

	assessment_conclusions = [''];
	development_ages = [''];
	prescription = [''];

	optionResult = [
		'Rối loạn phát triển trí tuệ',
		'Rối loạn nhận thức thần kinh',
		'Rối loạn học tập biệt định và rối loạn giao tiếp',
		'Rối loạn giao tiếp',
		'Rối loạn ngôn ngữ',
		'Biến thể bình thường của ngôn ngữ',
		'Khiếm thính hoặc suy giảm các giác quan khác',
		'Rối loạn thần kinh',
		'Ngôn ngữ thoái triển',
		'Rối loạn phát âm',
		'Chứng khó đọc',
		'Câm chọn lọc',
		'Khuyết tật cấu trúc (sứt môi, chẻ vòm)',
		'Rối loạn lưu loát ở trẻ',
		'Rối loạn giao tiếp xã hội',
		'Rối loạn lo âu xã hội',
		'Rối loạn phổ tự kỷ',
		'Hội chứng Rett',
		'Rối loạn vận động rập khuôn/ định hình',
		'OCD và các rối loạn liên quan',
		'Rối loạn Tic',
		'Rối loạn tic nhất thời',
		'Rối loạn tic vận động và lời nói mạn tính',
		'Tâm thần phân liệt',
		'Rối loạn tăng động giảm chú ý ',
		'Rối loạn tăng động giảm chú ý kết hợp',
		'Giảm chú ý biểu hiện ưu thế',
		'Tăng động biểu hiện ưu thế',
		'Rối loạn thách thức chống đối',
		'Rối loạn bùng phát gián đoạn',
		'Rối loạn học tập biệt định',
		'Rối loạn phản ứng gắn bó',
		'Rối loạn lo âu',
		'Rối loạn trầm cảm',
		'Rối loạn lưỡng cực',
		'Rối loạn điều hòa khí sắc',
		'Rối loạn nhân cách',
		'Thuốc gây ra rối loạn tăng động giảm chú ý',
		'Rối loạn nhận thức thần kinh',
		'Rối loạn học tập biệt định',
		'Học tập khó khăn do rối loạn giác quan và thần kinh',
		'Rối loạn phối hợp phát triển',
		'Rối loạn điều hòa phát triển',
		'Theo dõi nguy cơ rối loạn phổ tự kỷ',
		'Theo dõi nguy cơ tăng động giảm chú ý',
		'Chậm nói',
		'Rối loạn âm lời nói',
		'Chậm phát triển',
		'Chậm vận động',
		'Chậm phát triển tâm thần vận động',
		'Tổn thương não',
		'Tăng trương lực cơ',
		'Giảm trương lực cơ',
		'Khiếm thính',
		'Hở hàm ếch',
		'Động kinh',
		'Khó khăn học tập',
		'Khó khăn đọc viết',
		'Chứng khó đọc',
		'Táo bón',
		'Rối loạn giấc ngủ',
		'Khó khăn ăn uống',
	];

	error = {
		name: [],
		assessment_date: [],
	};

	@Watch('resultData', { deep: true, immediate: true })
	onResultData(value) {
		if (value) {
			this.assessment_conclusions = value?.assessment_conclusions
				? [...value?.assessment_conclusions]
				: [];
			this.development_ages = value?.development_ages
				? [...value?.development_ages]
				: [];
			this.prescription = value?.prescription
				? [...value?.prescription]
				: [];
			Object.assign(this.result, value);
		}
	}

	@Watch('refresh', { immediate: true })
	onRefresh(value) {
		if (value) {
			this.development_ages = [''];
			this.prescription = [''];
			this.result = {
				id: '',
				name: '',
				assessment_date: null,
				development_age: '',
				assessment_conclusions: [''],
				files: [],
			};
			this.resultData = {
				id: '',
				name: '',
				assessment_date: null,
				development_age: '',
				assessment_conclusions: [''],
				files: [],
			};
			this.tab = 0;
			this._isData = false;
		}
	}

	get errorName() {
		if (this.error.name) {
			return this.error.name.join('<br/>');
		} else {
			return '';
		}
	}

	get errorCreatedTime() {
		if (this.error.assessment_date) {
			return this.error.assessment_date.join('<br/>');
		} else {
			return '';
		}
	}

	get id() {
		return Number(this.$route.params.id);
	}

	get resultId() {
		return Number(this.result.id);
	}

	submit() {
		this.isEdit
			? this.updateAssessmentDocument()
			: this.createAssessmentDocument();
	}

	async fetchResultDetail() {
		try {
			const { data } = await $api.student.assessmentDocumentDetail(
				this.id,
				this.resultId
			);
			this.result = data.data;
		} catch (e) {
			await this.$store.dispatch('setAlertMessage', {
				message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
				type: 'danger',
			});
		}
	}

	async createAssessmentDocument() {
		this.error = {
			name: [],
			assessment_date: [],
		};
		if (!this.result.assessment_date) {
			this.error.assessment_date.push('không được để trống');
		}
		if (!this.result.name) {
			this.error.name.push('không được để trống');
		}

		if (this.error.name.length || this.error.assessment_date.length) {
			return;
		}
		try {
			const res = await $api.student.createAssessmentDocuments(this.id, {
				name: this.result.name,
				assessment_date: this.result.assessment_date,
				development_ages: this.development_ages,
				development_age: this.development_ages,
				prescription: this.prescription,
				assessment_conclusions: this.assessment_conclusions,
				assessment_conclusion: this.assessment_conclusions,
			});

			this.result = res?.data?.data;

			await this.fetchResultDetail();

			this.$emit('success');
			this.message = 'Tạo Kết quả đánh giá thành công';
			this.isAlertShow = true;

			setTimeout(() => {
				this.closeAlert();
			}, 3000);
			this.tab = 1;
		} catch (e) {
			this.error = e?.response?.data?.errors_by_field || '';
		}
	}

	async updateAssessmentDocument() {
		if (!this.result.assessment_date) {
			this.error.assessment_date.push('không được để trống');
			return;
		}
		try {
			await $api.student.updateAssessmentDocuments(
				this.id,
				this.result.id,
				{
					name: this.result.name,
					assessment_date: this.result.assessment_date,
					development_ages: this.development_ages,
					development_age: this.development_ages,
					prescription: this.prescription,
					assessment_conclusions: this.assessment_conclusions,
					assessment_conclusion: this.assessment_conclusions,
				}
			);
			this.$emit('success');
			await this.$store.dispatch('setAlertMessage', {
				message: 'Cập nhật kết quả đánh giá thành công.',
			});
		} catch (e) {
			this.error = e?.response?.data?.errors_by_field || '';
		}
	}

	async deleteFile(file_id) {
		try {
			await $api.student.deleteResultFile(
				this.id,
				this.result.id,
				file_id
			);
			if (!this.isEdit) {
				await this.fetchResultDetail();
			} else {
				this.$emit('success');
			}
			this.message = 'Xoá file kết quả đánh giá thành công';
			this.isAlertShow = true;

			setTimeout(() => {
				this.closeAlert();
			}, 3000);
		} catch (e) {
			this.error = e?.response?.data?.errors_by_field || '';
		}
	}

	openModelUpload() {
		const target = document.getElementById('upload-file');
		target ? target.click() : '';
	}

	isFile(icon) {
		const ext = [
			'.jpg',
			'.jpeg',
			'.png',
			'.doc',
			'.docx',
			'.xlsx',
			'.pdf',
			'.heic',
		];
		return ext.some((el) => icon.endsWith(el));
	}

	async uploadChange(event) {
		let file = event.target.files[0];

		let fileConvert = null;

		if (!file) {
			return;
		}

		/*		if (file.size > 3000000) {
			this.isAlertShow = true;
			this.message = 'Tài liệu vượt quá dung lượng cho phép.';
			this.type = 'danger';
			const target = document.getElementById('upload-file');
			target ? (target.value = null) : '';
			setTimeout(() => {
				this.closeAlert();
			}, 3000);
			return;
		}*/

		if (!this.isFile(file.name)) {
			this.isAlertShow = true;
			this.message = 'Định dạng tệp không hỗ trợ';
			this.type = 'danger';
			const target = document.getElementById('upload-file');
			target ? (target.value = null) : '';
			setTimeout(() => {
				this.closeAlert();
			}, 3000);
			return;
		}

		if (
			/image\/hei(c|f)/.test(file.type) ||
			event.target.files[0].name.endsWith('.heic') ||
			event.target.files[0].name.endsWith('.heif')
		) {
			heic2any({ blob: file, toType: 'image/jpeg', quality: 1 })
				.then(async (jpgBlob) => {
					//Change the name of the file according to the new format
					let newName = file.name.replace(/\.[^/.]+$/, '.jpg');

					//Convert blob back to file
					fileConvert = await this.blobToFile(jpgBlob, newName);

					try {
						let data = new FormData();
						data.append('file', fileConvert);
						const res = await $api.student.uploadResultFile(
							this.id,
							this.resultId,
							data
						);
						if (res) {
							this.isAlertShow = true;
							this.message = 'Tải lên tài liệu thành công';
							this.type = '';
						}
						this.$emit('success');
						const target = document.getElementById('upload-file');
						target ? (target.value = null) : '';

						if (!this.isEdit) {
							await this.fetchResultDetail();
						} else {
							this.$emit('success');
						}
						setTimeout(() => {
							this.closeAlert();
						}, 3000);
					} catch (e) {
						this.isAlertShow = true;
						this.message =
							'Có lỗi xảy ra, tải lên tài liệu không thành công';
						this.type = 'danger';
						setTimeout(() => {
							this.closeAlert();
						}, 3000);
					}
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			try {
				let data = new FormData();
				data.append('file', file);
				const res = await $api.student.uploadResultFile(
					this.id,
					this.resultId,
					data
				);
				if (res) {
					this.isAlertShow = true;
					this.message = 'Tải lên tài liệu thành công';
					this.type = '';
				}
				this.$emit('success');
				const target = document.getElementById('upload-file');
				target ? (target.value = null) : '';

				if (!this.isEdit) {
					await this.fetchResultDetail();
				} else {
					this.$emit('success');
				}
				setTimeout(() => {
					this.closeAlert();
				}, 3000);
			} catch (e) {
				this.isAlertShow = true;
				this.message =
					'Có lỗi xảy ra, tải lên tài liệu không thành công';
				this.type = 'danger';
				setTimeout(() => {
					this.closeAlert();
				}, 3000);
			}
		}
	}

	blobToFile(theBlob, fileName) {
		//A Blob() is almost a File() - it's just missing the two properties below which we will add

		theBlob.name = fileName;

		//Cast to a File() type
		return new File([theBlob], fileName, {
			lastModified: new Date().getTime(),
			lastModifiedDate: new Date(),
			type: theBlob.type,
		});
	}

	closeAlert() {
		this.isAlertShow = false;
		this.message = '';
		this.type = '';
	}

	removeAge(index) {
		this.development_ages.splice(index, 1);
	}

	addAge() {
		this.development_ages.push('');
	}

	removePescription(index) {
		this.prescription.splice(index, 1);
	}

	addPescription() {
		this.prescription.push('');
	}

	removeConclusions(index) {
		this.assessment_conclusions.splice(index, 1);
	}

	addConclusions() {
		this.assessment_conclusions.push('');
	}
}
</script>

<style scoped lang="scss">
@import '../../../../../assets/styles/responsive';

.side-over {
	width: 100vw;
	padding-left: 0;

	@include media-breakpoint-up(md) {
		width: 600px;
	}
}
.sideover-content {
	height: calc(100vh - 300px);
	overflow-y: auto;
	padding-bottom: 100px;
}
</style>
