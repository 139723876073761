<template>
	<div
		style="max-width: 400px"
		class="
			fixed
			inset-y-0
			right-0
			flex
			side-over
			z-50
			overflow-y-auto
			w-full
		"
	>
		<div
			class="h-full w-full flex flex-col bg-white shadow-xl"
			style="min-height: 100vh; height: fit-content"
		>
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M1.5 5.5C1.5 4.67157 2.17157 4 3 4H21C21.8284 4 22.5 4.67157 22.5 5.5C22.5 6.32843 21.8284 7 21 7H3C2.17157 7 1.5 6.32843 1.5 5.5Z"
								fill="#94A3B8"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M3.5 12C3.5 11.1716 4.17157 10.5 5 10.5H19C19.8284 10.5 20.5 11.1716 20.5 12C20.5 12.8284 19.8284 13.5 19 13.5H5C4.17157 13.5 3.5 12.8284 3.5 12Z"
								fill="#94A3B8"
							/>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M6.5 18C6.5 17.1716 7.17157 16.5 8 16.5H16C16.8284 16.5 17.5 17.1716 17.5 18C17.5 18.8284 16.8284 19.5 16 19.5H8C7.17157 19.5 6.5 18.8284 6.5 18Z"
								fill="#94A3B8"
							/>
						</svg>

						<div class="text-4xl font-bold text-neutral-700 ml-1.5">
							Bộ lọc
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							type="button"
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div class="mt-6 relative flex-1 px-4 sm:px-6">
				<div class="search-input w-ful relative mb-10">
					<div class="mb-1">Lọc theo học sinh</div>
					<div class="bg-white select-tabs w-full">
						<Multiselect
							v-model="student"
							deselect-label="Bỏ chọn"
							track-by="id"
							:options="
								students?.map((s) => {
									return {
										nameSearch: toEnglish(s?.name),
										...s,
									};
								})
							"
							:searchable="true"
							label="nameSearch"
							selectLabel=""
							placeholder="Tên học sinh"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									{{ props.option.name }}
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									{{ option.name }}
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<div class="search-input w-ful relative">
					<div class="bg-white select-tabs w-full">
						<div class="mb-1">Lọc theo giáo viên</div>
						<Multiselect
							v-model="staff"
							deselect-label="Bỏ chọn"
							track-by="id"
							:options="
								staffs?.map((s) => {
									return {
										nameSearch: toEnglish(s?.name),
										...s,
									};
								})
							"
							:searchable="true"
							label="nameSearch"
							selectLabel=""
							placeholder="Tên giáo viên"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									{{ props.option.name }}
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									{{ option.name }}
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
			</div>
			<div class="px-6 py-4">
				<div class="flex justify-end">
					<div>
						<button
							type="button"
							class="button-p text-center w-32"
							@click="submit"
						>
							Áp dụng bộ lọc
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';
import Multiselect from '@suadelabs/vue3-multiselect';
import { toEnglish } from '@/helper/toEnglish';
// import { useRoute } from 'vue-router';

const staff = ref({});
const student = ref({});
export default {
	name: 'SideOver',
	components: { Multiselect },
	props: {
		staffs: {
			type: Array,
		},
		students: {
			type: Array,
		},

		// 	isOpen: {
		// 		type: Boolean,
		// 		default: false,
		// 	},
	},
	setup(props, { emit }) {
		function submit() {
			if (student.value === null) student.value = {};
			if (staff.value === null) staff.value = {};
			let obj = {
				student: student.value,
				staff: staff.value,
			};
			emit('close');
			emit('submit', obj);
		}
		return {
			student,
			staff,
			toEnglish,
			submit,
		};
	},
};
</script>

<style scoped lang="scss">
.da-dropdown {
	@apply mt-2 rounded-md bg-white w-full z-20 shadow-input py-5 pl-2;
	position: absolute;

	&__wrapper {
		@apply overflow-y-auto w-full;
		height: fit-content;
		max-height: 150px;
	}

	&-item {
		@apply pl-3 py-2 cursor-pointer;
		border-radius: 5px;

		&:hover {
			@apply bg-yellow-100 text-yellow-700;
		}
	}

	.active {
		@apply bg-yellow-100 text-yellow-700;
	}
}
</style>
