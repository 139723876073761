
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import Rating from '@/components/elements/rating/Rating.vue';
import { Options, setup, Vue } from 'vue-class-component';
import SideOver from '@/components/elements/sideOver/SideOver.vue';
import { useStore } from 'vuex';
import { useWeeklyReportList } from '@/features/weeklyReport/weeklyReportList';
import DaInfinityLoading from '@/components/common/daInfinityLoading/DaInfinityLoading.vue';
import SlideOverWeekFilter from '@/views/report/features/week/SlideOverWeekFilter.vue';
import Loading from '@/components/common/Loading.vue';
import { _ROUTER_NAME } from "@/enums";
import router from "@/router";

@Options({
  name: 'ReportWeek',
  components: {
    Loading,
    SlideOverWeekFilter,
    SideOver,
    Rating,
    DaIcon,
    DaInfinityLoading,
  },
})
export default class ReportWeek extends Vue {
  sideOverShow = false;

  //----------------------- 🤍 Setup 🤍 -----------------------//
  setup = setup(() => {
    const store = useStore();
    const enable_weekly_report = store.state?.profile?.current_org?.enable_weekly_report;
    
    if (!enable_weekly_report) {
      router.push({ name: _ROUTER_NAME.REPORT_WEEK_GROUP })
    }

    const { error, loading, getWeeklyReportList } = useWeeklyReportList();
    return {
      store,
      error,
      loading,
      enable_weekly_report,
      getWeeklyReportList,
    };
  });

  //----------------------- 🤍 Getters 🤍 -----------------------//
  get isAdmin() {
    return this.setup.store.getters.isAdmin;
  }

  get tabs() {
    let tabsData: any[] = []

    if (this.setup.enable_weekly_report) {
      tabsData = [
        {
          id: 0,
          name: 'Học cá nhân',
          icon: 'das--book',
          link: _ROUTER_NAME.REPORT_WEEK_CLASS,
        },
      ];
    }

    return [
      ...tabsData,
      {
        id: 1,
        name: 'Học nhóm',
        icon: 'das--users',
        link: _ROUTER_NAME.REPORT_WEEK_GROUP,
      },
    ];
  }

  async submitFilter(value: any) {
    this.clean(value);
    this.$router.push({
      query: value,
    });
    this.sideOverShow = false;
  }

  clean(obj: any) {
    for (let propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName].length <= 0
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  async cancelFilter() {
    this.$router.push({
      query: {},
    });
  }
}
