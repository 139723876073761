<template>
	<div>
		<div
			v-if="isEdit"
			class="bg-white shadow-sm rounded-2xl overflow-hidden my-10"
		>
			<div class="p-6">
				<div class="font-semibold text-neutral-600">
					Kế hoạch tiếp theo
				</div>
				<div class="font-semibold text-green-500 capitalize mt-3">
					KẾ HOẠCH CAN THIỆP NHÓM {{ report?.group_plan?.order + 1 }}
				</div>
				<div class="text-neutral-500 mt-3">
					<p>
						Kế hoạch này sẽ được thiết lập dựa trên Báo cáo tháng
						bạn vừa thực hiện. Bạn có thể chỉnh sửa và thêm mục tiêu
						vào kế hoạch này trong mục Kế hoạch.
					</p>
				</div>
			</div>

			<div
				class="
					p-6
					border-t border-orange-50
					md-down:px-0
					overflow-x-auto
				"
			>
				<div class="md-down:px-6">
					<div class="font-semibold text-neutral-600">
						Ngày dự kiến áp dụng Kế hoạch
					</div>
					<div class="text-neutral-500 mt-3">
						<p>
							Ngày áp dụng là ngày bạn sẽ bắt đầu dạy các mục tiêu
							trong kế hoạch này.
						</p>
					</div>
					<div class="mt-6">
						<div class="flex items-center">
							<input
								v-model="applyNow"
								:value="1"
								class="
									h-4
									w-4
									text-yellow-600
									border-neutral-400 border-2
									focus:ring-yellow-600
								"
								name="notification-method"
								type="radio"
							/>
							<label
								class="
									pl-3
									block
									text-sm
									font-medium
									text-neutral-800
								"
								@click="applyNow = 1"
							>
								Áp dụng ngay (Trở thành
								<span class="font-semibold text-green-500"
									>Kế hoạch hiện tại</span
								>, có thể dạy các mục tiêu trong kế hoạch)
							</label>
						</div>
						<div class="flex items-center mt-6">
							<input
								v-model="applyNow"
								:value="2"
								class="
									h-4
									w-4
									text-yellow-600
									border-neutral-400 border-2
									focus:ring-yellow-600
								"
								name="notification-method"
								type="radio"
							/>
							<label
								class="
									pl-3
									block
									text-sm
									font-medium
									text-neutral-800
								"
								@click="applyNow = 2"
							>
								Áp dụng sau (Trở thành
								<span class="font-semibold text-green-500"
									>Kế hoạch dự kiến</span
								>, tới ngày áp dụng, Kế hoạch dự kiến sẽ trở
								thành kế hoạch hiện tại)
							</label>
						</div>
					</div>
					<div class="mt-10">
						<div v-if="applyNow === 1" class="w-full">
							<div class="input-group flex items-center">
								<input
									v-model="dateNow"
									placeholder="Chọn ngày áp dụng"
									readonly
									type="text"
								/>
							</div>
						</div>
						<div v-if="applyNow === 2" class="w-full">
							<div class="input-group items-center">
								<VueDatepicker
									v-model="applyDate"
									:class="{ 'text-error': errorApplyDate }"
									:enableTimePicker="false"
									:minDate="minDate"
									autoApply
									class="input-date w-full"
									format="dd/MM/yyyy"
									placeholder="Chọn ngày áp dụng"
								>
									<template #input-icon>
										<DaIcon
											class="h-4 w-4 text-neutral-400"
											name="das--lock"
										/>
									</template>
								</VueDatepicker>
							</div>
							<div
								v-if="errorApplyDate"
								class="text-sienna-600 mt-3"
							>
								Vui lòng chọn ngày áp dụng lớn hơn ngày hiện tại
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				class="
					p-6
					border-t
					flex
					justify-end
					border-orange-50
					overflow-x-auto
				"
			>
				<button
					:disabled="applyNow === 2 && errorApplyDate"
					class="button-p text-center whitespace-nowrap w-fit"
					type="button"
					@click="done"
				>
					Xác nhận
				</button>
			</div>
		</div>
		<div
			v-else
			class="bg-white shadow-sm rounded-2xl overflow-hidden my-10"
		>
			<div class="flex items-center justify-between p-6">
				<div class="font-semibold text-neutral-600">
					<div>Kế hoạch tiếp theo</div>
				</div>
				<div>
					<svg
						class="
							cursor-pointer
							text-neutral-400
							hover:text-neutral-600
						"
						fill="none"
						height="24"
						viewBox="0 0 24 24"
						width="24"
						xmlns="http://www.w3.org/2000/svg"
						@click="isEdit = true"
					>
						<path
							clip-rule="evenodd"
							d="M13.6303 5.68775L15.8833 3.43471C16.7491 2.56893 17.182 2.13604 17.689 1.99526C18.0391 1.89806 18.4091 1.89806 18.7591 1.99526C19.2662 2.13604 19.6991 2.56893 20.5649 3.43471C21.4306 4.3005 21.8635 4.73339 22.0043 5.24044C22.1015 5.59052 22.1015 5.96046 22.0043 6.31054C21.8635 6.8176 21.4306 7.25049 20.5649 8.11627L18.338 10.3432C16.3854 9.2234 14.7646 7.61489 13.6303 5.68775ZM12.1759 7.14212L3.86541 15.4526C3.44035 15.8777 3.22782 16.0902 3.08808 16.3513C2.94835 16.6124 2.8894 16.9071 2.77151 17.4966L1.98573 21.4255C1.9192 21.7581 1.88594 21.9244 1.98055 22.019C2.07516 22.1136 2.24145 22.0804 2.57404 22.0139L2.57406 22.0139L2.57408 22.0138L6.503 21.2281L6.50302 21.2281C7.09246 21.1102 7.38718 21.0512 7.64828 20.9115C7.90937 20.7718 8.1219 20.5592 8.54695 20.1342L8.54696 20.1342L16.8798 11.8013C14.9781 10.6172 13.3718 9.02191 12.1759 7.14212Z"
							fill="currentColor"
							fill-rule="evenodd"
						/>
					</svg>
				</div>
			</div>
			<div class="p-6 border-t border-orange-50 overflow-x-auto">
				<div
					class="
						flex
						items-center
						md-down:flex-col md-down:items-start
					"
				>
					<div
						class="
							w-56
							text-neutral-400
							uppercase
							font-semibold
							text-sm
						"
					>
						MÃ KẾ HOẠCH
					</div>
					<div
						class="
							font-semibold
							text-green-500
							capitalize
							md-down:mt-2
						"
					>
						KẾ HOẠCH CAN THIỆP NHÓM
						{{ report?.group_plan?.order + 1 }}
					</div>
				</div>
				<div
					class="
						flex
						items-center
						mt-8
						md-down:flex-col md-down:items-start md-down:mt-4
					"
				>
					<div
						class="
							w-56
							text-neutral-400
							uppercase
							font-semibold
							text-sm
						"
					>
						Ngày áp dụng
					</div>
					<div v-if="applyNow === 1" class="font-semibold">
						{{ dateNow }}
					</div>
					<div
						v-if="applyNow === 2"
						class="font-semibold md-down:mt-2"
					>
						{{ applyDateText }}
					</div>
				</div>
			</div>
		</div>
		<div class="relative">
			<div aria-hidden="true" class="absolute inset-0 flex items-center">
				<div class="w-full border-t border-neutral-300" />
			</div>
			<div class="relative flex justify-center">
				<div
					class="
						px-2.5
						py-1
						bg-body
						text-neutral-600
						font-semibold
						pb-2.5
					"
				>
					Xem trước Kế hoạch tiếp theo
				</div>
			</div>
		</div>
		<div class="mt-10">
			<div class="p-6 bg-white shadow-sm rounded-2xl mb-10">
				<div>
					<div class="flex items-center justify-between mb-8">
						<div class="font-semibold text-neutral-600">
							Thống kê chung
						</div>
					</div>
				</div>
				<div class="flex flex-nowrap w-full md-down:flex-col">
					<div
						class="
							p-6
							border
							rounded-l-lg
							border-neutral-200
							w-full
							md:border-r-0
							md-down:border-b-0
							md-down:rounded-t
							md-down:rounded-b-none
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/abc.png"
								style="height: 32px"
								width="32"
							/>
							{{ report?.next_plan?.stats?.skills_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Tổng số lĩnh vực
							</span>
						</div>
					</div>
					<div
						class="
							p-6
							border border-r-0 border-neutral-200
							w-full
							md-down:border-r md-down:border-b-0
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/light.png"
								style="height: 32px"
								width="32"
							/>
							{{ report?.next_plan?.stats?.goals_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Tổng số mục tiêu</span
							>
						</div>
					</div>
					<div
						class="
							p-6
							border
							rounded-r-lg
							border-neutral-200
							w-full
							md-down:rounded-b md-down:rounded-t-none
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/light.png"
								style="height: 32px"
								width="32"
							/>
							{{
								report?.next_plan?.stats?.continued_goals_count
							}}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Mục tiêu học lại</span
							>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="
					report?.next_plan?.skills &&
					report?.next_plan?.skills.length > 0
				"
			>
				<div>
					<div
						v-for="(goal, index) in report?.next_plan?.skills"
						:key="index"
						class="mb-10"
					>
						<div
							class="font-semibold text-lg text-neutral-600 mb-4"
						>
							{{ index + 1 }}. {{ goal.skill.name }} ({{
								goal.goals.length
							}})
						</div>
						<div class="shadow-sm sm:rounded-2xl overflow-hidden">
							<div
								class="
									__record__table
									overflow-x-auto
									scroll-bar
								"
							>
								<div
									class="
										overflow-hidden
										sm:rounded-t-2xl
										w-fit
										min-w-full
									"
								>
									<table
										class="
											min-w-full
											divide-y
											text-neutral-200
										"
									>
										<thead class="bg-neutral-100 h-16">
											<tr>
												<th
													class="
														px-6
														py-3
														text-left text-xs
														font-semibold
														text-neutral-600
														uppercase
														tracking-wider
														w-16
														whitespace-nowrap
													"
													scope="col"
												>
													STT
												</th>
												<th
													class="
														px-6
														py-3
														text-left text-xs
														font-semibold
														text-neutral-600
														uppercase
														tracking-wider
														whitespace-nowrap
													"
													scope="col"
													style="min-width: 160px"
												>
													Nội dung
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="(g, i) in goal.goals"
												:key="g.id"
												:class="
													i % 2 === 0
														? 'bg-white'
														: 'bg-neutral-50'
												"
												class="text-neutral-700"
											>
												<td
													class="
														px-6
														py-4
														whitespace-nowrap
														text-neutral-700
													"
												>
													<div class="ml-2">
														{{ i + 1 }}
													</div>
												</td>
												<td
													class="
														px-6
														py-4
														text-neutral-700
													"
												>
													<div>
														{{
															g.goal_name ||
															g.name
														}}
													</div>
													<div
														v-if="
															(g?.assistance_levels &&
																org.enable_goal_assistance_level) ||
															g?.raw_source
														"
														class="
															flex
															gap-2
															flex-wrap
														"
														style="max-width: 360px"
													>
														<div
															v-if="g?.raw_source"
															class="
																mt-1
																py-0.5
																px-2.5
																rounded-full
																bg-neutral-200
																text-neutral-700
																h-fit
																w-fit
																font-semibold
																shadow-input
																whitespace-nowrap
															"
															style="
																font-size: 10px;
															"
														>
															{{
																g?.display_source
															}}
														</div>
														<template
															v-if="
																g?.assistance_levels &&
																org.enable_goal_assistance_level
															"
															class="flex gap-2"
														>
															<div
																v-for="ass in g?.assistance_levels"
																:key="ass"
															>
																<div
																	class="
																		mt-1
																		py-0.5
																		px-2.5
																		rounded-full
																		bg-pink-100
																		whitespace-nowrap
																		text-neutral-700
																		h-fit
																		w-fit
																		font-semibold
																		shadow-input
																	"
																	style="
																		font-size: 10px;
																	"
																>
																	{{ ass }}
																</div>
															</div>
														</template>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				v-else
				class="w-full h-full flex items-center justify-center flex-col"
			>
				<img
					alt=""
					height="250"
					src="@/assets/images/illustrations/not_found.png"
					width="250"
				/>
				<div class="text-neutral-600 text-center text-lg">
					Chưa có mục tiêu dự kiến
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import dayjs from 'dayjs';

export default {
	name: 'ReportDetailStep4',
	components: { DaIcon },

	props: {
		apply: {
			type: Object,
			required: true,
		},

		report: {
			type: Object,
			required: true,
		},
	},

	watch: {
		apply: {
			deep: true,
			immediate: true,
			handler(value) {
				this.applyDate = value.apply_date;
				this.applyNow = value.apply_now ? 1 : 2;
			},
		},
		applyNow: {
			handler(value) {
				if (value === 2) this.applyDate = this.firstDayOfNextMonth();
			},
		},
	},

	data() {
		return {
			applyNow: 2,
			applyDate: new Date(),
			dateNow:
				'Hôm nay ' + dayjs(new Date()).format('DD/MM/YYYY - hh:mm a'),
			minDate: new Date(new Date().getTime() + 86400000),
			isEdit: false,
		};
	},

	computed: {
		org() {
			return this.$store.state.profile.current_org;
		},

		applyDateText() {
			return dayjs(this.applyDate).format('DD/MM/YYYY');
		},

		errorApplyDate() {
			return dayjs(this.applyDate).endOf('day').isBefore(new Date());
		},
	},

	methods: {
		done() {
			this.isEdit = false;
			this.$emit('submit', {
				applyNow: this.applyNow === 1,
				applyDate:
					this.applyNow === 1
						? dayjs().format('YYYY-MM-DD')
						: dayjs(this.applyDate).format('YYYY-MM-DD'),
			});
		},
		firstDayOfNextMonth() {
			let today = new Date();
			let year = today.getFullYear();
			let month = today.getMonth();
			let nextMonth = month + 1;
			if (nextMonth === 12) {
				nextMonth = 0;
				year += 1;
			}
			return new Date(year, nextMonth, 1);
		},
	},
};
</script>
