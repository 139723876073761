<template>
	<div class="_modal bg-white" style="width: 984px">
		<div
			class="
				_modal__content
				md-down:px-4
				p-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div class="w-full text-neutral-700">
				<div class="flex flex-wrap gap-10 justify-between">
					<div>
						<div>{{ goal?.skill?.name }}</div>
						<div class="font-semibold" v-html="goal?.name">
						</div>
					</div>
					<div class="flex items-center md-down:flex-col">
						<div class="relative w-60">
							<apexchart
								v-if="studentResultCount?.length"
								:options="chartOptions"
								:series="studentResultCount.map((i) => i.value)"
								height="220"
								type="donut"
							></apexchart>
							<div
								class="
									text-center
									absolute
									top-1/2
									left-1/2
									transform
								"
								style="transform: translate(-50%, -50%)"
							>
								<div
									class="text-4xl font-bold text-neutral-600"
								>
									{{ number_of_student }}
								</div>
								<div class="text-neutral-500">học sinh</div>
							</div>
						</div>
						<div
							class="
								flex flex-col
								justify-center
								md-down:mt-4
								w-fit
							"
						>
							<div
								v-for="(count, index) in studentResultCount"
								:key="index"
								:class="{
									hidden: count?.value <= 0,
								}"
								class="flex flex-nowrap mb-4"
							>
								<span
									class="font-semibold text-neutral-400 mr-6"
									>{{ count?.value }} HS</span
								>
								<span
									:style="{
										background: `${colors[index]}`,
									}"
									class="
										flex
										items-center
										justify-center
										py-0.5
										rounded-full
										text-sm
										font-medium
										text-white
										w-32
										whitespace-nowrap
										shadow-input
									"
								>
									{{ chartOptions.labels[index] }}
								</span>
							</div>
						</div>
					</div>
				</div>
				<div class="h-full w-full mt-6">
					<textarea
						v-model="note"
						placeholder="Nhận xét của giáo viên về mục tiêu này của lớp trẻ"
						style="height: 100%; resize: none; min-height: 200px"
					></textarea>
				</div>
				<div class="flex items-center justify-between mt-6">
					<div class="font-semibold text-neutral-400">Ghi chú</div>
					<div class="text-right">
						{{ goal?.assessment_note_text }}
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				type="button"
				@click="$emit('close')"
			>
				Quay lại
			</button>
			<button
				:disabled="!enableUpdateButton"
				class="button-p text-center whitespace-nowrap"
				type="button"
				@click="submit"
			>
				Xác nhận
			</button>
		</div>
	</div>
</template>

<script>
import { $api } from '@/services';

export default {
	name: 'UpdateNoteModal',

	props: {
		isOpen: {
			type: Boolean,
			required: true,
		},

		group_class_id: {
			type: Number,
			required: true,
		},

		monthly_report_id: {
			type: Number,
			required: true,
		},

		goal: {
			type: Object,
			required: true,
		},
	},

	computed: {
		enableUpdateButton() {
			return this.goal.note !== this.note;
		},

		studentResultCount() {
			const result = [
				{
					value: 0,
					students: [],
				},
				{
					value: 0,
					students: [],
				},
				{
					value: 0,
					students: [],
				},
				{
					value: 0,
					students: [],
				},
				{
					value: 0,
					students: [],
				},
			];
			this.data?.forEach((item) => {
				if (+item?.complete_percent >= 75) {
					result[0].value += 1;
					result[0].students.push(item.student_id);
				} else if (+item?.complete_percent >= 50) {
					result[1].value += 1;
					result[1].students.push(item.student_id);
				} else if (+item?.complete_percent >= 25) {
					result[2].value += 1;
					result[2].students.push(item.student_id);
				} else if (+item?.complete_percent >= 5) {
					result[3].value += 1;
					result[3].students.push(item.student_id);
				} else {
					result[4].value += 1;
					result[4].students.push(item.student_id);
				}
			});
			return result;
		},
	},

	watch: {
		isOpen: {
			immediate: true,
			handler(val) {
				if (val) {
					this.fetchGroupGoalDetail();
				}
			},
		},

		goal: {
			immediate: true,
			deep: true,
			handler(val) {
				this.note =
					val?.student_assessment_group_monthly_report?.note ?? '';
			},
		},
	},

	data() {
		return {
			colors: ['#2AA797', '#65A30D', '#F59E0B', '#F87171', '#9CA3AF'],
			data: [],
			assessment: {
				id: '',
			},
			studentAssessment: null,
			note: '',
			number_of_student: 0,
			chartOptions: {
				dataLabels: {
					enabled: false,
				},
				colors: ['#7AD2C6', '#93BF56', '#F8BB54', '#F47C7C', '#E5E7EB'],
				labels: [
					'Đạt 80 - 100%',
					'Đạt 55 - 75%',
					'Đạt 30 - 50%',
					'Đạt 5 - 25%',
					'Từ chối',
				],
				legend: {
					show: false,
				},
				tooltip: {
					fillSeriesColor: true,
					y: {
						formatter: function (value) {
							return value + ' HS';
						},
					},
				},
				stroke: {
					lineCap: 'round',
				},
			},
		};
	},

	methods: {
		async submit() {
			try {
				await $api.monthlyReport.updateAssessmentReportsGroup(
					`${this.group_class_id}`,
					`${this.monthly_report_id}`,
					this.goal?.student_assessment_group_monthly_report?.id,
					{
						group_goal_id: this.goal?.id,
						note: this.note,
						student_id: this.goal?.student_id,
					}
				);
				await this.$store.dispatch('setAlertMessage', {
					message: 'Cập nhật nhận xét mục tiêu thành công',
				});
				this.$emit('submit');
			} catch (err) {
				const error =
					err?.response?.data?.errors?.join(<br />) ||
					err?.message ||
					'Đã có lỗi xảy ra. Vui lòng thử lại';
				await this.$store.dispatch('setAlertMessage', {
					message: error,
					type: 'danger',
				});
			}
		},

		async fetchGroupGoalDetail() {
			this.isLoading = true;
			try {
				const {
					data: { data, assessment_group_monthly_report },
				} = await $api.monthlyReport.fetchGroupGoalDetail(
					`${this.group_class_id}`,
					`${this.monthly_report_id}`,
					this.goal?.id
				);
				this.assessment = assessment_group_monthly_report;
				this.number_of_student = data?.length;
				this.data = data;
			} catch (e) {
				console.log(e);
			} finally {
				this.isLoading = false;
			}
		},
	},
};
</script>
