
import { defineComponent } from 'vue';
import { $api } from '@/services';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import UpdateCalenderDaycareStudent from '@/views/daycareClassDetail/features/UpdateCalenderDaycareStudent.vue';
import Loading from '@/components/common/Loading.vue';

export default defineComponent({
	name: 'DaycareClassDetail',
	components: { Loading, UpdateCalenderDaycareStudent, DaModalFrame },
	data() {
		return {
			classDetail: {
				id: 0,
				name: '',
				number_of_staffs: 0,
				number_of_students: 0,
			},
			students: [],
			studentSelected: null,
			class_day_slots: {} as any,
			student_class_day_slots: [],
			modalIsOpen: false,
			isLoading: true,
		};
	},

	computed: {
		id(): string {
			return String(this.$route.params.id);
		},
		total(): number {
			return (this as any).classDetail?.number_of_students < 10
				? '0' + (this as any).classDetail?.number_of_students
				: (this as any).classDetail?.number_of_students;
		},
		totalTeacher(): number {
			return (this as any).classDetail?.number_of_staffs < 10
				? '0' + (this as any).classDetail?.number_of_staffs
				: (this as any).classDetail?.number_of_staffs;
		},
		dayOfWeekDisplay(): string {
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			return this.class_day_slots[this.classDetail.id]
				?.map((item: any) => (item !== 7 ? `T${item + 1}` : 'Chủ nhật'))
				.join(', ');
		},
	},

	created() {
		this.fetchBoardings();
		this.fetchStudentInClass();
	},

	methods: {
		updatedCalender() {
			this.modalIsOpen = false;
			this.studentSelected = null;
			this.fetchBoardings();
			this.fetchStudentInClass();
		},

		async fetchStudentInClass() {
			try {
				const {
					data: { data, class_data, student_class_day_slots },
				} = await $api.boarding.getStudentInClass(this.id);
				this.classDetail = class_data;
				this.students = data;
				this.student_class_day_slots = student_class_day_slots;
			} catch (e) {
				console.log(e);
			}
		},

		async fetchBoardings() {
			try {
				const {
					data: { class_day_slots },
				} = await $api.boarding.getDayCareClasses();
				this.class_day_slots = class_day_slots;
			} catch (e) {
				console.log(e);
			} finally {
				this.isLoading = false;
			}
		},

		updateCalender(data: any) {
			this.studentSelected = data;
			this.modalIsOpen = true;
		},

		getDaysData(student_id: number): any {
			const day: any = this.student_class_day_slots.find((item: any) => {
				return item.student_id == student_id;
			});
			return day?.day_of_week || [];
		},

		getDays(student_id: number): any {
			const day: any = this.student_class_day_slots.find((item: any) => {
				return item.student_id == student_id;
			});
			return day?.day_of_week
				? day?.day_of_week
						?.map((item: any) =>
							item !== 7 ? `T${item + 1}` : 'Chủ nhật'
						)
						.join(', ')
				: 'Mặc định';
		},
	},
});
