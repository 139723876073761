
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useStudent } from '@/features/student/useStudent';
import { useRoute, useRouter } from 'vue-router';
import { _ROUTER_NAME } from '@/enums';
import Multiselect from '@suadelabs/vue3-multiselect';
import PlanFutureSlideOver from '@/components/modules/reportTargetLib/PlanFutureSlideOver.vue';

export default defineComponent({
	name: 'CreateGoalsFuture',
	components: {
		PlanFutureSlideOver,
		Multiselect,
	},
	setup() {
		const id = ref('');
		const router = useRouter();
		const student: any = ref(null);
		const isSlideOpen = ref(true);
		const isUpdated = ref(false);
		const refreshTime = ref(new Date().getTime());
		const tab = ref({
			label: '',
			value: '',
		} as any);
		const tabs = ref([
			{
				label: 'Mục tiêu mới',
				value: 'plans_report_detail__goal_create_lib__create',
			},
			{
				label: 'Thư viện nội bộ',
				value: 'create_goal_by_lib',
			},
		]);
		const route = useRoute();
		id.value = route.params.id ? route.params.id + '' : '';
		const { getStudent } = useStudent();

		watch(route, () => {
			const name = route?.name;
			tab.value = tabs.value.find((item) => item.value == name);
		});

		watch(tab, (value: { value: string }) => {
			if (value) {
				router.push({ name: value.value });
			}
		});

		onMounted(() => {
			const name = route?.name;
			tab.value = tabs.value.find((item) => item.value == name);

			isSlideOpen.value = window.screen.width >= 1024;

			window.scrollTo(0, 0);
		});

		async function fetchStudent() {
			const res = await getStudent(id.value);
			if (res?.data) {
				student.value = res?.data;
			}
		}

		fetchStudent();

		function back() {
			router.push(`/plans/${id.value}/goal-bank`);
		}

		const isCompleted = ref(true);

		function openKHDK() {
			isSlideOpen.value = true;
			isCompleted.value = true;
			isUpdated.value = false;
		}

		function openMTHT() {
			isSlideOpen.value = true;
			isCompleted.value = false;
			isUpdated.value = false;
		}

		function onUpdated() {
			isUpdated.value = true;
		}

		function refresh() {
			// TODO:
		}

		return {
			isUpdated,
			openKHDK,
			openMTHT,
			isCompleted,
			isSlideOpen,
			tab,
			tabs,
			student,
			routerName: computed(() => _ROUTER_NAME),
			refreshTime,
			back,
			onUpdated,
			refresh,
		};
	},
});
