
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import Rating from '@/components/elements/rating/Rating.vue';
import { Options, Vue, setup } from 'vue-class-component';
import { useStore } from 'vuex';
import DaInfinityLoading from '@/components/common/daInfinityLoading/DaInfinityLoading.vue';
import { useStudent } from '@/features/student/useStudent';
import Loading from '@/components/common/Loading.vue';
import Modal from '@/views/report/features/plans/Modal.vue';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import { _ROUTER_NAME } from '@/enums';
import SlideOverPlans from '@/views/report/features/plans/SlideOverPlans.vue';
import { $api } from '@/services';
import { Watch } from 'vue-property-decorator';

@Options({
	name: 'ReportPlans',
	computed: {
		_ROUTER_NAME() {
			return _ROUTER_NAME;
		},
	},
	components: {
		SlideOverPlans,
		DaModalFrame,
		Modal,
		Loading,
		Rating,
		DaIcon,
		DaInfinityLoading,
	},
})
export default class ReportPlans extends Vue {
	students: any = [];
	groupClasses: any = [];
	sideOverShow = false;
	payload: any = null;
	alertShow = true;
	tabs: any = [
		{
			id: 0,
			name: 'Học cá nhân',
			icon: 'das--book',
			link: _ROUTER_NAME.REPORT_PLANS_CLASS,
		},
		{
			id: 1,
			name: 'Học nhóm',
			icon: 'das--users',
			link: _ROUTER_NAME.REPORT_PLANS_GROUP,
		},
	];

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const store = useStore();
		const { getStudents } = useStudent();
		return {
			store,
			getStudents,
		};
	});

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get isAdmin() {
		return this.setup.store.getters.isAdmin;
	}

	get store() {
		return this.setup.store;
	}

	@Watch('$route.name', {
		immediate: true,
		deep: true,
	})
	onRouteChanged() {
		if (Object.keys(this.$route.params).length > 0) return;
		if (this.$route.name === _ROUTER_NAME.REPORT_PLANS_CLASS) {
			this.fetchStudents();
		} else {
			this.fetchGroupClasses(this.isAdmin);
		}
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	clean(obj: any) {
		for (let propName in obj) {
			if (
				obj[propName] === null ||
				obj[propName] === undefined ||
				obj[propName].length <= 0
			) {
				delete obj[propName];
			}
		}
		return obj;
	}

	async fetchStudents() {
		let role =
			this.store.state.profile.staff.role === 'teacher'
				? 'staff'
				: 'org_admin';
		const studentList = await this.setup.getStudents(role, {
			state: 'active',
		});
		this.students = studentList.data;
	}

	async fetchGroupClasses(isAdmin: any) {
		try {
			const {
				data: { data },
			} = await $api.groupClasses.list({}, isAdmin);
			this.groupClasses = data.filter((i: any) => i.enabled);
		} catch (e) {
			await this.store.dispatch('setAlertMessage', {
				message:
					e?.response?.data?.errors?.join('<br/>') ||
					'Đã có lỗi xảy ra. Vui lòng thử lại',
				type: 'danger',
			});
		}
	}

	filterSubmit(value: any) {
		this.payload = value;
		this.clean(value);
		this.$router.push({
			query: value,
		});
		this.sideOverShow = false;
	}
}
