<template>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 w-screen md:w-2/5">
		<div class="h-full w-full flex flex-col bg-white shadow-xl">
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center" @click="$emit('close')">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM8.03033 6.96967C7.73744 6.67678 7.26256 6.67678 6.96967 6.96967C6.67678 7.26256 6.67678 7.73744 6.96967 8.03033L10.9393 12L6.96967 15.9697C6.67678 16.2626 6.67678 16.7374 6.96967 17.0303C7.26256 17.3232 7.73744 17.3232 8.03033 17.0303L12 13.0607L15.9697 17.0303C16.2626 17.3232 16.7374 17.3232 17.0303 17.0303C17.3232 16.7374 17.3232 16.2626 17.0303 15.9697L13.0607 12L17.0303 8.03033C17.3232 7.73744 17.3232 7.26256 17.0303 6.96967C16.7374 6.67678 16.2626 6.67678 15.9697 6.96967L12 10.9393L8.03033 6.96967Z"
								fill="#94A3B8"
							/>
						</svg>

						<div
							class="
								text-2xl
								font-bold
								text-neutral-700
								ml-2
								mt-1
							"
						>
							Báo nghỉ
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							type="button"
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div
				class="
					sideover-content
					pt-6
					relative
					flex-1
					px-4
					sm:px-6
					overflow-y-auto
				"
			>
				<div>
					<div
						class="
							mt-4
							space-y-4
							sm:flex sm:items-center sm:space-y-0 sm:space-x-10
						"
					>
						<div class="flex items-center">
							<input
								name="notification-method"
								type="radio"
								class="
									h-4
									w-4
									text-yellow-600
									border-neutral-400 border-2
									focus:ring-yellow-600
								"
								:value="true"
								v-model="student_on_leave"
							/>
							<label
								@click="student_on_leave = !student_on_leave"
								class="pl-3 block font-medium text-neutral-700"
							>
								Học sinh nghỉ
							</label>
						</div>
						<div class="flex items-center">
							<input
								name="notification-method"
								type="radio"
								class="
									h-4
									w-4
									text-yellow-600
									border-neutral-400 border-2
									focus:ring-yellow-600
								"
								:value="false"
								v-model="student_on_leave"
							/>
							<label
								@click="student_on_leave = !student_on_leave"
								class="pl-3 block font-medium text-neutral-700"
							>
								Trị liệu viên nghỉ
							</label>
						</div>
					</div>

					<div>
						<div class="font-semibold text-neutral-600 mt-10">
							Ghi chú*
						</div>
						<div class="w-full mt-2">
							<div class="input-group flex items-center">
								<textarea
									v-model="note"
									placeholder="Ghi chú về sự thay đổi này"
								>
								</textarea>
							</div>
						</div>
					</div>

					<div
						class="mt-6 flex flex-nowrap items-center"
						v-if="student_on_leave"
					>
						<Checkbox v-model:value="is_off_daycare" />
						<div class="ml-2 text-sm text-neutral-700">
							Học sinh xin phép nghỉ học bán trú những ngày trên
						</div>
					</div>
				</div>
				<div class="mt-6" :id="`list-dropdown-1`">
					<div
						class="
							bg-neutral-50
							flex
							items-center
							justify-between
							p-3
							border
							rounded-t
							cursor-pointer
						"
						@click="openList(1)"
					>
						<div>Số buổi đã chọn</div>
						<div class="relative">
							<div class="pr-10">{{ data.length }} buổi</div>
							<div
								class="
									absolute
									top-1/2
									right-0
									transform
									-translate-y-1/2
									transition-all
									duration-200
								"
								:id="`list-dropdown-1-icon`"
							>
								<svg
									width="12"
									height="6"
									viewBox="0 0 12 6"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10.5 5.25L6 0.75L1.5 5.25"
										stroke="#48566A"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div
						class="px-4 border border-t-0 rounded-b"
						:id="`list-dropdown-1-content`"
						v-if="data"
					>
						<div
							v-for="(item, index) in data"
							:key="index"
							class="py-4"
						>
							<div class="flex items-center justify-between">
								<div
									class="flex items-center w-1/3"
									v-if="getStudent(item.student_id)"
								>
									<div
										@click.stop="
											$router.push(
												`/record/student/${item.student_id}`
											)
										"
										class="flex items-center"
										v-if="
											item.student_id &&
											getStudent(item.student_id)
										"
									>
										<div>
											<img
												class="
													shadow-sm
													inline-block
													h-9
													w-9
													rounded-full
												"
												:src="
													getStudent(item.student_id)
														?.profile_photo?.w200
												"
												alt=""
												v-if="
													getStudent(item.student_id)
														?.profile_photo?.w200
												"
											/>
											<span
												v-else
												class="
													inline-flex
													items-center
													justify-center
													h-9
													w-9
													rounded-full
												"
												:style="{
													background: getStudent(
														item.student_id
													)?.profile_photo?.default
														?.color,
												}"
											>
												<span
													class="
														font-medium
														leading-none
														text-white text-xs
													"
													>{{
														getStudent(
															item.student_id
														)?.profile_photo
															?.default?.initial
													}}</span
												>
											</span>
										</div>
										<div
											class="
												ml-4
												text-yellow-600
												font-semibold
												truncate
											"
										>
											{{
												getStudent(item.student_id).name
											}}
										</div>
									</div>
								</div>
								<div class="w-1/3">
									<div
										v-if="item.staff_ids"
										class="flex flex-col gap-2"
									>
										<div
											@click.stop="
												$router.push(
													`/record/employee/${staff_id}`
												)
											"
											class="flex items-center"
											v-for="staff_id in item.staff_ids"
											:key="staff_id"
										>
											<div v-if="getStaff(staff_id)">
												<img
													class="
														shadow-sm
														inline-block
														h-9
														w-9
														rounded-full
													"
													:src="
														getStaff(staff_id)
															?.profile_photo
															?.w200
													"
													alt=""
													v-if="
														getStaff(staff_id)
															?.profile_photo
															?.w200
													"
												/>
												<span
													v-else
													class="
														inline-flex
														items-center
														justify-center
														h-9
														w-9
														rounded-full
													"
													:style="{
														background:
															getStaff(staff_id)
																?.profile_photo
																?.default
																?.color,
													}"
												>
													<span
														class="
															font-medium
															leading-none
															text-white text-xs
														"
														>{{
															getStaff(staff_id)
																?.profile_photo
																?.default
																?.initial
														}}</span
													>
												</span>
											</div>
											<div
												v-if="getStaff(staff_id)"
												class="
													ml-4
													text-blue-600
													font-semibold
												"
											>
												{{ getStaff(staff_id).name }}
											</div>
										</div>
									</div>
								</div>
								<div class="text-gray-600">
									<span v-if="item.date">
										{{ $filters.formatDate2(item.date) }}
									</span>
									<span>
										- {{ item.display_start_slot }}</span
									>
									<span> - {{ item.display_end_slot }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="px-6 py-4 border-t border-yellow-50">
				<div class="flex justify-end">
					<div>
						<button
							type="button"
							class="button-p text-center px-6"
							@click="submit"
							:disabled="!isValidate || inProgress"
						>
							Xác nhận
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import { $api } from '@/services';
import Checkbox from '@/components/elements/checkbox/Checkbox';

export default defineComponent({
	name: 'TakeLeaveForm',

	components: { Checkbox },

	props: {
		data: {
			type: Array,
			required: true,
		},

		staffs_map: {
			type: Object,
			required: true,
		},

		students_map: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			date: new Date(),
			note: '',
			student_on_leave: true,
			is_off_daycare: false,
			inProgress: false,
		};
	},

	computed: {
		isValidate() {
			return !!this.note.length;
		},
	},

	methods: {
		async submit() {
			this.inProgress = true;
			try {
				if (this.data.length > 1) {
					const payload = this.data.map((item) => {
						return item.id
							? {
									id: item.id,
									date: item.date,
									student_id: item.student_id,
									// eslint-disable-next-line no-mixed-spaces-and-tabs
							  }
							: {
									date: item.date,
									student_id: item.student_id,
									time_slot_id: item.time_slot_id,
									// eslint-disable-next-line no-mixed-spaces-and-tabs
							  };
					});
					await $api.schedule.cancelSchedule({
						ids: payload,
						schedule_session: {
							leave_note: this.note,
							student_on_leave: this.student_on_leave,
						},
						cancel_student_class_session: this.is_off_daycare,
					});
				} else {
					const payload = this.data[0].id
						? { id: this.data[0].id }
						: {
								date: this.data[0].date,
								time_slot_id: this.data[0].time_slot_id,
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  };
					await $api.schedule.cancelScheduleOne(
						payload,
						{
							schedule_session: {
								leave_note: this.note,
								student_on_leave: this.student_on_leave,
							},
							cancel_student_class_session: this.is_off_daycare,
							date: this.data[0].date,
						},
						this.data[0].student_id
					);
				}
				await this.$store.dispatch('setAlertMessage', {
					message: 'Báo nghỉ thành công',
				});
				this.$emit('success');
			} catch (e) {
				this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		openList(index) {
			const el = document.getElementById(
				`list-dropdown-${index}-content`
			);
			const icon = document.getElementById(`list-dropdown-${index}-icon`);
			if (el) {
				if (el.classList.contains('hidden')) {
					el.classList.remove('hidden');
					icon?.classList.add('rotate-180');
				} else {
					el.classList.add('hidden');
					icon?.classList.remove('rotate-180');
				}
			}
		},

		getStaff(staff_id) {
			return this.staffs_map[staff_id]?.[0];
		},

		getStudent(student_id) {
			return this.students_map[student_id]?.[0];
		},
	},
});
</script>
