<template>
	<DaModalFrame v-if="isUploadTimeTableGroupClass" v-model:open="modalIsOpen">
		<ChooseGroupClass
			@close="modalIsOpen = false"
			@submit="updateTimeTableGroupClass"
		/>
	</DaModalFrame>
	<DaModalFrame v-else v-model:open="modalIsOpen">
		<ChooseTargetUploading
			@close="modalIsOpen = false"
			@submit="uploadTimeTable"
		/>
	</DaModalFrame>
	<div :class="isShow && !isUploadingForStudent ? 'side-over--active' : ''">
		<div
			v-if="isShow && !isUploadingForStudent"
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			@click="isShow = false"
		></div>
		<StatisticalTimeTable id="" @close="isShow = false" />
	</div>
	<div :class="isShow && isUploadingForStudent ? 'side-over--active' : ''">
		<div
			v-if="isShow && isUploadingForStudent"
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			@click="isShow = false"
		></div>
		<UploadTimeTableStudent
			id=""
			:student="studentSelected"
			@close="isShow = false"
		/>
	</div>
	<div :class="isShowUpload ? 'side-over--active' : ''">
		<div
			v-if="isShowUpload"
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			@click="isShowUpload = false"
		></div>
		<UploadTimeTable
			:is-edit="false"
			:is-show="isShowUpload"
			@close="isShowUpload = false"
			@success="success"
		/>
	</div>
	<div class="__record px-10 pb-30 md-down:px-4">
		<div
			class="
				__record__header
				pt-10
				pb-6
				flex flex-nowrap
				justify-between
				items-center
				md-down:flex-col md-down:items-start
				gap-10
				md-down:gap-4
			"
		>
			<div class="flex flex-nowrap items-center w-full justify-between">
				<div
					class="
						__record__title
						text-neutral-500
						font-semibold
						whitespace-nowrap
						flex flex-nowrap
						items-center
						text-4xl
						cursor-pointer
					"
				>
					<DaIcon class="mr-3 h-6 w-6" name="das--calender" />
					<span>Thời khoá biểu</span>
				</div>
				<Menu as="div" class="ml-3 relative md:hidden">
					<div>
						<MenuButton
							class="
								rounded-full
								flex
								text-sm
								focus:outline-none
								focus:ring-2
								focus:ring-offset-2
								focus:ring-indigo-500
							"
						>
							<span class="sr-only">Open user menu</span>
							<svg
								fill="none"
								height="20"
								viewBox="0 0 20 20"
								width="20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									clip-rule="evenodd"
									d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
									fill="#94A3B8"
									fill-rule="evenodd"
								/>
							</svg>
						</MenuButton>
					</div>
					<transition
						enter-active-class="transition ease-out duration-200"
						enter-from-class="transform opacity-0 scale-95"
						enter-to-class="transform opacity-100 scale-100"
						leave-active-class="transition ease-in duration-75"
						leave-from-class="transform opacity-100 scale-100"
						leave-to-class="transform opacity-0 scale-95"
					>
						<MenuItems
							class="
								origin-top-right
								absolute
								right-0
								mt-2
								w-60
								rounded-md
								py-1
								bg-white
								focus:outline-none
								z-10
								shadow-sm
								border border-yellow-100
							"
						>
							<MenuItem
								v-slot="{ active }"
								@click="
									$router.push('/schedule/timetable-history')
								"
							>
								<div
									:class="active ? '' : ''"
									class="
										p-2
										px-4
										bg-white
										z-10
										text-gray-600
										hover:bg-neutral-50
										flex
										items-center
										gap-4
										cursor-pointer
									"
								>
									<svg
										fill="none"
										height="16"
										viewBox="0 0 16 16"
										width="16"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											clip-rule="evenodd"
											d="M4 0.5C4 0.223858 3.77614 0 3.5 0C3.22386 0 3 0.223858 3 0.5V1H2C0.895431 1 0 1.89543 0 3V4H16V3C16 1.89543 15.1046 1 14 1H13V0.5C13 0.223858 12.7761 0 12.5 0C12.2239 0 12 0.223858 12 0.5V1H4V0.5ZM16 14V5H0V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14ZM9.5 7C9.22386 7 9 7.22386 9 7.5V8.5C9 8.77614 9.22386 9 9.5 9H10.5C10.7761 9 11 8.77614 11 8.5V7.5C11 7.22386 10.7761 7 10.5 7H9.5ZM12.5 7C12.2239 7 12 7.22386 12 7.5V8.5C12 8.77614 12.2239 9 12.5 9H13.5C13.7761 9 14 8.77614 14 8.5V7.5C14 7.22386 13.7761 7 13.5 7H12.5ZM2 10.5C2 10.2239 2.22386 10 2.5 10H3.5C3.77614 10 4 10.2239 4 10.5V11.5C4 11.7761 3.77614 12 3.5 12H2.5C2.22386 12 2 11.7761 2 11.5V10.5ZM5.5 10C5.22386 10 5 10.2239 5 10.5V11.5C5 11.7761 5.22386 12 5.5 12H6.5C6.77614 12 7 11.7761 7 11.5V10.5C7 10.2239 6.77614 10 6.5 10H5.5Z"
											fill="#94A3B8"
											fill-rule="evenodd"
										/>
									</svg>
									<span class="mt-0.5">Lịch sử TKB</span>
								</div>
							</MenuItem>
							<MenuItem
								v-slot="{ active }"
								@click="updateTimeTable"
							>
								<div
									:class="active ? '' : ''"
									class="
										p-2
										px-4
										bg-white
										z-10
										text-gray-600
										hover:bg-neutral-50
										flex
										items-center
										gap-4
										cursor-pointer
									"
								>
									<svg
										fill="none"
										height="16"
										viewBox="0 0 16 16"
										width="16"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											clip-rule="evenodd"
											d="M12.4705 6.65175L13.7105 5.41172C13.8463 5.27586 13.9143 5.20793 13.9669 5.14597C14.6011 4.39929 14.6011 3.30311 13.9669 2.55644C13.9143 2.49448 13.8463 2.42655 13.7105 2.29069C13.5746 2.15482 13.5067 2.08689 13.4447 2.03426C12.6981 1.40004 11.6019 1.40004 10.8552 2.03426C10.7933 2.08688 10.7253 2.15479 10.5895 2.29059L10.5895 2.29067L10.5894 2.29069L9.33123 3.5489C10.0749 4.84309 11.1586 5.91837 12.4705 6.65175ZM7.87657 5.00356L2.86297 10.0172C2.43791 10.4422 2.22538 10.6547 2.08565 10.9158C1.94591 11.1769 1.88697 11.4717 1.76908 12.0611L1.37342 14.0394C1.3069 14.372 1.27364 14.5383 1.36825 14.6329C1.46286 14.7275 1.62916 14.6943 1.96177 14.6277L3.94005 14.2321L3.94007 14.2321L3.94009 14.2321C4.52951 14.1142 4.82423 14.0553 5.08532 13.9155C5.34641 13.7758 5.55894 13.5633 5.98398 13.1382L5.984 13.1382L5.98401 13.1382L11.012 8.11019C9.75135 7.31198 8.68226 6.25011 7.87657 5.00356Z"
											fill="#94A3B8"
											fill-rule="evenodd"
										/>
									</svg>

									<span class="mt-0.5">Chỉnh sửa</span>
								</div>
							</MenuItem>
						</MenuItems>
					</transition>
				</Menu>
			</div>
			<div
				v-if="!is_old_data"
				class="
					__record__intro
					flex flex-nowrap
					items-center
					justify-between
				"
			>
				<div class="search-input w-ful relative" style="width: 350px">
					<div
						v-if="
							$route.name ===
							_ROUTER_NAME.SCHEDULE_TIMETABLE_GROUP_CLASSES
						"
						class="search-input w-92"
					>
						<div class="input-group input--xl">
							<input
								v-model="searchQuery"
								class="bg-white input-with-icon"
								placeholder="Tìm theo lớp nhóm"
								type="text"
								@keyup="searching"
								@keypress.enter="search"
							/>
							<div class="input-icon absolute top-1/2">
								<DaIcon
									class="h-4 w-4 text-neutral-400"
									name="das--search"
								/>
							</div>
						</div>
					</div>
					<div v-else class="bg-white select-tabs w-full">
						<Multiselect
							v-model="studentSelected"
							:options="
								students?.map((s) => {
									return {
										nameSearch: toEnglish(s?.name),
										...s,
									};
								})
							"
							:searchable="true"
							deselect-label="Bỏ chọn"
							label="nameSearch"
							placeholder="Tìm kiếm theo học sinh"
							selectLabel=""
							track-by="id"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									{{ props.option.name }}
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									{{ option.name }}
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<Menu as="div" class="ml-3 relative md-down:hidden">
					<div>
						<MenuButton
							class="
								rounded-full
								flex
								text-sm
								focus:outline-none
								focus:ring-2
								focus:ring-offset-2
								focus:ring-indigo-500
							"
						>
							<span class="sr-only">Open user menu</span>
							<svg
								fill="none"
								height="20"
								viewBox="0 0 20 20"
								width="20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									clip-rule="evenodd"
									d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
									fill="#94A3B8"
									fill-rule="evenodd"
								/>
							</svg>
						</MenuButton>
					</div>
					<transition
						enter-active-class="transition ease-out duration-200"
						enter-from-class="transform opacity-0 scale-95"
						enter-to-class="transform opacity-100 scale-100"
						leave-active-class="transition ease-in duration-75"
						leave-from-class="transform opacity-100 scale-100"
						leave-to-class="transform opacity-0 scale-95"
					>
						<MenuItems
							class="
								origin-top-right
								absolute
								right-0
								mt-2
								w-60
								rounded-md
								py-1
								bg-white
								focus:outline-none
								z-10
								shadow-sm
								border border-yellow-100
							"
						>
							<MenuItem
								v-if="
									$route.name ===
									_ROUTER_NAME.SCHEDULE_TIMETABLE_CLASS
								"
								v-slot="{ active }"
								@click="
									$router.push('/schedule/timetable-history')
								"
							>
								<div
									:class="active ? '' : ''"
									class="
										p-2
										px-4
										bg-white
										z-10
										text-gray-600
										hover:bg-neutral-50
										flex
										items-center
										gap-4
										cursor-pointer
									"
								>
									<svg
										fill="none"
										height="16"
										viewBox="0 0 16 16"
										width="16"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											clip-rule="evenodd"
											d="M4 0.5C4 0.223858 3.77614 0 3.5 0C3.22386 0 3 0.223858 3 0.5V1H2C0.895431 1 0 1.89543 0 3V4H16V3C16 1.89543 15.1046 1 14 1H13V0.5C13 0.223858 12.7761 0 12.5 0C12.2239 0 12 0.223858 12 0.5V1H4V0.5ZM16 14V5H0V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14ZM9.5 7C9.22386 7 9 7.22386 9 7.5V8.5C9 8.77614 9.22386 9 9.5 9H10.5C10.7761 9 11 8.77614 11 8.5V7.5C11 7.22386 10.7761 7 10.5 7H9.5ZM12.5 7C12.2239 7 12 7.22386 12 7.5V8.5C12 8.77614 12.2239 9 12.5 9H13.5C13.7761 9 14 8.77614 14 8.5V7.5C14 7.22386 13.7761 7 13.5 7H12.5ZM2 10.5C2 10.2239 2.22386 10 2.5 10H3.5C3.77614 10 4 10.2239 4 10.5V11.5C4 11.7761 3.77614 12 3.5 12H2.5C2.22386 12 2 11.7761 2 11.5V10.5ZM5.5 10C5.22386 10 5 10.2239 5 10.5V11.5C5 11.7761 5.22386 12 5.5 12H6.5C6.77614 12 7 11.7761 7 11.5V10.5C7 10.2239 6.77614 10 6.5 10H5.5Z"
											fill="#94A3B8"
											fill-rule="evenodd"
										/>
									</svg>
									<span class="mt-0.5">Lịch sử TKB</span>
								</div>
							</MenuItem>
							<MenuItem
								v-slot="{ active }"
								@click="updateTimeTable"
							>
								<div
									:class="active ? '' : ''"
									class="
										p-2
										px-4
										bg-white
										z-10
										text-gray-600
										hover:bg-neutral-50
										flex
										items-center
										gap-4
										cursor-pointer
									"
								>
									<svg
										fill="none"
										height="16"
										viewBox="0 0 16 16"
										width="16"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											clip-rule="evenodd"
											d="M12.4705 6.65175L13.7105 5.41172C13.8463 5.27586 13.9143 5.20793 13.9669 5.14597C14.6011 4.39929 14.6011 3.30311 13.9669 2.55644C13.9143 2.49448 13.8463 2.42655 13.7105 2.29069C13.5746 2.15482 13.5067 2.08689 13.4447 2.03426C12.6981 1.40004 11.6019 1.40004 10.8552 2.03426C10.7933 2.08688 10.7253 2.15479 10.5895 2.29059L10.5895 2.29067L10.5894 2.29069L9.33123 3.5489C10.0749 4.84309 11.1586 5.91837 12.4705 6.65175ZM7.87657 5.00356L2.86297 10.0172C2.43791 10.4422 2.22538 10.6547 2.08565 10.9158C1.94591 11.1769 1.88697 11.4717 1.76908 12.0611L1.37342 14.0394C1.3069 14.372 1.27364 14.5383 1.36825 14.6329C1.46286 14.7275 1.62916 14.6943 1.96177 14.6277L3.94005 14.2321L3.94007 14.2321L3.94009 14.2321C4.52951 14.1142 4.82423 14.0553 5.08532 13.9155C5.34641 13.7758 5.55894 13.5633 5.98398 13.1382L5.984 13.1382L5.98401 13.1382L11.012 8.11019C9.75135 7.31198 8.68226 6.25011 7.87657 5.00356Z"
											fill="#94A3B8"
											fill-rule="evenodd"
										/>
									</svg>

									<span class="mt-0.5">Chỉnh sửa</span>
								</div>
							</MenuItem>
						</MenuItems>
					</transition>
				</Menu>
			</div>
		</div>
		<div class="mb-10" v-if="alertShow">
			<div
				class="bg-yellow-50 border-l-4 border-yellow-600 p-4 rounded-md"
			>
				<div class="flex justify-between gap-4">
					<div class="flex items-center gap-4">
						<div>
							<img
								src="@/assets/images/logo/logo.svg"
								alt="luca-logo"
								height="24"
								width="35"
							/>
						</div>
						<div class="pt-1 text-neutral-600 text-sm">
							Bạn đã thiết lập Thời khoá biểu nhưng chưa bật tính
							năng lịch học. Bật để quản lý lịch học và dạy theo
							thời khóa biểu, cùng với các chức năng quản lý
							chuyên cần như báo nghỉ, đổi giáo viên, tạo buổi học
							phát sinh, v.v. Các báo cáo cũng sẽ được tạo tự động
							theo Thời khóa biểu.
							<span
								@click="$router.push('/setup-center/schedule')"
								class="
									underline
									text-green-500
									font-medium
									cursor-pointer
								"
								target="_blank"
								rel="noopener"
								>Bật tính năng lịch học?</span
							>
						</div>
					</div>
					<div>
						<svg
							width="12"
							height="12"
							viewBox="0 0 12 12"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							class="cursor-pointer"
							@click="alertShow = false"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
								fill="#E5A42B"
							/>
						</svg>
					</div>
				</div>
			</div>
		</div>
		<div class="__record__content">
			<!-- <div class="w-full mb-10">
				<div class="border-b border-neutral-200 md-down:hidden">
					<nav aria-label="Tabs" class="-mb-px flex space-x-8 tabs">
						<router-link
							v-for="tab in tabs"
							:key="tab.id"
							:to="{
								name: tab.link,
							}"
							class="
								border-transparent
								hover:text-yellow-700 hover:border-yellow-500
								text-neutral-500
								whitespace-nowrap
								py-3
								px-1
								border-b-2
								font-semibold
								cursor-pointer
							"
						>
							<div class="flex items-center gap-2">
								<div>
									<DaIcon :name="tab.icon" class="h-4 w-4" />
								</div>
								<div>{{ tab.name }}</div>
							</div>
						</router-link>
					</nav>
				</div>
			</div> -->
			<div>
				<router-view
					v-model:isShow="isShow"
					v-model:isShowUpload="isShowUpload"
					v-model:isUploadingForStudent="isUploadingForStudent"
					v-model:modalIsOpen="modalIsOpen"
					v-model:studentSelected="studentSelected"
					:onRefetch="onRefetch"
					@updateTimeTable="updateTimeTable"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { $api } from '@/services';
import dayjs from 'dayjs';
import Multiselect from '@suadelabs/vue3-multiselect';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { toEnglish } from '@/helper/toEnglish';
import { _ROUTER_NAME } from '@/enums';
import UploadTimeTableStudent from '@/views/timetable/features/UploadTimeTableStudent.vue';
import ChooseTargetUploading from '@/views/timetable/features/ChooseTargetUploading.vue';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import UploadTimeTable from '@/views/timetable/features/UploadTimeTable.vue';
import StatisticalTimeTable from '@/views/timetable/features/StatisticalTimeTable.vue';
import ChooseGroupClass from '@/views/timetable/features/ChooseGroupClass.vue';

export default defineComponent({
	name: 'TimeTableGroupClass',
	components: {
		ChooseGroupClass,
		DaIcon,
		Multiselect,
		Menu,
		MenuItem,
		MenuItems,
		MenuButton,
		UploadTimeTableStudent,
		ChooseTargetUploading,
		DaModalFrame,
		UploadTimeTable,
		StatisticalTimeTable,
	},
	data() {
		return {
			students: [],
			params: {
				date: dayjs().format('YYYY-MM-DD'),
			},
			studentSelected: null,
			modalIsOpen: false,
			isShowUpload: false,
			isShow: false,
			isUploadingForStudent: false,
			onRefetch: 0,
			searchQuery: '',
			alertShow: false,
		};
	},

	created() {
		this.fetchStudents();
		if (this.$route.hash) this.isShowUpload = true;

		if (this.$route.query.hasModal === 'true') {
			this.updateTimeTable();
		}
		if (this.enablePersonalSchedule) this.alertShow = true;
	},

	watch: {
		'$route.name': {
			immediate: true,
			handler(value) {
				if (value === _ROUTER_NAME.SCHEDULE_TIMETABLE_CLASS) {
					this.$router.push({
						...this.$route.query,
						query: {
							hasModal: this.$route.query?.hasModal,
						},
					});
					this.searchQuery = undefined;
				}
			},
		},

		'$route.query.query': {
			immediate: true,
			handler(value) {
				this.searchQuery = value || undefined;
			},
		},
	},

	computed: {
		_ROUTER_NAME() {
			return _ROUTER_NAME;
		},

		isUploadTimeTableGroupClass() {
			return (
				this.$route.name ===
				_ROUTER_NAME.SCHEDULE_TIMETABLE_GROUP_CLASSES
			);
		},

		tabs() {
			return [
				{
					id: 0,
					name: 'Học cá nhân',
					icon: 'das--book',
					link: _ROUTER_NAME.SCHEDULE_TIMETABLE_CLASS,
				},
				{
					id: 1,
					name: 'Học nhóm',
					icon: 'das--users',
					link: _ROUTER_NAME.SCHEDULE_TIMETABLE_GROUP_CLASSES,
				},
			];
		},

		studentId() {
			return this.$route.query.student;
		},

		oldPayload() {
			return {
				'x-cross': this.$route.query.org_id,
			};
		},

		is_old_data() {
			return this.$route.query.is_old_data === 'true';
		},
		enablePersonalSchedule() {
			return (
				this.$store.state.profile.has_timetable &&
				!this.$store.state.profile.current_org.enable_personal_schedule
			);
		},
	},

	methods: {
		toEnglish,

		searching() {
			clearInterval(this.typing);
			this.typing = setTimeout(() => {
				this.search();
			}, 1000);
		},

		search() {
			this.$router.push({
				query: {
					...this.$route.query,
					query: this.searchQuery,
				},
			});
		},

		async fetchStudents() {
			let role =
				this.$store.state.profile.staff.role === 'teacher'
					? 'staff'
					: 'org_admin';
			try {
				const res = await $api.student.getStudents(
					role,
					{},
					this.is_old_data ? this.oldPayload : null
				);
				this.students = res?.data?.data || [];

				this.studentSelected = this.students.find(
					(item) => Number(item.id) === Number(this.studentId)
				);
			} catch (e) {
				console.log(e);
			}
		},

		updateTimeTable() {
			if (this.studentSelected) {
				this.isUploadingForStudent = true;
				this.modalIsOpen = false;
				this.isShow = false;
			} else {
				this.modalIsOpen = true;
			}
			this.$router.push({
				query: {
					...this.$route.query,
					hasModal: undefined,
				},
			});
		},

		uploadTimeTable(value) {
			this.modalIsOpen = false;
			this.isUploadingForStudent = !value;
			if (value) {
				this.isShowUpload = true;
			} else {
				this.isShow = true;
			}
		},

		success() {
			this.isShowUpload = false;
			this.onRefetch++;
		},

		updateTimeTableGroupClass(id) {
			this.$router.push(`/group-classes/${id}`);
		},
	},
});
</script>
<style lang="scss">
#timetable__table {
	scroll-behavior: smooth;
}

.tabs {
	.router-link-exact-active {
		@apply text-yellow-700 border-yellow-500;
	}
}
</style>
