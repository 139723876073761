
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import Rating from '@/components/elements/rating/Rating.vue';
import { Options, setup, Vue } from 'vue-class-component';
import SideOver from '@/components/elements/sideOver/SideOver.vue';
import { useStore } from 'vuex';
import DaInfinityLoading from '@/components/common/daInfinityLoading/DaInfinityLoading.vue';
import SlideOverWeekFilter from '@/views/report/features/week/SlideOverWeekFilter.vue';
import Loading from '@/components/common/Loading.vue';
import { Watch } from 'vue-property-decorator';
import { $api } from '@/services';

@Options({
	name: 'ReportWeek',
	components: {
		Loading,
		SlideOverWeekFilter,
		SideOver,
		Rating,
		DaIcon,
		DaInfinityLoading,
	},
})
export default class ReportWeek extends Vue {
	weeklyReportList: any = [];
	beforeDate = '';
	stopLoad = false;
	isInfinityLoading = false;
	isLoading = true;
	isHides: any = [];
	payload: any = null;

	setup = setup(() => {
		const store = useStore();
		return {
			store,
		};
	});

	get isAdmin() {
		return this.setup.store.getters.isAdmin;
	}

	get weeklyReports() {
		return this.weeklyReportList;
	}

	@Watch('$route.query', {
		deep: true,
		immediate: true,
	})
	onRouteChange(value: any) {
		this.payload = {
			...this.payload,
			state: value?.state,
			student_id: value?.student_id,
			staff_id: value?.staff_id,
			week_start: value?.week_start,
		};
		this.weeklyReportList = [];
		this.beforeDate = '';
		this.payload = {
			...this.payload,
			before: undefined,
		};
		this.getWeeklyReports();
	}

	showHide(date: any) {
		const index = this.isHides.indexOf(date);
		if (index !== -1) {
			this.isHides.splice(index, 1);
		} else {
			this.isHides.push(date);
		}
	}

	async getWeeklyReports() {
		const { data } = await $api.weeklyReport.weeklyReportsListGroup(
			this.beforeDate
				? {
						...this.payload,
						before: this.beforeDate,
				  }
				: this.payload
		);

		this.isInfinityLoading = true;
		if (data?.data?.length) {
			this.weeklyReportList.push({
				date: data?.data[data?.data?.length - 1]?.week_start,
				data: data?.data,
				stats: data?.stats,
			});
			this.beforeDate = data?.data[data?.data?.length - 1]?.week_start;
		} else {
			this.stopLoad = true;
		}
		this.isLoading = false;
		this.isInfinityLoading = true;
	}

	beforeDestroy() {
		this.isInfinityLoading = false;
	}
}
