<template>
	<div>
		<div v-for="(ass, index) in skills" :key="index" class="mt-10">
			<div class="font-semibold text-lg text-neutral-600 mb-2">
				{{ +index + 1 }}. {{ ass?.skill?.name }}
			</div>
			<div class="shadow-sm sm:rounded-2xl overflow-hidden">
				<div class="__record__table overflow-x-auto scroll-bar">
					<div
						class="
							overflow-hidden
							sm:rounded-t-2xl
							w-fit
							min-w-full
						"
					>
						<table class="min-w-full divide-y text-neutral-200">
							<thead class="bg-neutral-100 h-16">
								<tr>
									<th
										class="
											px-4
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
											w-16
										"
										scope="col"
									>
										STT
									</th>
									<th
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											w-8/12
											whitespace-nowrap
										"
										scope="col"
										style="width: 350px"
									>
										Mục Tiêu
									</th>
									<th
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
										scope="col"
										style="width: 170px"
									>
										Kết quả
									</th>
									<th
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
										scope="col"
										style="width: 200px"
									>
										Nhận xét
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(assessment, index) in ass.goals"
									:key="assessment?.goal_id"
									:class="
										index % 2 === 0
											? 'bg-white'
											: 'bg-neutral-50'
									"
									class="
										cursor-pointer
										text-neutral-700
										hover:bg-orange-50
									"
								>
									<td
										class="
											p-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div class="ml-4">
											{{ index + 1 }}
										</div>
									</td>
									<td
										class="
											px-6
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div>
											<div class="whitespace-normal">
												{{
													assessment?.goal_name ||
													assessment?.name
												}}
											</div>
											<div
												class="flex gap-2 flex-wrap"
												style="max-width: 360px"
											>
												<div
													v-if="
														assessment?.raw_source
													"
													class="
														mt-1
														py-0.5
														px-2.5
														rounded-full
														bg-neutral-200
														text-neutral-700
														h-fit
														w-fit
														whitespace-nowrap
														font-semibold
														shadow-input
													"
													style="font-size: 10px"
												>
													{{
														assessment?.display_source
													}}
												</div>
												<template
													v-if="
														assessment?.assistance_levels &&
														org.enable_goal_assistance_level
													"
												>
													<div
														v-for="ass in assessment?.assistance_levels"
														:key="ass"
													>
														<div
															class="
																mt-1
																py-0.5
																px-2.5
																rounded-full
																bg-pink-100
																whitespace-nowrap
																text-neutral-700
																h-fit
																w-fit
																font-semibold
																shadow-input
															"
															style="
																font-size: 10px;
															"
														>
															{{ ass }}
														</div>
													</div>
												</template>
											</div>
										</div>
									</td>
									<td
										class="
											px-6
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div
											v-if="
												assessment?.student_assessment_group_weekly_report
											"
										>
											<div
												:style="{
													background: TAG_COLOR(
														assessment
															?.student_assessment_group_weekly_report
															?.complete_percent
													),
												}"
												class="
													mt-1
													pt-1
													pb-0.5
													px-2.5
													rounded-full
													h-fit
													w-fit
													font-semibold
													text-sm
													shadow-input
													text-white
												"
											>
												{{
													assessment
														?.student_assessment_group_weekly_report
														?.result
												}}
											</div>
										</div>
									</td>
									<td
										class="
											px-6
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div class="whitespace-normal w-56">
											{{
												assessment
													?.student_assessment_group_weekly_report
													?.note
											}}
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { TAG_COLOR } from '@/helper/tag';

export default {
	name: 'GoalsGroupClassForStudent',

	props: {
		skills: {
			type: Array,
			required: true,
		},

		studentId: {
			type: String,
			required: true,
		},
	},

	computed: {
		org() {
			return this.$store.state.profile.current_org;
		},
	},

	methods: {
		TAG_COLOR,
	},
};
</script>
