<template>
	<div class="__setup-center px-10 md-down:px-4 pb-30">
		<div class="__setup-center__header py-6">
			<div
				class="
					__record__title
					text-neutral-500
					font-semibold
					whitespace-nowrap
					flex flex-nowrap
					items-center
					text-4xl
					cursor-pointer
				"
				@click="$router.push('/media')"
			>
				<svg
					class="mr-2"
					width="28"
					height="28"
					viewBox="0 0 28 28"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M17.5 7L10.5 14L17.5 21"
						stroke="#94A3B8"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
				Thiết lập cơ sở
			</div>
		</div>
		<div>
			<div class="font-semibold text-lg text-gray-600">Năm 2022</div>
			<div
				class="mt-2"
				:id="`list-dropdown-${index}`"
				v-for="(media, index) in mediaList"
				:key="index"
			>
				<div
					class="
						bg-neutral-50
						flex
						items-center
						justify-between
						p-3
						border
						rounded-t
						cursor-pointer
					"
					@click="openList(index)"
				>
					<div class="font-semibold text-gray-600">
						Tháng {{ media.date }}
					</div>
					<div class="relative">
						<div
							class="
								absolute
								top-1/2
								right-0
								transform
								-translate-y-1/2
								transition-all
								duration-200
							"
							:id="`list-dropdown-${index}-icon`"
						>
							<svg
								width="12"
								height="6"
								viewBox="0 0 12 6"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10.5 5.25L6 0.75L1.5 5.25"
									stroke="#48566A"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</div>
					</div>
				</div>
				<div
					class="px-4 border border-t-0 rounded-b bg-white"
					:id="`list-dropdown-${index}-content`"
					:class="{ hidden: index !== 0 }"
				>
					<div class="flex items-center justify-between py-3">
						<div>Số học sinh đăng ký</div>
						<div>{{ media.total_student }} học sinh</div>
					</div>
					<div class="flex items-center justify-between py-3">
						<div>Dung lượng video khả dụng</div>
						<div>{{ media.allocated_size }}</div>
					</div>
					<div class="flex items-center justify-between py-3">
						<div>Số dung lượng video đã sử dụng</div>
						<div>{{ media.used_size }}</div>
					</div>
					<div class="flex items-center justify-between py-3">
						<div>Số dung lượng video chưa sử dụng</div>
						<div>{{ media.unused_size }}</div>
					</div>
				</div>
			</div>
		</div>
		<DaInfinityLoading
			@infinite-load="getMediaDetailByMonth"
			:loaded="stopLoad"
			v-if="isInfinityLoading"
		/>
	</div>
</template>

<script>
import { $api } from '@/services';
import dayjs from 'dayjs';
import DaInfinityLoading from '@/components/common/daInfinityLoading/DaInfinityLoading';

export default {
	name: 'MediaHistory',

	components: { DaInfinityLoading },

	mounted() {
		this.openList(0);
	},

	data() {
		return {
			date: dayjs().toDate(),
			mediaList: [],
			stopLoad: false,
			isInfinityLoading: false,
			first_month_start: null,
		};
	},

	created() {
		if (!this.isEnableMedia) {
			this.$router.push('/report/lesson');
		} else {
			this.getMediaDetail();
			this.getMediaDetailByMonth();
		}
	},

	computed: {
		isEnableMedia() {
			return this.$store.state.profile.current_org.enable_goal_media;
		},
	},

	methods: {
		prevMonth() {
			this.date = dayjs(this.date).subtract(1, 'month').toDate();
		},
		openList(index) {
			const el = document.getElementById(
				`list-dropdown-${index}-content`
			);
			const icon = document.getElementById(`list-dropdown-${index}-icon`);
			if (el) {
				if (el.classList.contains('hidden')) {
					el.classList.remove('hidden');
					icon?.classList.add('rotate-180');
				} else {
					el.classList.add('hidden');
					icon?.classList.remove('rotate-180');
				}
			}
		},

		async getMediaDetail() {
			try {
				const {
					data: {
						data: { first_month_start },
					},
				} = await $api.org.mediaCurrent();

				if (first_month_start) {
					this.first_month_start = dayjs(first_month_start).toDate();
				}
			} catch (e) {
				console.log(e);
			}
		},

		async getMediaDetailByMonth() {
			if (this.date >= this.first_month_start) {
				try {
					const {
						data: { data },
					} = await $api.org.mediaByMonth({
						month_start: dayjs(this.date).format('YYYY-MM') + '-01',
					});
					this.mediaList.push({
						...data,
						date: dayjs(this.date).format('MM/YYYY'),
					});

					if (data) {
						this.prevMonth();
					} else {
						this.stopLoad = true;
					}
				} catch (e) {
					console.log(e);
				} finally {
					this.isInfinityLoading = true;
				}
			} else {
				this.stopLoad = true;
			}
		},
	},
};
</script>
