<script setup>
import { defineEmits, defineProps, ref, watch } from 'vue';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame';
import DeleteDocument from '@/views/documents/DeleteConfirm.vue';
import DeleteFolder from '@/views/documents/DeleteFolder.vue';
import CreateDocument from '@/views/documents/CreateDocument.vue';
import MoveDocument from '@/views/documents/MoveDocument.vue';
import EditName from '@/views/documents/CreateFolder.vue';
import { $api } from '@/services';
import ViewPhotoV2 from '@/components/common/ViewPhotoV2.vue';
import { useStore } from 'vuex';

const props = defineProps(['document']);
const emit = defineEmits(['success', 'sortList']);
const store = useStore();

const isHide = ref(true);
const id = ref(null);
const idFolder = ref(null);
const actionMenu = ref(false);
const actionFolderMenu = ref(false);
const modalIsOpen = ref(false);
const deleteFolderModal = ref(false);
const isSlideOpen = ref(false);
const moveSlide = ref(false);
const deleteData = ref(null);
const data = ref(null);
const photos = ref([]);
const index = ref(0);
const changeNameModal = ref(false);
const title = ref('');
const viewOnWeb = ref(false);
const params = ref({
	order_by: '',
});
watch(
	params,
	() => {
		emit('sortList', params.value.order_by);
	},
	{ deep: true }
);

const openMenuAction = (data) => {
	if (id.value == data) {
		id.value = null;
		actionMenu.value = false;
	} else {
		id.value = data;
		actionMenu.value = true;
	}
};
const openMenuFolderAction = () => {
	actionFolderMenu.value = !actionFolderMenu.value;
};

const openModalDelete = (data) => {
	modalIsOpen.value = true;
	deleteData.value = data;
};
const openModalDeleteFolder = (data) => {
	if (data.reference_documents.length > 0) return;
	actionFolderMenu.value = false;
	deleteFolderModal.value = true;
	deleteData.value = data;
};
const openModal = (value, id) => {
	actionMenu.value = false;
	actionFolderMenu.value = false;
	moveSlide.value = false;
	isSlideOpen.value = true;
	idFolder.value = id;
	data.value = value;
	deleteData.value = null;
};
const openMoveSlideModal = (value) => {
	isSlideOpen.value = false;
	moveSlide.value = true;
	data.value = value;
	deleteData.value = null;
};
const changeFolderName = (value) => {
	actionFolderMenu.value = false;
	changeNameModal.value = true;
	data.value = value;
};
const close = () => {
	changeNameModal.value = false;
	moveSlide.value = false;
	isSlideOpen.value = false;
	modalIsOpen.value = false;
	deleteFolderModal.value = false;
	data.value = null;
	deleteData.value = null;
};
const onSuccess = () => {
	isSlideOpen.value = false;
	modalIsOpen.value = false;
	deleteFolderModal.value = false;
	changeNameModal.value = false;
	moveSlide.value = false;
	emit('success');
};

const openMediaPopup = (item) => {
	actionMenu.value = false;
	viewOnWeb.value = false;
	fetchDocumentDetail(item?.id);
};
const viewDocumentsOnWebsite = (item) => {
	if (item?.asset_type === 'pdf') {
		window
			.open(item?.reference_document_items[0]?.asset_url, '_blank')
			.focus();
	} else {
		fetchDocumentDetail(item?.id);
		viewOnWeb.value = true;
	}
};
const fetchDocumentDetail = async (id) => {
	photos.value = [];
	try {
		const {
			data: { data },
		} = await $api.org.documentDetail(id);
		photos.value = data?.reference_document_items?.filter((i) =>
			i.asset_type.includes('image')
		);
		title.value = data.title;
	} catch (e) {
		store.dispatch('setAlertMessage', {
			message:
				e?.response?.data?.errors?.join('<br/>') ||
				'Đã có lỗi xảy ra. Vui lòng thử lại',
			type: 'danger',
		});
	}
};
</script>
<template>
	<ViewPhotoV2
		v-if="photos?.length"
		:images="photos"
		:index="index"
		:title="title"
		:viewOnWeb="viewOnWeb"
		@close="photos = []"
	/>
	<div v-if="isSlideOpen" :class="isSlideOpen ? 'side-over--active' : ''">
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			@click="isSlideOpen = false"
		></div>
		<CreateDocument
			:id="data?.id"
			:idFolder="idFolder"
			:show="isSlideOpen"
			@close="close"
			@success="onSuccess"
		/>
	</div>
	<div v-if="moveSlide" :class="moveSlide ? 'side-over--active' : ''">
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			@click="moveSlide = false"
		></div>
		<MoveDocument
			:data="data"
			:folderId="props.document.id"
			:show="moveSlide"
			@close="close"
			@success="onSuccess"
		/>
	</div>
	<DaModalFrame v-model:open="modalIsOpen">
		<DeleteDocument
			v-if="deleteData"
			:data="deleteData"
			@close="close"
			@success="onSuccess"
		/>
	</DaModalFrame>
	<DaModalFrame v-model:open="deleteFolderModal">
		<DeleteFolder
			v-if="deleteData"
			:data="deleteData"
			@close="close"
			@success="onSuccess"
		/>
	</DaModalFrame>
	<DaModalFrame v-model:open="changeNameModal">
		<EditName
			:data="data"
			@close="changeNameModal = false"
			@success="onSuccess"
		/>
	</DaModalFrame>
	<div>
		<div class="flex items-center">
			<div class="relative my-4 w-full">
				<div
					aria-hidden="true"
					class="absolute inset-0 flex items-center"
				>
					<div class="w-full border-t border-neutral-300" />
				</div>
				<div class="relative flex justify-between">
					<div class="flex items-center bg-body px-2 gap-3">
						<div
							:class="{ 'rotate-180': !isHide }"
							class="cursor-pointer transform"
							@click="isHide = !isHide"
						>
							<svg
								fill="none"
								height="28"
								viewBox="0 0 28 28"
								width="28"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M21 17.5L14 10.5L7 17.5"
									stroke="#94A3B8"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
							</svg>
						</div>
						<div
							class="
								text-xl
								font-semibold
								text-gray-500
								cursor-pointer
							"
						>
							{{
								props.document.name ||
								props.document?.reference_category?.name
							}}
						</div>
					</div>
					<div class="bg-body pl-4 flex items-center gap-4">
						<span
							class="
								font-semibold
								text-neutral-400 text-sm
								whitespace-nowrap
							"
						>
							Tài liệu:
							{{
								props.document.reference_documents?.length ||
								props.document.references?.length ||
								0
							}}</span
						>
						<div class="w-full flex justify-end pr-6 relative">
							<img
								alt=""
								class="w-6 h-6 cursor-pointer"
								src="@/assets/images/icons/three-dots.png"
								@click.stop="
									openMenuFolderAction(props.document.id)
								"
							/>
							<div
								v-if="actionFolderMenu"
								class="
									absolute
									menu-action
									folder-menu
									py-1
									z-10
									cursor-pointer
								"
							>
								<div
									class="flex items-center gap-3 px-4 py-2"
									@click.stop="
										openModal(null, props.document.id)
									"
								>
									<img
										alt=""
										class="w-4 h-4"
										src="@/assets/images/icons/plus-circle-fill.png"
									/>
									<div class="text-neutral-700">
										Tạo tài liệu
									</div>
								</div>
								<div
									class="flex items-center gap-3 px-4 py-2"
									@click.stop="
										changeFolderName(props.document)
									"
								>
									<img
										alt=""
										class="w-4 h-4"
										src="@/assets/images/icons/pen.png"
									/>
									<div class="text-neutral-700">
										Đổi tên thư mục
									</div>
								</div>
								<div
									:class="{
										disabled:
											props.document.reference_documents
												?.length > 0 ||
											props.document.references?.length >
												0,
									}"
									class="flex items-center gap-3 px-4 py-2"
									@click.stop="
										openModalDeleteFolder(props.document)
									"
								>
									<img
										alt=""
										class="w-4 h-4"
										src="@/assets/images/icons/trash.png"
									/>
									<div
										:class="{
											disabled:
												props.document
													.reference_documents
													?.length > 0 ||
												props.document.references
													?.length > 0,
										}"
										class="text-neutral-700"
									>
										Xoá thư mục
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="
				props.document.reference_documents?.length ||
				props.document.references?.length
			"
		>
			<div
				v-if="isHide"
				class="__report-lesson__container overflow-x-auto"
			>
				<div class="shadow-sm sm:rounded-2xl mb-12 mt-1">
					<div class="__record__table scroll-bar">
						<div class="sm:rounded-t-2xl w-fit min-w-full">
							<table class="min-w-full divide-y text-neutral-200">
								<thead class="bg-neutral-100 h-16">
									<tr>
										<th
											class="
												px-3
												py-3
												text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
												text-center
											"
											scope="col"
										>
											STT
										</th>
										<th
											class="
												px-6
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
											"
											scope="col"
											@click="
												params.order_by =
													params.order_by !==
													'title_asc'
														? 'title_asc'
														: 'title_desc'
											"
										>
											<div
												class="
													flex
													items-center
													gap-1
													justify-between
													cursor-pointer
												"
											>
												Tiêu đề
												<svg
													fill="none"
													height="16"
													viewBox="0 0 16 16"
													width="16"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														clip-rule="evenodd"
														d="M7.99999 2.39999C8.21216 2.39999 8.41564 2.48428 8.56567 2.63431L10.9657 5.03431C11.2781 5.34673 11.2781 5.85326 10.9657 6.16568C10.6533 6.4781 10.1467 6.4781 9.8343 6.16568L7.99999 4.33136L6.16567 6.16568C5.85325 6.4781 5.34672 6.4781 5.0343 6.16568C4.72188 5.85326 4.72188 5.34673 5.0343 5.03431L7.4343 2.63431C7.58433 2.48428 7.78782 2.39999 7.99999 2.39999ZM5.0343 9.83431C5.34672 9.52189 5.85325 9.52189 6.16567 9.83431L7.99999 11.6686L9.8343 9.83431C10.1467 9.52189 10.6533 9.52189 10.9657 9.83431C11.2781 10.1467 11.2781 10.6533 10.9657 10.9657L8.56567 13.3657C8.25325 13.6781 7.74672 13.6781 7.4343 13.3657L5.0343 10.9657C4.72188 10.6533 4.72188 10.1467 5.0343 9.83431Z"
														fill="#94A3B8"
														fill-rule="evenodd"
													/>
												</svg>
											</div>
										</th>
										<th
											class="
												px-3
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
											"
											scope="col"
										>
											Trạng thái
										</th>
										<th
											class="
												px-3
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
											"
											scope="col"
										>
											Ảnh thumbnail
										</th>
										<th
											class="
												px-6
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
											"
											scope="col"
											@click="
												params.order_by =
													params.order_by !==
													'inserted_at_asc'
														? 'inserted_at_asc'
														: 'inserted_at_desc'
											"
										>
											<div
												class="
													flex
													items-center
													gap-1
													justify-between
													cursor-pointer
												"
											>
												Người tạo
												<svg
													fill="none"
													height="16"
													viewBox="0 0 16 16"
													width="16"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														clip-rule="evenodd"
														d="M7.99999 2.39999C8.21216 2.39999 8.41564 2.48428 8.56567 2.63431L10.9657 5.03431C11.2781 5.34673 11.2781 5.85326 10.9657 6.16568C10.6533 6.4781 10.1467 6.4781 9.8343 6.16568L7.99999 4.33136L6.16567 6.16568C5.85325 6.4781 5.34672 6.4781 5.0343 6.16568C4.72188 5.85326 4.72188 5.34673 5.0343 5.03431L7.4343 2.63431C7.58433 2.48428 7.78782 2.39999 7.99999 2.39999ZM5.0343 9.83431C5.34672 9.52189 5.85325 9.52189 6.16567 9.83431L7.99999 11.6686L9.8343 9.83431C10.1467 9.52189 10.6533 9.52189 10.9657 9.83431C11.2781 10.1467 11.2781 10.6533 10.9657 10.9657L8.56567 13.3657C8.25325 13.6781 7.74672 13.6781 7.4343 13.3657L5.0343 10.9657C4.72188 10.6533 4.72188 10.1467 5.0343 9.83431Z"
														fill="#94A3B8"
														fill-rule="evenodd"
													/>
												</svg>
											</div>
										</th>
										<th
											class="
												px-6
												py-3
												text-left text-xs
												font-semibold
												text-neutral-600
												uppercase
												tracking-wider
												whitespace-nowrap
											"
											scope="col"
											@click="
												params.order_by =
													params.order_by !==
													'updated_at_asc'
														? 'updated_at_asc'
														: 'updated_at_desc'
											"
										>
											<div
												class="
													flex
													items-center
													gap-1
													justify-between
													cursor-pointer
												"
											>
												chỉnh sửa cuối
												<svg
													fill="none"
													height="16"
													viewBox="0 0 16 16"
													width="16"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														clip-rule="evenodd"
														d="M7.99999 2.39999C8.21216 2.39999 8.41564 2.48428 8.56567 2.63431L10.9657 5.03431C11.2781 5.34673 11.2781 5.85326 10.9657 6.16568C10.6533 6.4781 10.1467 6.4781 9.8343 6.16568L7.99999 4.33136L6.16567 6.16568C5.85325 6.4781 5.34672 6.4781 5.0343 6.16568C4.72188 5.85326 4.72188 5.34673 5.0343 5.03431L7.4343 2.63431C7.58433 2.48428 7.78782 2.39999 7.99999 2.39999ZM5.0343 9.83431C5.34672 9.52189 5.85325 9.52189 6.16567 9.83431L7.99999 11.6686L9.8343 9.83431C10.1467 9.52189 10.6533 9.52189 10.9657 9.83431C11.2781 10.1467 11.2781 10.6533 10.9657 10.9657L8.56567 13.3657C8.25325 13.6781 7.74672 13.6781 7.4343 13.3657L5.0343 10.9657C4.72188 10.6533 4.72188 10.1467 5.0343 9.83431Z"
														fill="#94A3B8"
														fill-rule="evenodd"
													/>
												</svg>
											</div>
										</th>
										<th style="width: 50px"></th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(item, index) in props.document
											.reference_documents ||
										props.document.references"
										:key="item.id"
										:class="
											index % 2 === 0
												? 'bg-white'
												: 'bg-neutral-50'
										"
										class="text-neutral-700"
									>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700 text-center
											"
										>
											<div>
												{{ index + 1 }}
											</div>
										</td>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700
											"
										>
											<div
												class="
													hover:underline
													cursor-pointer
												"
												@click="
													viewDocumentsOnWebsite(item)
												"
											>
												{{ item?.title }}
											</div>
										</td>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700
											"
										>
											<div
												v-if="
													item?.state === 'published'
												"
												class="
													text-green-500
													font-semibold
												"
											>
												Công khai
											</div>
											<div v-else>Bản nháp</div>
										</td>
										<td
											class="
												px-3
												py-4
												whitespace-nowrap
												text-neutral-700
											"
										>
											<div
												class="
													bg-neutral-100
													rounded-lg
													overflow-hidden
													object-fill
												"
												style="
													width: 120px;
													max-height: 80px;
												"
											>
												<img
													:alt="item.title"
													:src="
														item.thumbnail_url ||
														'/img/16.png'
													"
													class="object-fill"
												/>
											</div>
										</td>
										<td class="px-6 py-4 text-neutral-700">
											<div>
												<div
													v-if="item.created_by_staff"
													class="
														flex
														items-center
														mb-4
													"
												>
													<div>
														<img
															v-if="
																item
																	.created_by_staff
																	?.profile_photo
																	?.w200
															"
															:src="
																item
																	.created_by_staff
																	?.profile_photo
																	?.w200
															"
															alt=""
															class="
																shadow-sm
																inline-block
																h-9
																w-9
																rounded-full
															"
														/>
														<span
															v-else
															:style="{
																background:
																	item
																		.created_by_staff
																		?.profile_photo
																		?.default
																		?.color,
															}"
															class="
																inline-flex
																items-center
																justify-center
																h-9
																w-9
																rounded-full
															"
														>
															<span
																class="
																	font-medium
																	leading-none
																	text-white
																	text-xs
																"
																>{{
																	item
																		.created_by_staff
																		?.profile_photo
																		?.default
																		?.initial
																}}</span
															>
														</span>
													</div>
													<div
														class="
															ml-4
															text-blue-600
															font-semibold
															truncate
														"
													>
														{{
															item
																.created_by_staff
																.name
														}}
													</div>
												</div>
												<div v-if="item.inserted_at">
													{{
														$filters.formatDateTime(
															item.inserted_at
														)
													}}
												</div>
											</div>
										</td>
										<td class="px-6 py-4 text-neutral-700">
											<div>
												<div
													v-if="item.updated_by_staff"
													class="
														flex
														items-center
														mb-4
													"
												>
													<div>
														<img
															v-if="
																item
																	.updated_by_staff
																	?.profile_photo
																	?.w200
															"
															:src="
																item
																	.updated_by_staff
																	?.profile_photo
																	?.w200
															"
															alt=""
															class="
																shadow-sm
																inline-block
																h-9
																w-9
																rounded-full
															"
														/>
														<span
															v-else
															:style="{
																background:
																	item
																		.updated_by_staff
																		?.profile_photo
																		?.default
																		?.color,
															}"
															class="
																inline-flex
																items-center
																justify-center
																h-9
																w-9
																rounded-full
															"
														>
															<span
																class="
																	font-medium
																	leading-none
																	text-white
																	text-xs
																"
																>{{
																	item
																		.updated_by_staff
																		?.profile_photo
																		?.default
																		?.initial
																}}</span
															>
														</span>
													</div>
													<div
														class="
															ml-4
															text-blue-600
															font-semibold
															truncate
														"
													>
														{{
															item
																.updated_by_staff
																.name
														}}
													</div>
												</div>
												<div v-if="item.updated_at">
													{{
														$filters.formatDateTime(
															item.updated_at
														)
													}}
												</div>
											</div>
										</td>
										<td
											style="width: 50px; min-width: 50px"
										>
											<div
												class="
													w-full
													flex
													justify-end
													pr-6
													relative
												"
											>
												<img
													alt=""
													class="
														w-6
														h-6
														cursor-pointer
													"
													src="@/assets/images/icons/three-dots.png"
													@click.stop="
														openMenuAction(item.id)
													"
												/>
												<div
													v-if="
														actionMenu &&
														item.id == id
													"
													class="
														absolute
														menu-action
														py-1
														z-10
														cursor-pointer
													"
												>
													<div
														class="
															flex
															items-center
															gap-3
															px-4
															py-2
														"
														@click.stop="
															openModal(item)
														"
													>
														<img
															alt=""
															class="w-4 h-4"
															src="@/assets/images/icons/pen.png"
														/>
														<div
															class="
																text-neutral-700
															"
														>
															Chỉnh sửa tài liệu
														</div>
													</div>
													<div
														class="
															flex
															items-center
															gap-3
															px-4
															py-2
														"
														@click.stop="
															openMoveSlideModal(
																item
															)
														"
													>
														<img
															alt=""
															class="w-4 h-4"
															src="@/assets/images/icons/move.png"
														/>
														<div
															class="
																text-neutral-700
															"
														>
															Di chuyển
														</div>
													</div>
													<div
														v-if="
															item?.asset_type?.includes(
																'image'
															)
														"
														class="
															flex
															items-center
															gap-3
															px-4
															py-2
														"
														@click.stop="
															openMediaPopup(item)
														"
													>
														<img
															alt=""
															class="w-4 h-4"
															src="@/assets/images/icons/eye-fill.png"
														/>
														<div
															class="
																text-neutral-700
															"
														>
															Xem trước tài liệu
														</div>
													</div>
													<div
														class="
															flex
															items-center
															gap-3
															px-4
															py-2
														"
														@click.stop="
															openModalDelete(
																item
															)
														"
													>
														<img
															alt=""
															class="w-4 h-4"
															src="@/assets/images/icons/trash.png"
														/>
														<div
															class="
																text-neutral-700
															"
														>
															Xoá tài liệu
														</div>
													</div>
												</div>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.disabled {
	background: var(--Gray-200, #e1e7ef) !important;
	color: var(--Gray-400, #94a3b8) !important;
	cursor: not-allowed;
}
.menu-action {
	z-index: 100;
	bottom: -25px;
	right: 45px;
	border-radius: 6px;
	border: 1px solid #f9e3b7;
	background: #fff;
	width: 224px;
	box-shadow: 0px 10px 16px 0px rgba(20, 37, 63, 0.06),
		0px 0px 1px 0px rgba(12, 26, 75, 0.1);
}
</style>
