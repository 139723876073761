import { ref } from 'vue';
import { $api } from '@/services';
import store from '@/store';

const error = ref(null);
const loading = ref(false);

export async function getStudents(role: string, query: any) {
	error.value = null;
	try {
		const res = await $api.student.getStudents(role, query);
		if (!res) throw new Error('Lỗi khi lấy danh sách sinh viên');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export async function getStudentsForStaff(id: string) {
	error.value = null;
	try {
		const res = await $api.student.studentsForStaff(id);
		if (!res) throw new Error('Lỗi khi lấy danh sách sinh viên');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export async function createStudents(data: any, id: string) {
	error.value = null;
	try {
		const res = id
			? await $api.student.updateStudent(id, data)
			: await $api.student.createStudent(data);
		if (!res) throw new Error('Lỗi khi lấy danh sách sinh viên');
		return res?.data;
	} catch (err) {
		return err?.response?.data;
	} finally {
		loading.value = false;
	}
}

export async function getStudent(id: string, oldData?: any) {
	error.value = null;
	try {
		const res = await $api.student.getStudent(id, oldData);

		if (!res) throw new Error('Lỗi khi lấy danh sách sinh viên');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export async function updateStudent(id: string, data: any) {
	error.value = null;
	try {
		const res = await $api.student.updateStudent(id, data);

		if (!res) throw new Error('Lỗi khi lấy danh sách sinh viên');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export async function closeStudent(
	id: string,
	isActive: boolean,
	state: string
) {
	error.value = null;
	try {
		const res = isActive
			? await $api.student.openStudent(id, state)
			: await $api.student.closeStudent(id, state);

		if (res && isActive) {
			await store.dispatch('setAlertMessage', {
				message: 'Kích hoạt tài khoản thành công',
			});
		}
		if (res && !isActive) {
			await store.dispatch('setAlertMessage', {
				message: 'Khoá tài khoản thành công',
			});
		}
		if (!res) throw new Error('Lỗi khi lấy danh sách sinh viên');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors?.join('<br/>') || err.message;
		await store.dispatch('setAlertMessage', {
			message: error,
			type: 'error',
		});
	} finally {
		loading.value = false;
	}
}

export async function goalByStudent(id: string) {
	error.value = null;
	try {
		const res = await $api.goal.goalByStudent(id);
		if (!res) throw new Error('Lỗi khi lấy danh sách sinh viên');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors?.join('<br/>') || err.message;
		await store.dispatch('setAlertMessage', {
			message: error,
			type: 'error',
		});
	} finally {
		loading.value = false;
	}
}

export async function goalStoppedByStudent(id: string, oldData?: any) {
	error.value = null;
	try {
		const res = await $api.goal.goalStoppedByStudent(id, oldData);
		if (!res) throw new Error('Lỗi khi lấy danh sách sinh viên');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors?.join('<br/>') || err.message;
		await store.dispatch('setAlertMessage', {
			message: error,
			type: 'error',
		});
	} finally {
		loading.value = false;
	}
}

export async function goalCompletedByStudent(id: string, oldData?: any) {
	error.value = null;
	try {
		const res = await $api.goal.goalCompletedByStudent(id, oldData);
		if (!res) throw new Error('Lỗi khi lấy danh sách sinh viên');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors?.join('<br/>') || err.message;
		await store.dispatch('setAlertMessage', {
			message: error,
			type: 'error',
		});
	} finally {
		loading.value = false;
	}
}

export async function goalLibByStudent(id: string, oldData?: any) {
	error.value = null;
	try {
		const res = await $api.goal.goalLibByStudent(id, oldData);
		if (!res) throw new Error('Lỗi khi lấy danh sách sinh viên');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors?.join('<br/>') || err.message;
		await store.dispatch('setAlertMessage', {
			message: error,
			type: 'error',
		});
	} finally {
		loading.value = false;
	}
}

export async function goalHistoryByStudent(id: string) {
	error.value = null;
	try {
		const res = await $api.monthlyReport.planHistory(id);
		if (!res) throw new Error('Lỗi khi lấy danh sách sinh viên');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors?.join('<br/>') || err.message;
		await store.dispatch('setAlertMessage', {
			message: error,
			type: 'error',
		});
	} finally {
		loading.value = false;
	}
}

export function useStudent() {
	return {
		error,
		loading,
		getStudents,
		createStudents,
		getStudent,
		updateStudent,
		closeStudent,
		goalByStudent,
		goalStoppedByStudent,
		goalCompletedByStudent,
		goalLibByStudent,
		goalHistoryByStudent,
	};
}
