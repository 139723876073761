<template>
	<div
		class="
			fixed
			top-1/2
			left-1/2
			transform
			-translate-y-1/2 -translate-x-1/2
			h-screen
			w-screen
			flex
			items-center
			justify-center
		"
		style="z-index: 1000000"
	>
		<div
			class="background h-full w-full absolute"
			@click="$emit('close')"
		></div>
		<div class="pt-12 pb-6 px-20 bg-white z-50 rounded-lg relative">
			<div class="image-show">
				<video
					v-if="images[imgIndex]?.asset_type === 'video'"
					controls
					id="video"
					:key="images[imgIndex]?.asset_url"
				>
					<source
						:key="images[imgIndex]?.asset_url"
						:src="images[imgIndex]?.asset_url"
						type="video/mp4"
					/>
					<a download="" :href="images[imgIndex]?.asset_url">
						Download
					</a>
				</video>
				<img
					v-else
					class="rounded-lg"
					:src="images[imgIndex]?.asset_url"
					alt=""
				/>
			</div>
			<div class="flex w-full justify-between">
				<div
					class="
						absolute
						transform
						top-1/2
						-translate-y-1/2
						left-6
						cursor-pointer
						opacity-70
						hover:opacity-100
						rounded-lg
						border border-yellow-500
						p-2
						text-yellow-700
					"
					@click="prev"
				>
					<svg
						width="25"
						height="24"
						viewBox="0 0 25 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M15.1667 19L8.16675 12L15.1667 5"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</div>
				<div
					class="
						absolute
						transform
						top-1/2
						-translate-y-1/2
						right-6
						cursor-pointer
						opacity-70
						hover:opacity-100
						rounded-lg
						border border-yellow-500
						p-2
						text-yellow-700
					"
					@click="next"
				>
					<svg
						width="25"
						height="24"
						viewBox="0 0 25 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M9.8335 5L16.8335 12L9.8335 19"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</div>
			</div>
			<div class="flex justify-between pt-4">
				<div class="font-light text-grey-c">
					<div
						class="font-semibold text-navy mr-2 truncate"
						style="max-width: 200px"
						v-html="name || images[imgIndex]?.asset_key"
					></div>
					<div
						class="text-navy mr-2"
						v-if="images[imgIndex]?.inserted_at"
					>
						{{ $filters.formatDate(images[imgIndex]?.inserted_at) }}
					</div>
				</div>
				<div class="font-semibold text-grey-c">
					{{ imgIndex + 1 }}/{{ images.length || 0 }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ViewPhoto',

	props: {
		images: {
			type: Array,
			required: true,
		},
		index: {
			type: Number,
			required: true,
		},
		name: {
			type: String,
			required: false,
		},
	},

	watch: {
		index: {
			immediate: true,
			handler(value) {
				this.imgIndex = value;
			},
		},
	},

	data() {
		return {
			imgIndex: 0,
		};
	},

	methods: {
		next() {
			if (this.imgIndex < this.images.length - 1) {
				this.imgIndex++;
			}
		},
		prev() {
			if (this.imgIndex > 0) {
				this.imgIndex--;
			}
		},
	},
};
</script>

<style scoped lang="scss">
.background {
	background-color: rgba(0, 0, 0, 0.8);
}

.image-show {
	img {
		height: 70vh;
		object-fit: contain;
	}

	#video {
		object-fit: initial;
		height: 70vh;
	}
}
</style>
