import { ref } from 'vue';
import { $api } from '@/services';

const error = ref(null);
const loading = ref(false);

export async function listDayCareReport(
	filter: any,
	before?: string | null,
	teacher = false
) {
	error.value = null;
	try {
		const q = filter
			? Object.keys(filter)
					.map((key) => {
						if (filter[key]) {
							return `${key}=${filter[key]}`;
						}
					})
					.join('&')
			: '';
		const meta = before ? '?' + q + `&before=${before}` : '?' + q + '';
		const res = await $api.lessonReport.listDayCareReport(meta, teacher);
		if (!res) throw new Error('Lỗi');
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export function useListDayCareReport() {
	return { error, loading, listDayCareReport };
}
