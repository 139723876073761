
import { computed, defineComponent } from 'vue';

export default defineComponent({
	name: 'Rating',
	props: {
		rating: {
			type: Number,
			default: 5,
		},
	},
	setup(props: any) {
		return {
			number: computed(() => props.rating),
		};
	},
});
