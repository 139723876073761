<template>
	<div
		class="
			fixed
			top-1/2
			left-1/2
			transform
			-translate-y-1/2 -translate-x-1/2
			h-screen
			w-screen
			flex
			items-center
			justify-center
		"
		style="z-index: 1000000"
	>
		<div
			class="background h-full w-full absolute text-white"
			@click="$emit('close')"
		>
			<div class="flex items-center justify-between w-full py-4 px-8">
				<div class="font-medium text-2xl">
					{{ viewOnWeb ? title : 'Xem trước' }}
				</div>
				<div>
					<svg
						width="32"
						height="32"
						viewBox="0 0 32 32"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M25.3334 8.54699L23.4534 6.66699L16 14.1203L8.54669 6.66699L6.66669 8.54699L14.12 16.0003L6.66669 23.4537L8.54669 25.3337L16 17.8803L23.4534 25.3337L25.3334 23.4537L17.88 16.0003L25.3334 8.54699Z"
							fill="white"
						/>
					</svg>
				</div>
			</div>
		</div>
		<div class="z-50 relative mt-12 ">
			<div class="image-show relative flex justify-center">
				<img
					class="rounded-lg"
					:src="images[imgIndex]?.asset_url"
					alt=""
				/>
				<div
					class="
						absolute
						bottom-2
						text-white
						left-1/2
						-translate-y-1/2
						text-lg
						font-medium
					"
					v-if="!viewOnWeb"
				>
					{{ imgIndex + 1 }} / {{ images?.length }}
				</div>
			</div>
			<div class="flex w-full justify-between" v-if="images?.length > 1">
				<div
					class="
						absolute
						transform
						top-1/2
						-translate-y-1/2
						left-6
						cursor-pointer
						rounded-full
						p-2
					"
					style="background: #0000001a"
					@click="prev"
				>
					<svg
						width="17"
						height="17"
						viewBox="0 0 17 17"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M10.5 4.7251L6.5 8.7251L10.5 12.7251"
							stroke="white"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</div>
				<div
					class="
						absolute
						transform
						top-1/2
						-translate-y-1/2
						right-6
						cursor-pointer
						rounded-full
						p-2
					"
					style="background: #0000001a"
					@click="next"
				>
					<svg
						width="17"
						height="17"
						viewBox="0 0 17 17"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M6.5 12.7251L10.5 8.7251L6.5 4.7251"
							stroke="white"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</div>
			</div>
			<div class="mt-6 flex items-center gap-1 justify-center">
				<div v-for="i,index in images" :key="i.id" class="cursor-pointer" @click="imgIndex = index">
					<img class="thumbnail-img" :src="i.asset_url" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ViewPhotoV2',

	props: {
		images: {
			type: Array,
			required: true,
		},
		index: {
			type: Number,
			required: true,
		},
		title: {
			type: String,
			required: false,
		},
		viewOnWeb: {
			type: Boolean,
			required: true,
		},
	},

	watch: {
		index: {
			immediate: true,
			handler(value) {
				this.imgIndex = value;
			},
		},
	},

	data() {
		return {
			imgIndex: 0,
		};
	},

	methods: {
		next() {
			if (this.imgIndex < this.images.length - 1) {
				this.imgIndex++;
			}
		},
		prev() {
			if (this.imgIndex > 0) {
				this.imgIndex--;
			}
		},
	},
};
</script>

<style scoped lang="scss">
.background {
	background: #6b7280bf;
}
.thumbnail-img{
	width: 108px;
	height: 108px;
}
.image-show {
	img {
		height: calc(100vh - 196px);
		object-fit: contain;
	}

	#video {
		object-fit: initial;
		height: 70vh;
	}
}
</style>
