<template>
	<div class="_modal bg-white">
		<div
			class="
				_modal__content
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<img
					src="@/assets/images/illustrations/email_parent.svg"
					alt="Luca Education"
					title="Luca Education"
					height="150"
					width="150"
				/>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
					text-center
				"
			>
				Gửi Kế hoạch hiện tại đến Phụ huynh học sinh
			</div>
			<div class="text-center mt-5 text-neutral-500">
				Luca sẽ gửi
				<span class="font-semibold text-green-500 uppercase"
					>kế hoạch {{ name }}</span
				>
				tới app phụ huynh. Phụ huynh cần vào app để xem kế hoạch.
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="$emit('close')"
			>
				Quay lại
			</button>
			<button
				type="button"
				class="button-p text-center whitespace-nowrap"
				@click="$emit('submit')"
			>
				Xác nhận
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ConfirmSendReportTarget',
	props: {
		name: {
			type: String,
			required: true,
		},
	},
};
</script>
