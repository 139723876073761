<template>
	<DaModalFrame v-model:open="modalIsOpenOverlap">
		<ErrorOverlap
			@close="modalIsOpenOverlap = false"
			@submit="submit"
			:current-session="{
				...data[0],
				student: getStudent(data[0].student_id),
				staffs: getStaffs,
			}"
			:data-staffs="staffsOverload"
		/>
	</DaModalFrame>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 w-screen md:w-2/5">
		<div class="h-full w-full flex flex-col bg-white shadow-xl">
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center" @click="$emit('close')">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12 5.25C12 4.83579 11.6642 4.5 11.25 4.5C10.8358 4.5 10.5 4.83579 10.5 5.25V13.5C10.5 13.7691 10.6442 14.0177 10.8779 14.1512L16.1279 17.1512C16.4875 17.3567 16.9457 17.2317 17.1512 16.8721C17.3567 16.5125 17.2317 16.0543 16.8721 15.8488L12 13.0648V5.25Z"
								fill="#94A3B8"
							/>
						</svg>

						<div
							class="
								text-2xl
								font-bold
								text-neutral-700
								ml-2
								mt-1
							"
						>
							Đổi giờ học
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							type="button"
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div
				class="
					sideover-content
					pt-6
					relative
					flex-1
					px-4
					sm:px-6
					overflow-y-auto
				"
			>
				<div class="relative">
					<div class="mt-10">
						<div class="form-group flex flex-col gap-10">
							<div class="input-group w-full">
								<div
									class="
										input-group__label
										w-56
										font-semibold
										text-neutral-600
									"
								>
									Giờ bắt đầu
								</div>
								<VueDatepicker
									class="input-date w-full"
									timePicker
									placeholder="Giờ bắt đầu"
									autoApply
									v-model="startTime"
									uid="start_timeslot_hour"
									minutesIncrement="5"
								>
									<template #input-icon>
										<DaIcon
											name="das--lock"
											class="h-4 w-4 text-neutral-400"
										/>
									</template>
								</VueDatepicker>
							</div>
							<div class="input-group w-full">
								<div
									class="
										input-group__label
										w-56
										font-semibold
										text-neutral-600
									"
								>
									Giờ kết thúc
								</div>
								<VueDatepicker
									v-model="endTime"
									class="input-date w-full"
									timePicker
									placeholder="Giờ kết thúc"
									autoApply
									uid="end_timeslot_hour"
									minutesIncrement="5"
								>
									<template #input-icon>
										<DaIcon
											name="das--lock"
											class="h-4 w-4 text-neutral-400"
										/>
									</template>
								</VueDatepicker>
							</div>
						</div>
					</div>
					<div class="mt-10">
						<div class="font-semibold text-neutral-600 text-sm">
							Ghi chú*
						</div>
						<div class="w-full mt-2">
							<div class="input-group flex items-center">
								<textarea
									v-model="note"
									placeholder="Ghi chú về sự thay đổi này"
								>
								</textarea>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-6" :id="`list-dropdown-1`">
					<div
						class="
							bg-neutral-50
							flex
							items-center
							justify-between
							p-3
							border
							rounded-t
							cursor-pointer
						"
						@click="openList(1)"
					>
						<div>Số buổi đã chọn</div>
						<div class="relative">
							<div class="pr-10">{{ data.length }} buổi</div>
							<div
								class="
									absolute
									top-1/2
									right-0
									transform
									-translate-y-1/2
									transition-all
									duration-200
								"
								:id="`list-dropdown-1-icon`"
							>
								<svg
									width="12"
									height="6"
									viewBox="0 0 12 6"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10.5 5.25L6 0.75L1.5 5.25"
										stroke="#48566A"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div
						class="px-4 border border-t-0 rounded-b"
						:id="`list-dropdown-1-content`"
						v-if="data"
					>
						<div
							v-for="(item, index) in data"
							:key="index"
							class="py-4"
						>
							<div class="flex items-center justify-between">
								<div
									class="flex items-center w-1/3"
									v-if="getStudent(item.student_id)"
								>
									<div
										@click.stop="
											$router.push(
												`/record/student/${item.student_id}`
											)
										"
										class="flex items-center"
										v-if="
											item.student_id &&
											getStudent(item.student_id)
										"
									>
										<div>
											<img
												class="
													shadow-sm
													inline-block
													h-9
													w-9
													rounded-full
												"
												:src="
													getStudent(item.student_id)
														?.profile_photo?.w200
												"
												alt=""
												v-if="
													getStudent(item.student_id)
														?.profile_photo?.w200
												"
											/>
											<span
												v-else
												class="
													inline-flex
													items-center
													justify-center
													h-9
													w-9
													rounded-full
												"
												:style="{
													background: getStudent(
														item.student_id
													)?.profile_photo?.default
														?.color,
												}"
											>
												<span
													class="
														font-medium
														leading-none
														text-white text-xs
													"
													>{{
														getStudent(
															item.student_id
														)?.profile_photo
															?.default?.initial
													}}</span
												>
											</span>
										</div>
										<div
											class="
												ml-4
												text-yellow-600
												font-semibold
												whitespace-pre-wrap
											"
										>
											{{
												getStudent(item.student_id).name
											}}
										</div>
									</div>
								</div>
								<div class="w-1/3">
									<div
										v-if="item.staff_ids"
										class="flex flex-col gap-2"
									>
										<div
											@click.stop="
												$router.push(
													`/record/employee/${staff_id}`
												)
											"
											class="flex items-center"
											v-for="staff_id in item.staff_ids"
											:key="staff_id"
										>
											<div v-if="getStaff(staff_id)">
												<img
													class="
														shadow-sm
														inline-block
														h-9
														w-9
														rounded-full
													"
													:src="
														getStaff(staff_id)
															?.profile_photo
															?.w200
													"
													alt=""
													v-if="
														getStaff(staff_id)
															?.profile_photo
															?.w200
													"
												/>
												<span
													v-else
													class="
														inline-flex
														items-center
														justify-center
														h-9
														w-9
														rounded-full
													"
													:style="{
														background:
															getStaff(staff_id)
																?.profile_photo
																?.default
																?.color,
													}"
												>
													<span
														class="
															font-medium
															leading-none
															text-white text-xs
														"
														>{{
															getStaff(staff_id)
																?.profile_photo
																?.default
																?.initial
														}}</span
													>
												</span>
											</div>
											<div
												v-if="getStaff(staff_id)"
												class="
													ml-4
													text-blue-600
													font-semibold
												"
											>
												{{ getStaff(staff_id).name }}
											</div>
										</div>
									</div>
								</div>
								<div class="text-gray-600">
									<span v-if="item.date">
										{{ $filters.formatDate2(item.date) }}
									</span>
									<span>
										- {{ item.display_start_slot }}</span
									>
									<span> - {{ item.display_end_slot }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="px-6 py-4 border-t border-yellow-50">
				<div class="flex justify-end">
					<div>
						<button
							type="button"
							class="button-p text-center px-6"
							@click="onHandleSubmit"
							:disabled="!isValidate || inProgress"
						>
							Xác nhận
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import { $api } from '@/services';
import DaIcon from '@/components/elements/daIcon/DaIcon';
import dayjs from 'dayjs';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import ErrorOverlap from '@/components/elements/ErrorOverload.vue';

export default defineComponent({
	name: 'ChangeTimeSlot',
	components: { ErrorOverlap, DaModalFrame, DaIcon },
	props: {
		data: {
			type: Array,
			required: true,
		},

		staffs_map: {
			type: Object,
			required: true,
		},

		students_map: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			date: new Date(),
			startTime: null,
			endTime: null,
			note: '',
			inProgress: false,
			staffsOverload: [],
			modalIsOpenOverlap: false,
		};
	},

	computed: {
		isValidate() {
			return this.startTime && this.endTime && this.note;
		},

		getStaffs() {
			return this.data[0]?.staff_ids.map((i) => this.getStaff(i));
		},
	},

	created() {
		this.startTime = this.initStartTime();
	},

	watch: {
		startTime(value) {
			if (!value) {
				this.startTime = this.initStartTime();
			} else {
				this.endTime = {
					hours: value.hours + 1,
					minutes: value.minutes,
				};
			}
		},

		endTime(value) {
			if (!value) {
				this.endTime = {
					hours: this.startTime.hours + 1,
					minutes: this.startTime.minutes,
				};
			}
		},
	},

	methods: {
		initStartTime() {
			const time = new Date();
			let minutes = time.getMinutes() / 5;
			return {
				hours: time.getHours(),
				minutes: Math.ceil(minutes) * 5,
			};
		},

		async fetchStaffsOverload() {
			try {
				const {
					data: { data },
				} = await $api.staff.getStaffWhenCreateUnScheduleOverload({
					schedule_session: {
						date: this.date
							? dayjs(this.date).format('YYYY-MM-DD')
							: null,
						end_slot: this.getTimePayload(
							this.endTime?.hours || null,
							this.endTime?.minutes || null
						),
						start_slot: this.getTimePayload(
							this.startTime?.hours || null,
							this.startTime?.minutes || null
						),
						staff_ids: this.data[0]?.staff_ids,
						group_time_slot_id: this.data[0]?.group_time_slot_id,
						time_slot_id: this.data[0]?.time_slot_id,
						id: this.data[0]?.id,
					},
				});
				this.staffsOverload = data;
			} catch (e) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},

		async onHandleSubmit() {
			await this.fetchStaffsOverload();
			if (this.staffsOverload.length) {
				console.log(this.staffsOverload);
				this.modalIsOpenOverlap = true;
			} else {
				this.modalIsOpenOverlap = false;
				await this.submit();
			}
		},

		async submit() {
			this.inProgress = true;
			const payload = {
				schedule_session: {
					date: this.data[0].date,
					end_slot: this.getTimePayload(
						this.endTime.hours || null,
						this.endTime.minutes || null
					),
					slot_change_note: this.note,
					start_slot: this.getTimePayload(
						this.startTime.hours || null,
						this.startTime.minutes || null
					),
				},
			};
			try {
				await $api.schedule.changeTimeSlot(
					this.data[0]?.id
						? {
								id: this.data[0]?.id,
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  }
						: {
								date: this.data[0].date,
								time_slot_id: this.data[0].time_slot_id,
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  },
					this.data[0]?.student_id,
					payload
				);
				await this.$store.dispatch('setAlertMessage', {
					message: 'Đổi giờ học thành công',
				});
				this.$emit('success');
			} catch (e) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		openList(index) {
			const el = document.getElementById(
				`list-dropdown-${index}-content`
			);
			const icon = document.getElementById(`list-dropdown-${index}-icon`);
			if (el) {
				if (el.classList.contains('hidden')) {
					el.classList.remove('hidden');
					icon?.classList.add('rotate-180');
				} else {
					el.classList.add('hidden');
					icon?.classList.remove('rotate-180');
				}
			}
		},

		getStaff(staff_id) {
			return this.staffs_map[staff_id]?.[0];
		},

		getStudent(student_id) {
			return this.students_map[student_id]?.[0];
		},

		getTimePayload(hour = 0, minutes = 0) {
			return hour * 60 + minutes;
		},
	},
});
</script>
