<template>
	<div class="_create-boarding _modal bg-white">
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<img
					src="@/assets/images/illustrations/spoon.png"
					alt="Luca Education"
					title="Luca Education"
					height="60"
					width="60"
				/>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
				"
			>
				Chỉnh sửa lịch học lớp bán trú cho từng học sinh
			</div>
			<div class="text-center text-neutral-500 mt-2">
				Chọn ngày giờ học cố định cho từng học sinh hoặc sử dụng lịch
				bán trú mặc định của lớp
			</div>
			<div class="w-full mt-10">
				<div class="input-group flex flex-nowrap items-center">
					<div
						class="
							input-group__label
							text-neutral-400 text-xs
							font-semibold
							uppercase
							w-2/5
							whitespace-nowrap
						"
					>
						Tên học sinh
					</div>
					<div class="font-semibold w-full text-neutral-500">
						{{ student.name }}
					</div>
				</div>
			</div>
			<div class="w-full mt-10" v-if="boarding">
				<div class="input-group flex flex-nowrap items-start">
					<div
						class="
							input-group__label
							text-neutral-400 text-xs
							w-2/5
							whitespace-nowrap
							uppercase
							font-semibold
						"
					>
						Ngày học
					</div>
					<div class="w-full">
						<div class="w-full flex mb-4">
							<div class="input-group w-full">
								<input
									type="text"
									:value="daysOfWeekFormat(dayOfWeek)"
									disabled
								/>
							</div>
						</div>
						<div class="w-full flex gap-4">
							<div
								v-for="index in 6"
								class="flex items-center w-fit cursor-pointer"
								@click="check(index)"
								:key="index"
							>
								<Checkbox :value="dayOfWeek.includes(index)" />
								<div class="ml-2 text-sm text-neutral-700">
									T{{ index + 1 }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				md-down:px-4
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="$emit('close')"
			>
				Đóng
			</button>
			<button
				type="button"
				class="button-p text-center whitespace-nowrap"
				@click="submit"
			>
				Lưu chỉnh sửa
			</button>
		</div>
	</div>
</template>

<script>
import Checkbox from '@/components/elements/checkbox/Checkbox';
import { $api } from '@/services';
import { daysOfWeekFormat } from '@/helper/daysOfWeekFormat';

export default {
	components: { Checkbox },

	props: {
		boarding: {
			type: Object,
			required: true,
		},
		days: {
			type: Object,
			required: true,
		},
		student: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			dayOfWeek: [],
		};
	},

	watch: {
		days: {
			immediate: true,
			deep: true,
			handler(value) {
				if (value) {
					this.dayOfWeek = value || [];
				}
			},
		},
	},

	methods: {
		async submit() {
			try {
				await $api.boarding.updateCalenderStudent({
					day_care_class_id: this.boarding.id,
					days_of_week: this.dayOfWeek,
					student_id: this.student.id,
				});
				this.$emit('updated');
				this.$store.dispatch('setAlertMessage', {
					message: 'Chỉnh sửa lịch học thành công',
				});
			} catch (err) {
				if (err?.response?.data?.errors.length) {
					this.$store.dispatch('setAlertMessage', {
						message: err?.response?.data?.errors.join('<br/>'),
						type: 'danger',
					});
				} else {
					this.$store.dispatch('setAlertMessage', {
						message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
						type: 'danger',
					});
				}
			}
		},

		check(value) {
			const index = this.dayOfWeek.indexOf(value);

			if (index === -1) {
				this.dayOfWeek.push(value);
			} else {
				this.dayOfWeek = this.dayOfWeek.filter(
					(item) => item !== value
				);
			}
		},

		daysOfWeekFormat(days) {
			return daysOfWeekFormat(days);
		},
	},
};
</script>
