
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import Rating from '@/components/elements/rating/Rating.vue';
import { Options, Vue, setup } from 'vue-class-component';
import SideOver from '@/components/elements/sideOver/SideOver.vue';
import { useStore } from 'vuex';
import DaInfinityLoading from '@/components/common/daInfinityLoading/DaInfinityLoading.vue';
import { $api } from '@/services';
import Loading from '@/components/common/Loading.vue';
import { _GET_YEAR } from '@/helper/formatDate';

@Options({
	name: 'ReportPlans',
	components: { Loading, SideOver, Rating, DaIcon, DaInfinityLoading },
})
export default class ReportPlans extends Vue {
	monthlyReportList: any = [];
	month_start = '';
	stopLoad = false;
	students = [];
	payload: any = null;
	isInfinityLoading = false;
	isLoading = true;

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const store = useStore();
		return {
			store,
		};
	});

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get isAdmin() {
		return this.setup.store.getters.isAdmin;
	}

	get monthlyReports() {
		return this.monthlyReportList;
	}

	get store() {
		return this.setup.store;
	}

	get id() {
		return this.$route.params.id;
	}

	get oldPayload() {
		return {
			'x-cross': this.$route.query.org_id,
		};
	}

	//----------------------- 🤍 Created 🤍 -----------------------//
	created() {
		this.getMonthlyReports();
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	clean(obj: any) {
		for (let propName in obj) {
			if (
				obj[propName] === null ||
				obj[propName] === undefined ||
				obj[propName].length <= 0
			) {
				delete obj[propName];
			}
		}
		return obj;
	}

	async getMonthlyReports() {
		try {
			this.clean(this.payload);
			const { data }: any =
				await $api.monthlyReport.monthlyReportOldReport(
					String(this.id || ''),
					this.oldPayload,
					this.payload
				);
			this.isInfinityLoading = true;
			if (data?.data.length > 0) {
				this.monthlyReportList.push({
					title: data.latest_date
						? `Năm ${_GET_YEAR(data.latest_date)}`
						: '',
					data: [...data?.data],
				});
				this.month_start = data?.previous_year;
				this.payload = {
					...this.payload,
					date: data?.previous_year,
				};
			} else {
				this.stopLoad = true;
			}
			this.isLoading = false;
		} catch (e) {
			console.log(e);
		}
	}

	beforeDestroy() {
		this.isInfinityLoading = false;
	}
}
