<template>
	<div class="_modal bg-white">
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<svg
					width="60"
					height="60"
					viewBox="0 0 60 60"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g clip-path="url(#clip0_10522_9392)">
						<path
							d="M29.8034 3.38292C15.2228 3.38292 3.36674 15.3238 3.36674 30.001C3.36674 44.6762 15.2228 56.6171 29.8034 56.6171C44.3839 56.6171 56.2452 44.6762 56.2452 30.001C56.2452 15.3238 44.3839 3.38292 29.8034 3.38292ZM29.8034 60C13.3737 60 0 46.5418 0 30.001C0 13.4582 13.3737 0 29.8034 0C46.2382 0 59.6068 13.4582 59.6068 30.001C59.6068 46.5418 46.2382 60 29.8034 60Z"
							fill="#E37056"
						/>
						<path
							d="M52.5551 30.0011C52.5551 42.6493 42.3697 52.9026 29.8011 52.9026C17.2376 52.9026 7.05225 42.6493 7.05225 30.0011C7.05225 17.3518 17.2376 7.09799 29.8011 7.09799C42.3697 7.09799 52.5551 17.3518 52.5551 30.0011Z"
							fill="#F4C870"
						/>
						<path
							d="M29.7888 36.6172C29.0368 36.6172 28.3901 36.8821 27.8687 37.4075C27.3523 37.9309 27.0865 38.5743 27.0865 39.3213C27.0865 40.1717 27.3673 40.8485 27.9238 41.3319C28.4602 41.8023 29.0969 42.0405 29.8189 42.0405C30.5308 42.0405 31.1625 41.7988 31.6939 41.3224C32.2454 40.8318 32.5211 40.1585 32.5211 39.3213C32.5211 38.5728 32.2504 37.9273 31.719 37.404C31.1875 36.8806 30.5358 36.6172 29.7888 36.6172Z"
							fill="white"
						/>
						<path
							d="M27.7132 31.5486V31.5602C27.8085 32.5691 27.974 33.3095 28.2046 33.8227C28.4903 34.4379 29.0167 34.7629 29.7286 34.7629C30.4255 34.7629 30.9519 34.4329 31.2577 33.8076C31.5184 33.2741 31.6789 32.5474 31.7541 31.5885L32.4108 24.0243C32.486 23.3157 32.5211 22.6086 32.5211 21.9232C32.5211 20.7014 32.3607 19.7808 32.0348 19.1076C31.7841 18.5837 31.2076 17.9604 29.9091 17.9604C29.0719 17.9604 28.38 18.2456 27.8586 18.8058C27.3473 19.3594 27.0865 20.1245 27.0865 21.0799C27.0865 21.7103 27.1317 22.757 27.227 24.1943L27.7132 31.5486Z"
							fill="white"
						/>
					</g>
					<defs>
						<clipPath id="clip0_10522_9392">
							<rect width="60" height="60" fill="white" />
						</clipPath>
					</defs>
				</svg>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
					text-center
				"
			>
				Bạn muốn mời lại Phụ huynh này vào hệ thống?
			</div>
			<div class="text-center text-neutral-500 mt-2">
				Phụ huynh bạn đang mời đã tự xoá tài khoản trên hệ thống. Bạn có
				muốn mời lại Phụ huynh này vào hệ thống của cơ sở can thiệp?
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="$emit('close')"
			>
				Hủy
			</button>
			<button
				type="button"
				class="button-p text-center whitespace-nowrap"
				@click="$emit('submit')"
			>
				Xác nhận
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InviteError',
};
</script>
