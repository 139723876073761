
import { Options, Vue } from 'vue-class-component';
import Checkbox from '@/components/elements/checkbox/Checkbox.vue';
import { isEmail } from '@/helper/DAValidate';
import { staff } from '@/views/record/employeeDetail/@types/staff.interface';
import { Prop, PropSync, Watch } from 'vue-property-decorator';
import { _GET_ROLE } from '@/helper/formatEnums';

@Options({
	name: 'SendInvite',
	components: { Checkbox },
})
export default class SendInvite extends Vue {
	//----------------------- 🤍 Props 🤍 -----------------------//
	@Prop() staff!: staff;
	@PropSync('invited', { default: false, type: Boolean })
	invitedValue!: boolean;
	@PropSync('inProgress', { default: false, type: Boolean })
	inProgress!: boolean;
	@PropSync('error', { type: Object })
	error!: any;

	//----------------------- 🤍 Data 🤍 -----------------------//
	invited = false;

	email = '';

	errorMes = {
		email: '',
	};

	@Watch('error', { deep: true })
	onErrorChange(value: any) {
		this.errorMes = value;
	}

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get isEmailValid() {
		return isEmail(this.email);
	}

	get getRole() {
		return _GET_ROLE;
	}

	//----------------------- 🤍 Mounted 🤍 -----------------------//
	mounted() {
		this.email = this.staff.email ? this.staff.email : '';
	}

	//----------------------- 🤍 Submit 🤍 -----------------------//
	submit() {
		if (this.staff.state == 'invited') {
			this.$emit('resend', {
				id: this.staff.id,
				email: this.email,
			});
		} else {
			this.$emit('submit', {
				email: this.email,
				staff_id: this.staff.id,
			});
		}
	}
}
