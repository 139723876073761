<template>
	<DaModalFrame v-model:open="modalIsOpenOverlap">
		<ErrorOverlap
			@close="modalIsOpenOverlap = false"
			@submit="submit"
			:current-session="{
				...data[0],
				groupClass: getClass(data[0].group_class_id),
				staffs: getStaffs,
			}"
			:data-staffs="staffsOverload"
		/>
	</DaModalFrame>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 w-screen md:w-3/5">
		<div class="h-full w-full flex flex-col bg-white shadow-xl">
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center" @click="$emit('close')">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12 5.25C12 4.83579 11.6642 4.5 11.25 4.5C10.8358 4.5 10.5 4.83579 10.5 5.25V13.5C10.5 13.7691 10.6442 14.0177 10.8779 14.1512L16.1279 17.1512C16.4875 17.3567 16.9457 17.2317 17.1512 16.8721C17.3567 16.5125 17.2317 16.0543 16.8721 15.8488L12 13.0648V5.25Z"
								fill="#94A3B8"
							/>
						</svg>

						<div
							class="
								text-2xl
								font-bold
								text-neutral-700
								ml-2
								mt-1
							"
						>
							Đổi giờ học
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							type="button"
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div
				class="
					sideover-content
					pt-6
					relative
					flex-1
					px-4
					sm:px-6
					overflow-y-auto
				"
			>
				<div class="relative">
					<div class="mt-10">
						<div class="form-group flex flex-col gap-10">
							<div class="input-group w-full">
								<div
									class="
										input-group__label
										w-56
										font-semibold
										text-neutral-600
									"
								>
									Giờ bắt đầu
								</div>
								<VueDatepicker
									class="input-date w-full"
									timePicker
									placeholder="Giờ bắt đầu"
									autoApply
									v-model="startTime"
									uid="start_timeslot_hour"
									minutesIncrement="5"
								>
									<template #input-icon>
										<DaIcon
											name="das--lock"
											class="h-4 w-4 text-neutral-400"
										/>
									</template>
								</VueDatepicker>
							</div>
							<div class="input-group w-full">
								<div
									class="
										input-group__label
										w-56
										font-semibold
										text-neutral-600
									"
								>
									Giờ kết thúc
								</div>
								<VueDatepicker
									v-model="endTime"
									class="input-date w-full"
									timePicker
									placeholder="Giờ kết thúc"
									autoApply
									uid="end_timeslot_hour"
									minutesIncrement="5"
								>
									<template #input-icon>
										<DaIcon
											name="das--lock"
											class="h-4 w-4 text-neutral-400"
										/>
									</template>
								</VueDatepicker>
							</div>
						</div>
					</div>
					<div class="mt-10">
						<div class="font-semibold text-neutral-600 text-sm">
							Ghi chú*
						</div>
						<div class="w-full mt-2">
							<div class="input-group flex items-center">
								<textarea
									v-model="note"
									placeholder="Ghi chú về sự thay đổi này"
								>
								</textarea>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-6" :id="`list-dropdown-1`">
					<div
						class="
							bg-neutral-50
							flex
							items-center
							justify-between
							p-3
							border
							rounded-t
							cursor-pointer
						"
						@click="openList(1)"
					>
						<div>Số buổi đã chọn</div>
						<div class="relative">
							<div class="pr-10">{{ data.length }} buổi</div>
							<div
								class="
									absolute
									top-1/2
									right-0
									transform
									-translate-y-1/2
									transition-all
									duration-200
								"
								:id="`list-dropdown-1-icon`"
							>
								<svg
									width="12"
									height="6"
									viewBox="0 0 12 6"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10.5 5.25L6 0.75L1.5 5.25"
										stroke="#48566A"
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div
						class="px-4 border border-t-0 rounded-b"
						:id="`list-dropdown-1-content`"
						v-if="data"
					>
						<div
							v-for="(item, index) in data"
							:key="index"
							class="py-4"
						>
							<div class="flex items-center justify-between">
								<div
									class="flex items-center w-1/3"
									v-if="
										item.group_class_id &&
										getClass(item.group_class_id)
									"
								>
									<div
										@click.stop="
											$router.push(
												`/group-classes/${item.group_class_id}`
											)
										"
										class="flex items-center gap-4"
										v-if="
											item.group_class_id &&
											getClass(item.group_class_id)
										"
									>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<g
												clip-path="url(#clip0_21795_4077)"
											>
												<path
													fill-rule="evenodd"
													clip-rule="evenodd"
													d="M7.34111 8.43768C7.34111 9.90561 6.15158 11.1204 4.65835 11.1204C3.16511 11.1204 1.97559 9.8803 1.97559 8.41238C1.97559 6.94445 3.16511 5.72961 4.65835 5.72961C6.12627 5.72961 7.34111 6.96976 7.34111 8.43768ZM6.60714 11.3989C7.46765 11.7279 8.1763 12.3606 8.60656 13.1958C7.8979 13.7526 7.3158 14.4866 7.01209 15.3724C6.86023 15.8026 6.73369 16.2835 6.63245 16.7391H0.634203C0.279876 16.7391 -0.0238327 16.4354 0.00147638 16.0557C0.0520945 15.2458 0.229258 14.436 0.507658 13.6767C0.887294 12.6137 1.69718 11.8038 2.70955 11.3989C3.26635 11.7785 3.94969 11.981 4.65834 11.981C5.367 11.981 6.05034 11.7785 6.60714 11.3989ZM23.5136 13.6767C23.134 12.6137 22.3241 11.8038 21.3117 11.3989C20.7296 11.7785 20.0716 11.981 19.3376 11.981C18.6037 11.981 17.9456 11.7785 17.3888 11.3989C16.5283 11.7279 15.8197 12.3606 15.3894 13.1958C16.0981 13.7526 16.6802 14.4866 16.9839 15.3724C17.1357 15.828 17.2623 16.2835 17.3635 16.7391H23.3618C23.7414 16.7391 24.0451 16.4354 23.9945 16.0557C23.9692 15.2458 23.792 14.436 23.5136 13.6767ZM22.0204 8.43768C22.0204 9.90561 20.8309 11.1204 19.3377 11.1204C17.8697 11.1204 16.6549 9.8803 16.6549 8.41238C16.6549 6.94445 17.8444 5.72961 19.3377 5.72961C20.8309 5.72961 22.0204 6.96976 22.0204 8.43768ZM13.9468 13.373C13.39 13.7526 12.7066 13.9551 11.998 13.9551C11.2893 13.9551 10.606 13.7526 10.0492 13.373C9.03682 13.7779 8.20162 14.5878 7.82199 15.6508C7.54359 16.4101 7.39173 17.22 7.31581 18.0299C7.2905 18.4095 7.59421 18.7132 7.97384 18.7132H16.0221C16.4018 18.7132 16.7055 18.4095 16.6802 18.0299C16.6295 17.22 16.4524 16.4101 16.174 15.6508C15.7943 14.5878 14.9591 13.7779 13.9468 13.373ZM14.6808 10.4118C14.6808 11.8798 13.4913 13.0946 11.9981 13.0946C10.5048 13.0946 9.31531 11.9051 9.31531 10.4118C9.31531 8.9439 10.5301 7.72906 11.9981 7.72906C13.466 7.72906 14.6808 8.91859 14.6808 10.4118Z"
													fill="#94A3B8"
												/>
											</g>
											<defs>
												<clipPath id="clip0_21795_4077">
													<rect
														width="24"
														height="24"
														fill="white"
													/>
												</clipPath>
											</defs>
										</svg>
										<div>
											{{
												getClass(item.group_class_id)
													?.name
											}}
										</div>
									</div>
								</div>
								<div class="w-1/3">
									<div
										v-if="item.staff_ids"
										class="flex flex-col gap-2"
									>
										<div
											@click.stop="
												$router.push(
													`/record/employee/${staff_id}`
												)
											"
											class="flex items-center"
											v-for="staff_id in item.staff_ids"
											:key="staff_id"
										>
											<div v-if="getStaff(staff_id)">
												<img
													class="
														shadow-sm
														inline-block
														h-9
														w-9
														rounded-full
													"
													:src="
														getStaff(staff_id)
															?.profile_photo
															?.w200
													"
													alt=""
													v-if="
														getStaff(staff_id)
															?.profile_photo
															?.w200
													"
												/>
												<span
													v-else
													class="
														inline-flex
														items-center
														justify-center
														h-9
														w-9
														rounded-full
													"
													:style="{
														background:
															getStaff(staff_id)
																?.profile_photo
																?.default
																?.color,
													}"
												>
													<span
														class="
															font-medium
															leading-none
															text-white text-xs
														"
														>{{
															getStaff(staff_id)
																?.profile_photo
																?.default
																?.initial
														}}</span
													>
												</span>
											</div>
											<div
												v-if="getStaff(staff_id)"
												class="
													ml-4
													text-blue-600
													font-semibold
												"
											>
												{{ getStaff(staff_id).name }}
											</div>
										</div>
									</div>
								</div>
								<div class="text-gray-600">
									<span v-if="item.date">
										{{ $filters.formatDate2(item.date) }}
									</span>
									<span>
										- {{ item.display_start_slot }}</span
									>
									<span> - {{ item.display_end_slot }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="px-6 py-4 border-t border-yellow-50">
				<div class="flex justify-end">
					<div>
						<button
							type="button"
							class="button-p text-center px-6"
							@click="onHandleSubmit"
							:disabled="!isValidate || inProgress"
						>
							Xác nhận
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import { $api } from '@/services';
import DaIcon from '@/components/elements/daIcon/DaIcon';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import ErrorOverlap from '@/components/elements/ErrorOverload.vue';

export default defineComponent({
	name: 'ChangeTimeSlotGroupClass',
	components: { ErrorOverlap, DaModalFrame, DaIcon },
	props: {
		data: {
			type: Array,
			required: true,
		},

		staffs: {
			type: Array,
			required: true,
		},

		group_classes: {
			type: Array,
			required: true,
		},
	},

	data() {
		return {
			date: new Date(),
			startTime: null,
			endTime: null,
			note: '',
			inProgress: false,
			staffsOverload: [],
			modalIsOpenOverlap: false,
		};
	},

	computed: {
		isValidate() {
			return this.startTime && this.endTime && this.note;
		},

		getStaffs() {
			return this.data[0]?.staff_ids.map((i) => this.getStaff(i));
		},
	},

	created() {
		this.startTime = this.initStartTime();
	},

	watch: {
		startTime(value) {
			if (!value) {
				this.startTime = this.initStartTime();
			} else {
				this.endTime = {
					hours: value.hours + 1,
					minutes: value.minutes,
				};
			}
		},

		endTime(value) {
			if (!value) {
				this.endTime = {
					hours: this.startTime.hours + 1,
					minutes: this.startTime.minutes,
				};
			}
		},
	},

	methods: {
		initStartTime() {
			const time = new Date();
			let minutes = time.getMinutes() / 5;
			return {
				hours: time.getHours(),
				minutes: Math.ceil(minutes) * 5,
			};
		},

		async onHandleSubmit() {
			await this.fetchStaffsOverload();
			if (this.staffsOverload.length) {
				this.modalIsOpenOverlap = true;
			} else {
				this.modalIsOpenOverlap = false;
				await this.submit();
			}
		},

		async fetchStaffsOverload() {
			try {
				const payload = {
					date: this.data[0].date,
					end_slot: this.getTimePayload(
						this.endTime.hours || null,
						this.endTime.minutes || null
					),
					start_slot: this.getTimePayload(
						this.startTime.hours || null,
						this.startTime.minutes || null
					),
					staff_ids: this.data[0]?.staff_ids,
					group_time_slot_id:
						this.data[0]?.group_time_slot_id || undefined,
				};
				const {
					data: { data },
				} = await $api.groupClasses.getOverloads(payload);
				this.staffsOverload = data;
			} catch (e) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},

		async submit() {
			this.inProgress = true;
			const payload = {
				date: this.data[0].date,
				end_slot: this.getTimePayload(
					this.endTime.hours || null,
					this.endTime.minutes || null
				),
				slot_change_note: this.note,
				group_class_id: this.data[0]?.group_class_id || undefined,
				group_session_id: this.data[0]?.group_session_id || undefined,
				group_time_slot_id:
					this.data[0]?.group_time_slot_id || undefined,
				start_slot: this.getTimePayload(
					this.startTime.hours || null,
					this.startTime.minutes || null
				),
			};
			try {
				await $api.groupClasses.changeTimeSlot(
					this.data[0]?.id,
					payload
				);
				await this.$store.dispatch('setAlertMessage', {
					message: 'Đổi giờ học thành công',
				});
				this.$emit('success');
			} catch (e) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		openList(index) {
			const el = document.getElementById(
				`list-dropdown-${index}-content`
			);
			const icon = document.getElementById(`list-dropdown-${index}-icon`);
			if (el) {
				if (el.classList.contains('hidden')) {
					el.classList.remove('hidden');
					icon?.classList.add('rotate-180');
				} else {
					el.classList.add('hidden');
					icon?.classList.remove('rotate-180');
				}
			}
		},

		getStaff(staff_id) {
			return this.staffs?.find((s) => s.id === staff_id);
		},

		getClass(class_id) {
			return this.group_classes?.find((s) => s.id === class_id);
		},

		getTimePayload(hour = 0, minutes = 0) {
			return hour * 60 + minutes;
		},
	},
});
</script>
