<template>
	<div class="_change-status _modal bg-white">
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-10
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<svg
					v-if="success"
					width="60"
					height="60"
					viewBox="0 0 60 60"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M29.8034 3.38292C15.2228 3.38292 3.36674 15.3238 3.36674 30.001C3.36674 44.6762 15.2228 56.6171 29.8034 56.6171C44.3839 56.6171 56.2452 44.6762 56.2452 30.001C56.2452 15.3238 44.3839 3.38292 29.8034 3.38292ZM29.8034 60C13.3737 60 0 46.5418 0 30.001C0 13.4582 13.3737 0 29.8034 0C46.2382 0 59.6068 13.4582 59.6068 30.001C59.6068 46.5418 46.2382 60 29.8034 60Z"
						fill="#2AA797"
					/>
					<path
						d="M52.5546 30.0009C52.5546 42.6492 42.3692 52.9025 29.8006 52.9025C17.2371 52.9025 7.05176 42.6492 7.05176 30.0009C7.05176 17.3516 17.2371 7.09787 29.8006 7.09787C42.3692 7.09787 52.5546 17.3516 52.5546 30.0009Z"
						fill="#F0BB4F"
					/>
					<path
						d="M27.6707 40.4L19.4697 31.4556L23.2598 27.9308L27.4082 32.4568L37.7082 19.6L41.7214 22.8604L27.6707 40.4Z"
						fill="white"
					/>
				</svg>
				<svg
					v-else
					width="60"
					height="60"
					viewBox="0 0 60 60"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g clip-path="url(#clip0_30911_5230)">
						<path
							d="M29.8034 3.38292C15.2228 3.38292 3.36674 15.3238 3.36674 30.001C3.36674 44.6762 15.2228 56.6171 29.8034 56.6171C44.3839 56.6171 56.2452 44.6762 56.2452 30.001C56.2452 15.3238 44.3839 3.38292 29.8034 3.38292ZM29.8034 60C13.3737 60 0 46.5418 0 30.001C0 13.4582 13.3737 0 29.8034 0C46.2382 0 59.6068 13.4582 59.6068 30.001C59.6068 46.5418 46.2382 60 29.8034 60Z"
							fill="#E37056"
						/>
						<path
							d="M52.5551 30.0009C52.5551 42.6492 42.3697 52.9025 29.8011 52.9025C17.2376 52.9025 7.05225 42.6492 7.05225 30.0009C7.05225 17.3516 17.2376 7.09787 29.8011 7.09787C42.3697 7.09787 52.5551 17.3516 52.5551 30.0009Z"
							fill="#F0BB4F"
						/>
						<path
							d="M29.7887 36.6172C29.0367 36.6172 28.3899 36.8821 27.8685 37.4075C27.3522 37.9309 27.0864 38.5743 27.0864 39.3213C27.0864 40.1717 27.3672 40.8485 27.9237 41.3319C28.4601 41.8023 29.0968 42.0405 29.8187 42.0405C30.5306 42.0405 31.1624 41.7988 31.6938 41.3224C32.2453 40.8318 32.521 40.1585 32.521 39.3213C32.521 38.5728 32.2503 37.9273 31.7188 37.404C31.1874 36.8806 30.5357 36.6172 29.7887 36.6172Z"
							fill="white"
						/>
						<path
							d="M27.7131 31.5486V31.5602C27.8084 32.5691 27.9738 33.3095 28.2045 33.8227C28.4902 34.4379 29.0166 34.7629 29.7285 34.7629C30.4254 34.7629 30.9518 34.4329 31.2576 33.8076C31.5183 33.2741 31.6788 32.5474 31.754 31.5885L32.4107 24.0243C32.4859 23.3157 32.521 22.6086 32.521 21.9232C32.521 20.7014 32.3606 19.7808 32.0347 19.1076C31.784 18.5837 31.2075 17.9604 29.909 17.9604C29.0718 17.9604 28.3799 18.2456 27.8585 18.8058C27.3471 19.3594 27.0864 20.1245 27.0864 21.0799C27.0864 21.7103 27.1316 22.757 27.2268 24.1943L27.7131 31.5486Z"
							fill="white"
						/>
					</g>
					<defs>
						<clipPath id="clip0_30911_5230">
							<rect width="60" height="60" fill="white" />
						</clipPath>
					</defs>
				</svg>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
				"
			>
				{{
					success
						? 'Kích hoạt gói đăng ký thành công'
						: error
						? 'Chưa thể kích hoạt gói đăng ký mới'
						: 'Kích hoạt gói đăng ký mới'
				}}
			</div>
			<div class="text-center text-neutral-500 mt-2" v-if="error">
				{{ errorMessage }}
				<a
					target="_blank"
					class="underline text-green-500 font-medium cursor-pointer"
					href="https://docs.google.com/forms/d/1wbi3QVCwCLJV5odp3JE0S14BZUNMpvs8y9NtmTbVGpk/viewform?edit_requested=true"
					>tại đây</a
				>
			</div>
			<div class="text-center text-neutral-500 mt-2" v-else>
				{{
					success
						? 'Gói đăng ký mới đã được kích hoạt thành công'
						: 'Gói đăng ký cũ đã hết hạn, tuy nhiên bạn đã đăng ký gói mới. Vui lòng kích hoạt gói đăng ký mới để sử dụng'
				}}
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				type="button"
				@click="$emit('close')"
			>
				Đóng
			</button>
			<div
				v-if="error"
				@click="router.push('/record/student')"
				class="button-p text-center whitespace-nowrap cursor-pointer"
				type="button"
			>
				Khoá học sinh
			</div>
			<div
				v-if="!success && !error"
				@click="activeCentre"
				class="button-p text-center whitespace-nowrap cursor-pointer"
				type="button"
			>
				Xác nhận
			</div>
		</div>
	</div>
</template>

<script setup>
import { defineProps, ref, defineEmits } from 'vue';
import { $api } from '@/services';

const props = defineProps(['id']);
const success = ref(false);
const error = ref(false);
const errorMessage = ref('');
import router from '@/router';

const emit = defineEmits(['success']);

const activeCentre = async () => {
	try {
		const res = await $api.staff.activeCentre(props.id);
		console.log('res', res);
		if (res.data) {
			success.value = true;
			emit('success');
		}
	} catch (e) {
		error.value = true;
		errorMessage.value = e.response?.data.errors[0];
	}
};
</script>
