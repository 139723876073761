
import { Options, setup, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ACTIONS } from '@/enums/Common';
import Multiselect from '@suadelabs/vue3-multiselect';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { $api } from '@/services';
import Checkbox from '@/components/elements/checkbox/Checkbox.vue';
import { daysOfWeekFormatWithSunday } from '@/helper/daysOfWeekFormat';
import { toEnglish } from '@/helper/toEnglish';
import { useStore } from 'vuex';
import SwitchButton from '@/components/elements/switchButton/SwitchButton.vue';

@Options({
	components: { SwitchButton, Checkbox, DaIcon, Multiselect },
})
export default class CreateBoarding extends Vue {
	//----------------------- 🤍 Props 🤍 -----------------------//
	@Prop() boardingData!: any;
	@Prop() staffs!: any;

	start_opening_hour_DayCare: any = '';
	end_opening_hour_DayCare: any = '';
	steps = 1;
	turnOn = false;

	//----------------------- 🤍 Methods 🤍 -----------------------//
	boarding: { name: string; working_days: number[] } = {
		name: '',
		working_days: [],
	};
	setup = setup(() => {
		const store = useStore();
		return {
			store,
		};
	});
	staffsBoarding: any = [];
	error: null | string = null;
	mode = ACTIONS.CREATE;
	count: any = null;
	daySelectedDayCare: number[] = [];
	selectedDayCare = '';
	defaultWorkingDays: number[] = [];

	get org_setting() {
		return this.setup.store.state.profile.current_org;
	}

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get description() {
		if (this.mode === ACTIONS.CREATE) {
			return 'Bạn hãy điền tên lớp bán trú và ấn "enter" để tạo lớp bán trú.';
		} else {
			return 'Chỉnh sửa tên và giáo viên lớp bán trú.';
		}
	}

	get title() {
		if (this.mode === ACTIONS.CREATE) {
			return 'Tạo lớp bán trú';
		} else {
			return 'Chỉnh sửa lớp bán trú';
		}
	}

	get _staffs() {
		return this.staffs.filter((item: any) => item.state === 'active');
	}

	get actions() {
		return ACTIONS;
	}

	get daySelectedListDayCare() {
		return this.daySelectedDayCare.sort();
	}

	@Watch('selectedDayCare')
	onSelectedDayCareChange(value: string) {
		const index = this.daySelectedDayCare.indexOf(+value);

		if (index == -1) {
			this.daySelectedDayCare.push(+value);
		}
	}

	mounted() {
		if (this.boardingData) {
			this.boarding = this.boardingData;
			this.mode = ACTIONS.UPDATE;
			this.fetchStaffs(this.boardingData.id);
		} else {
			this.boarding = {
				name: '',
				working_days: [],
			};
			this.mode = ACTIONS.CREATE;
		}

		if (this.org_setting.allow_create_day_care_class) {
			this.steps = 2;
			this.turnOn = true;
		}

		this.buildFormDayOffs();
	}

	buildFormDayOffs() {
		this.defaultWorkingDays = [];

		const org = {
			monday: !this.org_setting?.day_care_monday,
			tuesday: !this.org_setting?.day_care_tuesday,
			thursday: !this.org_setting?.day_care_thursday,
			wednesday: !this.org_setting?.day_care_wednesday,
			friday: !this.org_setting?.day_care_friday,
			saturday: !this.org_setting?.day_care_saturday,
			sunday: !this.org_setting?.day_care_sunday,
		};

		if (org.monday) {
			this.defaultWorkingDays.push(1);
		}

		if (org.tuesday) {
			this.defaultWorkingDays.push(2);
		}

		if (org.wednesday) {
			this.defaultWorkingDays.push(3);
		}

		if (org.thursday) {
			this.defaultWorkingDays.push(4);
		}

		if (org.friday) {
			this.defaultWorkingDays.push(5);
		}

		if (org.saturday) {
			this.defaultWorkingDays.push(6);
		}

		if (org.sunday) {
			this.defaultWorkingDays.push(7);
		}
	}

	async fetchStaffs(id: string) {
		try {
			const { data } = await $api.boarding.membershipsStaffList(id);
			if (data) {
				this.staffsBoarding = this.staffs.filter((s: any) =>
					data?.data?.find((d: any) => d.staff_id === s.id)
				);

				if (!this.staffsBoarding?.length) {
					this.staffsBoarding.push(null);
				}
			}
		} catch (e) {
			console.log(e);
		}
	}

	daysOfWeekFormat(days: any[]) {
		return daysOfWeekFormatWithSunday(days);
	}

	toEnglish(string = '') {
		return toEnglish(string);
	}

	submit() {
		if (this.boarding.name?.length <= 0) {
			return (this.error = 'Tên lớp bán trú không được bỏ trống');
		}
		if (this.mode === ACTIONS.CREATE) {
			this.$emit('submit', {
				...this.boarding,
				working_days: [1, 2, 3, 4, 5, 6, 7].filter(
					(item) =>
						![
							...this.defaultWorkingDays,
							...this.boarding.working_days,
						].includes(item)
				),
				staff_ids: this.staffsBoarding
					.filter((s: any) => !!s)
					.map((s: any) => s.id),
			});
		} else {
			this.$emit('update', {
				...this.boarding,
				staff_ids: this.staffsBoarding
					.filter((s: any) => !!s)
					.map((s: any) => s.id),
			});
		}
	}

	removeDayCare(day: number | string) {
		const index = this.daySelectedDayCare.indexOf(+day);
		if (index !== -1) {
			this.daySelectedDayCare.splice(index, 1);
		}
	}

	check(value: any) {
		if (this.defaultWorkingDays.includes(value)) {
			return;
		}

		const index = this.boarding.working_days.indexOf(value);

		if (index === -1) {
			this.boarding.working_days.push(value);
		} else {
			this.boarding.working_days = this.boarding.working_days.filter(
				(item) => item !== value
			);
		}
	}

	deleteStaff(index: number | string) {
		this.staffsBoarding.splice(index, 1);
	}

	addStaff() {
		this.staffsBoarding.push(null);
	}

	addZero(value: string | null) {
		if (value && value.toString()?.length <= 1) {
			return '0' + value;
		}
		if (value == '0') {
			return '00';
		}
		return value;
	}

	async setupCenter() {
		let day_care_end_opening_hour: null | string = null;
		let day_care_start_opening_hour: null | string = null;
		if (this.end_opening_hour_DayCare) {
			day_care_end_opening_hour =
				this.addZero(this.end_opening_hour_DayCare.hours) +
				':' +
				this.addZero(this.end_opening_hour_DayCare.minutes);
		}
		if (this.start_opening_hour_DayCare) {
			day_care_start_opening_hour =
				this.addZero(this.start_opening_hour_DayCare.hours) +
				':' +
				this.addZero(this.start_opening_hour_DayCare.minutes);
		}
		const params = {
			day_care_end_opening_hour,
			day_care_start_opening_hour,
			day_care_monday: !this.daySelectedDayCare.includes(1),
			day_care_tuesday: !this.daySelectedDayCare.includes(2),
			day_care_thursday: !this.daySelectedDayCare.includes(3),
			day_care_wednesday: !this.daySelectedDayCare.includes(4),
			day_care_friday: !this.daySelectedDayCare.includes(5),
			day_care_saturday: !this.daySelectedDayCare.includes(6),
			day_care_sunday: !this.daySelectedDayCare.includes(7),
			has_day_care: true,
		};

		try {
			const { data } = await $api.org.updateOrg({ org: params });

			if (data.data) {
				await this.setup.store.dispatch('setProfile', {
					...this.setup.store.state.profile,
					current_org: {
						...data.data,
						allow_create_day_care_class: true,
					},
				});
				this.buildFormDayOffs();
				this.steps = 2;
			}
		} catch (err) {
			if (err?.response?.data?.errors_by_field['start_opening_hour']) {
				const error =
					'Giờ bắt đầu ' +
					err?.response?.data?.errors_by_field[
						'start_opening_hour'
					].join('<br/>');
				await this.setup.store.dispatch('setAlertMessage', {
					message: error,
					type: 'danger',
				});
			} else {
				const error = err?.response?.data?.errors || err.message;
				await this.setup.store.dispatch('setAlertMessage', {
					message: error.join('<br/>'),
					type: 'danger',
				});
			}
		}
	}
}
