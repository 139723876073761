<template>
	<div class="_create-boarding _modal bg-white">
		<div
			class="
				_modal__content
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<img
					src="@/assets/images/illustrations/warning.png"
					alt="Luca Education"
					title="Luca Education"
					height="60"
					width="60"
				/>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
					text-center
				"
			>
				Xoá Tài liệu tham khảo
			</div>
			<div class="text-center text-neutral-500 mt-2">
				Tài liệu này sẽ được xoá khỏi hệ thống
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="close()"
			>
				Quay lại
			</button>
			<button
				type="button"
				class="button-p text-center whitespace-nowrap"
				@click="submit"
			>
				Xác nhận
			</button>
		</div>
	</div>
</template>

<script>
import { $api } from '@/services';

export default {
	name: 'DeleteDocument',

	props: {
		data: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			success: false,
			error: '',
		};
	},

	methods: {
		async submit() {
			try {
				await $api.org.deleteDocuments(this.data.id);
				await this.$store.dispatch('setAlertMessage', {
					message: 'Xoá tài liệu thành công',
				});
				this.success = true;
				this.close();
			} catch (e) {
				this.error =
					e?.response?.data?.errors ||
					'Đã có lỗi xảy ra. Vui lòng thử lại';
			}
		},

		close() {
			if (this.success) {
				this.$emit('success');
				this.$emit('close');
			} else {
				this.$emit('close');
			}
		},
	},
};
</script>
