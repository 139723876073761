<template>
	<DaModalFrame v-model:open="modalIsOpen">
		<ConfirmSendReportTarget
			v-if="modalType === type.CREATE"
			:name="reportName"
			@close="cancel"
			@submit="sendNotification"
		/>
		<LoadingDowloadPDF v-if="modalType === type.LOADING" @close="cancel" />
	</DaModalFrame>
	<div v-if="!isLoading">
		<div class="tabs flex mb-10 gap-4" v-if="listVideo.length > 0">
			<div v-for="tab in tabs" :key="tab.value">
				<div
					:class="{ 'tab-selected': tabSelected == tab.value }"
					class="tab flex items-center justify-center cursor-pointer"
					@click="tabSelected = tab.value"
				>
					{{ tab.name }}
				</div>
			</div>
		</div>
		<div v-if="tabSelected == 0">
			<div
				v-if="data?.id"
				class="p-6 bg-white shadow-sm rounded-2xl mb-10"
			>
				<div>
					<div class="flex items-center justify-between mb-6">
						<div class="font-semibold text-neutral-600">
							Kế hoạch {{ data?.order }}
						</div>
						<div class="flex items-center">
							<div
								v-if="data?.apply_at"
								class="font-semibold text-neutral-600 mr-8"
							>
								Áp dụng:
								{{ $filters.formatDate(data?.apply_at) }}
							</div>
							<Menu
								v-if="!is_old_data"
								as="div"
								class="ml-3 relative"
							>
								<div>
									<MenuButton
										class="
											rounded-full
											flex
											text-sm
											focus:outline-none
											focus:ring-2
											focus:ring-offset-2
											focus:ring-indigo-500
										"
									>
										<span class="sr-only"
											>Open user menu</span
										>
										<svg
											fill="none"
											height="20"
											viewBox="0 0 20 20"
											width="20"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												clip-rule="evenodd"
												d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
												fill="#94A3B8"
												fill-rule="evenodd"
											/>
										</svg>
									</MenuButton>
								</div>
								<transition
									enter-active-class="transition ease-out duration-200"
									enter-from-class="transform opacity-0 scale-95"
									enter-to-class="transform opacity-100 scale-100"
									leave-active-class="transition ease-in duration-75"
									leave-from-class="transform opacity-100 scale-100"
									leave-to-class="transform opacity-0 scale-95"
								>
									<MenuItems
										class="
											origin-top-right
											absolute
											right-0
											mt-2
											w-60
											rounded-md
											py-1
											bg-white
											focus:outline-none
											z-10
											shadow-sm
											border border-yellow-100
										"
									>
										<MenuItem
											v-if="data"
											v-slot="{ active }"
											@click="
												$router.push(
													`/create-group/${data.id}?student_id=${id}`
												)
											"
										>
											<div
												:class="active ? '' : ''"
												class="
													p-2
													px-4
													bg-white
													z-10
													text-gray-600
													hover:bg-neutral-50
													flex
													items-center
													gap-4
													cursor-pointer
												"
											>
												<svg
													fill="none"
													height="16"
													viewBox="0 0 16 16"
													width="16"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														clip-rule="evenodd"
														d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V7.5H4.5C4.22386 7.5 4 7.72386 4 8C4 8.27614 4.22386 8.5 4.5 8.5H7.5V11.5C7.5 11.7761 7.72386 12 8 12C8.27614 12 8.5 11.7761 8.5 11.5V8.5H11.5C11.7761 8.5 12 8.27614 12 8C12 7.72386 11.7761 7.5 11.5 7.5H8.5V4.5Z"
														fill="#94A3B8"
														fill-rule="evenodd"
													/>
												</svg>
												<span class="mt-0.5"
													>Tạo nhóm mới</span
												>
											</div>
										</MenuItem>
										<MenuItem
											v-if="data && !data.sent_at"
											v-slot="{ active }"
											@click="confirmSendOpen"
										>
											<div
												:class="active ? '' : ''"
												class="
													p-2
													px-4
													bg-white
													z-10
													text-gray-600
													hover:bg-neutral-50
													flex
													items-center
													gap-4
													cursor-pointer
												"
											>
												<svg
													fill="none"
													height="16"
													viewBox="0 0 16 16"
													width="16"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M13.9759 2.73814L9.31765 15.7438C9.26208 15.8975 9.1162 15.9999 8.95276 16H8.82854C8.67608 16 8.53718 15.9124 8.47141 15.7748L6.63918 11.8926C6.36938 11.3202 6.4738 10.6414 6.90314 10.1766L9.23225 7.62981C9.3752 7.47984 9.3752 7.24403 9.23225 7.09406L8.93723 6.799C8.78728 6.65604 8.55149 6.65604 8.40154 6.799L5.85504 9.12837C5.39031 9.55775 4.71155 9.66219 4.13926 9.39236L0.257408 7.55993C0.107709 7.50356 0.00662856 7.36264 0.00120576 7.20276V7.07853C-0.0125429 6.90383 0.0924328 6.74158 0.257408 6.68254L13.2616 2.0238C13.4014 1.97246 13.5583 2.00564 13.6653 2.10921L13.8672 2.31109C13.9885 2.41709 14.0318 2.58703 13.9759 2.73814Z"
														fill="#94A3B8"
													/>
												</svg>

												<span class="mt-0.5"
													>Gửi tới phụ huynh</span
												>
											</div>
										</MenuItem>
										<MenuItem
											v-if="
												data?.id &&
												data?.monthly_report?.state !==
													'draft' &&
												data?.monthly_report?.state !==
													'completed'
											"
											v-slot="{ active }"
											@click="toEdit"
										>
											<div
												:class="active ? '' : ''"
												class="
													p-2
													px-4
													bg-white
													z-10
													text-gray-600
													hover:bg-neutral-50
													flex
													items-center
													gap-4
													cursor-pointer
												"
											>
												<svg
													fill="none"
													height="16"
													viewBox="0 0 16 16"
													width="16"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														clip-rule="evenodd"
														d="M12.4705 6.65175L13.7105 5.41172C13.8463 5.27586 13.9143 5.20793 13.9669 5.14597C14.6011 4.39929 14.6011 3.30311 13.9669 2.55644C13.9143 2.49448 13.8463 2.42655 13.7105 2.29069C13.5746 2.15482 13.5067 2.08689 13.4447 2.03426C12.6981 1.40004 11.6019 1.40004 10.8552 2.03426C10.7933 2.08688 10.7253 2.15479 10.5895 2.29059L10.5895 2.29067L10.5894 2.29069L9.33123 3.5489C10.0749 4.84309 11.1586 5.91837 12.4705 6.65175ZM7.87657 5.00356L2.86297 10.0172C2.43791 10.4422 2.22538 10.6547 2.08565 10.9158C1.94591 11.1769 1.88697 11.4717 1.76908 12.0611L1.37342 14.0394C1.3069 14.372 1.27364 14.5383 1.36825 14.6329C1.46286 14.7275 1.62916 14.6943 1.96177 14.6277L3.94005 14.2321L3.94007 14.2321L3.94009 14.2321C4.52951 14.1142 4.82423 14.0553 5.08532 13.9155C5.34641 13.7758 5.55894 13.5633 5.98398 13.1382L5.984 13.1382L5.98401 13.1382L11.012 8.11019C9.75135 7.31198 8.68226 6.25011 7.87657 5.00356Z"
														fill="#94A3B8"
														fill-rule="evenodd"
													/>
												</svg>

												<span class="mt-0.5"
													>Chỉnh sửa</span
												>
											</div>
										</MenuItem>
										<MenuItem
											v-if="groups?.length"
											v-slot="{ active }"
											@click="downloadCSV(id, data.id)"
										>
											<div
												:class="active ? '' : ''"
												class="
													p-2
													px-4
													bg-white
													z-10
													text-gray-600
													hover:bg-neutral-50
													flex
													items-center
													gap-4
													cursor-pointer
												"
											>
												<svg
													fill="none"
													height="16"
													viewBox="0 0 16 16"
													width="16"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														clip-rule="evenodd"
														d="M8 0C6.57359 0 5.31176 0.560456 4.40576 1.34182C3.63985 2.00237 3.08479 2.8617 2.94223 3.725C1.26586 4.09512 0 5.55511 0 7.31818C0 9.36599 1.70754 11 3.78125 11H7.5V5.5C7.5 5.22386 7.72386 5 8 5C8.27614 5 8.5 5.22386 8.5 5.5V11H12.6875C14.5023 11 16 9.56949 16 7.77273C16 6.13656 14.7581 4.80411 13.1657 4.57898C12.9233 1.99944 10.6902 0 8 0Z"
														fill="#94A3B8"
														fill-rule="evenodd"
													/>
													<path
														clip-rule="evenodd"
														d="M7.64645 15.8536C7.84171 16.0488 8.15829 16.0488 8.35355 15.8536L11.3536 12.8536C11.5488 12.6583 11.5488 12.3417 11.3536 12.1464C11.1583 11.9512 10.8417 11.9512 10.6464 12.1464L8.5 14.2929V11H7.5V14.2929L5.35355 12.1464C5.15829 11.9512 4.84171 11.9512 4.64645 12.1464C4.45118 12.3417 4.45118 12.6583 4.64645 12.8536L7.64645 15.8536Z"
														fill="#94A3B8"
														fill-rule="evenodd"
													/>
												</svg>

												<span class="mt-0.5"
													>Tải file .CSV</span
												>
											</div>
										</MenuItem>
										<MenuItem
											v-slot="{ active }"
											@click="downloadPDF"
										>
											<div
												:class="active ? '' : ''"
												class="
													p-2
													px-4
													bg-white
													z-10
													text-gray-600
													hover:bg-neutral-50
													flex
													items-center
													gap-4
													cursor-pointer
												"
											>
												<svg
													fill="none"
													height="16"
													viewBox="0 0 16 16"
													width="16"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														clip-rule="evenodd"
														d="M8 0C6.57359 0 5.31176 0.560456 4.40576 1.34182C3.63985 2.00237 3.08479 2.8617 2.94223 3.725C1.26586 4.09512 0 5.55511 0 7.31818C0 9.36599 1.70754 11 3.78125 11H7.5V5.5C7.5 5.22386 7.72386 5 8 5C8.27614 5 8.5 5.22386 8.5 5.5V11H12.6875C14.5023 11 16 9.56949 16 7.77273C16 6.13656 14.7581 4.80411 13.1657 4.57898C12.9233 1.99944 10.6902 0 8 0Z"
														fill="#94A3B8"
														fill-rule="evenodd"
													/>
													<path
														clip-rule="evenodd"
														d="M7.64645 15.8536C7.84171 16.0488 8.15829 16.0488 8.35355 15.8536L11.3536 12.8536C11.5488 12.6583 11.5488 12.3417 11.3536 12.1464C11.1583 11.9512 10.8417 11.9512 10.6464 12.1464L8.5 14.2929V11H7.5V14.2929L5.35355 12.1464C5.15829 11.9512 4.84171 11.9512 4.64645 12.1464C4.45118 12.3417 4.45118 12.6583 4.64645 12.8536L7.64645 15.8536Z"
														fill="#94A3B8"
														fill-rule="evenodd"
													/>
												</svg>

												<span class="mt-0.5"
													>Tải file .PDF</span
												>
											</div>
										</MenuItem>
									</MenuItems>
								</transition>
							</Menu>
						</div>
					</div>
				</div>
				<div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6">
					<div
						class="
							p-6
							border
							lg:rounded-l-lg
							md:rounded-tl-lg
							border-neutral-200
							w-full
							md-down:rounded-t md-down:rounded-b-none
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/abc.png"
								style="height: 32px"
								width="32"
							/>
							{{ skills_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Tổng số lĩnh vực
							</span>
						</div>
					</div>
					<div
						class="
							p-6
							border border-neutral-200
							w-full
							md:border-l-0
							md-down:border-t-0
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/light.png"
								style="height: 32px"
								width="32"
							/>
							{{ goals_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Tổng số mục tiêu</span
							>
						</div>
					</div>
					<div
						class="
							p-6
							border border-neutral-200
							w-full
							md:rounded-tr-lg
							lg:rounded-tr-none
							md:border-l-0
							md-down:border-t-0
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/light.png"
								style="height: 32px"
								width="32"
							/>
							{{ continued_goals_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Mục tiêu lặp lại</span
							>
						</div>
					</div>
					<div
						class="
							p-6
							border border-neutral-200
							w-full
							lg:border-l-0
							lg-down:border-t-0
							md:rounded-bl-lg
							lg:rounded-bl-none
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/light.png"
								style="height: 32px"
								width="32"
							/>
							{{ new_goals_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Mục tiêu mới</span
							>
						</div>
					</div>
					<div
						class="
							p-6
							border border-neutral-200
							w-full
							md:border-l-0
							lg-down:border-t-0
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/light.png"
								style="height: 32px"
								width="32"
							/>
							{{ goals_from_stopped_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Từ MT tạm dừng</span
							>
						</div>
					</div>
					<div
						class="
							p-6
							border
							md:border-l-0
							lg:rounded-r-lg
							md:rounded-br-lg
							border-neutral-200
							w-full
							lg-down:border-t-0
							md-down:rounded-b md-down:rounded-t-none
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/light.png"
								style="height: 32px"
								width="32"
							/>
							{{ goals_from_lib_count }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Từ Ngân hàng MT</span
							>
						</div>
					</div>
				</div>
				<div class="mt-4 flex justify-end items-center text-gray-700">
					<div
						v-if="data && data.sent_at && data.sent_by_staff"
						class="flex gap-2"
					>
						<router-link
							:to="`/record/employee/${data.sent_by_staff?.id}`"
						>
							<div class="flex items-center">
								<div>
									<img
										v-if="
											data.sent_by_staff?.profile_photo
												?.w200
										"
										:src="
											data.sent_by_staff?.profile_photo
												?.w200
										"
										alt=""
										class="
											shadow-sm
											inline-block
											h-6
											w-6
											rounded-full
										"
									/>
									<span
										v-else
										:style="{
											background:
												data.sent_by_staff
													?.profile_photo?.default
													?.color,
										}"
										class="
											inline-flex
											items-center
											justify-center
											h-6
											w-6
											rounded-full
										"
									>
										<span
											class="
												font-medium
												leading-none
												text-white text-xs
											"
											>{{
												data.sent_by_staff
													?.profile_photo?.default
													?.initial
											}}</span
										>
									</span>
								</div>
								<div class="ml-4 text-blue-600 font-semibold">
									{{ data.sent_by_staff.name }}
								</div>
							</div>
						</router-link>
						đã gửi KH tới PH
						<span>{{ $filters.formatDateTime(data.sent_at) }}</span>
					</div>
					<div v-else></div>
				</div>
			</div>
			<div v-if="groups && groups.length > 0">
				<div
					v-if="
						groups.length > 1 || groups?.[0]?.id !== 'no_have_group'
					"
				>
					<div
						v-for="(group, index) in groups"
						:key="group.id"
						class="mb-8"
					>
						<GroupDetail
							:data="group"
							:index="index"
							@refresh="listGroup"
							@sortList="sortList"
							:hasPendingPlan="hasPendingPlan"
						/>
					</div>
				</div>
				<div v-else>
					<div
						v-for="(group, index) in groups?.[0]
							?.goals_group_by_skill"
						:key="group.id"
					>
						<GoalList
							:data="group"
							:index="index"
							:student-id="Number(id)"
							class="mb-6"
							:hasPendingPlan="hasPendingPlan"
						/>
					</div>
				</div>
			</div>
			<div
				v-else-if="data?.id"
				class="text-center text-neutral-500 p-6 text-lg"
			>
				Kế hoạch hiện tại chưa có mục tiêu. <br />
				Bạn hãy chọn Chỉnh sửa kế hoạch để thêm mục tiêu cho học sinh
			</div>
			<div
				v-else
				class="w-full h-full flex items-center justify-center flex-col"
			>
				<img
					alt=""
					height="250"
					src="@/assets/images/illustrations/not_found.png"
					width="250"
				/>
				<div
					class="text-neutral-500 text-lg text-center"
					style="max-width: 700px"
				>
					Học sinh chưa có Kế hoạch hiện tại. Kế hoạch hiện tại là kế
					hoạch được dạy và có thể báo cáo kết quả học của các mục
					tiêu trên app.
				</div>
				<div class="mt-8">
					<button
						v-if="!is_old_data"
						class="button-p btn--ghost w-fit"
						type="button"
						@click="redirectCreatePage"
					>
						<span class="ml-2 whitespace-nowrap">Lên kế hoạch</span>
					</button>
				</div>
			</div>
		</div>
		<div v-if="tabSelected == 1" class="flex flex-wrap gap-4">
			<div v-for="i in listVideo" :key="i.id">
				<video controls width="215">
					<source :src="i.asset_url" type="video/mp4" />
				</video>
			</div>
		</div>
	</div>
	<div v-else class="fixed top-1/2 left-1/2">
		<Loading />
	</div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { _ROUTER_NAME } from '@/enums';
import router from '@/router';
import { $api } from '@/services';
import { useStore } from 'vuex';
import DownloadCSV from '@/mixins/downloadCSV.vue';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import ConfirmSendReportTarget from '@/views/plansDetail/features/reportTarget/confirmSendReportTarget/ConfirmSendReportTarget.vue';
import { ACTIONS } from '@/enums/Common';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import Loading from '@/components/common/Loading.vue';
import GroupDetail from '@/components/modules/groups/GroupDetail.vue';
import GoalList from '@/components/elements/GoalList.vue';
import LoadingDowloadPDF from './loadingDowloadPDF/loadingDowloadPDF.vue';


const route = useRoute();
const store = useStore();
const data = ref(null);

const isLoading = ref(true);
const modalIsOpen = ref(false);
const modalType = ref('');
const order_by = ref('');
//
const goals_count = ref(0);
const skills_count = ref(0);
const continued_goals_count = ref(0);
const goals_from_lib_count = ref(0);
const goals_from_stopped_count = ref(0);
const new_goals_count = ref(0);
const tabs = ref([
	{
		name: 'Kế hoạch',
		value: 0,
	},
	{
		name: 'Video từ phụ huynh',
		value: 1,
	},
]);
const tabSelected = ref(0);
const listVideo = ref([]);
const id = computed(() => (route.params?.id ? route.params?.id + '' : ''));
const hasPendingPlan = ref(false);
const oldPayload = ref({
	'x-cross': route.query.org_id,
});

onMounted(() => {
	planActive();
	listGroup();
});
const is_old_data = ref(route.query.is_old_data === 'true');

async function planActive() {
	try {
		const {
			data: { data: data_report },
		} = await $api.monthlyReport.planActive(
			id.value,
			is_old_data.value ? oldPayload.value : null
		);
		data.value = data_report;
	} catch (e) {
		console.log(e);
	}
}

function redirectCreatePage() {
	router.push({
		name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_CURRENT__CREATE,
		params: {
			id: id.value,
			plan_id: data.value.id,
		},
	});
}

function toEdit() {
	router.push({
		name: _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_CURRENT__CREATE_BY_BANK,
		params: {
			id: id.value,
			plan_id: data.value.id,
		},
	});
}

function cancel() {
	modalIsOpen.value = false;
	modalType.value = '';
}

const type = computed(() => ACTIONS);
const reportName = ref('');

function confirmSendOpen() {
	modalIsOpen.value = true;
	reportName.value = data.value?.order || '';
	modalType.value = type.value.CREATE;
}

async function sendNotification() {
	try {
		await $api.monthlyReport.sendNotification(id.value, data.value?.id);
		cancel();
		await listGroup();
		await store.dispatch('setAlertMessage', {
			message: 'Gửi kế hoạch đến phụ huynh thành công',
		});
	} catch (err) {
		await store.dispatch('setAlertMessage', {
			message: 'Phụ huynh chưa kích hoạt tài khoản',
			type: 'danger',
		});
	}
}

const groups = ref([]);
const sortList = (value) => {
	order_by.value = value;
	listGroup();
};

async function listGroup() {
	try {
		const {
			data: { data, plan, stats, has_pending_plan },
		} = await $api.goal.getListGroupWithDetail(id.value, {
			order_by: order_by.value,
		});
		groups.value = data;
		if (has_pending_plan) hasPendingPlan.value = has_pending_plan;
		if (plan) getVideo(plan.applied_at);
		if (stats) {
			goals_count.value = stats.goals_count;
			skills_count.value = stats.skills_count;
			continued_goals_count.value = stats.continued_goals_count;
			goals_from_lib_count.value = stats.goals_from_lib_count;
			goals_from_stopped_count.value = stats.goals_from_stopped_count;
			new_goals_count.value = stats.new_goals_count;
		}
	} catch (err) {
		await store.dispatch('setAlertMessage', {
			message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
			type: 'danger',
		});
	} finally {
		isLoading.value = false;
	}
}

async function getVideo(month_start) {
	try {
		const res = await $api.student.getVideoFromParents(id.value, {
			month_start: month_start,
			month_end: '',
		});
		listVideo.value = res.data.data;
	} catch (err) {
		await store.dispatch('setAlertMessage', {
			message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
			type: 'danger',
		});
	} finally {
		isLoading.value = false;
	}
}

async function downloadPDF() {
	try {
		modalIsOpen.value = true;
		modalType.value = type.value.LOADING;
		const url =
			process.env.VUE_APP_PDF_URL +
			id.value +
			'?token=' +
			localStorage.getItem('tk') +
			'&subdomain=' +
			localStorage.getItem('sub-domain');
		const params = {
			url: url,
			format: 'a2',
			id_render: 'plans_pdf',
		};
		const response = await fetch(
			'https://g4ridpplxi.execute-api.ap-southeast-1.amazonaws.com/',
			{
				method: 'POST',
				body: JSON.stringify(params),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			}
		);
		const data = await response.json();
		if (data.data) {
			cancel();
			const linkSource = `data:application/pdf;base64, ${data.data}`;
			const link = document.createElement('a');
			const fileName = 'KeHoachHienTai.pdf';
			link.href = linkSource;
			link.download = fileName;
			link.click();
		}
	} catch (e) {
		cancel();
		await store.dispatch('setAlertMessage', {
			message:
				e?.response?.data?.errors?.join('<br/>') ||
				'Đã có lỗi xảy ra. Vui lòng thử lại',
			type: 'danger',
		});
		console.log(e);
	}
}
</script>
<style scoped>
.tab {
	padding: 8px 12px 8px 12px;
	color: #48566a;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

.tab-selected {
	border-radius: 8px;
	background: #e5a42b;
	box-shadow: 0px 3px 8px -1px #3232470d;
	color: #fff;
}
</style>
