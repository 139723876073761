
import Rating from '@/components/elements/rating/Rating.vue';
import { useStats } from '@/features/lessonReport/stats';
import { Options, Vue, setup } from 'vue-class-component';
import { useListLessonReport } from '@/features/lessonReport/listLessonReport';
import { Watch } from 'vue-property-decorator';
import SideOver from '@/components/elements/sideOver/SideOver.vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import DaInfinityLoading from '@/components/common/daInfinityLoading/DaInfinityLoading.vue';
import { useGetEmployees } from '@/views/record/employee/services/getEmployees';
import { useStudent } from '@/features/student/useStudent';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import Loading from '@/components/common/Loading.vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import DeleteConfirmModal from './DeleteConfirm.vue';
import ConfirmSendMonthlyReport from '@/views/report/features/monthlyReport/features/ConfirmSendMonthlyReport/ConfirmSendMonthlyReport.vue';
import { $api } from '@/services';

@Options({
	name: 'ReportLesson',
	components: {
		ConfirmSendMonthlyReport,
		DeleteConfirmModal,
		DaModalFrame,
		Loading,
		DaInfinityLoading,
		SideOver,
		Rating,
		DaIcon,
		Menu,
		MenuButton,
		MenuItem,
		MenuItems,
	},
})
export default class ReportLesson extends Vue {
	sideOverShow = false;
	statsData: any = [];
	cancelData: any = [];
	filter: any = null;
	LessonReports = [];
	dateNow = new Date();
	date = '';
	afterId = null;
	before = '';
	isFilterDate = false;
	stopLoad = false;
	staffs = [];
	students = [];
	isInfinityLoading = false;
	isLoading = true;
	alertShow = true;
	modalIsOpen = false;
	dataDelete = null;
	isHides: any = [];

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const store = useStore();
		const { error, loading, stats } = useStats();
		const { getStudents } = useStudent();
		const { getEmployees } = useGetEmployees();
		const { listLessonReport } = useListLessonReport();
		return {
			getEmployees,
			getStudents,
			error,
			loading,
			stats,
			listLessonReport,
			store,
		};
	});

	//----------------------- 🤍 Getter 🤍 -----------------------//
	get lessonReports() {
		return this.LessonReports;
	}

	get reports() {
		const g: any[] = this.groups(this.lessonReports);
		const rs: any = Object.keys(g).map((date: any) => {
			return {
				date,
				data: g[date],
			};
		});
		return rs;
	}

	get isAdmin() {
		return this.setup.store.getters.isAdmin;
	}

	get org() {
		return this.setup.store.state.profile.current_org;
	}

	get store() {
		return this.setup.store;
	}

	//----------------------- 🤍 Created 🤍 -----------------------//
	created() {
		const q: any = this.$route.query;
		this.date = q.date ? dayjs(q.date).format('DD/MM/YYYY') : '';
		if (q?.date) {
			this.filter = {
				date: q?.date,
				toDate: q?.toDate,
			};
			this.isFilterDate = !!q?.date;
			this.before = dayjs(q.date).add(1, 'day').format('YYYY-MM-DD');
		}
		this.getLessonList();
		this.search();
		this.fetchStudents();
	}

	//----------------------- 🤍 Watch 🤍 -----------------------//
	@Watch('$route')
	onRouterChange(value: any) {
		if (value?.name === 'report_lesson') {
			this.LessonReports = [];
			if (this.$route.query?.date) {
				this.before = dayjs(this.$route.query?.date.toString())
					.add(1, 'day')
					.format('YYYY-MM-DD');
			} else {
				this.before = '';
			}
			this.getLessonList();
		}
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	async getLessonList() {
		const q = this.$route.query;
		const { data, date, stats, cancelled_stats } =
			await this.setup.listLessonReport(q, this.before);
		if (
			data?.length > 0 &&
			data.find((l: any) => l[0]?.date !== this.before) &&
			(!this.filter?.toDate ||
				date === this.filter.toDate ||
				dayjs(date).isAfter(this.filter.toDate))
		) {
			this.statsData[date] = {
				completed_count: stats.completed_count,
				incomplete_count: stats.incomplete_count,
				total: stats.total_count,
				cancelled: cancelled_stats.total_count,
				number_of_parent_read_count: stats.number_of_parent_read_count
			};
			this.cancelData[date] = {
				completed_count: cancelled_stats.completed_count,
				incomplete_count: cancelled_stats.incomplete_count,
				total: cancelled_stats.total_count,
			};
			// @ts-ignore
			this.LessonReports.push(...data);
			this.before = date;
		} else {
			this.stopLoad = true;
		}
		this.isInfinityLoading = true;
		this.isLoading = false;
	}

	async search() {
		const res = await this.setup.getEmployees('');
		this.staffs = res?.data || [];
	}

	async fetchStudents() {
		let role =
			this.store.state.profile.staff.role === 'teacher'
				? 'staff'
				: 'org_admin';
		const studentList = await this.setup.getStudents(role, {
			state: 'active',
		});
		this.students = studentList.data;
	}

	studentById(id: string) {
		return this.students.find((item: { id: string }) => item.id === id);
	}

	staffById(id: string) {
		return this.staffs.find((item: { id: string }) => item.id === id);
	}

	clean(obj: any) {
		for (let propName in obj) {
			if (
				obj[propName] === null ||
				obj[propName] === undefined ||
				obj[propName].length <= 0
			) {
				delete obj[propName];
			}
		}
		return obj;
	}

	filterSubmit(queryPa: any) {
		this.afterId = null;
		if (!queryPa) {
			this.date = dayjs(this.dateNow).format('DD/MM/YYYY');
			this.filter = null;
			this.$router.push('');
		} else {
			this.filter = {
				...this.filter,
				...queryPa,
			};
			this.clean(this.filter);
			const query = Object.keys(this.filter)
				.map((key) => `${key}=${this.filter[key]}`)
				.join('&');
			this.$router.push(`?${query}`);
		}
		this.sideOverShow = false;
	}

	// this gives an object with dates as keys
	groups(array: any) {
		return array.reduce((groups: any, item: any) => {
			const date = item.date;
			if (!groups[date]) {
				groups[date] = [];
			}
			groups[date].push(item);
			return groups;
		}, {});
	}

	showHide(date: any) {
		const index = this.isHides.indexOf(date);
		if (index !== -1) {
			this.isHides.splice(index, 1);
		} else {
			this.isHides.push(date);
		}
	}

	openDeleteConfirm(data: any) {
		this.dataDelete = data;
		this.modalIsOpen = true;
	}

	closeModal() {
		this.dataDelete = null;
		this.modalIsOpen = false;
	}

	async success() {
		this.closeModal();
		this.LessonReports = [];
		this.before = '';
		await this.getLessonList();
	}

	beforeDestroy() {
		this.isInfinityLoading = false;
	}

	async sendReport(item: any) {
		try {
			await $api.lessonReport.sendReport(item?.id);
			this.closeModal();
			await this.store.dispatch('setAlertMessage', {
				message: 'Báo cáo đã được gửi thành công đến phụ huynh.',
			});
			item.sent_at = new Date();
		} catch (err) {
			await this.store.dispatch('setAlertMessage', {
				message:
					err?.response?.data?.errors?.join('<br/>') ||
					'Đã có lỗi xảy ra. Vui lòng thử lại',
				type: 'danger',
			});
		}
	}
	async recoverReport(id: any) {
		try {
			const res = await $api.lessonReport.recoverReport(id);
			if (res.data) {
				await this.store.dispatch('setAlertMessage', {
					message: 'Khôi phục báo cáo thành công',
				});
			}
		} catch (err) {
			await this.store.dispatch('setAlertMessage', {
				message:
					err?.response?.data?.errors?.join('<br/>') ||
					'Đã có lỗi xảy ra. Vui lòng thử lại',
				type: 'danger',
			});
		}
	}
}
