<template>
	<DaModalFrame v-model:open="modalIsOpen">
		<ModalConfirmSendReport
			v-if="modalIsOpen && modalType === modalTypes.CONFIRM"
			:name="`${report?.group_plan?.order}`"
			@close="cancelSendReport"
			@submit="sendReport"
		/>
		<UpdateNoteModal
			v-if="
				modalIsOpen &&
				modalType === modalTypes.UPDATE_NOTE &&
				!!goalViewDetail
			"
			:goal="goalViewDetail"
			:group_class_id="Number(id)"
			:is-open="
				modalIsOpen &&
				modalType === modalTypes.UPDATE_NOTE &&
				!!goalViewDetail
			"
			:monthly_report_id="reportId || report?.data?.id"
			@close="cancelSendReport"
			@submit="updateNoteSuccess"
		/>
	</DaModalFrame>
	<div class="flex min-h-screen overflow-x-hidden">
		<div class="w-full flex">
			<div class="w-full pb-30">
				<div class="w-full p-10 md-down:px-4 gap-4">
					<div
						class="
							__breadcrumbs
							text-sm text-neutral-500
							font-semibold
							flex-nowrap flex
							items-center
							mb-4
						"
					>
						<router-link
							:to="{ name: ROUTER_NAME.REPORT_PLANS_GROUP }"
						>
							Báo cáo tháng
						</router-link>
					</div>
					<div class="flex items-center justify-between">
						<div v-if="report?.group_class" class="flex gap-2">
							<div class="w-7 h-7">
								<img
									alt=""
									src="@/assets/images/icons/people-fill.png"
								/>
							</div>
							<div>
								<div
									class="text-neutral-700 font-bold text-3xl"
								>
									{{ report?.group_class?.name }}
								</div>
								<div class="flex gap-4 items-center mt-2">
									<UserGroup
										:data="report?.group_class?.students"
										placement="bottom-start"
									/>
									<div
										class="
											uppercase
											text-green-500
											font-semibold
										"
									>
										{{
											report?.group_class?.students
												?.length
										}}
										học sinh
									</div>
								</div>
							</div>
						</div>
						<div class="flex gap-4">
							<div v-if="report && !report?.data?.sent_at">
								<button
									class="
										button-p
										text-center
										whitespace-nowrap
										w-fit
									"
									type="button"
									@click="openSendConfirm"
								>
									Gửi tới phụ huynh
								</button>
							</div>
							<div v-if="isEdit">
								<button
									class="
										button-p
										text-center
										w-fit
										btn--ghost
									"
									type="button"
									@click="isEdit = false"
								>
									Lưu báo cáo
								</button>
							</div>
						</div>
					</div>
					<div v-if="!isEdit" class="relative mt-10">
						<div
							aria-hidden="true"
							class="absolute inset-0 flex items-center"
						>
							<div class="w-full border-t border-neutral-300" />
						</div>
						<div class="relative flex justify-center">
							<div
								class="
									px-3
									bg-white
									p-2
									text-lg
									font-semibold
									text-neutral-600
									flex
									items-center
									shadow-sm
									rounded
									cursor-pointer
									border-neutral-200 border
								"
								@click="goToEdit"
							>
								<svg
									fill="none"
									height="16"
									viewBox="0 0 17 16"
									width="17"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										clip-rule="evenodd"
										d="M12.9705 6.65078L14.2105 5.41075C14.3463 5.27489 14.4143 5.20696 14.4669 5.14499C15.1011 4.39832 15.1011 3.30214 14.4669 2.55546C14.4143 2.4935 14.3463 2.42557 14.2105 2.28971C14.0746 2.15385 14.0067 2.08592 13.9447 2.03329C13.1981 1.39906 12.1019 1.39906 11.3552 2.03329C11.2933 2.08591 11.2253 2.15382 11.0895 2.28961L11.0895 2.28968L11.0894 2.28971L9.83123 3.54792C10.5749 4.84212 11.6586 5.91739 12.9705 6.65078ZM8.37657 5.00258L3.36297 10.0162C2.93791 10.4412 2.72538 10.6538 2.58565 10.9149C2.44591 11.176 2.38697 11.4707 2.26908 12.0601L1.87342 14.0384C1.8069 14.371 1.77364 14.5373 1.86825 14.6319C1.96286 14.7266 2.12916 14.6933 2.46177 14.6268L4.44005 14.2311L4.44007 14.2311L4.44009 14.2311C5.02951 14.1132 5.32423 14.0543 5.58532 13.9145C5.84641 13.7748 6.05894 13.5623 6.48398 13.1373L6.484 13.1372L6.48401 13.1372L11.512 8.10922C10.2513 7.311 9.18226 6.24913 8.37657 5.00258Z"
										fill="#94A3B8"
										fill-rule="evenodd"
									/>
								</svg>
								<div class="ml-2 text-sm text-neutral-600">
									Chỉnh sửa
								</div>
							</div>
						</div>
					</div>
					<div>
						<ReportDetail
							v-if="reportData"
							:is-edit="isEdit"
							:report="reportData"
							:student-selected="studentSelected"
							:students="report?.group_class?.students"
							@change-student="onChangeStudent"
							@open-modal="openUpdateNoteModal"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import UserGroup from '@/components/elements/UserGroup.vue';
import { _MODAL_TYPE as modalTypes, _MODAL_TYPE, _ROUTER_NAME } from '@/enums';
import { $api } from '@/services';
import ReportDetail from '@/views/planGroupDetail/ReportDetail.vue';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import ModalConfirmSendReport from '@/views/planGroupDetail/ModalConfirmSendReport.vue';
import UpdateNoteModal from '@/views/planGroupEdit/UpdateNoteModal.vue';

export default {
	name: 'PlanGroupDetail',

	components: {
		UpdateNoteModal,
		ModalConfirmSendReport,
		DaModalFrame,
		ReportDetail,
		UserGroup,
	},

	data() {
		return {
			ROUTER_NAME: _ROUTER_NAME,
			report: null,
			studentReport: {},
			studentSelected: {
				id: 'all',
			},
			isEdit: false,
			isLoading: false,
			modalIsOpen: false,
			modalType: null,
			goalViewDetail: null,
		};
	},

	computed: {
		modalTypes() {
			return modalTypes;
		},
		id() {
			return this.$route.params?.id;
		},

		reportId() {
			return this.$route.query?.report_id;
		},

		reportData() {
			return this.studentSelected?.id !== 'all' &&
				this.studentReport[+this.studentSelected?.id]
				? this.studentReport[+this.studentSelected?.id]
				: this.report;
		},
	},

	watch: {
		'studentSelected.id': {
			deep: true,
			handler(value) {
				if (value !== 'all' && !this.studentReport[value]) {
					this.fetchStatsForStudentGroup();
				}
			},
		},
	},

	created() {
		this.fetchReport();
	},

	methods: {
		openUpdateNoteModal(item) {
			this.modalIsOpen = true;
			this.modalType = _MODAL_TYPE.UPDATE_NOTE;
			this.goalViewDetail = item;
		},

		onChangeStudent(student) {
			this.studentSelected = student;
		},

		async fetchReport() {
			try {
				const { data } =
					await $api.monthlyReport.detailMonthlyReportGroupClass(
						this.id,
						this.reportId
					);
				this.report = data;
			} catch (e) {
				console.log(e);
			}
		},

		async fetchStatsForStudentGroup() {
			this.isLoading = true;
			try {
				const {
					data: {
						data: {
							emotion_scale_stats,
							group_sessions_count,
							skills_stats,
							skills,
						},
					},
				} = await $api.monthlyReport.fetchStatsForStudentGroup(
					this.report?.group_class?.id,
					this.report?.data?.id,
					this.studentSelected?.id
				);
				this.studentReport[this.studentSelected?.id] = {
					...this.report,
					emotion_scale_stats,
					group_sessions_count,
					skills_stats,
					skills,
				};
			} catch (e) {
				console.log(e);
			} finally {
				this.isLoading = false;
			}
		},

		goToEdit() {
			this.isEdit = true;
		},

		openSendConfirm() {
			this.modalIsOpen = true;
			this.modalType = _MODAL_TYPE.CONFIRM;
		},

		cancelSendReport() {
			this.modalIsOpen = false;
			this.modalType = null;
			this.goalViewDetail = null;
		},

		updateNoteSuccess() {
			this.fetchStatsForStudentGroup();
			this.cancelSendReport();
		},

		async sendReport() {
			try {
				await $api.monthlyReport.sendMonthlyReportGroupClass(
					this.report?.group_class?.id,
					this.report?.data?.id
				);
				await this.$store.dispatch('setAlertMessage', {
					message: 'Gửi kế hoạch đến phụ huynh thành công',
				});
				this.report.data.sent_at = new Date();
				this.cancelSendReport();
			} catch (err) {
				await this.$store.dispatch('setAlertMessage', {
					message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		},
	},
};
</script>
