
import { Options, Vue } from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';
import Checkbox from '@/components/elements/checkbox/Checkbox.vue';
import { _ROUTER_NAME } from '@/enums';

@Options({
	name: 'DeleteConfirm',
	components: { Checkbox },
})
export default class DeleteConfirm extends Vue {
	@Prop({ default: false }) successfully?: boolean;
	@PropSync('isNoRepeat') noRepeat?: boolean;
	@Prop({ default: false }) isReTeach?: boolean;

	//----------------------- 🤍 Methods 🤍 -----------------------//
	close() {
		this.$emit('close');
	}

	submit() {
		this.$emit('submit');
	}

	get routeName() {
		return this.$route.name;
	}

	get label() {
		switch (this.routeName) {
			case _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_CURRENT__CREATE_BY_BANK:
			case _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_FUTURE__CREATE_BY_BANK:
				return 'Ngân hàng mục tiêu';
			case _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_CURRENT__CREATE_BAY_STOP:
			case _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_FUTURE__CREATE_BAY_STOP:
				return 'Mục tiêu tạm dừng';
			case _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_CURRENT__CREATE_BY_COMPLETED:
			case _ROUTER_NAME.PLANS_REPORT_DETAIL__GOAL_CREATE_FUTURE__CREATE_BAY_COMPLETED:
				return 'Mục tiêu hoàn thành';
			default:
				return 'Mục tiêu';
		}
	}
}
