
import { Vue, Options, setup } from 'vue-class-component';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { useGetBoardings } from '@/views/setupCenter/features/boarding/services/getBoardings';
import { useDeleteBoarding } from '@/views/setupCenter/features/boarding/services/deleteBoarding';
import { Boarding } from '@/views/setupCenter/features/boarding/@types/boarding.interface';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import CreateBoarding from '@/views/setupCenter/features/boarding/features/createBoarding/CreateBoarding.vue';
import { useCreateBoarding } from '@/views/setupCenter/features/boarding/services/createBoarding';
import { useStore } from 'vuex';
import { useUpdateBoarding } from '@/views/setupCenter/features/boarding/services/updateBoarding';
import DeleteConfirm from '@/views/setupCenter/features/deleteConfirm/DeleteConfirm.vue';
import { ACTIONS } from '@/enums/Common';
import { useGetEmployees } from '@/views/record/employee/services/getEmployees';
import UpdateCalenderDaycare from '@/views/daycareClass/features/UpdateCalenderDaycare.vue';
import Loading from '@/components/common/Loading.vue';
import { Watch } from 'vue-property-decorator';
import { $api } from '@/services';

@Options({
	name: 'Boarding',
	components: {
		Loading,
		UpdateCalenderDaycare,
		DeleteConfirm,
		CreateBoarding,
		DaModalFrame,
		DaIcon,
	},
})
export default class SetupBoarding extends Vue {
	//----------------------- 🤍 Data 🤍 -----------------------//
	totalBoardings = 0;
	numberTeacher = 0;
	boardings: Boarding[] = [];
	class_day_slots = [];
	createdData = false;
	modalIsOpen = false;
	actionType = ACTIONS.CREATE;
	boardingEditData = null;
	boardingDeleteData = {
		name: '',
		id: '',
	};
	actionMenu = false;
	isLoading = true;
	id = '';

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const store = useStore();
		const { error, loading, getBoardings } = useGetBoardings();
		const { getEmployees } = useGetEmployees();
		const { deleteBoarding__error, deleteBoarding } = useDeleteBoarding();
		const { createBoarding, createBoarding__error } = useCreateBoarding();
		const { updateDayCareClass, updateDayCareClass__error } =
			useUpdateBoarding();
		return {
			error,
			deleteBoarding__error,
			createBoarding__error,
			updateDayCareClass__error,
			loading,
			getBoardings,
			deleteBoarding,
			createBoarding,
			updateDayCareClass,
			store,
			getEmployees,
		};
	});

	//----------------------- 🤍 Created 🤍 -----------------------//
	created() {
		Promise.all([this.fetchBoardings(), this.fetchStaff()]);
	}

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get total() {
		return this.totalBoardings < 10
			? '0' + this.totalBoardings
			: this.totalBoardings;
	}

	get totalTeacher() {
		return this.numberTeacher < 10
			? '0' + this.numberTeacher
			: this.numberTeacher;
	}

	get store() {
		return this.setup.store;
	}

	get org_setting() {
		return this.setup.store.state.profile.current_org;
	}

	get modalType() {
		return ACTIONS;
	}

	get titleModal() {
		return this.createdData
			? 'Xoá lớp bán trú thành công'
			: 'Bạn có muốn xoá lớp bán trú này?';
	}

	get labelModal() {
		return this.createdData
			? 'Lớp bán trú đã được xoá khỏi dữ liệu của cơ sở.'
			: 'Lớp bán trú sẽ được xoá khỏi dữ liệu của cơ sở.';
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	async fetchBoardings() {
		try {
			const {
				data: { count, data, class_day_slots, numbers_of_staff },
			} = await $api.boarding.getDayCareClasses();
			this.totalBoardings = count;
			this.class_day_slots = class_day_slots;
			this.numberTeacher = numbers_of_staff;
			this.boardings = data;
			this.isLoading = false;
		} catch (err) {
			await this.store.dispatch('setAlertMessage', {
				message:
					err?.response?.data?.errors?.join('<br/>') ||
					'Đã có lỗi xảy ra. Vui lòng thử lại',
				type: 'danger',
			});
		}
	}

	staffs: any = [];

	@Watch('modalIsOpen')
	onModalIsOpen(value: boolean) {
		if (!value) {
			this.boardingDeleteData = {
				name: '',
				id: '',
			};
		}
	}

	async fetchStaff() {
		const res = await this.setup.getEmployees('');
		this.staffs = res?.data || [];
	}

	async deleteData() {
		try {
			await $api.boarding.deleteDayCareClass(this.boardingDeleteData?.id);
			this.createdData = true;
			await this.fetchBoardings();
		} catch (err) {
			await this.store.dispatch('setAlertMessage', {
				message:
					err?.response?.data?.errors?.join('<br/>') ||
					'Đã có lỗi xảy ra. Vui lòng thử lại',
				type: 'danger',
			});
		}
	}

	openModal(type = this.modalType.CREATE) {
		this.actionType = type;
		this.modalIsOpen = true;
	}

	createModal() {
		this.boardingEditData = null;
		this.actionType = this.modalType.CREATE;
		this.modalIsOpen = true;
	}

	deleteModal(data: any) {
		this.setup.deleteBoarding__error = null;
		this.boardingDeleteData = data;
		this.actionType = this.modalType.DELETE;
		this.modalIsOpen = true;
		this.actionMenu = false;
	}

	editData(data: any) {
		this.boardingEditData = {
			...data,
			working_days: this.class_day_slots[data.id],
		};
		this.actionType = this.modalType.UPDATE;
		this.modalIsOpen = true;
		this.actionMenu = false;
	}

	updateCalender(data: any) {
		this.boardingEditData = {
			...data,
			days_of_week: this.class_day_slots[data.id],
		};
		this.actionType = this.modalType.UPDATE_CALENDER;
		this.modalIsOpen = true;
		this.actionMenu = false;
	}

	updatedCalender() {
		this.actionType = '';
		this.boardingEditData = null;
		this.modalIsOpen = false;
		this.fetchBoardings();
	}
	openMenuAction(id: any) {
		this.id = id;
		this.actionMenu = !this.actionMenu;
	}
	async createRBoarding(value: { name: string; staff_ids: number[] }) {
		const res = await this.setup.createBoarding({
			day_care_class: value,
		});
		if (res && res?.data) {
			this.createdData = true;
			await this.fetchBoardings();
			this.modalIsOpen = false;
			await this.store.dispatch('setAlertMessage', {
				message: 'Thêm lớp bán trú thành công',
			});
		}
	}

	async updateBoarding(value: {
		id: string;
		name: string;
		staff_ids: number[];
	}) {
		const [res] = await Promise.all([
			this.setup.updateDayCareClass(value.id, {
				day_care_class: {
					name: value.name,
				},
			}),
			$api.boarding.addStaffToDaycareClass({
				staff_memberships: {
					day_care_class_id: value.id,
					staff_ids: value.staff_ids,
				},
			}),
		]);
		if (res && res?.data) {
			await this.store.dispatch('setAlertMessage', {
				message: 'Cập nhật lớp bán trú thành công',
			});
			this.updatedCalender();
		}
	}

	createdStaffBoarding() {
		this.store.dispatch('setAlertMessage', {
			message: 'Cập nhật lớp bán trú thành công',
		});
		this.fetchBoardings();
	}
}
