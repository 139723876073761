/* eslint-disable */
import { ref } from 'vue';
import { $api } from '@/services';

const error = ref(null);
const loading = ref(false);

export async function register(data: {
	account: {
		name: string;
		password: string;
		password_confirmation: string;
	};
	token: string | null;
}) {
	error.value = null;
	try {
		const res = await $api.auth.register(data);
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export function useRegister() {
	return { error, loading, register };
}
