<template>
	<div :class="isShow ? 'side-over--active' : ''">
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			v-if="isShow"
			@click="isShow = false"
		></div>
		<StatisticalTimeTable @close="isShow = false" :id="id" />
	</div>
	<div class="__report-lesson px-12 md-down:px-4 pb-30">
		<div
			class="
				__report-lesson__header
				py-10
				flex flex-nowrap
				justify-between
				items-center
			"
		>
			<div
				class="
					__record__title
					text-neutral-500
					font-semibold
					whitespace-nowrap
					flex flex-nowrap
					items-center
					text-4xl
					cursor-pointer
				"
				@click="$router.push('/schedule/timetable')"
			>
				<svg
					class="mr-2"
					width="28"
					height="28"
					viewBox="0 0 28 28"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M17.5 7L10.5 14L17.5 21"
						stroke="#94A3B8"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
				Lịch sử thời khoá biểu
			</div>
			<button
				@click="$router.push('/schedule/timetable')"
				type="button"
				class="
					h-10
					button-p
					btn--ghost
					text-center
					whitespace-nowrap
					w-fit
				"
			>
				TKB Hiện tại
			</button>
		</div>
		<div class="mb-8">
			<span
				class="
					text-sm
					inline-flex
					items-center
					py-1
					pl-3
					pr-2
					rounded-full
					bg-sienna-100
					text-neutral-900
					mr-2
					shadow-input
				"
				v-if="filter?.state === 'completed'"
			>
				Thông báo đã gửi
				<button
					type="button"
					class="
						flex-shrink-0
						ml-2
						h-4
						w-4
						rounded-full
						inline-flex
						items-center
						justify-center
						text-neutral-700
						hover:text-neutral-900
					"
					@click="filterSubmit({ state: '' })"
				>
					<svg width="7" height="7" viewBox="0 0 7 7" fill="none">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M0.35861 0.35861C0.529464 0.187755 0.806474 0.187755 0.977328 0.35861L3.29297 2.67425L5.60861 0.35861C5.77946 0.187755 6.05647 0.187755 6.22733 0.35861C6.39818 0.529464 6.39818 0.806474 6.22733 0.977328L3.91169 3.29297L6.22733 5.60861C6.39818 5.77946 6.39818 6.05647 6.22733 6.22733C6.05647 6.39818 5.77946 6.39818 5.60861 6.22733L3.29297 3.91169L0.977328 6.22733C0.806474 6.39818 0.529464 6.39818 0.35861 6.22733C0.187755 6.05647 0.187755 5.77946 0.35861 5.60861L2.67425 3.29297L0.35861 0.977328C0.187755 0.806474 0.187755 0.529464 0.35861 0.35861Z"
							fill="currentColor"
						/>
					</svg>
				</button>
			</span>
			<span
				class="
					text-sm
					inline-flex
					items-center
					py-1
					pl-3
					pr-2
					rounded-full
					bg-sienna-100
					text-neutral-900
					mr-2
					shadow-input
				"
				v-if="filter?.state === 'draft'"
			>
				Thông báo nháp
				<button
					type="button"
					class="
						flex-shrink-0
						ml-2
						h-4
						w-4
						rounded-full
						inline-flex
						items-center
						justify-center
						text-neutral-700
						hover:text-neutral-900
					"
					@click="filterSubmit({ state: '' })"
				>
					<svg width="7" height="7" viewBox="0 0 7 7" fill="none">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M0.35861 0.35861C0.529464 0.187755 0.806474 0.187755 0.977328 0.35861L3.29297 2.67425L5.60861 0.35861C5.77946 0.187755 6.05647 0.187755 6.22733 0.35861C6.39818 0.529464 6.39818 0.806474 6.22733 0.977328L3.91169 3.29297L6.22733 5.60861C6.39818 5.77946 6.39818 6.05647 6.22733 6.22733C6.05647 6.39818 5.77946 6.39818 5.60861 6.22733L3.29297 3.91169L0.977328 6.22733C0.806474 6.39818 0.529464 6.39818 0.35861 6.22733C0.187755 6.05647 0.187755 5.77946 0.35861 5.60861L2.67425 3.29297L0.35861 0.977328C0.187755 0.806474 0.187755 0.529464 0.35861 0.35861Z"
							fill="currentColor"
						/>
					</svg>
				</button>
			</span>
			<span
				class="
					text-sm
					inline-flex
					items-center
					py-1
					pl-3
					pr-2
					rounded-full
					bg-sienna-100
					text-neutral-900
					mr-2
					shadow-input
				"
				v-if="filter?.day_care_class_id"
			>
				{{ filter?.className }}
				<button
					type="button"
					class="
						flex-shrink-0
						ml-2
						h-4
						w-4
						rounded-full
						inline-flex
						items-center
						justify-center
						text-neutral-700
						hover:text-neutral-900
					"
					@click="filterSubmit({ day_care_class_id: null })"
				>
					<svg width="7" height="7" viewBox="0 0 7 7" fill="none">
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M0.35861 0.35861C0.529464 0.187755 0.806474 0.187755 0.977328 0.35861L3.29297 2.67425L5.60861 0.35861C5.77946 0.187755 6.05647 0.187755 6.22733 0.35861C6.39818 0.529464 6.39818 0.806474 6.22733 0.977328L3.91169 3.29297L6.22733 5.60861C6.39818 5.77946 6.39818 6.05647 6.22733 6.22733C6.05647 6.39818 5.77946 6.39818 5.60861 6.22733L3.29297 3.91169L0.977328 6.22733C0.806474 6.39818 0.529464 6.39818 0.35861 6.22733C0.187755 6.05647 0.187755 5.77946 0.35861 5.60861L2.67425 3.29297L0.35861 0.977328C0.187755 0.806474 0.187755 0.529464 0.35861 0.35861Z"
							fill="currentColor"
						/>
					</svg>
				</button>
			</span>
		</div>
		<div
			class="__report-lesson__container"
			v-if="timeTable && timeTable.length > 0"
		>
			<div class="shadow-sm sm:rounded-2xl overflow-hidden">
				<div class="__record__table overflow-x-auto scroll-bar">
					<div
						class="
							overflow-hidden
							sm:rounded-t-2xl
							w-fit
							min-w-full
						"
					>
						<table class="min-w-full divide-y text-neutral-200">
							<thead class="bg-neutral-100 h-16">
								<tr>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-center text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											w-16
											whitespace-nowrap
										"
									>
										STT
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										ID
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Thời gian dạy
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Trạng thái
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										NGƯỜI TẠO
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									@click="showDetail(item.id)"
									v-for="(item, index) in timeTable"
									:key="item.id"
									:class="
										index % 2 === 0
											? 'bg-white'
											: 'bg-neutral-50'
									"
									class="
										text-neutral-700
										cursor-pointer
										hover:bg-orange-50
									"
								>
									<td>
										<div
											class="
												px-3
												py-4
												text-center text-neutral-700
											"
										>
											{{ index + 1 }}
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div>
											Thời khoá biểu {{ item?.order }}
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div>
											<span v-if="!item.activated_date"
												>Từ</span
											>
											{{
												$filters.formatDate2(
													item.activated_date
												)
											}}
											-
											<span v-if="item.deactivated_date">
												{{
													$filters.formatDate2(
														item.deactivated_date
													)
												}}
												<span
													v-if="item.deactivated_date"
												>
													({{
														item?.duration_in_days
													}}
													ngày)
												</span>
											</span>
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div>
											<div
												v-if="item?.state === 'future'"
												class="flex items-center"
											>
												<svg
													class="mr-2"
													width="21"
													height="20"
													viewBox="0 0 21 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.5 4.47715 16.0228 0 10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20ZM6.33333 9.16667C5.98816 9.16667 5.70833 9.53976 5.70833 10C5.70833 10.4602 5.98816 10.8333 6.33333 10.8333H15.0833C15.4285 10.8333 15.7083 10.4602 15.7083 10C15.7083 9.53976 15.4285 9.16667 15.0833 9.16667H6.33333Z"
														fill="#CBD5E1"
													/>
												</svg>

												Dự kiến
											</div>
											<div
												v-if="item?.state === 'past'"
												class="flex items-center"
											>
												<svg
													class="mr-2"
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M11.2125 6.21209C11.5786 5.84597 12.1722 5.84597 12.5384 6.21209C12.9 6.57371 12.9044 7.15727 12.5517 7.52434L7.56141 13.7622C7.5542 13.7712 7.54651 13.7798 7.53835 13.7879C7.17224 14.154 6.57865 14.154 6.21253 13.7879L2.90447 10.4799C2.53835 10.1137 2.53835 9.52015 2.90447 9.15403C3.27059 8.78791 3.86418 8.78791 4.2303 9.15403L6.8472 11.7709L11.1877 6.24013C11.1954 6.23029 11.2037 6.22093 11.2125 6.21209Z"
														fill="#94A3B8"
													/>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M10.062 12.6374L11.2125 13.7879C11.5786 14.154 12.1722 14.154 12.5384 13.7879C12.5465 13.7798 12.5542 13.7712 12.5614 13.7622L17.5517 7.52434C17.9044 7.15727 17.9 6.57371 17.5384 6.21209C17.1722 5.84597 16.5786 5.84597 16.2125 6.21209C16.2037 6.22093 16.1954 6.23029 16.1877 6.24013L11.8472 11.7709L11.2405 11.1642L10.062 12.6374Z"
														fill="#94A3B8"
													/>
												</svg>
												Đã dạy
											</div>
											<div
												v-if="item?.state === 'active'"
												class="flex items-center"
											>
												<svg
													class="mr-2"
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M11.2125 6.21209C11.5786 5.84597 12.1722 5.84597 12.5384 6.21209C12.9 6.57371 12.9044 7.15727 12.5517 7.52434L7.56141 13.7622C7.5542 13.7712 7.54651 13.7798 7.53835 13.7879C7.17224 14.154 6.57865 14.154 6.21253 13.7879L2.90447 10.4799C2.53835 10.1137 2.53835 9.52015 2.90447 9.15403C3.27059 8.78791 3.86418 8.78791 4.2303 9.15403L6.8472 11.7709L11.1877 6.24013C11.1954 6.23029 11.2037 6.22093 11.2125 6.21209Z"
														fill="#2AA797"
													/>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M10.062 12.6374L11.2125 13.7879C11.5786 14.154 12.1722 14.154 12.5384 13.7879C12.5465 13.7798 12.5542 13.7712 12.5614 13.7622L17.5517 7.52434C17.9044 7.15727 17.9 6.57371 17.5384 6.21209C17.1722 5.84597 16.5786 5.84597 16.2125 6.21209C16.2037 6.22093 16.1954 6.23029 16.1877 6.24013L11.8472 11.7709L11.2405 11.1642L10.062 12.6374Z"
														fill="#2AA797"
													/>
												</svg>
												Hiện tại
											</div>
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div
											@click.stop="
												$router.push({
													name: routerName.RECORD_EMPLOYEE_DETAIL,
													params: {
														id: item
															.created_by_staff
															.id,
													},
												})
											"
											class="flex items-center"
											v-if="item.created_by_staff"
										>
											<div>
												<img
													class="
														shadow-sm
														inline-block
														h-6
														w-6
														rounded-full
													"
													:src="
														item.created_by_staff
															?.profile_photo
															?.w200
													"
													alt=""
													v-if="
														item.created_by_staff
															?.profile_photo
															?.w200
													"
												/>
												<span
													v-else
													class="
														inline-flex
														items-center
														justify-center
														h-6
														w-6
														rounded-full
													"
													:style="{
														background:
															item
																.created_by_staff
																?.profile_photo
																?.default
																?.color,
													}"
												>
													<span
														class="
															font-medium
															leading-none
															text-white text-xs
														"
														>{{
															item
																.created_by_staff
																?.profile_photo
																?.default
																?.initial
														}}</span
													>
												</span>
											</div>
											<div
												class="
													ml-4
													text-blue-600
													font-semibold
													whitespace-pre-wrap
													w-40
												"
											>
												{{ item.created_by_staff.name }}
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="w-full h-full flex items-center justify-center flex-col"
		>
			<img
				src="@/assets/images/illustrations/not_found.png"
				alt=""
				height="250"
				width="250"
			/>
			<div
				class="text-neutral-500 text-lg text-center"
				style="max-width: 700px"
			>
				Không có thời khoá biểu
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import { $api } from '@/services';
import StatisticalTimeTable from '@/views/timetable/features/StatisticalTimeTable.vue';
import { _ROUTER_NAME } from '@/enums';

export default defineComponent({
	name: 'TimeTableHistory',
	components: { StatisticalTimeTable },
	data() {
		return {
			timeTable: [],
			params: {},
			isShow: false,
			id: '',
		};
	},

	created() {
		this.fetchTimeTableHistory();
	},

	computed: {
		routerName() {
			return _ROUTER_NAME;
		},
	},

	methods: {
		async fetchTimeTableHistory() {
			try {
				const res = await $api.schedule.fetchTimetableHistory(
					this.params
				);
				this.timeTable = res?.data?.data;
			} catch (e) {
				console.log(e);
			}
		},

		showDetail(id) {
			this.id = id;
			this.isShow = true;
		},
	},
});
</script>
