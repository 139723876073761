<template>
	<DaModalFrame v-model:open="deleteErrorPopup">
		<InviteError @close="deleteErrorPopup = false" @submit="submit" />
	</DaModalFrame>
	<div class="_invite-employee _modal bg-white">
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<img
					src="@/assets/images/illustrations/email_parent.svg"
					alt="Luca Education"
					title="Luca Education"
				/>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
				"
			>
				{{
					_invited
						? 'Gửi lời mời liên kết thành công'
						: 'Thông tin của phụ huynh học sinh'
				}}
			</div>
			<div class="text-center text-neutral-500" v-if="_invited">
				<div class="text-sienna-500 uppercase font-semibold my-4">
					MÃ LIÊN KẾT NÀY SẼ HẾT HẠN SAU 48 TIẾNG
				</div>
				Lời mời liên kết đã được gửi tới email
				<span class="text-green-500 font-semibold">{{ email }}</span>
				với Hướng dẫn tải, cài đặt và liên kết với tài khoản của học
				sinh. <br /><br />
				Trong trường hợp Phụ huynh chưa nhận được email, bạn có thể tải
				tài liệu này và gửi cho phụ huynh qua các kênh truyền thông khác
				mà Cơ sở can thiệp đang sử dụng như tin nhắn, zalo, fb...
			</div>
			<div class="text-center text-neutral-500 mt-2" v-else>
				Luca sẽ tự động gửi lời mời liên kết tới email của phụ huynh.
				Phụ huynh cần sử dụng email này để đăng nhập vào app
			</div>
			<div class="w-full my-6" v-if="!_invited">
				<div class="input-group flex items-center mt-5">
					<div
						class="
							input-group__label
							text-neutral-500
							font-normal
							w-2/5
						"
					>
						Email của PH*
					</div>
					<input
						type="text"
						placeholder="Điền email của PH dùng để đăng nhập App"
						v-model="email"
						v-on:keydown.enter="
							_invited
								? generateReport()
								: sendInviteParent(false)
						"
					/>
				</div>
				<div class="input-group flex items-center" v-if="error">
					<div class="w-2/5"></div>
					<div class="mt-4 w-full">
						<div class="error text-sienna-600 text-sm w-full">
							{{ error }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
				md-down:flex-col-reverse
			"
		>
			<button
				v-if="_invited"
				type="button"
				class="button-p btn--ghost text-center whitespace-nowrap"
				@click="generateReport"
			>
				Tải lời mời liên kết
			</button>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="$emit('close')"
			>
				Thoát
			</button>
			<button
				v-if="!_invited"
				type="button"
				class="button-p text-center whitespace-nowrap"
				@click="sendInviteParent(false)"
			>
				Gửi lời mời liên kết app PH
			</button>
		</div>
	</div>
</template>

<script>
import { Options, Vue } from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';
import { $api } from '@/services';
import { generateReport } from '@/helper/generateReport';
import { _GET_DATE_TIME } from '@/helper/formatDate';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import InviteError from '@/views/record/studentDetail/features/inviteParent/InviteError.vue';

@Options({
	name: 'InviteParentPopup',
	components: { InviteError, DaModalFrame },
})
export default class InviteParentPopup extends Vue {
	@PropSync('invited') _invited = false;
	@Prop() studentName;
	@Prop() id;

	email = '';
	error = '';
	data = null;
	deleteErrorPopup = false;

	// get id() {
	// 	return String(this.$route.params.id || '');
	// }

	submit() {
		this.sendInviteParent(true);
		this.deleteErrorPopup = false;
	}

	async sendInviteParent(ignore_parent_deleted) {
		this.error = '';

		try {
			const { data } = await $api.student.sendInviteParent(
				this.id ? this.id : String(this.$route.params.id),
				this.email,
				ignore_parent_deleted || false
			);
			if (data) {
				this.data = data.data;
				await $api.student.sendEmailInviteParent(
					this.data.student_id,
					this.data.id
				);
				this._invited = true;
				await this.$store.dispatch('setAlertMessage', {
					message: 'Gửi lời mời cho phụ huynh thành công',
				});
			}
			this.$emit('invited');
		} catch (err) {
			if (err?.response?.data?.code === 'parent_deleted') {
				this.deleteErrorPopup = true;
			} else if (err?.response?.data?.errors_by_field?.email) {
				this.error =
					'Email ' +
						err?.response?.data?.errors_by_field?.email.join(
							'<br/>'
						) || err.message;
			} else {
				this.error =
					err?.response?.data?.errors.join('<br/>') || err.message;
			}
		}
	}

	get org() {
		return this.$store.state.profile.current_org;
	}

	generateReport() {
		this.error = '';

		let fileName = this.data.email + '_' + this.studentName;
		fileName = fileName.toLowerCase();
		fileName = fileName.replace(/\s/g, '_');
		return generateReport({
			fileName: fileName + '.pdf',
			orgName: this.org.name,
			studentName: this.studentName,
			email: this.data.email,
			code: this.data.code,
			date: _GET_DATE_TIME(this.data?.valid_until),
		});
	}
}
</script>
