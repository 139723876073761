<template>
	<div :class="isTakeLeaveModal ? 'side-over--active' : ''">
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			v-if="isTakeLeaveModal"
			@click="isTakeLeaveModal = false"
		></div>
		<TakeLeaveFormDaycare
			@success="takeLeaveSuccess"
			@close="isTakeLeaveModal = false"
			:data="dataTakeLeave"
			:staffs="staffsTakeLeave"
			:type="type"
			:default-staff="staffSelected"
			:open="isTakeLeaveModal"
		/>
	</div>

	<!--  restore cancel session-->
	<DaModalFrame v-model:open="modalIsOpen">
		<RestoreCancelSessionDayCare
			:data="idRestore"
			:type="type"
			@close="closeRestoreCancelSession"
			@success="restoreSessionSuccess"
		/>
	</DaModalFrame>
	<div v-if="!isLoading" class="my-10">
		<div class="flex items-end justify-between md-down:flex-col">
			<div class="w-full grid grid-cols-2 md:flex gap-8 flex-wrap">
				<div>
					<div class="font-semibold uppercase text-gray-400 text-xs">
						Ngày
					</div>
					<div class="mt-2 w-full md:w-40">
						<Multiselect
							v-model="dateSelected"
							deselect-label="Đã chọn"
							track-by="id"
							:options="
								dates?.map((s) => {
									return {
										nameSearch: toEnglish(s?.name),
										...s,
									};
								})
							"
							:searchable="true"
							label="nameSearch"
							:allow-empty="false"
							selectLabel=""
							placeholder="Chọn ngày"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									<div>
										{{ props.option.name }}
									</div>
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									<div>{{ option.name }}</div>
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<div>
					<div class="font-semibold uppercase text-gray-400 text-xs">
						Lớp bán trú
					</div>
					<div class="mt-2 w-full md:w-40">
						<Multiselect
							v-model="classSelected"
							deselect-label="Đã chọn"
							track-by="id"
							:options="
								[
									{
										name: '-',
										id: '-',
										value: '-',
									},
									{
										name: 'Tất cả',
										id: null,
										value: null,
									},
									...classOptions,
								]?.map((s) => {
									return {
										nameSearch: toEnglish(s?.name),
										...s,
									};
								})
							"
							:searchable="true"
							label="nameSearch"
							:allow-empty="false"
							selectLabel=""
							placeholder="Chọn lớp bán trú"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									<div>
										{{ props.option.name }}
									</div>
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									<div>{{ option.name }}</div>
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<div>
					<div class="font-semibold uppercase text-gray-400 text-xs">
						Giáo viên
					</div>
					<div class="mt-2 w-full md:w-40">
						<Multiselect
							v-model="staffSelected"
							deselect-label="Đã chọn"
							track-by="id"
							:options="
								[
									{
										name: '-',
										id: '-',
										value: '-',
									},
									{
										name: 'Tất cả',
										id: null,
										value: null,
									},
									...staffs,
								]?.map((s) => {
									return {
										nameSearch: toEnglish(s?.name),
										...s,
									};
								})
							"
							:searchable="true"
							label="nameSearch"
							:allow-empty="false"
							selectLabel=""
							placeholder="Chọn học sinh"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									<div>
										{{ props.option.name }}
									</div>
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									<div>{{ option.name }}</div>
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<div>
					<div class="font-semibold uppercase text-gray-400 text-xs">
						Học sinh
					</div>
					<div class="mt-2 w-full md:w-40">
						<Multiselect
							v-model="studentSelected"
							deselect-label="Đã chọn"
							track-by="id"
							:options="
								[
									{
										name: '-',
										id: '-',
										value: '-',
									},
									{
										name: 'Tất cả',
										id: null,
										value: null,
									},
									...students,
								]?.map((s) => {
									return {
										nameSearch: toEnglish(s?.name),
										...s,
									};
								})
							"
							:searchable="true"
							label="nameSearch"
							:allow-empty="false"
							selectLabel=""
							placeholder="Chọn học sinh"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									<div>
										{{ props.option.name }}
									</div>
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									<div>{{ option.name }}</div>
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<div>
					<div class="font-semibold uppercase text-gray-400 text-xs">
						Trạng thái
					</div>
					<div class="mt-2 w-full md:w-40">
						<Multiselect
							v-model="stateSelected"
							deselect-label="Đã chọn"
							track-by="id"
							:options="
								states?.map((s) => {
									return {
										nameSearch: toEnglish(s?.name),
										...s,
									};
								})
							"
							:searchable="true"
							label="nameSearch"
							:allow-empty="false"
							selectLabel=""
							placeholder="Chọn trạng thái"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									<div>
										{{ props.option.name }}
									</div>
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									<div>{{ option.name }}</div>
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<div
					class="
						flex
						items-end
						pb-2
						cursor-pointer
						font-semibold
						text-gray-400
						hover:text-gray-600
					"
					@click="removeFilter"
				>
					Bỏ bộ lọc
				</div>
			</div>
			<div
				class="
					md-down:w-full
					grid grid-cols-2
					md:flex
					items-end
					justify-start
					md:justify-end
					gap-4
					md-down:mt-4
				"
			>
				<button
					type="button"
					class="
						button-p
						text-center
						whitespace-nowrap
						w-full
						px-4
						btn--ghost
					"
					:disabled="!isTakeLeave"
					@click="downloadCSVSchedule"
				>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M10.0842 4.92956C9.93745 4.92956 9.79691 4.87117 9.69326 4.76725C9.58961 4.66322 9.53137 4.52232 9.53137 4.37535V0H3.54448C2.95814 0 2.39559 0.233557 1.981 0.649336C1.56628 1.06499 1.33331 1.62898 1.33331 2.21681V13.7832C1.33331 14.371 1.56628 14.935 1.981 15.3507C2.39559 15.7664 2.95814 16 3.54448 16H12.4555C13.0418 16 13.6044 15.7664 14.019 15.3507C14.4337 14.935 14.6666 14.371 14.6666 13.7832V4.92987L10.0842 4.92956ZM12.732 12.7106C12.732 12.8575 12.6738 12.9986 12.5701 13.1025C12.4663 13.2064 12.3258 13.2648 12.1792 13.2648H3.821C3.67441 13.2648 3.53387 13.2064 3.4301 13.1025C3.32645 12.9986 3.26821 12.8575 3.26821 12.7106V7.99984C3.26821 7.85288 3.32645 7.71185 3.4301 7.60794C3.53387 7.50403 3.67441 7.44564 3.821 7.44564H12.1792C12.3258 7.44564 12.4663 7.50403 12.5701 7.60794C12.6738 7.71185 12.732 7.85288 12.732 7.99984V12.7106ZM4.37379 10.9094H7.44735V12.1564H4.37379V10.9094ZM4.37379 8.55677H7.44735V9.801H4.37379V8.55677ZM8.5529 8.55405H11.6265V9.79828H8.5529V8.55405ZM8.5529 10.9067H11.6265V12.1564H8.5529V10.9067ZM14.3323 3.82112H10.6371V0.318558L14.3323 3.82112Z"
							fill="currentColor"
						/>
					</svg>

					<span class="ml-2">Tải file .XLXS</span>
				</button>
				<button
					type="button"
					class="
						button-p
						text-center
						whitespace-nowrap
						w-fit
						px-4
						btn--ghost
					"
					:disabled="!isTakeLeave"
					@click="openModalTakeLeave"
				>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM5.35355 4.64645C5.15829 4.45118 4.84171 4.45118 4.64645 4.64645C4.45118 4.84171 4.45118 5.15829 4.64645 5.35355L7.29289 8L4.64645 10.6464C4.45118 10.8417 4.45118 11.1583 4.64645 11.3536C4.84171 11.5488 5.15829 11.5488 5.35355 11.3536L8 8.70711L10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L8.70711 8L11.3536 5.35355C11.5488 5.15829 11.5488 4.84171 11.3536 4.64645C11.1583 4.45118 10.8417 4.45118 10.6464 4.64645L8 7.29289L5.35355 4.64645Z"
							fill="currentColor"
						/>
					</svg>

					<span class="ml-2">Báo nghỉ</span>
				</button>
			</div>
		</div>
		<div
			v-if="dateSelected && dateSelected.id === 'custom'"
			class="flex items-center gap-8 mt-8"
		>
			<div class="w-56">
				<div class="font-semibold uppercase text-gray-400 text-xs">
					Từ ngày
				</div>
				<div class="w-full mt-2">
					<div class="input-group flex items-center">
						<VueDatepicker
							uid="session-by-from-date"
							v-model="fromDate"
							:enableTimePicker="false"
							class="input-date w-full"
							placeholder="Chọn ngày bắt đầu"
							format="dd/MM/yyyy"
							autoApply
						>
							<template #input-icon>
								<DaIcon
									name="das--lock"
									class="h-4 w-4 text-neutral-400"
								/>
							</template>
						</VueDatepicker>
					</div>
				</div>
			</div>
			<div class="w-56">
				<div class="font-semibold uppercase text-gray-400 text-xs">
					Đến ngày
				</div>
				<div class="w-full mt-2">
					<div class="input-group flex items-center">
						<VueDatepicker
							uid="session-by-to-date"
							v-model="toDate"
							:enableTimePicker="false"
							class="input-date w-full"
							placeholder="Chọn ngày kết thúc"
							format="dd/MM/yyyy"
							autoApply
						>
							<template #input-icon>
								<DaIcon
									name="das--lock"
									class="h-4 w-4 text-neutral-400"
								/>
							</template>
						</VueDatepicker>
					</div>
				</div>
			</div>
		</div>
		<div v-if="data && data.length">
			<div class="shadow-sm sm:rounded-2xl mt-10">
				<div class="__record__table overflow-x-auto scroll-bar">
					<div class="sm:rounded-t-2xl w-fit min-w-full">
						<table class="min-w-full divide-y text-neutral-200">
							<thead class="bg-neutral-100 h-16">
								<tr>
									<th class="px-4">
										<Checkbox v-model:value="checkBoxAll" />
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Ngày
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Lớp bán trú
									</th>
									<th
										v-if="
											studentSelected?.id !== '-' ||
											classSelected?.id !== '-'
										"
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										{{
											classSelected?.id !== '-'
												? 'Học sinh'
												: 'Tên học sinh'
										}}
									</th>
									<th
										v-if="staffSelected?.id !== '-'"
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Giáo viên
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Trạng thái
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Phân loại
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Ghi chú
									</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in data"
									:key="item.uuid"
									:class="
										item.state === 'cancelled'
											? 'bg-sienna-50'
											: index % 2 === 0
											? 'bg-white'
											: 'bg-neutral-50'
									"
									class="text-neutral-700"
								>
									<td class="px-4">
										<Checkbox
											:value="
												checkBoxSelected.find(
													(i) => i === item.uuid
												)
											"
											@click="checkChanged(item.uuid)"
										/>
									</td>
									<td>
										<div class="px-3 py-4 text-neutral-700">
											<span v-if="item.date">{{
												$filters.formatDate2(item.date)
											}}</span>
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											text-neutral-700
											hover:text-blue-600 hover:underline
											cursor-pointer
											whitespace-pre-wrap
											w-40
										"
									>
										{{
											item.day_care_class
												? item.day_care_class.name
												: ''
										}}
									</td>
									<td
										v-if="studentSelected?.id !== '-'"
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
											hover:text-yellow-700
											hover:underline
											cursor-pointer
										"
									>
										<div
											@click.stop="
												$router.push(
													`/record/student/${item.student.id}`
												)
											"
											class="flex items-center"
											v-if="item.student"
										>
											<div>
												<img
													class="
														shadow-sm
														inline-block
														h-9
														w-9
														rounded-full
													"
													:src="
														item.student
															?.profile_photo
															?.w200
													"
													alt=""
													v-if="
														item.student
															?.profile_photo
															?.w200
													"
												/>
												<span
													v-else
													class="
														inline-flex
														items-center
														justify-center
														h-9
														w-9
														rounded-full
													"
													:style="{
														background:
															item.student
																?.profile_photo
																?.default
																?.color,
													}"
												>
													<span
														class="
															font-medium
															leading-none
															text-white text-xs
														"
														>{{
															item.student
																?.profile_photo
																?.default
																?.initial
														}}</span
													>
												</span>
											</div>
											<div
												class="
													ml-4
													text-yellow-600
													font-semibold
													whitespace-pre-wrap
													w-40
												"
											>
												{{ item.student.name }}
											</div>
										</div>
									</td>
									<td
										v-if="classSelected?.id !== '-'"
										class="px-3 py-4 whitespace-nowrap"
									>
										<UserGroup
											v-if="
												item.day_care_class?.students
													?.length
											"
											:data="
												item.day_care_class?.students
											"
										/>
									</td>
									<td
										v-if="staffSelected?.id !== '-'"
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div
											v-if="item?.staff"
											@click.stop="
												$router.push(
													`/record/staff/${item.staff.id}`
												)
											"
											class="
												flex
												items-center
												hover:text-blue-600
												hover:underline
												cursor-pointer
											"
										>
											<div>
												<img
													class="
														shadow-sm
														inline-block
														h-9
														w-9
														rounded-full
													"
													:src="
														item?.staff
															?.profile_photo
															?.w200
													"
													alt=""
													v-if="
														item?.staff
															?.profile_photo
															?.w200
													"
												/>
												<span
													v-else
													class="
														inline-flex
														items-center
														justify-center
														h-9
														w-9
														rounded-full
													"
													:style="{
														background:
															item?.staff
																?.profile_photo
																?.default
																?.color,
													}"
												>
													<span
														class="
															font-medium
															leading-none
															text-white text-xs
														"
														>{{
															item?.staff
																?.profile_photo
																?.default
																?.initial
														}}</span
													>
												</span>
											</div>
											<div
												class="
													ml-4
													text-blue-600
													font-semibold
												"
											>
												{{ item?.staff.name }}
											</div>
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div>
											<div
												v-if="
													item.state !== 'created' &&
													item.state !==
														'cancelled' &&
													item.state !== 'ongoing'
												"
												class="
													text-xs
													w-fit
													font-semibold
													bg-neutral-100
													text-gray-500
													p-2
													px-4
													pb-1.5
													rounded-full
													flex
													items-center
													justify-center
													gap-2
												"
											>
												<div>
													<svg
														width="15"
														height="14"
														viewBox="0 0 15 14"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M7.5 14C11.366 14 14.5 10.866 14.5 7C14.5 3.13401 11.366 0 7.5 0C3.63401 0 0.5 3.13401 0.5 7C0.5 10.866 3.63401 14 7.5 14ZM11.0491 6.63407C10.9689 6.50148 10.846 6.41667 10.7083 6.41667H4.58333C4.44564 6.41667 4.3228 6.50148 4.2426 6.63407C4.14461 6.72535 4.08333 6.85552 4.08333 7C4.08333 7.14448 4.14461 7.27465 4.2426 7.36593C4.3228 7.49852 4.44564 7.58333 4.58333 7.58333H10.7083C10.846 7.58333 10.9689 7.49852 11.0491 7.36593C11.1471 7.27465 11.2083 7.14448 11.2083 7C11.2083 6.85552 11.1471 6.72535 11.0491 6.63407Z"
															fill="#CBD5E1"
														/>
													</svg>
												</div>
												<div>Chưa học</div>
											</div>
											<div
												v-if="item.state === 'ongoing'"
												class="
													w-fit
													font-semibold
													bg-blue-100
													text-xs text-blue-600
													p-2
													px-4
													pb-1.5
													rounded-full
													flex
													items-center
													justify-center
													gap-2
												"
											>
												<div>
													<svg
														width="15"
														height="14"
														viewBox="0 0 15 14"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M7.5 14C11.366 14 14.5 10.866 14.5 7C14.5 3.13401 11.366 0 7.5 0C3.63401 0 0.5 3.13401 0.5 7C0.5 10.866 3.63401 14 7.5 14ZM11.0491 6.63407C10.9689 6.50148 10.846 6.41667 10.7083 6.41667H4.58333C4.44564 6.41667 4.3228 6.50148 4.2426 6.63407C4.14461 6.72535 4.08333 6.85552 4.08333 7C4.08333 7.14448 4.14461 7.27465 4.2426 7.36593C4.3228 7.49852 4.44564 7.58333 4.58333 7.58333H10.7083C10.846 7.58333 10.9689 7.49852 11.0491 7.36593C11.1471 7.27465 11.2083 7.14448 11.2083 7C11.2083 6.85552 11.1471 6.72535 11.0491 6.63407Z"
															fill="#CBD5E1"
														/>
													</svg>
												</div>
												<div>Đang học</div>
											</div>
											<div
												v-if="
													item.state === 'cancelled'
												"
												class="
													text-xs
													w-fit
													font-semibold
													bg-sienna-100
													text-sienna-500
													p-2
													px-4
													pb-1.5
													rounded-full
													flex
													items-center
													justify-center
													gap-2
												"
											>
												<div>
													<svg
														width="15"
														height="14"
														viewBox="0 0 15 14"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M5.19063 4.35739C5.36148 4.18653 5.63849 4.18653 5.80934 4.35739L8.12498 6.67303L10.4406 4.35739C10.6115 4.18653 10.8885 4.18653 11.0593 4.35739C11.2302 4.52824 11.2302 4.80525 11.0593 4.97611L8.7437 7.29175L11.0593 9.60739C11.2302 9.77824 11.2302 10.0553 11.0593 10.2261C10.8885 10.397 10.6115 10.397 10.4406 10.2261L8.12498 7.91047L5.80934 10.2261C5.63849 10.397 5.36148 10.397 5.19063 10.2261C5.01977 10.0553 5.01977 9.77824 5.19063 9.60739L7.50627 7.29175L5.19063 4.97611C5.01977 4.80525 5.01977 4.52824 5.19063 4.35739Z"
															fill="#DB5A41"
														/>
													</svg>
												</div>
												<div>
													{{
														item.is_class_session_cancelled ||
														type === 2
															? 'Lớp nghỉ'
															: type === 1
															? 'HS nghỉ'
															: 'GV nghỉ'
													}}
												</div>
											</div>
											<div
												v-if="item.state === 'created'"
												class="
													text-xs
													w-fit
													font-semibold
													bg-green-50
													text-green-500
													p-2
													px-4
													pb-1.5
													rounded-full
													flex
													items-center
													justify-center
													gap-2
												"
											>
												<div>
													<svg
														width="15"
														height="14"
														viewBox="0 0 15 14"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M8.68176 4.34846C8.93804 4.09218 9.35355 4.09218 9.60983 4.34846C9.86297 4.6016 9.86608 5.01009 9.61915 5.26704L6.12597 9.63351C6.12093 9.63981 6.11554 9.64583 6.10983 9.65154C5.85355 9.90782 5.43804 9.90782 5.18176 9.65154L2.86612 7.3359C2.60983 7.07962 2.60983 6.6641 2.86612 6.40782C3.1224 6.15154 3.53791 6.15154 3.79419 6.40782L5.62602 8.23965L8.66435 4.36809C8.66975 4.36121 8.67557 4.35465 8.68176 4.34846Z"
															fill="#339588"
														/>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M7.87639 8.84617L8.68176 9.65154C8.93804 9.90782 9.35355 9.90782 9.60984 9.65154C9.61554 9.64583 9.62093 9.63981 9.62597 9.63351L13.1191 5.26704C13.3661 5.01009 13.363 4.6016 13.1098 4.34846C12.8536 4.09218 12.438 4.09218 12.1818 4.34846C12.1756 4.35465 12.1698 4.36121 12.1644 4.36809L9.12602 8.23965L8.70135 7.81497L7.87639 8.84617Z"
															fill="#339588"
														/>
													</svg>
												</div>
												<div>Đã học</div>
											</div>
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div>
											<div
												v-if="!item.scheduled"
												class="
													text-xs
													w-fit
													font-semibold
													text-sienna-600
													p-2
													px-4
													pb-1.5
													rounded-full
													border border-sienna-200
													flex
													items-center
													justify-center
												"
											>
												Phát sinh
											</div>
											<div
												v-else
												class="
													text-xs
													w-fit
													font-semibold
													text-neutral-600
													p-2
													px-4
													pb-1.5
													rounded-full
													border border-neutral-300
													flex
													items-center
													justify-center
												"
											>
												Cố định
											</div>
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div class="text-neutral-600">
											<div v-if="item.unscheduled_note">
												<span class="font-semibold"
													>Phát sinh: </span
												>{{ item.unscheduled_note }}
											</div>
											<div v-if="item.leave_note">
												<span class="font-semibold"
													>Báo nghỉ: </span
												>{{ item.leave_note }}
											</div>
											<div v-if="item.staff_change_note">
												<span class="font-semibold"
													>Đổi TLV: </span
												>{{ item.staff_change_note }}
											</div>
											<div v-if="item.slot_change_note">
												<span class="font-semibold"
													>Đổi giờ:
												</span>
												{{ item.slot_change_note }}
											</div>
											<div v-if="item.note">
												{{ item.note }}
											</div>
										</div>
									</td>
									<td>
										<Menu as="div" class="ml-3 relative">
											<div>
												<MenuButton
													class="
														rounded-full
														flex
														text-sm
														focus:outline-none
														focus:ring-2
														focus:ring-offset-2
														focus:ring-indigo-500
													"
												>
													<span class="sr-only"
														>Open user menu</span
													>
													<svg
														width="20"
														height="20"
														viewBox="0 0 20 20"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
															fill="#94A3B8"
														/>
													</svg>
												</MenuButton>
											</div>
											<transition
												enter-active-class="transition ease-out duration-200"
												enter-from-class="transform opacity-0 scale-95"
												enter-to-class="transform opacity-100 scale-100"
												leave-active-class="transition ease-in duration-75"
												leave-from-class="transform opacity-100 scale-100"
												leave-to-class="transform opacity-0 scale-95"
											>
												<MenuItems
													class="
														origin-top-right
														absolute
														right-0
														mt-2
														w-48
														rounded-md
														shadow-lg
														py-1
														bg-white
														ring-1
														ring-black
														ring-opacity-5
														focus:outline-none
														z-10
													"
												>
													<MenuItem
														v-if="
															item.state !==
															'cancelled'
														"
														v-slot="{ active }"
													>
														<div
															@click="
																selectedTakeLeave(
																	item.uuid
																)
															"
															class="
																p-2
																px-4
																bg-white
																z-10
																text-gray-600
																hover:bg-neutral-50
																flex
																items-center
																gap-4
															"
															:class="
																active ? '' : ''
															"
														>
															<svg
																width="16"
																height="16"
																viewBox="0 0 16 16"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM5.35355 4.64645C5.15829 4.45118 4.84171 4.45118 4.64645 4.64645C4.45118 4.84171 4.45118 5.15829 4.64645 5.35355L7.29289 8L4.64645 10.6464C4.45118 10.8417 4.45118 11.1583 4.64645 11.3536C4.84171 11.5488 5.15829 11.5488 5.35355 11.3536L8 8.70711L10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L8.70711 8L11.3536 5.35355C11.5488 5.15829 11.5488 4.84171 11.3536 4.64645C11.1583 4.45118 10.8417 4.45118 10.6464 4.64645L8 7.29289L5.35355 4.64645Z"
																	fill="#94A3B8"
																/>
															</svg>

															<span class="mt-0.5"
																>Báo nghỉ</span
															>
														</div>
													</MenuItem>
													<MenuItem
														v-else
														v-slot="{ active }"
													>
														<div
															@click="
																openRestoreCancelSession(
																	item
																)
															"
															class="
																p-2
																px-4
																bg-white
																z-10
																text-gray-600
																hover:bg-neutral-50
																flex
																items-center
																gap-4
															"
															:class="
																active ? '' : ''
															"
														>
															<svg
																width="16"
																height="16"
																viewBox="0 0 16 16"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M11.5338 7H15.4663C15.6782 7 15.794 7.24721 15.6583 7.41005L13.6921 9.76953C13.5922 9.88947 13.4079 9.88947 13.308 9.76953L11.3417 7.41005C11.2061 7.24721 11.3218 7 11.5338 7Z"
																	fill="#94A3B8"
																/>
																<path
																	d="M0.533803 9H4.46628C4.67824 9 4.79403 8.75279 4.65834 8.58995L2.6921 6.23047C2.59215 6.11053 2.40794 6.11053 2.30799 6.23047L0.341748 8.58995C0.206054 8.75279 0.321844 9 0.533803 9Z"
																	fill="#94A3B8"
																/>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M8.00004 3C6.44755 3 5.06061 3.70697 4.14273 4.81839C3.96689 5.03131 3.65174 5.06137 3.43882 4.88552C3.2259 4.70968 3.19584 4.39453 3.37168 4.18161C4.47139 2.85003 6.13666 2 8.00004 2C10.9417 2 13.3881 4.1165 13.9011 6.90967C13.9066 6.9397 13.9119 6.96981 13.917 7H12.9001C12.4371 4.71778 10.4187 3 8.00004 3ZM3.09996 9C3.56297 11.2822 5.58142 13 8.00004 13C9.55254 13 10.9395 12.293 11.8574 11.1816C12.0332 10.9687 12.3484 10.9386 12.5613 11.1145C12.7742 11.2903 12.8042 11.6055 12.6284 11.8184C11.5287 13.15 9.86343 14 8.00004 14C5.05834 14 2.61202 11.8835 2.09896 9.09033C2.09344 9.0603 2.08815 9.03019 2.08308 9H3.09996Z"
																	fill="#94A3B8"
																/>
															</svg>

															<span class="mt-0.5"
																>Khôi phục buổi
																học</span
															>
														</div>
													</MenuItem>
													<MenuItem
														v-slot="{ active }"
													>
														<div
															@click="
																downloadCSVScheduleOnly(
																	item.uuid
																)
															"
															class="
																p-2
																px-4
																bg-white
																z-10
																text-gray-600
																hover:bg-neutral-50
																flex
																items-center
																gap-4
															"
															:class="
																active ? '' : ''
															"
														>
															<svg
																width="16"
																height="16"
																viewBox="0 0 16 16"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M8 0C6.57359 0 5.31176 0.560456 4.40576 1.34182C3.63985 2.00237 3.08479 2.8617 2.94223 3.725C1.26586 4.09512 0 5.55511 0 7.31818C0 9.36599 1.70754 11 3.78125 11H7.5V5.5C7.5 5.22386 7.72386 5 8 5C8.27614 5 8.5 5.22386 8.5 5.5V11H12.6875C14.5023 11 16 9.56949 16 7.77273C16 6.13656 14.7581 4.80411 13.1657 4.57898C12.9233 1.99944 10.6902 0 8 0Z"
																	fill="#94A3B8"
																/>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M7.64645 15.8536C7.84171 16.0488 8.15829 16.0488 8.35355 15.8536L11.3536 12.8536C11.5488 12.6583 11.5488 12.3417 11.3536 12.1464C11.1583 11.9512 10.8417 11.9512 10.6464 12.1464L8.5 14.2929V11H7.5V14.2929L5.35355 12.1464C5.15829 11.9512 4.84171 11.9512 4.64645 12.1464C4.45118 12.3417 4.45118 12.6583 4.64645 12.8536L7.64645 15.8536Z"
																	fill="#94A3B8"
																/>
															</svg>
															<span class="mt-0.5"
																>Tải file
																.XLXS</span
															>
														</div>
													</MenuItem>
												</MenuItems>
											</transition>
										</Menu>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="
				w-full
				h-full
				flex
				items-center
				justify-center
				flex-col
				mt-10
			"
		>
			<img
				src="@/assets/images/illustrations/not_found.png"
				alt=""
				height="250"
				width="250"
			/>
			<div
				class="text-neutral-500 text-lg text-center"
				style="max-width: 700px"
			>
				<span
					v-if="
						classSelected?.id ||
						studentSelected?.id ||
						staffSelected?.id ||
						stateSelected?.id ||
						dateSelected?.id !== 'today'
					"
					>Không có buổi học nào phù hợp. <br />
					Bạn vui lòng kiểm tra lại bộ lọc</span
				>
				<span v-else>Không có lịch học bán trú hôm nay</span>
			</div>
		</div>
	</div>
	<div v-else class="fixed top-1/2 left-1/2">
		<Loading />
	</div>
</template>

<script>
import Checkbox from '@/components/elements/checkbox/Checkbox';
import { $api } from '@/services';
import Multiselect from '@suadelabs/vue3-multiselect';
import { Menu, MenuItem, MenuItems, MenuButton } from '@headlessui/vue';
import TakeLeaveFormDaycare from '@/views/sessionManagement/features/takeLeaveFormDaycare';
import DaIcon from '@/components/elements/daIcon/DaIcon';
import dayjs from 'dayjs';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame';
import RestoreCancelSessionDayCare from '@/views/sessionManagement/features/RestoreCancelSessionDayCare';
import Loading from '@/components/common/Loading';
import DownloadCSV from '@/mixins/downloadCSV';
import { toEnglish } from '@/helper/toEnglish';
import UserGroup from '@/components/elements/UserGroup.vue';

export default {
	name: 'SessionManagementDayCare',
	components: {
		UserGroup,
		Loading,
		RestoreCancelSessionDayCare,
		DaModalFrame,
		DaIcon,
		TakeLeaveFormDaycare,
		Checkbox,
		Multiselect,
		Menu,
		MenuItem,
		MenuItems,
		MenuButton,
	},
	data() {
		return {
			checkBoxAll: false,
			isLoading: true,
			dateSelected: {
				id: 'today',
				name: 'Hôm nay',
			},
			dates: [
				{
					id: 'yesterday',
					name: 'Hôm qua',
				},
				{
					id: 'today',
					name: 'Hôm nay',
				},
				{
					id: 'this_week',
					name: 'Tuần này',
				},
				{
					id: 'this_month',
					name: 'Tháng này',
				},
				{
					id: 'last_month',
					name: 'Tháng trước',
				},
				{
					id: 'custom',
					name: 'Tuỳ chọn',
				},
			],
			toDate: null,
			fromDate: null,
			classOptions: [],
			classSelected: {
				name: '-',
				id: '-',
				value: '-',
			},
			studentSelected: {
				name: 'Tất cả',
				id: null,
				value: null,
			},
			staffSelected: {
				name: '-',
				id: '-',
				value: '-',
			},
			students: [],
			staffs: [],
			staffsTakeLeave: [],
			stateSelected: {
				name: 'Tất cả',
				id: null,
				value: null,
			},
			states: [
				{
					id: '',
					name: 'Tất cả',
				},
				{
					id: 'created',
					name: 'Đã học',
				},
				{
					id: 'future',
					name: 'Chưa học',
				},
				{
					id: 'cancelled',
					name: 'Nghỉ học',
				},
			],
			data: [],
			staffs_map: {},
			students_map: {},
			params: {
				duration: 'today',
				state: undefined,
			},
			checkBoxSelected: [],
			isTakeLeaveModal: false,
			dataTakeLeave: [],
			modalIsOpen: false,
			idRestore: '',
		};
	},

	created() {
		const month = this.$route?.query?.month;
		if (month) {
			this.dateSelected = {
				id: 'custom',
				name: 'Tuỳ chọn',
			};
			this.fromDate = dayjs(
				new Date(new Date().getFullYear(), month - 1, 1)
			);
			this.toDate = dayjs(this.fromDate).endOf('month');
		}
		this.detectCallBy();
		this.fetchClasses();
		this.fetchStaffs();
		this.fetchStudents();
	},

	computed: {
		isTakeLeave() {
			const sessions = this.data.filter((item) =>
				this.checkBoxSelected.includes(item.uuid)
			);
			return (
				sessions?.length &&
				!sessions.some((s) => s.state === 'cancelled')
			);
		},

		isAdminAndAccountant() {
			return this.$store.getters.isAdminAndAccountant;
		},

		type() {
			if (this.classSelected?.id !== '-') {
				return 2;
			}

			if (this.staffSelected?.id !== '-') {
				return 3;
			}

			return 1;
		},
	},

	watch: {
		studentSelected: {
			deep: true,
			immediate: true,
			handler(value) {
				if (value && value?.id !== '-') {
					this.classSelected = {
						name: '-',
						id: '-',
						value: '-',
					};
					this.staffSelected = {
						name: '-',
						id: '-',
						value: '-',
					};
					this.checkBoxSelected = [];
					this.fetchSessionOnToDay();
				} else if (
					this.classSelected?.id === '-' &&
					this.staffSelected?.id === '-'
				) {
					this.studentSelected = {
						name: 'Tất cả',
						id: null,
						value: null,
					};
				}
			},
		},

		classSelected: {
			deep: true,
			handler(value) {
				if (value && value?.id !== '-') {
					this.studentSelected = {
						name: '-',
						id: '-',
						value: '-',
					};
					this.staffSelected = {
						name: '-',
						id: '-',
						value: '-',
					};
					this.checkBoxSelected = [];
					this.fetchSessionOnToDayByClass();
				} else if (
					this.staffSelected?.id === '-' &&
					this.studentSelected?.id === '-'
				) {
					this.studentSelected = {
						name: 'Tất cả',
						id: null,
						value: null,
					};
				}
			},
		},

		staffSelected: {
			deep: true,
			handler(value) {
				if (value && value?.id !== '-') {
					this.studentSelected = {
						name: '-',
						id: '-',
						value: '-',
					};
					this.classSelected = {
						name: '-',
						id: '-',
						value: '-',
					};
					this.checkBoxSelected = [];
					this.fetchSessionOnToDayByStaff();
				} else if (
					this.classSelected?.id === '-' &&
					this.studentSelected?.id === '-'
				) {
					this.studentSelected = {
						name: 'Tất cả',
						id: null,
						value: null,
					};
				}
			},
		},

		stateSelected: {
			deep: true,
			immediate: true,
			handler(value) {
				if (value) {
					this.params = {
						...this.params,
						state: value?.id,
					};
					this.checkBoxSelected = [];
					this.detectCallBy();
				}
			},
		},

		dateSelected: {
			deep: true,
			immediate: true,
			handler(value) {
				if (value && value.id !== 'custom') {
					this.params = {
						...this.params,
						duration: value.id || 'today',
					};
					this.checkBoxSelected = [];
					this.detectCallBy();
				}
			},
		},

		fromDate: {
			deep: true,
			immediate: true,
			handler(value) {
				if (
					value &&
					this.dateSelected &&
					this.dateSelected.id === 'custom' &&
					this.toDate
				) {
					this.params = {
						...this.params,
						duration: 'custom',
						to_date: dayjs(this.toDate).format('YYYY-MM-DD'),
						from_date: dayjs(value).format('YYYY-MM-DD'),
					};
					this.checkBoxSelected = [];
					this.detectCallBy();
				}
			},
		},

		toDate: {
			deep: true,
			immediate: true,
			handler(value) {
				if (
					value &&
					this.dateSelected &&
					this.dateSelected.id === 'custom' &&
					this.fromDate
				) {
					this.params = {
						...this.params,
						duration: 'custom',
						from_date: dayjs(this.fromDate).format('YYYY-MM-DD'),
						to_date: dayjs(value).format('YYYY-MM-DD'),
					};
					this.checkBoxSelected = [];
					this.detectCallBy();
				}
			},
		},

		checkBoxAll(value) {
			if (value) {
				this.checkBoxSelected = this.data.map((item) => item.uuid);
			} else {
				this.checkBoxSelected = [];
			}
		},
	},

	methods: {
		toEnglish,

		detectCallBy() {
			if (this.studentSelected?.id !== '-') {
				this.fetchSessionOnToDay();
				return;
			}

			if (this.classSelected?.id !== '-') {
				this.fetchSessionOnToDayByClass();
				return;
			}

			if (this.staffSelected?.id !== '-') {
				this.fetchSessionOnToDayByStaff();
			}
		},

		removeFilter() {
			this.params = {
				duration: 'today',
				student_id: undefined,
				day_care_class_id: undefined,
				state: undefined,
			};
			this.studentSelected = {
				name: 'Tất cả',
				id: null,
				value: null,
			};
			this.staffSelected = {
				name: '-',
				id: '-',
				value: '-',
			};
			this.stateSelected = {
				name: '-',
				id: '-',
				value: '-',
			};
			this.staffSelected = {
				name: '-',
				id: '-',
				value: '-',
			};
			this.dateSelected = {
				id: 'today',
				name: 'Hôm nay',
			};
			this.fromDate = null;
			this.toDate = null;

			this.detectCallBy();
		},
		checkChanged(uuid) {
			const result = this.checkBoxSelected.find((item) => item === uuid);

			if (result) {
				this.checkBoxSelected = this.checkBoxSelected.filter((item) => {
					return item !== uuid;
				});
			} else {
				this.checkBoxSelected.push(uuid);
			}
		},

		openModalTakeLeave() {
			this.staffsTakeLeave = [];
			this.dataTakeLeave = this.data.filter((item) =>
				this.checkBoxSelected.includes(item.uuid)
			);
			const sts = this.classOptions.filter((c) =>
				this.dataTakeLeave.find((d) => d.day_care_class?.id === c.id)
			);
			for (let staff of sts) {
				this.staffsTakeLeave = this.staffsTakeLeave.concat(
					staff?.staffs
				);
			}
			this.isTakeLeaveModal = true;
		},

		async fetchSessionOnToDay() {
			try {
				const {
					data: { data, staffs_map, students_map },
				} = await $api.schedule.fetchTimetableScheduleOnDayCare({
					...this.params,
					state:
						this.params?.state !== '-'
							? this.params?.state
							: undefined,
					student_id: this.studentSelected?.id ?? undefined,
				});
				this.staffs_map = staffs_map || {};
				this.students_map = students_map || {};
				this.data = data.map((item, index) => {
					return {
						...item,
						uuid: index + 1,
					};
				});
				this.isLoading = false;
			} catch (e) {
				// TODO: e
			}
		},

		async fetchSessionOnToDayByStaff() {
			try {
				const {
					data: { data, staffs_map, students_map },
				} = await $api.schedule.fetchTimetableScheduleOnDayCareByStaff({
					...this.params,
					staff_id: this.staffSelected?.id ?? undefined,
				});
				this.staffs_map = staffs_map || {};
				this.students_map = students_map || {};
				this.data = data.map((item, index) => {
					return {
						...item,
						uuid: index + 1,
					};
				});
				this.isLoading = false;
			} catch (e) {
				// TODO: e
			}
		},

		async fetchSessionOnToDayByClass() {
			try {
				const {
					data: { data, staffs_map, students_map },
				} = await $api.schedule.fetchTimetableScheduleOnDayCareByClass({
					...this.params,
					day_care_class_id: this.classSelected?.id ?? undefined,
				});
				this.staffs_map = staffs_map || {};
				this.students_map = students_map || {};
				this.data = data.map((item, index) => {
					return {
						...item,
						uuid: index + 1,
					};
				});
				this.isLoading = false;
			} catch (e) {
				// TODO: e
			}
		},

		getStaff(staff_id) {
			return this.staffs_map[staff_id]?.[0];
		},

		getStudent(student_id) {
			return this.students_map[student_id]?.[0];
		},

		async fetchClasses() {
			try {
				const {
					data: { data },
				} = await $api.boarding.getDayCareClasses(
					this.isAdminAndAccountant
				);
				this.classOptions = data;
			} catch (e) {
				// TODO: e
			}
		},

		async fetchStaffs() {
			try {
				const {
					data: { data },
				} = await $api.staff.getEmployees('');
				this.staffs = data;
			} catch (e) {
				// TODO: e
			}
		},

		async fetchStudents() {
			let role =
				this.$store.state.profile.staff.role === 'teacher'
					? 'staff'
					: 'org_admin';
			try {
				const {
					data: { data },
				} = await $api.student.getStudents(role, '');
				this.students = data;
			} catch (e) {
				// TODO: e
			}
		},

		selectedTakeLeave(uuid) {
			this.checkBoxSelected = [uuid];
			this.openModalTakeLeave();
		},

		openRestoreCancelSession(data) {
			this.checkBoxSelected = [data.uuid];
			this.idRestore = data;
			this.modalIsOpen = true;
			this.deleteConfirm = false;
		},

		closeRestoreCancelSession() {
			this.idRestore = '';
			this.modalIsOpen = false;
			this.deleteConfirm = false;
		},

		restoreSessionSuccess() {
			this.closeRestoreCancelSession();
			this.detectCallBy();
		},

		takeLeaveSuccess() {
			this.checkBoxSelected = [];
			this.isTakeLeaveModal = false;
			this.detectCallBy();
		},

		downloadCSVSchedule() {
			const dataDownload = this.data
				.filter((item) => this.checkBoxSelected.includes(item.uuid))
				.map((item) => {
					return item.state === 'future'
						? {
								t_id: item.time_slot_id,
								date: item.date,
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  }
						: {
								s_id: item.id,
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  };
				});
			this.downloadCSVScheduleService({
				session_and_time_slot_id_with_dates: dataDownload,
			});
		},

		downloadCSVScheduleOnly(uuid) {
			this.checkBoxSelected = [uuid];
			this.downloadCSVSchedule();
		},
	},

	mixins: [DownloadCSV],
};
</script>
