
import { Options, Vue } from 'vue-class-component';
import { PropSync } from 'vue-property-decorator';

@Options({
	name: 'ConfirmModal',
})
export default class ConfirmModal extends Vue {
	@PropSync('data')
	listData!: any;
	@PropSync('deleteOne')
	deleteOneData!: any;

	submit() {
		this.$emit(this.deleteOneData ? 'submit-one' : 'submit');
	}
}
