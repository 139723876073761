
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Checkbox from '@/components/elements/checkbox/Checkbox.vue';

@Options({
	name: 'ApplyNowConfirm',
	components: { Checkbox },
})
export default class ApplyNowConfirm extends Vue {
	@Prop({ default: false }) successfully?: boolean;

	//----------------------- 🤍 Methods 🤍 -----------------------//
	close() {
		this.$emit('close');
	}

	submit() {
		this.$emit('submit');
	}
}
