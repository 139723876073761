
import { Options, Vue, setup } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import SideOver from '@/views/groupClassReport/features/SideOver.vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import DaInfinityLoading from '@/components/common/daInfinityLoading/DaInfinityLoading.vue';
import { useGetEmployees } from '@/views/record/employee/services/getEmployees';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import Loading from '@/components/common/Loading.vue';
import { $api } from '@/services';
import UserGroup from '@/components/elements/UserGroup.vue';
import { _ROUTER_NAME } from '@/enums';

@Options({
	name: 'GroupClassReport',
	components: {
		UserGroup,
		Loading,
		DaInfinityLoading,
		SideOver,
		DaIcon,
	},
})
export default class GroupClassReport extends Vue {
	sideOverShow = false;
	filter: any = null;
	payload: any = null;
	reports: any = [];
	dateNow = new Date();
	before = '';
	isFilterDate = false;
	stopLoad = false;
	staffs: any = [];
	isInfinityLoading = false;
	isLoading = true;
	alertShow = true;
	isHides: any = [];

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const store = useStore();
		const { getEmployees } = useGetEmployees();
		return {
			getEmployees,
			store,
		};
	});

	//----------------------- 🤍 Getter 🤍 -----------------------//

	get isAdmin() {
		return this.setup.store.getters.isAdmin;
	}

	get org() {
		return this.setup.store.state.profile.current_org;
	}

	get store() {
		return this.setup.store;
	}

	//----------------------- 🤍 Created 🤍 -----------------------//
	created() {
		const q: any = this.$route.query;
		this.before = q.before ? dayjs(q.before).format('DD/MM/YYYY') : '';
		if (q?.before) {
			this.filter = {
				before: q?.before,
				toDate: q?.toDate,
			};
			this.isFilterDate = !!q?.before;
			this.before = dayjs(q.before).add(1, 'day').format('YYYY-MM-DD');
		}
		this.getReportsList();
		this.fetchStaffs();
	}

	//----------------------- 🤍 Watch 🤍 -----------------------//
	@Watch('$route')
	onRouterChange(value: any) {
		if (value?.name === _ROUTER_NAME.REPORT_GROUP) {
			this.reports = [];
			if (this.$route.query?.before) {
				this.before = dayjs(this.$route.query?.before.toString())
					.add(1, 'day')
					.format('YYYY-MM-DD');
			} else {
				this.before = '';
			}
			this.getReportsList();
		}
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	async getReportsList() {
		this.clean(this.payload);
		const q = this.$route.query;
		const { data }: any = await $api.groupClasses.fetchReports({
			...q,
			...this.payload,
			toDate: undefined,
		});
		if (
			data &&
			data.data.length > 0 &&
			(!this.filter?.toDate ||
				data?.date === this.filter.toDate ||
				dayjs(data?.date).isAfter(this.filter.toDate))
		) {
			this.reports.push({
				date: data?.date,
				data: data?.data,
				stats: data?.stats,
			});
			this.before = dayjs(data?.date).format('YYYY-MM-DD');
			this.payload = {
				...this.payload,
				before: this.before,
			};
		} else {
			this.stopLoad = true;
		}
		this.isInfinityLoading = true;
		this.isLoading = false;
	}

	async fetchStaffs() {
		const res = await this.setup.getEmployees('');
		this.staffs = res?.data || [];
	}

	staffById(id: string) {
		return this.staffs.find((item: { id: string }) => item.id === id);
	}

	clean(obj: any) {
		for (let propName in obj) {
			if (
				obj[propName] === null ||
				obj[propName] === undefined ||
				obj[propName].length <= 0
			) {
				delete obj[propName];
			}
		}
		return obj;
	}

	filterSubmit(queryPa: any) {
		if (!queryPa) {
			this.before = dayjs(this.dateNow).format('DD/MM/YYYY');
			this.filter = null;
			this.$router.push('');
		} else {
			this.filter = {
				...this.filter,
				...queryPa,
			};
			this.clean(this.filter);
			const query = Object.keys(this.filter)
				.map((key) => `${key}=${this.filter[key]}`)
				.join('&');
			this.$router.push(`?${query}`);
		}
	}

	showHide(date: any) {
		const index = this.isHides.indexOf(date);
		if (index !== -1) {
			this.isHides.splice(index, 1);
		} else {
			this.isHides.push(date);
		}
	}

	beforeDestroy() {
		this.isInfinityLoading = false;
	}
}
