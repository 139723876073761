
import { Options, Vue } from 'vue-class-component';
import { PropSync } from 'vue-property-decorator';
import Multiselect from '@suadelabs/vue3-multiselect';
import { toEnglish } from '@/helper/toEnglish';

@Options({
	name: 'CreateDaycareClass',
	components: { Multiselect },
})
export default class CreateDaycareClass extends Vue {
	@PropSync('data')
	classes!: any[];

	@PropSync('selected')
	classesSelected!: any[];

	classSelected = null;

	//----------------------- 🤍 Methods 🤍 -----------------------//
	submit() {
		this.$emit(
			`submit`,
			// @ts-ignore
			this.classSelected?.id || null
		);
	}

	toEnglish(string = '') {
		return toEnglish(string);
	}
}
