<template>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 w-screen md:w-3/5">
		<div class="h-full w-full flex flex-col bg-white shadow-xl">
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center" @click="$emit('close')">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12.75 6.75C12.75 6.33579 12.4142 6 12 6C11.5858 6 11.25 6.33579 11.25 6.75V11.25H6.75C6.33579 11.25 6 11.5858 6 12C6 12.4142 6.33579 12.75 6.75 12.75H11.25V17.25C11.25 17.6642 11.5858 18 12 18C12.4142 18 12.75 17.6642 12.75 17.25V12.75H17.25C17.6642 12.75 18 12.4142 18 12C18 11.5858 17.6642 11.25 17.25 11.25H12.75V6.75Z"
								fill="#94A3B8"
							/>
						</svg>
						<div
							class="
								text-2xl
								font-bold
								text-neutral-700
								ml-2
								mt-1
							"
						>
							Tạo buổi học phát sinh
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							type="button"
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							@click="$emit('close')"
						>
							<span class="sr-only">Close panel</span>
							<svg
								class="h-6 w-6"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div
				class="
					sideover-content
					pt-6
					relative
					flex-1
					px-4
					sm:px-6
					overflow-y-auto
				"
			>
				<div>
					<div>
						<div class="font-semibold text-neutral-600 mt-10">
							Chọn ngày*
						</div>
						<div class="w-full mt-2">
							<div class="input-group flex items-center">
								<VueDatepicker
									v-model="date"
									:enableTimePicker="false"
									class="input-date w-full"
									placeholder="Chọn ngày áp dụng"
									format="dd/MM/yyyy"
									autoApply
									:clearable="false"
								>
									<template #input-icon>
										<DaIcon
											name="das--lock"
											class="h-4 w-4 text-neutral-400"
										/>
									</template>
								</VueDatepicker>
							</div>
						</div>
					</div>
					<div class="mt-10">
						<div
							class="
								form-group
								flex
								items-center
								justify-center
								gap-8
							"
						>
							<div class="input-group w-full">
								<div
									class="
										input-group__label
										w-full
										md:w-56
										font-normal
									"
								>
									Giờ bắt đầu
								</div>
								<VueDatepicker
									class="input-date w-full"
									timePicker
									placeholder="Giờ bắt đầu"
									autoApply
									v-model="startTime"
									uid="start_opening_hour"
									minutesIncrement="5"
									:clearable="false"
								>
									<template #input-icon>
										<DaIcon
											name="das--lock"
											class="h-4 w-4 text-neutral-400"
										/>
									</template>
								</VueDatepicker>
							</div>
							<div class="input-group w-full">
								<div
									class="
										input-group__label
										w-full
										md:w-56
										font-normal
									"
								>
									Giờ kết thúc
								</div>
								<VueDatepicker
									v-model="endTime"
									class="input-date w-full"
									timePicker
									placeholder="Giờ kết thúc"
									autoApply
									uid="end_opening_hour"
									minutesIncrement="5"
									:clearable="false"
								>
									<template #input-icon>
										<DaIcon
											name="das--lock"
											class="h-4 w-4 text-neutral-400"
										/>
									</template>
								</VueDatepicker>
							</div>
						</div>
					</div>
					<div>
						<div class="font-semibold text-neutral-600 mt-10">
							Chọn học sinh*
						</div>
						<div class="w-full mt-2">
							<Multiselect
								v-model="studentSelected"
								deselect-label="Đã chọn"
								track-by="id"
								:options="
									students?.map((s) => {
										return {
											nameSearch: toEnglish(s?.name),
											...s,
										};
									})
								"
								:searchable="true"
								label="nameSearch"
								:allow-empty="false"
								selectLabel=""
								placeholder="Tìm theo tên học sinh"
								:maxHeight="140"
							>
								<template #noResult>
									Không tìm thấy kết quả
								</template>
								<template v-slot:option="props">
									<div class="flex flex-nowrap items-center">
										<div>
											{{ props.option.name }}
										</div>
									</div>
								</template>
								<template v-slot:singleLabel="{ option }">
									<div
										class="
											flex flex-nowrap
											items-center
											font-medium
										"
									>
										<div>{{ option.name }}</div>
									</div>
								</template>
							</Multiselect>
						</div>
					</div>
					<div v-for="(staff, index) in staffSelected" :key="index">
						<div class="font-semibold text-neutral-600 mt-10">
							Chọn trị liệu viên*
						</div>
						<div class="w-full mt-2">
							<div class="w-full mt-2">
								<Multiselect
									v-model="staffSelected[index]"
									deselect-label="Đã chọn"
									track-by="id"
									:options="
										staffs?.map((s) => {
											return {
												nameSearch: toEnglish(s?.name),
												...s,
											};
										})
									"
									:searchable="true"
									label="nameSearch"
									:allow-empty="false"
									selectLabel=""
									placeholder="Tìm theo tên TLV"
									:maxHeight="140"
								>
									<template #noResult>
										Không tìm thấy kết quả
									</template>
									<template v-slot:option="props">
										<div
											class="
												flex flex-nowrap
												items-center
											"
										>
											<div>
												{{ props.option.name }}
											</div>
										</div>
									</template>
									<template v-slot:singleLabel="{ option }">
										<div
											class="
												flex flex-nowrap
												items-center
												font-medium
											"
										>
											<div>{{ option.name }}</div>
										</div>
									</template>
								</Multiselect>
							</div>
						</div>
						<div
							v-if="staffSelected.length > 1"
							@click="removeInputFieldStaff(index)"
							class="
								text-yellow-700
								font-semibold
								mt-2
								text-sm
								cursor-pointer
								text-right
								hover:text-yellow-800
							"
						>
							Xoá bỏ
						</div>
					</div>
					<div class="flex items-center justify-between mt-4">
						<div
							@click="isAllStaff = !isAllStaff"
							class="
								text-sm
								flex
								items-center
								gap-2
								text-neutral-500
								cursor-pointer
							"
						>
							<Checkbox :value="isAllStaff" />
							<span
								>Hiển thị tất cả TLV (ngay cả khi bị trùng giờ
								dạy)</span
							>
						</div>
						<div
							v-if="staffSelected.length <= 2"
							@click="addInputFieldStaff"
							class="
								text-yellow-700
								font-semibold
								text-sm
								cursor-pointer
								text-right
								hover:text-yellow-800
							"
						>
							Thêm trị liệu viên
						</div>
					</div>
					<div>
						<div class="font-semibold text-neutral-600 mt-10">
							Ghi chú*
						</div>
						<div class="w-full mt-2">
							<div class="input-group flex items-center">
								<textarea
									v-model="note"
									placeholder="Ghi chú về sự thay đổi này"
								>
								</textarea>
							</div>
						</div>
					</div>
				</div>
				<template v-if="isAllStaff && data && data.length">
					<div class="flex text-neutral-500 mt-10">
						Danh sách buổi học bị trùng
					</div>
					<div class="text-sm text-red-400 mt-2">
						Trị liệu viên được chọn có thể báo cáo cho cả
						{{ data.length }} buổi học này
					</div>
					<div class="shadow-sm sm:rounded-2xl mt-10">
						<div class="__record__table overflow-x-auto scroll-bar">
							<div
								class="
									sm:rounded-t-2xl
									overflow-hidden
									w-fit
									min-w-full
								"
							>
								<table
									class="min-w-full divide-y text-neutral-200"
								>
									<thead class="bg-neutral-100 h-16">
										<tr>
											<th
												scope="col"
												class="
													px-3
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												Giờ học
											</th>
											<th
												scope="col"
												class="
													px-3
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												Trị liệu viên
											</th>
											<th
												scope="col"
												class="
													px-3
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												Tên học sinh
											</th>
											<th
												scope="col"
												class="
													px-3
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												Trạng thái
											</th>
											<th
												scope="col"
												class="
													px-3
													py-3
													text-left text-xs
													font-semibold
													text-neutral-600
													uppercase
													tracking-wider
													whitespace-nowrap
												"
											>
												Phân loại
											</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(item, index) in data"
											:key="item.id"
											:class="
												item.state === 'cancelled'
													? 'bg-sienna-50'
													: index % 2 === 0
													? 'bg-white'
													: 'bg-neutral-50'
											"
											class="text-neutral-700"
										>
											<td>
												<div
													class="
														px-3
														py-4
														text-neutral-700
													"
												>
													<span
														v-if="
															item.display_start_slot
														"
														>{{
															item.display_start_slot
														}}</span
													><span
														v-if="
															item.display_end_slot
														"
													>
														-
														{{
															item.display_end_slot
														}}</span
													>
												</div>
											</td>
											<td
												class="
													px-3
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div
													v-if="item.staffs"
													class="flex flex-col gap-2"
												>
													<div
														@click.stop="
															$router.push(
																`/record/student/${staff?.id}`
															)
														"
														class="
															flex
															items-center
														"
														v-for="staff in item.staffs"
														:key="staff?.id"
													>
														<div>
															<img
																class="
																	shadow-sm
																	inline-block
																	h-9
																	w-9
																	rounded-full
																"
																:src="
																	staff
																		?.profile_photo
																		?.w200
																"
																alt=""
																v-if="
																	staff
																		?.profile_photo
																		?.w200
																"
															/>
															<span
																v-else
																class="
																	inline-flex
																	items-center
																	justify-center
																	h-9
																	w-9
																	rounded-full
																"
																:style="{
																	background:
																		staff
																			?.profile_photo
																			?.default
																			?.color,
																}"
															>
																<span
																	class="
																		font-medium
																		leading-none
																		text-white
																		text-xs
																	"
																	>{{
																		staff
																			?.profile_photo
																			?.default
																			?.initial
																	}}</span
																>
															</span>
														</div>
														<div
															class="
																ml-4
																text-blue-600
																hover:text-blue-700
																hover:underline
																cursor-pointer
																font-semibold
																whitespace-pre-wrap
																w-40
															"
														>
															{{ staff?.name }}
														</div>
													</div>
												</div>
											</td>
											<td
												class="
													px-3
													py-4
													whitespace-nowrap
													text-neutral-700
													hover:text-yellow-700
													hover:underline
													cursor-pointer
												"
											>
												<div
													@click.stop="
														$router.push(
															`/record/student/${item.student.id}`
														)
													"
													class="flex items-center"
													v-if="item.student"
												>
													<div>
														<img
															class="
																shadow-sm
																inline-block
																h-9
																w-9
																rounded-full
															"
															:src="
																item.student
																	?.profile_photo
																	?.w200
															"
															alt=""
															v-if="
																item.student
																	?.profile_photo
																	?.w200
															"
														/>
														<span
															v-else
															class="
																inline-flex
																items-center
																justify-center
																h-9
																w-9
																rounded-full
															"
															:style="{
																background:
																	item.student
																		?.profile_photo
																		?.default
																		?.color,
															}"
														>
															<span
																class="
																	font-medium
																	leading-none
																	text-white
																	text-xs
																"
																>{{
																	item.student
																		?.profile_photo
																		?.default
																		?.initial
																}}</span
															>
														</span>
													</div>
													<div
														class="
															ml-4
															text-yellow-600
															font-semibold
															whitespace-pre-wrap
															w-40
														"
													>
														{{ item.student.name }}
													</div>
												</div>
											</td>
											<td
												class="
													px-3
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div>
													<div
														v-if="
															item.state !==
																'created' &&
															item.state !==
																'cancelled' &&
															item.state !==
																'ongoing'
														"
														class="
															text-xs
															w-fit
															font-semibold
															bg-neutral-100
															text-gray-500
															p-2
															px-4
															pb-1.5
															rounded-full
															flex
															items-center
															justify-center
															gap-2
														"
													>
														<div>
															<svg
																width="15"
																height="14"
																viewBox="0 0 15 14"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M7.5 14C11.366 14 14.5 10.866 14.5 7C14.5 3.13401 11.366 0 7.5 0C3.63401 0 0.5 3.13401 0.5 7C0.5 10.866 3.63401 14 7.5 14ZM11.0491 6.63407C10.9689 6.50148 10.846 6.41667 10.7083 6.41667H4.58333C4.44564 6.41667 4.3228 6.50148 4.2426 6.63407C4.14461 6.72535 4.08333 6.85552 4.08333 7C4.08333 7.14448 4.14461 7.27465 4.2426 7.36593C4.3228 7.49852 4.44564 7.58333 4.58333 7.58333H10.7083C10.846 7.58333 10.9689 7.49852 11.0491 7.36593C11.1471 7.27465 11.2083 7.14448 11.2083 7C11.2083 6.85552 11.1471 6.72535 11.0491 6.63407Z"
																	fill="#CBD5E1"
																/>
															</svg>
														</div>
														<div>Chưa học</div>
													</div>
													<div
														v-if="
															item.state ===
															'ongoing'
														"
														class="
															w-fit
															font-semibold
															bg-blue-100
															text-xs
															text-blue-600
															p-2
															px-4
															pb-1.5
															rounded-full
															flex
															items-center
															justify-center
															gap-2
														"
													>
														<div>
															<svg
																width="15"
																height="14"
																viewBox="0 0 15 14"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M7.5 14C11.366 14 14.5 10.866 14.5 7C14.5 3.13401 11.366 0 7.5 0C3.63401 0 0.5 3.13401 0.5 7C0.5 10.866 3.63401 14 7.5 14ZM11.0491 6.63407C10.9689 6.50148 10.846 6.41667 10.7083 6.41667H4.58333C4.44564 6.41667 4.3228 6.50148 4.2426 6.63407C4.14461 6.72535 4.08333 6.85552 4.08333 7C4.08333 7.14448 4.14461 7.27465 4.2426 7.36593C4.3228 7.49852 4.44564 7.58333 4.58333 7.58333H10.7083C10.846 7.58333 10.9689 7.49852 11.0491 7.36593C11.1471 7.27465 11.2083 7.14448 11.2083 7C11.2083 6.85552 11.1471 6.72535 11.0491 6.63407Z"
																	fill="#CBD5E1"
																/>
															</svg>
														</div>
														<div>Đang học</div>
													</div>
													<div
														v-if="
															item.state ===
															'cancelled'
														"
														class="
															text-xs
															w-fit
															font-semibold
															bg-sienna-100
															text-sienna-500
															p-2
															px-4
															pb-1.5
															rounded-full
															flex
															items-center
															justify-center
															gap-2
														"
													>
														<div>
															<svg
																width="15"
																height="14"
																viewBox="0 0 15 14"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M5.19063 4.35739C5.36148 4.18653 5.63849 4.18653 5.80934 4.35739L8.12498 6.67303L10.4406 4.35739C10.6115 4.18653 10.8885 4.18653 11.0593 4.35739C11.2302 4.52824 11.2302 4.80525 11.0593 4.97611L8.7437 7.29175L11.0593 9.60739C11.2302 9.77824 11.2302 10.0553 11.0593 10.2261C10.8885 10.397 10.6115 10.397 10.4406 10.2261L8.12498 7.91047L5.80934 10.2261C5.63849 10.397 5.36148 10.397 5.19063 10.2261C5.01977 10.0553 5.01977 9.77824 5.19063 9.60739L7.50627 7.29175L5.19063 4.97611C5.01977 4.80525 5.01977 4.52824 5.19063 4.35739Z"
																	fill="#DB5A41"
																/>
															</svg>
														</div>
														<div>Nghỉ học</div>
													</div>
													<div
														v-if="
															item.state ===
															'created'
														"
														class="
															text-xs
															w-fit
															font-semibold
															bg-green-50
															text-green-500
															p-2
															px-4
															pb-1.5
															rounded-full
															flex
															items-center
															justify-center
															gap-2
														"
													>
														<div>
															<svg
																width="15"
																height="14"
																viewBox="0 0 15 14"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M8.68176 4.34846C8.93804 4.09218 9.35355 4.09218 9.60983 4.34846C9.86297 4.6016 9.86608 5.01009 9.61915 5.26704L6.12597 9.63351C6.12093 9.63981 6.11554 9.64583 6.10983 9.65154C5.85355 9.90782 5.43804 9.90782 5.18176 9.65154L2.86612 7.3359C2.60983 7.07962 2.60983 6.6641 2.86612 6.40782C3.1224 6.15154 3.53791 6.15154 3.79419 6.40782L5.62602 8.23965L8.66435 4.36809C8.66975 4.36121 8.67557 4.35465 8.68176 4.34846Z"
																	fill="#339588"
																/>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M7.87639 8.84617L8.68176 9.65154C8.93804 9.90782 9.35355 9.90782 9.60984 9.65154C9.61554 9.64583 9.62093 9.63981 9.62597 9.63351L13.1191 5.26704C13.3661 5.01009 13.363 4.6016 13.1098 4.34846C12.8536 4.09218 12.438 4.09218 12.1818 4.34846C12.1756 4.35465 12.1698 4.36121 12.1644 4.36809L9.12602 8.23965L8.70135 7.81497L7.87639 8.84617Z"
																	fill="#339588"
																/>
															</svg>
														</div>
														<div>Đã học</div>
													</div>
												</div>
											</td>
											<td
												class="
													px-3
													py-4
													whitespace-nowrap
													text-neutral-700
												"
											>
												<div>
													<div
														v-if="!item.scheduled"
														class="
															text-xs
															w-fit
															font-semibold
															text-sienna-600
															p-2
															px-4
															pb-1.5
															rounded-full
															border
															border-sienna-200
															flex
															items-center
															justify-center
														"
													>
														Phát sinh
													</div>
													<div
														v-else
														class="
															text-xs
															w-fit
															font-semibold
															text-neutral-600
															p-2
															px-4
															pb-1.5
															rounded-full
															border
															border-neutral-300
															flex
															items-center
															justify-center
														"
													>
														Cố định
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</template>
			</div>
			<div class="px-6 py-4 border-t border-yellow-50">
				<div class="flex justify-end">
					<div>
						<button
							:disabled="!isSubmit || inProgress"
							type="button"
							class="button-p text-center w-32"
							@click="submit"
						>
							Xác nhận
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';
import DaIcon from '@/components/elements/daIcon/DaIcon';
import { $api } from '@/services';
import Multiselect from '@suadelabs/vue3-multiselect';
import dayjs from 'dayjs';
import { toEnglish } from '@/helper/toEnglish';
import Checkbox from '@/components/elements/checkbox/Checkbox';

export default defineComponent({
	name: 'CreateTimeSlot',

	components: { Checkbox, DaIcon, Multiselect },

	data() {
		return {
			date: new Date(),
			students: [],
			studentSelected: null,
			staffs: [],
			staffSelected: [null],
			startTime: null,
			endTime: null,
			note: '',
			inProgress: false,
			isAllStaff: false,
			data: [],
		};
	},

	created() {
		this.fetchStudents();
		this.startTime = this.initStartTime();
	},

	computed: {
		isSubmit() {
			return (
				this.note &&
				this.studentSelected &&
				this.staffSelected.length &&
				this.staffSelected[0] &&
				this.startTime &&
				this.endTime &&
				this.staffSelected
			);
		},
	},

	watch: {
		date() {
			this.fetchStaffs();
		},

		staffSelected: {
			immediate: true,
			deep: true,
			handler(value) {
				if (value) {
					this.fetchStaffsOverload();
				}
			},
		},

		isAllStaff(value) {
			this.fetchStaffs();

			if (value) {
				this.fetchStaffsOverload();
			}
		},

		startTime(value) {
			if (!value) {
				this.startTime = this.initStartTime();
			} else {
				this.endTime = {
					hours: value.hours + 1,
					minutes: value.minutes,
				};
			}
		},

		endTime(value) {
			if (!value) {
				this.endTime = {
					hours: this.startTime.hours + 1,
					minutes: this.startTime.minutes,
				};
			} else {
				this.fetchStaffs();

				if (this.isAllStaff) {
					this.fetchStaffsOverload();
				}
			}
		},
	},

	methods: {
		toEnglish,
		initStartTime() {
			const time = new Date();
			let minutes = time.getMinutes() / 5;
			return {
				hours: time.getHours(),
				minutes: Math.ceil(minutes) * 5,
			};
		},
		addInputFieldStaff() {
			if (this.staffSelected.length <= 2) {
				this.staffSelected.push(null);
			}
		},
		removeInputFieldStaff(index) {
			if (index < this.staffSelected.length) {
				this.staffSelected.splice(index, 1);
			}
		},
		async submit() {
			this.inProgress = true;
			try {
				const { data } = await $api.schedule.checkUnSchedule({
					schedule_session: {
						date: this.date
							? dayjs(this.date).format('YYYY-MM-DD')
							: null,
						end_slot: this.getTimePayload(
							this.endTime.hours || null,
							this.endTime.minutes || null
						),
						staff_ids: this.staffSelected
							? this.staffSelected.map((item) => item.id)
							: null,
						start_slot: this.getTimePayload(
							this.startTime.hours || null,
							this.startTime.minutes || null
						),
						student_id: this.studentSelected
							? this.studentSelected.id
							: null,
					},
				});

				await $api.schedule.createUnSchedule(
					this.studentSelected ? this.studentSelected.id : '',
					{
						schedule_session: {
							date: this.date
								? dayjs(this.date).format('YYYY-MM-DD')
								: null,
							start_slot: this.getTimePayload(
								this.startTime.hours || null,
								this.startTime.minutes || null
							),
							end_slot: this.getTimePayload(
								this.endTime.hours || null,
								this.endTime.minutes || null
							),
							staff_ids: this.staffSelected
								? this.staffSelected.map((item) => item.id)
								: null,
							unscheduled_note: this.note,
						},
					}
				);
				await this.$store.dispatch('setAlertMessage', {
					message: 'Tạo thành công buổi học phát sinh',
				});
				this.resetForm();
				this.$emit('success', data);
			} catch (e) {
				await this.$store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			} finally {
				this.inProgress = false;
			}
		},

		resetForm() {
			this.date = new Date();
			this.studentSelected = null;
			this.staffSelected = [null];
			this.startTime = null;
			this.endTime = null;
		},

		async fetchStudents() {
			let role =
				this.$store.state.profile.staff.role === 'teacher'
					? 'staff'
					: 'org_admin';
			try {
				const {
					data: { data },
				} = await $api.student.getStudents(role, '');
				this.students = data;
			} catch (err) {
				console.log(err);
			}
		},

		async fetchStaffs() {
			try {
				const {
					data: { data },
				} = await $api.staff.getStaffWhenCreateUnSchedule({
					date: this.date
						? dayjs(this.date).format('YYYY-MM-DD')
						: null,
					end_slot: this.getTimePayload(
						this.endTime?.hours || null,
						this.endTime?.minutes || null
					),
					start_slot: this.getTimePayload(
						this.startTime?.hours || null,
						this.startTime?.minutes || null
					),
					only_free_staff: !this.isAllStaff,
				});
				this.staffs = data;
			} catch (err) {
				console.log(err);
			}
		},

		async fetchStaffsOverload() {
			try {
				const {
					data: { data },
				} = await $api.staff.getStaffWhenCreateUnScheduleOverload({
					schedule_session: {
						date: this.date
							? dayjs(this.date).format('YYYY-MM-DD')
							: null,
						end_slot: this.getTimePayload(
							this.endTime?.hours || null,
							this.endTime?.minutes || null
						),
						start_slot: this.getTimePayload(
							this.startTime?.hours || null,
							this.startTime?.minutes || null
						),
						staff_ids: this.staffSelected
							? this.staffSelected.map((item) => item?.id)
							: null,
					},
				});
				this.data = data;
			} catch (err) {
				console.log(err);
			}
		},

		getTimePayload(hour = 0, minutes = 0) {
			return hour * 60 + minutes;
		},
	},
});
</script>
