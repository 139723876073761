
import { Options, setup, Vue } from 'vue-class-component';
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import { tabs } from '@/views/setupCenter/data/tabs';
import Multiselect from '@suadelabs/vue3-multiselect';
import { useGetOrg } from '@/views/setupCenter/services/getOrg';
import { useUpdateOrg } from '@/views/setupCenter/services/updateOrg';
import { useStore } from 'vuex';
import { $api } from '@/services';

@Options({
	name: 'SetupCenter',
	components: { DaIcon, Multiselect },
})
export default class SetupCenter extends Vue {
	//----------------------- 🤍 Data 🤍 -----------------------//
	selected = this.tabsMenu[0];
	options = ['list', 'of', 'options'];

	org = null;
	updateSuccessful = false;
	timetable = {};
	student_count = 0;
	loading = false;

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const store = useStore();
		const { error, loading, getOrg } = useGetOrg();
		const { update_org_error, updateOrg } = useUpdateOrg();
		return { error, update_org_error, loading, getOrg, updateOrg, store };
	});

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get tabsMenu() {
		return tabs;
	}

	get store() {
		return this.setup.store;
	}

	//----------------------- 🤍 Created 🤍 -----------------------//
	created() {
		this.fetchOrg();
		this.fetchTimetableScheduleActive();
	}

	mounted() {
		const res = this.tabsMenu.find(
			(item) => item.link === this.$route.path
		);
		if (res) {
			this.selected = res;
		}
	}

	//----------------------- 🤍 Methods 🤍 -----------------------//
	async updateOrg(orgUpdate: any) {
		try {
			const {
				data: { data },
			} = await $api.org.updateOrg({ org: orgUpdate });
			if (data) {
				this.org = data;
				this.updateSuccessful = true;
				await this.store.dispatch('setAlertMessage', {
					message: 'Cập nhật thông tin thành công',
				});
				window.location.reload();
			}
		} catch (err) {
			if (err?.response?.data?.errors_by_field['start_opening_hour']) {
				const error =
					'Giờ bắt đầu ' +
					err?.response?.data?.errors_by_field[
						'start_opening_hour'
					].join('<br/>');
				await this.store.dispatch('setAlertMessage', {
					message: error,
					type: 'danger',
				});
			} else {
				const error = err?.response?.data?.errors || err.message;
				await this.store.dispatch('setAlertMessage', {
					message: error.join('<br/>'),
					type: 'danger',
				});
			}
		}
	}

	async fetchTimetableScheduleActive() {
		try {
			this.loading = true;
			const res = await $api.schedule.fetchTimetableScheduleActive();
			if (res.data) {
				this.timetable = res.data.data;
				this.student_count = res.data.student_count;
			}
			this.loading = false;
		} catch (e) {
			await this.setup.store.dispatch('setAlertMessage', {
				message:
					e?.response?.data?.errors?.join('<br/>') ||
					'Đã có lỗi xảy ra. Vui lòng thử lại',
				type: 'danger',
			});
		}
	}

	onUpdated() {
		this.fetchOrg();
	}

	async fetchOrg() {
		const res = await this.setup.getOrg();
		if (res && res?.data) {
			this.org = res?.data;
		}
	}

	tabSelectEvent(selectedOption: any) {
		this.$router.push(selectedOption.link);
	}
}
