<template>
	<div class="__report-student pb-30 px-10 md-down:px-4">
		<div class="pt-10">
			<div class="flex items-center justify-between">
				<div
					class="
						__breadcrumbs
						text-sm text-neutral-500
						font-semibold
						flex-nowrap flex
						items-center
					"
				>
					<div>Thống kê lịch học chi tiết</div>
				</div>
			</div>
			<div class="__report-student__header pt-5">
				<div class="flex justify-between items-center w-full">
					<router-link :to="`/record/student/${studentDetail?.id}`">
						<div class="flex items-center">
							<img
								class="
									shadow-sm
									inline-block
									h-14
									w-14
									rounded-full
								"
								:src="studentDetail?.profile_photo?.w200"
								v-if="studentDetail?.profile_photo?.w200"
								alt=""
							/>
							<span
								v-else
								class="
									inline-flex
									items-center
									justify-center
									h-14
									w-14
									rounded-full
								"
								:style="{
									background:
										studentDetail?.profile_photo?.default
											?.color,
								}"
							>
								<span
									class="
										font-medium
										leading-none
										text-white text-4xl
									"
									>{{
										studentDetail?.profile_photo?.default
											?.initial
									}}</span
								>
							</span>
							<div class="ml-4">
								<div
									class="
										font-bold
										text-neutral-700 text-4xl
										md-down:text-2xl
									"
								>
									{{ studentDetail?.name }}
								</div>
								<div
									v-if="studentDetail?.age"
									class="
										text-green-500
										font-semibold
										text-xs
										uppercase
									"
								>
									{{ studentDetail?.age }}
									{{
										studentDetail?.learning_time
											? `| ${studentDetail?.learning_time}`
											: ''
									}}
								</div>
							</div>
						</div>
					</router-link>
					<div class="flex space-x-4">
						<button
							@click.stop="$router.push('/schedule/timetable')"
							type="button"
							class="
								button-p
								text-center
								bg-none
								shadow-none
								flex
								items-center
								justify-center
								btn--ghost
								w-fit
							"
						>
							<span class="ml-2">Thời khoá biểu</span>
						</button>
					</div>
				</div>
			</div>
			<div class="relative my-10">
				<div
					class="absolute inset-0 flex items-center"
					aria-hidden="true"
				>
					<div class="w-full border-t border-neutral-300" />
				</div>
				<div class="relative flex justify-center">
					<div
						class="
							bg-body
							px-2
							text-neutral-600
							font-semibold
							whitespace-nowrap
							flex flex-nowrap
							items-center
							text-2xl
							justify-center
							gap-6
						"
					>
						<div class="cursor-pointer" @click="prevMonth">
							<svg
								width="8"
								height="12"
								viewBox="0 0 8 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6.5 1L1.5 6L6.5 11"
									stroke="#48566A"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</div>
						<div v-if="date">Tháng {{ toMMYY(date) }}</div>
						<div
							v-if="canNext"
							class="cursor-pointer"
							@click="nextMonth"
						>
							<svg
								width="8"
								height="12"
								viewBox="0 0 8 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1.5 11L6.5 6L1.5 1"
									stroke="#48566A"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</div>
					</div>
				</div>
			</div>
			<div class="border-b border-neutral-200 md-down:hidden">
				<nav class="-mb-px flex space-x-8 tabs" aria-label="Tabs">
					<router-link
						v-for="tab in tabsMenu"
						:key="tab.id"
						:to="{ path: tab.link, query }"
						class="
							border-transparent
							text-neutral-500
							hover:text-yellow-700 hover:border-yellow-500
							whitespace-nowrap
							py-3
							px-1
							border-b-2
							font-semibold
						"
					>
						<div class="flex items-center gap-2">
							<div>{{ tab.name }}</div>
						</div>
					</router-link>
				</nav>
			</div>
			<div>
				<router-view
					:timeData="getMAndY"
					:student="studentDetail"
					:key="date"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { $api } from '@/services';
import dayjs from 'dayjs';

export default {
	name: 'ScheduleStatsDetail',
	data() {
		return {
			studentDetail: {},
			tabsMenu: [
				{
					id: 0,
					link: '/schedule/stats/student',
					name: 'Học can thiệp',
				},
				{
					id: 1,
					link: '/schedule/stats/student/daycare',
					name: 'Học bán trú',
				},
				{
					id: 2,
					link: '/schedule/stats/student/group-class',
					name: 'Học nhóm',
				},
			],
			date: null,
		};
	},

	computed: {
		studentId() {
			return this.$route.query.student_id;
		},

		getMAndY() {
			return {
				month:
					dayjs(this.date).month() < 10
						? `0${dayjs(this.date).month() + 1}`
						: dayjs(this.date).month() + 1,
				year: dayjs(this.date).year(),
			};
		},

		query() {
			return this.$route.query;
		},

		canNext() {
			const toDate = dayjs().startOf('m').subtract(1, 'month');
			const selectedDate = dayjs(this.date).startOf('month');
			return selectedDate.isBefore(toDate);
		},
	},

	created() {
		this.date = this.$route.query.date;
		this.getStudentDetail();
	},

	methods: {
		toMMYY(date) {
			return dayjs(date).format('MM/YYYY');
		},
		prevMonth() {
			this.date = dayjs(this.date).subtract(1, 'month').toDate();
		},
		nextMonth() {
			this.date = dayjs(this.date).add(1, 'month').toDate();
		},
		async getStudentDetail() {
			try {
				const {
					data: { data },
				} = await $api.student.getStudent(this.studentId);
				this.studentDetail = data;
			} catch (err) {
				console.log(err?.response?.data?.errors || err.message);
			}
		},
	},
};
</script>
<style scoped lang="scss">
.tabs {
	.router-link-exact-active {
		@apply text-yellow-700 border-yellow-500;
	}
}
</style>
