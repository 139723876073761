<template>
	<div class="_modal bg-white">
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div class="loader"></div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
					text-center
				"
			>
				Đang tải xuống file PDF Kế hoạch hiện tại
			</div>
			<div class="text-center mt-2 text-neutral-500">
				Vui lòng chờ trong giây lát
			</div>
		</div>
	</div>
</template>
<style scoped>
.loader {
	width: 110px;
	aspect-ratio: 1;
	border-radius: 50%;
	background: radial-gradient(farthest-side, #ffa516 94%, #0000) top/8px 8px
			no-repeat,
		conic-gradient(#0000 30%, #ffa516);
	-webkit-mask: radial-gradient(
		farthest-side,
		#0000 calc(100% - 8px),
		#000 0
	);
	animation: l13 1s infinite linear;
}
@keyframes l13 {
	100% {
		transform: rotate(1turn);
	}
}
</style>
