/* eslint-disable */
import { ref } from 'vue';
import { $api } from '@/services';

const error = ref(null);
const loading = ref(false);

export async function updateStaff(id: string, staff: any) {
	error.value = null;
	try {
		const res = await $api.staff.updateStaff(id, {
			staff: staff,
		});
		return res?.data;
	} catch (err) {
		error.value = err?.response?.data?.errors || err.message;
	} finally {
		loading.value = false;
	}
}

export function useUpdateStaff() {
	return { updateStaff__error: error, loading, updateStaff };
}
