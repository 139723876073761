
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'ChooseCenterCard',
})
export default class ChooseCenterCard extends Vue {
	@Prop() dataOrg = null;

	//----------------------- 🤍 Methods 🤍 -----------------------//
	onSubmit() {
		this.$emit('submit');
	}
}
