
import { Options, Vue } from 'vue-class-component';
import { PropSync } from 'vue-property-decorator';

@Options({
	name: 'ErrorModal',
})
export default class ErrorModal extends Vue {
	@PropSync('error')
	popupError!: {
		error: '';
		reportError: [];
	};
}
