<template>
	<div class="pt-10 md-down:pt-4 pr-6 w-full -mb-40">
		<div>
			<div v-for="index in 19" :key="index" class="py-6">
				<div class="relative mb-4">
					<div
						class="absolute inset-0 flex items-center"
						aria-hidden="true"
					>
						<div
							class="w-full border-gray-300 border-dashed"
							:class="{ 'border-t': index < 19 }"
						/>
					</div>
					<div class="relative flex justify-start">
						<span
							class="pr-2 bg-body text-sm text-gray-500"
							v-if="index < 19"
						>
							{{ index + 6 }}:00
						</span>
					</div>
				</div>
				<div class="container flex flex-wrap gap-3">
					<div
						@click="$emit('selectedGroup', item)"
						v-for="(item, i) in getSlotByStart(index)"
						:key="i"
						class="md-down:w-full w-fit p-3 rounded-xl border-2"
						:class="
							groupSelected && groupSelected.id === item.id
								? 'bg-yellow-50 border-yellow-200'
								: item.state === 'cancelled'
								? 'bg-neutral-200 border-neutral-300'
								: item.overlapped
								? 'bg-sienna-50 border-sienna-200'
								: 'bg-white border-neutral-200'
						"
					>
						<div class="flex items-center justify-between">
							<div class="mr-4">
								<div v-if="item.staff_ids">
									<div
										class="flex items-center"
										v-for="staff_id in item.staff_ids"
										:key="staff_id"
									>
										<div>
											<img
												class="
													shadow-sm
													inline-block
													h-6
													w-6
													rounded-full
												"
												:src="
													getStaff(staff_id)
														?.profile_photo?.w200
												"
												alt=""
												v-if="
													getStaff(staff_id)
														?.profile_photo?.w200
												"
											/>
											<span
												v-else
												class="
													inline-flex
													items-center
													justify-center
													h-6
													w-6
													rounded-full
												"
												:style="{
													background:
														getStaff(staff_id)
															?.profile_photo
															?.default?.color,
												}"
											>
												<span
													class="
														font-medium
														leading-none
														text-white text-ls
													"
													>{{
														getStaff(staff_id)
															?.profile_photo
															?.default?.initial
													}}</span
												>
											</span>
										</div>
									</div>
								</div>
							</div>
							<div
								class="
									h-full
									flex
									justify-between
									flex-col
									border-l
									pl-20
									md:pl-4 md:ml-auto
								"
								:class="
									groupSelected &&
									groupSelected.id === item.id
										? 'border-yellow-200'
										: item.state === 'cancelled'
										? 'bg-neutral-200 border-neutral-300'
										: item.overlapped
										? 'border-sienna-200'
										: 'border-neutral-300'
								"
							>
								<div
									class="
										flex
										items-center
										gap-2
										font-semibold
										text-indigo-600
									"
								>
									<span
										>{{ item.display_start_slot }} -
										{{ item.display_end_slot }}</span
									>
								</div>
							</div>
						</div>
						<div class="flex items-center border-t mt-2 pt-2 gap-4">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M10.5 21C10.5 21 9 21 9 19.5C9 18 10.5 13.5 16.5 13.5C22.5 13.5 24 18 24 19.5C24 21 22.5 21 22.5 21H10.5Z"
									fill="#94A3B8"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M16.5 12C18.9853 12 21 9.98528 21 7.5C21 5.01472 18.9853 3 16.5 3C14.0147 3 12 5.01472 12 7.5C12 9.98528 14.0147 12 16.5 12Z"
									fill="#94A3B8"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M7.82454 21C7.61334 20.5739 7.5 20.0687 7.5 19.5C7.5 17.4668 8.51858 15.3758 10.4039 13.9199C9.57914 13.6561 8.61764 13.5 7.5 13.5C1.5 13.5 0 18 0 19.5C0 21 1.5 21 1.5 21H7.82454Z"
									fill="#94A3B8"
								/>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M6.75 12C8.82107 12 10.5 10.3211 10.5 8.25C10.5 6.17893 8.82107 4.5 6.75 4.5C4.67893 4.5 3 6.17893 3 8.25C3 10.3211 4.67893 12 6.75 12Z"
									fill="#94A3B8"
								/>
							</svg>
							<div
								v-if="item.group_class_id"
								class="ml-2 font-semibold text-neutral-600"
							>
								{{ getDetail(item.group_class_id)?.name }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'TimeSlotClass',

	props: {
		dataGroup: {
			type: Array,
			required: true,
		},
		staffs: {
			type: Array,
			required: true,
		},
		group_classes: {
			type: Array,
			required: true,
		},
		groupSelected: {
			type: Object,
			required: true,
		},
	},

	methods: {
		getSlotByStart(index) {
			return this.dataGroup.filter(
				(item) =>
					item.start_slot >= (index + 6) * 60 &&
					item.start_slot < (index + 7) * 60
			);
		},
		getStaff(staff_id) {
			return this.staffs.find((item) => item.id === staff_id);
		},
		getDetail(id) {
			return this.group_classes.find((item) => item.id === id);
		},
	},
});
</script>
