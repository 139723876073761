
import { Options, Vue } from 'vue-class-component';
import SwitchButton from '@/components/elements/switchButton/SwitchButton.vue';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
	name: 'Report',
	components: { SwitchButton },
})
export default class Report extends Vue {
	//----------------------- 🤍 Props 🤍 -----------------------//
	@Prop() org!: any;

	//----------------------- 🤍 Data 🤍 -----------------------//
	enable_half_year_report = true;
	enable_monthly_report = true;
	enable_quarterly_report = false;
	enable_weekly_report = false;
	enable_day_care_report_sent = false;
	enable_lesson_report_sent = false;
	enable_weekly_report_sent = false;
	require_goal_note = false;
	require_report_note = false;
	onChange = false;
	onWatchOrg = true;
	dateValue = false;

	//----------------------- 🤍 Watch 🤍 -----------------------//
	@Watch('org', { deep: true, immediate: true })
	onOrgChange(org: any) {
		if (org && this.onWatchOrg) {
			this.enable_half_year_report = org?.enable_half_year_report;
			this.enable_monthly_report = org?.enable_monthly_report;
			this.enable_quarterly_report = org?.enable_quarterly_report;
			this.enable_weekly_report = org?.enable_weekly_report;
			this.enable_day_care_report_sent = org?.enable_day_care_report_sent;
			this.enable_lesson_report_sent = org?.enable_lesson_report_sent;
			this.enable_weekly_report_sent = org?.enable_weekly_report_sent;
			this.require_goal_note = org?.require_goal_note;
			this.require_report_note = org?.require_report_note;
			setTimeout(() => {
				this.onChange = true;
			}, 1000);
			this.onWatchOrg = false;
		}
	}

	@Watch('require_goal_note')
	onRequestGoalNote(value: any) {
		if (this.onChange) {
			this.$emit('updateOrg', {
				require_goal_note: value,
			});
		}
	}

	@Watch('require_report_note')
	onRequestGoalReport(value: any) {
		if (this.onChange) {
			this.$emit('updateOrg', {
				require_report_note: value,
			});
		}
	}

	@Watch('enable_half_year_report')
	onHaftYearReport(value: any) {
		if (this.onChange) {
			this.$emit('updateOrg', {
				enable_half_year_report: value,
			});
		}
	}

	@Watch('enable_quarterly_report')
	onQuarterlyReport(value: any) {
		if (this.onChange) {
			this.$emit('updateOrg', {
				enable_quarterly_report: value,
			});
		}
	}

	@Watch('enable_weekly_report')
	onWeeklyReport(value: any) {
		if (this.onChange) {
			this.$emit('updateOrg', {
				enable_weekly_report: value,
			});
		}
	}

	@Watch('enable_day_care_report_sent')
	onEnable_day_care_report_sent(value: any) {
		if (this.onChange) {
			this.$emit('updateOrg', {
				enable_day_care_report_sent: value,
			});
		}
	}

	@Watch('enable_lesson_report_sent')
	onEnable_lesson_report_sent(value: any) {
		if (this.onChange) {
			this.$emit('updateOrg', {
				enable_lesson_report_sent: value,
			});
		}
	}

	@Watch('enable_weekly_report_sent')
	onEnable_weekly_report_sent(value: any) {
		if (this.onChange) {
			this.$emit('updateOrg', {
				enable_weekly_report_sent: value,
			});
		}
	}
}
