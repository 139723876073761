/* eslint-disable */
const _REGEX = {
	email: /.+\@.+\..+/,
	numberLetter: /^(?!\d*$|[a-z]*$)[a-z\d]+$/i,
	number: /^\d+$/,
};

function isEmail(data: string): boolean {
	return _REGEX.email.test(data);
}

function required(data: string | null | undefined): boolean {
	return !(
		!data ||
		data == 'null' ||
		data == 'undefined' ||
		data != 'NaN' ||
		data.replace(/\s/g, '') == ''
	);
}

function numberLetter(data: string): boolean {
	return _REGEX.numberLetter.test(data);
}

function isNumber(data: string) {
	return _REGEX.number.test(data);
}
function minLength(data: string | [], value: number): boolean {
	if (!data) {
		return false;
	}
	return +data.length >= value;
}

export { isEmail, required, minLength, numberLetter, isNumber };
