<template>
	<div>
		<div class="w-full mb-10">
			<div
				class="
					border-b border-neutral-200
					overflow-x-auto
					mt-6
					overflow-y-hidden
				"
			>
				<nav aria-label="Tabs" class="-mb-px flex space-x-8 tabs">
					<div
						v-for="student in [
							{
								id: 'all',
								name: 'Báo cáo nhóm nội bộ',
							},
							...groupClass?.students,
						]"
						:key="student.id"
						:class="
							studentSelected?.id === student.id
								? 'text-yellow-700 border-yellow-500 font-semibold'
								: 'text-neutral-500 hover:text-yellow-700 hover:border-yellow-500 font-medium'
						"
						class="
							border-transparent
							whitespace-nowrap
							py-3
							px-1
							border-b-2
							cursor-pointer
						"
						@click="selectNewStudent(student)"
					>
						<div class="flex items-center gap-2">
							<div>{{ student.name }}</div>
						</div>
					</div>
				</nav>
			</div>
			<div
				v-if="studentSelected?.id !== 'all'"
				class="mt-8 flex items-center justify-between"
			>
				<div class="flex items-center">
					<div class="relative">
						<img
							v-if="studentSelected?.profile_photo?.w200"
							:alt="studentSelected?.name"
							:src="studentSelected?.profile_photo?.w200"
							class="
								shadow-sm
								inline-block
								h-14
								w-14
								rounded-full
							"
							style="min-width: 56px"
						/>
						<span
							v-else
							:style="{
								background:
									studentSelected?.profile_photo?.default
										?.color,
							}"
							class="
								inline-flex
								items-center
								justify-center
								h-14
								w-14
								rounded-full
							"
							style="min-width: 56px"
						>
							<span
								class="
									font-medium
									leading-none
									text-white text-4xl
								"
								>{{
									studentSelected?.profile_photo?.default
										?.initial
								}}</span
							>
						</span>
						<div
							v-if="
								studentSelected?.state !== 'active' &&
								studentSelected?.profile_photo?.state !==
									'active'
							"
							class="
								absolute
								transform
								-translate-x-1/2 -translate-y-1/2
								top-1/2
								left-1/2
								h-14
								w-14
								rounded-full
								flex
								items-center
								justify-center
							"
							style="
								min-width: 56px;
								background-color: rgba(241, 162, 103, 0.5);
							"
						>
							<svg
								fill="none"
								height="32"
								viewBox="0 0 40 40"
								width="32"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									clip-rule="evenodd"
									d="M9.84364 16.0997V10.2496C9.84364 4.86466 14.3907 0.5 20 0.5C25.6093 0.5 30.1564 4.865 30.1564 10.2496V16.0997C33.5218 16.0997 36.25 18.7188 36.25 21.9497V33.6499C36.25 36.8812 33.5218 39.5 30.1564 39.5H9.84364C6.47821 39.5 3.75 36.8812 3.75 33.6499V21.9497C3.75 18.7188 6.47821 16.0997 9.84364 16.0997V16.0997ZM26.0936 16.0997V10.2496C26.0936 7.01866 23.3654 4.3995 20 4.3995C16.6346 4.3995 13.9064 7.01866 13.9064 10.2496V16.0997H26.0936ZM20 23.9C21.1218 23.9 22.0311 24.7729 22.0311 25.8499V29.7501C22.0311 30.8271 21.1218 31.7 20 31.7C18.8782 31.7 17.9689 30.8271 17.9689 29.7501V25.8499C17.9689 24.7729 18.8782 23.9 20 23.9Z"
									fill="#FAF5F1"
									fill-rule="evenodd"
								/>
							</svg>
						</div>
					</div>
					<div class="ml-4">
						<div
							class="
								font-bold
								text-neutral-700 text-4xl
								md-down:text-2xl
							"
						>
							{{ studentSelected?.name }}
						</div>
						<div class="flex items-center">
							<div
								v-if="studentSelected?.age"
								class="
									text-green-500
									font-semibold
									text-xs
									uppercase
									letter-spacing-8
								"
							>
								<span
									>{{ studentSelected?.age }}
									{{
										studentSelected?.learning_time &&
										![
											'graduated',
											'closed',
											'transfered',
											'stopped',
											'pending',
										].includes(studentSelected?.state)
											? `| ${studentSelected?.learning_time}`
											: ''
									}}</span
								>
							</div>
							<div
								v-if="
									[
										'graduated',
										'closed',
										'transfered',
										'stopped',
										'pending',
									].includes(studentSelected?.state)
								"
								class="
									text-green-500
									font-semibold
									text-xs
									uppercase
									letter-spacing-8
								"
							>
								<span v-if="studentSelected?.age" class="ml-1"
									>|</span
								>
								<span class="ml-1">{{
									studentState(studentSelected?.state)
								}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="false" class="mt-10">
				<div
					class="bg-white shadow-sm rounded-2xl overflow-hidden pb-4"
				>
					<div
						class="
							bg-orange-50
							p-4
							px-6
							flex
							md:items-center
							justify-between
							text-gray-700
							md-down:flex-col
						"
					>
						<div class="font-semibold text-lg">Báo cáo tuần</div>
					</div>
					<div
						class="
							flex
							items-end
							justify-between
							p-6
							pb-0
							flex-wrap
						"
					>
						<div class="flex-col gap-4">
							<div v-if="report?.data?.week_start">
								{{
									`Tuần ${$filters.toWeek(
										report?.data?.week_start,
										true
									)} (${$filters.formatDate2(
										report?.data?.week_start
									)} - ${$filters.endWeek(
										report?.data?.week_start
									)})`
								}}
							</div>
						</div>
						<div class="flex-col gap-4">
							<div class="flex items-center gap-2 justify-end">
								<span>Giáo viên báo cáo</span>
								<router-link
									v-if="report?.data?.submitted_by_staff"
									:to="`/record/employee/${report?.data?.submitted_by_staff?.id}`"
								>
									<div>
										<span
											class="font-semibold text-blue-700"
											>{{
												report?.data?.submitted_by_staff
													?.name
											}}</span
										>
									</div>
								</router-link>
							</div>
							<div
								v-if="groupClass?.staffs?.length"
								class="flex items-center flex-wrap gap-2"
							>
								<div>Giáo viên hỗ trợ</div>
								<div class="flex items-center flex-wrap gap-1">
									<router-link
										v-for="(staff, index) in report
											?.group_class?.staffs"
										:key="staff.id"
										:to="`/record/employee/${staff?.id}`"
									>
										<div>
											<span
												class="
													font-semibold
													text-blue-700
												"
												>{{ staff?.name }}</span
											><span
												v-if="
													index <
													groupClass?.staffs?.length -
														1
												"
												>,</span
											>
										</div>
									</router-link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="mt-10">
				<div class="bg-white shadow-sm rounded-2xl">
					<div class="font-semibold text-neutral-600 p-6">
						Nhận xét chung của cả tuần
					</div>
					<div
						class="
							flex flex-col
							items-stretch
							gap-2
							border-t border-orange-50
							p-6
						"
					>
						<div class="w-full h-full">
							<textarea
								v-if="studentSelected.id === 'all'"
								v-model="note"
								:class="noteError ? 'text-error' : ''"
								:disabled="isLoading"
								placeholder="Nhận xét chung/ lưu ý cho cấp quản lý"
								style="
									height: 100%;
									resize: none;
									min-height: 160px;
								"
								@blur="submitNoteReport"
							></textarea>
							<textarea
								v-else
								v-model="student_group_weekly_report_note"
								:class="noteError ? 'text-error' : ''"
								:disabled="isLoading"
								placeholder="Nhận xét chung/ lưu ý cho phụ huynh"
								style="
									height: 100%;
									resize: none;
									min-height: 160px;
								"
								@blur="submitNoteReportForStudent"
							></textarea>
						</div>
						<div v-if="noteError" class="text-sienna-600">
							Vui lòng nhập ghi chú trước khi chọn học sinh khác
						</div>
					</div>
				</div>
			</div>
			<div class="relative mt-12">
				<div
					aria-hidden="true"
					class="absolute inset-0 flex items-center"
				>
					<div class="w-full border-t border-neutral-300" />
				</div>
				<div class="relative flex items-center justify-center">
					<div
						class="
							px-2.5
							py-1
							bg-body
							text-neutral-600
							font-semibold
						"
					>
						Nội dung báo cáo
					</div>
				</div>
			</div>
			<div class="mt-10">
				<div class="p-6 bg-white shadow-sm rounded-2xl">
					<div class="font-semibold text-neutral-600 mb-6">
						Thống kê chung
						{{
							`tuần ${$filters.toWeek(
								report?.data?.week_start,
								true
							)} (${$filters.formatDate2(
								report?.data?.week_start
							)} - ${$filters.endWeek(report?.data?.week_start)})`
						}}
					</div>
					<div class="flex flex-nowrap w-full md-down:flex-col">
						<div
							class="
								p-6
								border
								rounded-l-lg
								border-neutral-200
								w-full
								md-down:rounded-t md-down:rounded-b-none
							"
						>
							<div
								class="
									text-33
									font-bold
									text-neutral-600
									mb-6
									flex flex-nowrap
									items-center
								"
							>
								<img
									alt=""
									class="mr-2"
									height="32"
									src="@/assets/images/icons/schedule.png"
									style="height: 32px"
									width="32"
								/>
								{{ Number(report?.group_sessions_count || 0) }}
							</div>
							<div class="flex flex-nowrap items-center">
								<span class="text-neutral-500 text-sm"
									>Tổng số buổi học
									<span
										v-if="studentSelected?.id !== 'all'"
										style="font-style: italic"
										>(PH không xem được thông tin này)</span
									>
								</span>
							</div>
						</div>
						<div
							class="
								p-6
								border-t border-b border-neutral-200
								w-full
								md-down:border-l
								md-down:border-r
								md-down:border-b-0
							"
						>
							<div
								class="
									text-33
									font-bold
									text-neutral-600
									mb-6
									flex flex-nowrap
									items-center
								"
							>
								<img
									alt=""
									class="mr-2"
									height="32"
									src="@/assets/images/icons/abc.png"
									style="height: 32px"
									width="32"
								/>
								{{
									Number(
										report?.stats?.learn_skill_count || 0
									)
								}}
							</div>
							<div class="flex flex-nowrap items-center">
								<span class="text-neutral-500 text-sm"
									>Tổng số lĩnh vực đã học
								</span>
							</div>
						</div>
						<div
							class="
								p-6
								border-l
								border-t
								border-b
								border-r
								border-neutral-200
								w-full
								md:rounded-r-lg
							"
						>
							<div
								class="
									text-33
									font-bold
									text-neutral-600
									mb-6
									flex flex-nowrap
									items-center
								"
							>
								<img
									alt=""
									class="mr-2"
									height="32"
									src="@/assets/images/icons/light.png"
									style="height: 32px"
									width="32"
								/>
								{{ report?.stats?.learn_goal_count }}
							</div>
							<div class="flex flex-nowrap items-center">
								<span class="text-neutral-500 text-sm"
									>Tổng số mục tiêu đã học
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="mt-10">
				<div class="grid grid-cols-2 gap-8 xl-down:grid-cols-1">
					<div class="bg-white shadow-sm rounded-2xl overflow-hidden">
						<div class="font-semibold text-neutral-500 p-6">
							Biểu đồ cảm xúc
						</div>
						<div
							class="
								border-t border-orange-50
								flex
								justify-center
								items-center
								p-6
								md-down:flex-col
							"
						>
							<div
								class="
									relative
									w-1/2
									md-down:w-full
									flex
									items-center
									justify-center
								"
							>
								<apexchart
									:options="chartOptions"
									:series="emotions"
									height="220"
									type="donut"
								></apexchart>
								<div
									class="
										text-center
										absolute
										top-1/2
										left-1/2
										transform
									"
									style="transform: translate(-50%, -50%)"
								>
									<div class="text-neutral-500">Cảm xúc</div>
								</div>
							</div>
							<div class="w-1/3 md-down:w-full md-down:mt-4">
								<div
									v-if="emotions[0] > 0"
									class="
										flex flex-nowrap
										items-center
										justify-between
										w-full
									"
								>
									<div class="flex flex-nowrap items-center">
										<img
											alt=""
											height="24"
											src="@/assets/images/emoji/happy.png"
											width="24"
										/>
										<div
											class="
												h-2.5
												w-2.5
												rounded-full
												ml-2
											"
											style="background: #9edf93"
										></div>
										<div
											class="
												ml-2
												text-xs text-neutral-500
												whitespace-nowrap
											"
										>
											Vui vẻ
										</div>
									</div>
									<div
										class="
											text-xs text-neutral-600
											font-bold
										"
									>
										{{ emotions[0] }}
										%
									</div>
								</div>
								<div
									v-if="emotions[1] > 0"
									class="
										flex flex-nowrap
										items-center
										justify-between
										w-full
										mt-2.5
									"
								>
									<div class="flex flex-nowrap items-center">
										<img
											alt=""
											height="24"
											src="@/assets/images/emoji/5565d66a50b44df9ae37a955bdfb3757.png"
											width="24"
										/>
										<div
											class="
												h-2.5
												w-2.5
												rounded-full
												ml-2
											"
											style="background: #d0db54"
										></div>
										<div
											class="
												ml-2
												text-xs text-neutral-500
												whitespace-nowrap
											"
										>
											Bình thường
										</div>
									</div>
									<div
										class="
											text-xs text-neutral-600
											font-bold
										"
									>
										{{ emotions[1] }}
										%
									</div>
								</div>
								<div
									v-if="emotions[2] > 0"
									class="
										flex flex-nowrap
										items-center
										justify-between
										w-full
										mt-2.5
									"
								>
									<div class="flex flex-nowrap items-center">
										<img
											alt=""
											height="24"
											src="@/assets/images/emoji/cf1f643bc1244f5e8e37767bc946d607.png"
											width="24"
										/>
										<div
											class="
												h-2.5
												w-2.5
												rounded-full
												ml-2
											"
											style="background: #ffe68c"
										></div>
										<div
											class="
												ml-2
												text-xs text-neutral-500
												whitespace-nowrap
											"
										>
											Khó chịu
										</div>
									</div>
									<div
										class="
											text-xs text-neutral-600
											font-bold
										"
									>
										{{ emotions[2] }}
										%
									</div>
								</div>
								<div
									v-if="emotions[3] > 0"
									class="
										flex flex-nowrap
										items-center
										justify-between
										w-full
										mt-2.5
									"
								>
									<div class="flex flex-nowrap items-center">
										<img
											alt=""
											height="24"
											src="@/assets/images/emoji/5ede936757cd4fbdb6247bad22bf454d.png"
											width="24"
										/>
										<div
											class="
												h-2.5
												w-2.5
												rounded-full
												ml-2
											"
											style="background: #ff9d6d"
										></div>
										<div
											class="
												ml-2
												text-xs text-neutral-500
											"
										>
											Tức giận
										</div>
									</div>
									<div
										class="
											text-xs text-neutral-600
											font-bold
										"
									>
										{{ emotions[3] }}
										%
									</div>
								</div>
								<div
									v-if="emotions[4] > 0"
									class="
										flex flex-nowrap
										items-center
										justify-between
										w-full
										mt-2.5
									"
								>
									<div class="flex flex-nowrap items-center">
										<img
											alt=""
											height="24"
											src="@/assets/images/emoji/0b356bb507d64b9489e2fbced131f795.png"
											width="24"
										/>
										<div
											class="
												h-2.5
												w-2.5
												rounded-full
												ml-2
											"
											style="background: #ff6967"
										></div>
										<div
											class="
												ml-2
												text-xs text-neutral-500
												whitespace-nowrap
											"
										>
											Bùng nổ
										</div>
									</div>
									<div
										class="
											text-xs text-neutral-600
											font-bold
										"
									>
										{{ emotions[4] }}
										%
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="bg-white shadow-sm rounded-2xl overflow-hidden">
						<div class="font-semibold text-neutral-500 p-6">
							Chuyên cần
						</div>
						<div
							class="
								border-t border-orange-50
								p-6
								flex
								items-center
								justify-center
								flex-col
							"
						>
							<div
								class="text-neutral-600 font-semibold mb-5"
								style="font-size: 72px"
							>
								{{ report?.group_sessions_count }}
							</div>
							<div class="text-neutral-500 mb-5 text-center">
								Số buổi học tuần này <br />
								<span
									v-if="studentSelected?.id !== 'all'"
									style="font-style: italic"
									>(PH không xem được thông tin này)</span
								>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="studentSelected?.id !== 'all'">
				<div class="mt-10">
					<div class="grid grid-cols-2 gap-8 xl-down:grid-cols-1">
						<div
							v-if="report?.result?.length >= 6"
							class="
								bg-white
								shadow-sm
								rounded-2xl
								overflow-hidden
							"
						>
							<div class="font-semibold text-neutral-500 p-6">
								Kết quả mục tiêu đã học
							</div>
							<div
								class="
									flex flex-nowrap
									p-6
									border-t border-orange-50
									items-center
									justify-center
									md-down:flex-col
								"
							>
								<div class="relative w-fit md-down:w-full">
									<apexchart
										:options="chartOptions"
										:series="
											report?.result?.map(
												(item) => item.count
											)
										"
										height="220"
										type="donut"
									></apexchart>
									<div
										class="
											text-center
											absolute
											top-1/2
											left-1/2
											transform
										"
										style="transform: translate(-50%, -50%)"
									>
										<div
											class="
												text-4xl
												font-bold
												text-neutral-600
											"
										>
											{{
												report?.stats?.learn_goal_count
											}}
										</div>
										<div class="text-neutral-500">
											Mục tiêu
										</div>
									</div>
								</div>
								<div
									class="
										flex flex-col
										justify-center
										md-down:mt-4
									"
								>
									<div
										v-if="report?.result[0]?.count > 0"
										class="
											flex flex-nowrap
											items-center
											mb-4
										"
									>
										<div
											class="
												text-sm
												font-semibold
												text-neutral-500
												w-14
												blue
											"
										>
											{{ report?.result[0]?.count }}
											M.T
										</div>
										<span
											class="
												flex
												items-center
												justify-center
												py-0.5
												rounded-full
												text-sm text-white
												font-semibold
												whitespace-nowrap
												w-32
												shadow-input
											"
											style="background: #2aa797"
										>
											{{ report?.result[0]?.result }}
										</span>
									</div>
									<div
										v-if="report?.result[1]?.count > 0"
										class="
											flex flex-nowrap
											items-center
											mb-4
										"
									>
										<div
											class="
												text-sm text-neutral-500
												w-14
												font-semibold
											"
										>
											{{ report?.result[1]?.count }}
											M.T
										</div>
										<span
											class="
												flex
												items-center
												justify-center
												py-0.5
												rounded-full
												text-sm text-white
												font-semibold
												w-32
												whitespace-nowrap
												shadow-input
											"
											style="background: #65a30d"
										>
											{{ report?.result[1]?.result }}
										</span>
									</div>
									<div
										v-if="report?.result[2]?.count > 0"
										class="
											flex flex-nowrap
											items-center
											mb-4
										"
									>
										<div
											class="
												text-sm
												font-semibold
												text-neutral-500
												w-14
											"
										>
											{{ report?.result[2]?.count }}
											M.T
										</div>
										<span
											class="
												flex
												items-center
												justify-center
												py-0.5
												rounded-full
												text-sm text-white
												font-semibold
												w-32
												whitespace-nowrap
												shadow-input
											"
											style="background: #f59e0b"
										>
											{{ report?.result[2]?.result }}
										</span>
									</div>
									<div
										v-if="report?.result[3]?.count > 0"
										class="
											flex flex-nowrap
											items-center
											mb-4
										"
									>
										<div
											class="
												text-sm
												font-semibold
												text-neutral-500
												w-14
												blue
											"
										>
											{{ report?.result[3]?.count }}
											M.T
										</div>
										<span
											class="
												flex
												items-center
												justify-center
												px-3
												py-0.5
												rounded-full
												text-sm text-white
												font-semibold
												w-32
												whitespace-nowrap
												shadow-input
											"
											style="background: #f87171"
										>
											{{ report?.result[3]?.result }}
										</span>
									</div>
									<div
										v-if="
											report?.result[4]?.count > 0 ||
											report?.result[5]?.count > 0 ||
											report?.result[6]?.count > 0
										"
										class="
											flex flex-nowrap
											items-center
											mb-4
										"
									>
										<div
											class="
												text-sm
												font-semibold
												text-neutral-500
												w-14
												blue
											"
										>
											{{
												report?.result[4]?.count +
												report?.result[5]?.count +
												report?.result[6]?.count
											}}
											M.T
										</div>
										<span
											class="
												flex
												items-center
												justify-center
												px-3
												py-0.5
												rounded-full
												text-sm text-white
												font-semibold
												w-32
												whitespace-nowrap
												shadow-input
											"
											style="background: #9ca3af"
										>
											X
										</span>
									</div>
								</div>
							</div>
						</div>
						<div
							class="
								bg-white
								shadow-sm
								rounded-2xl
								overflow-hidden
							"
						>
							<div class="font-semibold text-neutral-500 p-6">
								So sánh với tuần trước
							</div>
							<div
								class="
									border-t border-orange-50
									grid grid-cols-3
									items-start
									md-down:grid-cols-1
								"
							>
								<div
									class="
										flex flex-col
										justify-center
										items-center
										py-6
										w-full
									"
								>
									<apexchart
										:options="chartOptions2_1"
										:series="[
											+report?.compare_with_last?.average
												?.average_result_in_percent,
										]"
										height="240"
										type="radialBar"
									></apexchart>
									<div
										v-show="
											+report?.compare_with_last?.compare
												?.average_result !== 0
										"
										:class="
											+report?.compare_with_last?.compare
												?.average_result >= 0
												? 'bg-green-50 text-green-400'
												: 'bg-sienna-50 text-sienna-400'
										"
										class="
											grow-value
											flex flex-nowrap
											rounded-lg
											py-1
											px-2
											items-center
											w-fit
										"
									>
										<svg
											:class="{
												'transform rotate-180':
													+report?.compare_with_last
														?.compare
														?.average_result >= 0,
											}"
											fill="none"
											height="12"
											viewBox="0 0 10 12"
											width="10"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												clip-rule="evenodd"
												d="M4.36354 0.498901V9.45415L1.4547 6.54531L0.5 7.50001L5 12L9.5 7.50001L8.5453 6.54531L5.63646 9.45415V0.498901H4.36354Z"
												fill="currentColor"
												fill-rule="evenodd"
											/>
										</svg>
										<div
											class="
												ml-2
												font-bold
												text-sm text-neutral-700
											"
										>
											{{
												Math.abs(
													Math.round(
														+report
															?.compare_with_last
															?.compare
															?.average_result *
															10
													) / 10
												)
											}}%
										</div>
									</div>
								</div>
								<div
									class="
										flex flex-col
										justify-center
										items-center
										py-6
									"
								>
									<apexchart
										:options="chartOptions2_2"
										:series="[
											Math.round(
												+report?.compare_with_last
													?.average
													?.average_cooperation_scale_in_percent *
													200
											) / 10,
										]"
										height="240"
										type="radialBar"
									></apexchart>
									<div
										v-show="
											+report?.compare_with_last?.compare
												?.average_cooperation_scale !==
											0
										"
										:class="
											+report?.compare_with_last?.compare
												?.average_cooperation_scale >= 0
												? 'bg-green-50 text-green-400'
												: 'bg-sienna-50 text-sienna-400'
										"
										class="
											grow-value
											flex flex-nowrap
											rounded-lg
											py-1
											px-2
											items-center
											w-fit
										"
									>
										<svg
											:class="{
												'transform rotate-180':
													+report?.compare_with_last
														?.compare
														?.average_cooperation_scale >=
													0,
											}"
											fill="none"
											height="12"
											viewBox="0 0 10 12"
											width="10"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												clip-rule="evenodd"
												d="M4.36354 0.498901V9.45415L1.4547 6.54531L0.5 7.50001L5 12L9.5 7.50001L8.5453 6.54531L5.63646 9.45415V0.498901H4.36354Z"
												fill="currentColor"
												fill-rule="evenodd"
											/>
										</svg>
										<div
											class="
												ml-2
												font-bold
												text-sm text-neutral-700
											"
										>
											{{
												Math.abs(
													Math.round(
														+report
															?.compare_with_last
															?.compare
															?.average_cooperation_scale *
															10
													) / 10
												)
											}}%
										</div>
									</div>
								</div>
								<div
									class="
										flex flex-col
										justify-center
										items-center
										py-6
									"
								>
									<apexchart
										:options="chartOptions2_3"
										:series="[
											Math.round(
												+report?.compare_with_last
													?.average
													?.average_emotion_scale_in_percent *
													200
											) / 10,
										]"
										height="240"
										type="radialBar"
									></apexchart>
									<div
										v-show="
											+report?.compare_with_last?.compare
												?.average_emotion_scale !== 0
										"
										:class="
											+report?.compare_with_last?.compare
												?.average_emotion_scale >= 0
												? 'bg-green-50 text-green-400'
												: 'bg-sienna-50 text-sienna-400'
										"
										class="
											grow-value
											flex flex-nowrap
											rounded-lg
											py-1
											px-2
											items-center
											w-fit
										"
									>
										<svg
											:class="{
												'transform rotate-180':
													+report?.compare_with_last
														?.compare
														?.average_emotion_scale >=
													0,
											}"
											fill="none"
											height="12"
											viewBox="0 0 10 12"
											width="10"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												clip-rule="evenodd"
												d="M4.36354 0.498901V9.45415L1.4547 6.54531L0.5 7.50001L5 12L9.5 7.50001L8.5453 6.54531L5.63646 9.45415V0.498901H4.36354Z"
												fill="currentColor"
												fill-rule="evenodd"
											/>
										</svg>
										<div
											class="
												ml-2
												font-bold
												text-sm text-neutral-700
											"
										>
											{{
												Math.abs(
													Math.round(
														+report
															?.compare_with_last
															?.compare
															?.average_emotion_scale *
															10
													) / 10
												)
											}}%
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					v-if="radarData.length && radarData[0]?.data.length >= 3"
					class="mt-10"
				>
					<div
						class="
							bg-white
							shadow-sm
							overflow-hidden
							rounded-2xl
							mb-10
							pb-5
						"
					>
						<div class="font-semibold text-neutral-500 p-6">
							Biểu đồ mối tương quan giữa kết quả các lĩnh vực
							trong tuần
						</div>
						<div
							class="
								p-6
								border-t border-orange-50
								md-down:px-0
								overflow-x-auto
								w-full
							"
						>
							<apexchart
								:options="radarChartOptions"
								:series="radarData"
								height="700"
								type="radar"
							></apexchart>
							<div
								class="
									py-4
									flex flex-wrap
									gap-6
									justify-center
									items-center
								"
							>
								<div
									v-for="(c, index) in categories"
									:key="c"
									class="flex items-center flex-nowrap gap-2"
								>
									<div
										class="
											font-bold
											text-lf text-neutral-700
										"
									>
										{{ index + 1 }}: {{ c }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="relative mt-10">
				<div
					aria-hidden="true"
					class="absolute inset-0 flex items-center"
				>
					<div class="w-full border-t border-neutral-300" />
				</div>
				<div class="relative flex items-center justify-center">
					<div
						class="
							px-2.5
							py-1
							bg-body
							text-neutral-600
							font-semibold
						"
					>
						Kết quả mục tiêu đã học
					</div>
				</div>
			</div>
			<div v-if="studentSelected?.id === 'all'">
				<GoalsGroupClass
					:group_class="report?.group_class"
					:skills="report?.skills"
					:students="groupClass?.students"
				/>
			</div>
			<div v-else>
				<GoalsGroupClassForStudent
					:skills="report?.skills"
					:student-id="studentSelected?.id"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import GoalsGroupClass from '@/views/weekGroupEdit/GoalsGroupClass.vue';
import GoalsGroupClassForStudent from '@/views/weekGroupEdit/GoalsGroupClassForStudent.vue';
import { studentState } from '@/helper/studentState';
import { $api } from '@/services';

export default {
	name: 'ReportDetail',

	components: {
		GoalsGroupClassForStudent,
		GoalsGroupClass,
	},

	props: {
		report: {
			type: Object,
			required: true,
		},

		groupClass: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			chartOptions2_1: {
				chart: {
					type: 'radialBar',
				},
				plotOptions: {
					radialBar: {
						hollow: {
							size: '54%',
						},
						dataLabels: {
							name: {
								offsetY: -10,
								show: true,
								color: '#65758B',
								fontWeight: 'normal',
								fontSize: '13px',
							},
							value: {
								color: '#48566A',
								fontSize: '24px',
								show: true,
								fontWeight: 'bold',
							},
						},
					},
				},
				fill: {
					colors: '#6ED0B9',
				},
				stroke: {
					lineCap: 'round',
				},
				labels: ['Kết quả'],
			},
			chartOptions2_2: {
				chart: {
					type: 'radialBar',
				},
				plotOptions: {
					radialBar: {
						hollow: {
							size: '54%',
						},
						dataLabels: {
							name: {
								offsetY: -10,
								show: true,
								color: '#65758B',
								fontWeight: 'normal',
								fontSize: '13px',
							},
							value: {
								color: '#48566A',
								fontSize: '24px',
								show: true,
								fontWeight: 'bold',
							},
						},
					},
				},
				fill: {
					colors: '#FFD270',
				},
				stroke: {
					lineCap: 'round',
				},
				labels: ['Sự tham gia'],
			},
			chartOptions2_3: {
				chart: {
					type: 'radialBar',
				},
				plotOptions: {
					radialBar: {
						hollow: {
							size: '54%',
						},
						dataLabels: {
							name: {
								offsetY: -10,
								show: true,
								color: '#65758B',
								fontWeight: 'normal',
								fontSize: '13px',
							},
							value: {
								color: '#48566A',
								fontSize: '24px',
								show: true,
								fontWeight: 'bold',
							},
						},
					},
				},
				fill: {
					colors: '#DA8F8F',
				},
				stroke: {
					lineCap: 'round',
				},
				labels: ['Cảm xúc'],
			},
			modalIsOpen: false,
			chartOptions: {
				colors: ['#9EDF93', '#D0DB54', '#FFE68C', '#FF9D6D', '#FF6967'],
				chart: {
					width: 380,
					type: 'polarArea',
				},
				labels: [
					'Vui vẻ',
					'Bình thường',
					'Khó chịu',
					'Tức giận',
					'Bùng nổ',
				],
				fill: {
					opacity: 1,
					colors: [
						'#9EDF93',
						'#D0DB54',
						'#FFE68C',
						'#FF9D6D',
						'#FF6967',
					],
				},
				stroke: {
					lineCap: 'round',
				},
				yaxis: {
					show: false,
				},
				tooltip: {
					fillSeriesColor: true,
					y: {
						formatter: function (value) {
							if (!value || value == 'null') {
								return 'Không thực hiện';
							}
							return value + '%';
						},
					},
				},
				legend: {
					show: false,
				},
				dataLabels: {
					enabled: false,
				},
				plotOptions: {
					polarArea: {
						rings: {
							strokeWidth: 0,
						},
						spokes: {
							strokeWidth: 0,
						},
					},
				},
			},
			radarChartOptions: {
				chart: {
					id: 'radarChart',
					type: 'radar',
				},
				title: {
					show: false,
				},
				stroke: {
					width: 0,
				},
				fill: {
					opacity: 0.2,
					colors: ['#65A30D'],
				},
				markers: {
					size: 5,
					hover: {
						size: 10,
					},
					colors: ['#65A30D'],
				},
				dataLabels: {
					enabled: true,
					formatter: function (val) {
						return val + '%';
					},
					style: {
						fontFamily: 'Gilrory',
						fontSize: '20px',
						fontWeight: 'bold',
						colors: ['#344256'],
					},
					background: {
						enabled: false,
					},
				},
				yaxis: {
					show: false,
					logBase: 100,
					tickAmount: 10,
					min: 0,
					max: 100,
					labels: {
						show: false,
					},
				},
				xaxis: {
					categories: [],
					labels: {
						show: true,
						style: {
							fontFamily: 'Gilrory',
							fontSize: '14px',
							fontWeight: 600,
							colors: Array(1000).fill('#344256'),
						},
						formatter: function (val, index) {
							return index?.dataPointIndex + 1;
						},
					},
				},
				tooltip: {
					x: {
						show: true,
						formatter: function (val, object) {
							return `${object?.dataPointIndex + 1}: ${val}`;
						},
						title: {
							formatter: (seriesName) => seriesName,
						},
					},
					y: {
						show: true,
						formatter: function (val) {
							return val + '%';
						},
						title: {
							title: {
								formatter: (seriesName) => seriesName + ':',
							},
						},
					},
				},
			},
			isLoading: false,
			studentSelected: {
				id: 'all',
				name: 'Báo cáo nhóm nội bộ',
			},
			student_group_weekly_report_note: '',
			note: '',
			noteError: '',
		};
	},

	watch: {
		report: {
			handler(value) {
				this.note = value?.data?.note;
				this.student_group_weekly_report_note =
					value?.student_group_weekly_report_note;
			},
			immediate: true,
			deep: true,
		},

		studentSelected: {
			handler() {
				this.$emit('fetchReportFilterByStudent', {
					studentId: this.studentSelected?.id,
				});
			},
			immediate: true,
			deep: true,
		},
	},

	computed: {
		studentOptions() {
			return [
				{
					id: 'all',
					name: 'Báo cáo nhóm nội bộ',
				},
				...this.groupClass?.students,
			];
		},

		emotionTotal() {
			return this.report?.emotion_scale_stats?.reduce(
				(a, b) => +a + +b.count,
				0
			);
		},

		emotions() {
			return this.report?.emotion_scale_stats?.map((item) => {
				const r = (item.count * 100) / this.emotionTotal;
				return Math.round(r * 10) / 10 || 0;
			});
		},

		categories() {
			return this.report?.skills?.map((skill) => {
				return skill?.skill?.name;
			});
		},

		radarData() {
			return [
				{
					name: 'Kết quả',
					data: this.report?.skills_stats?.map((s) => {
						return +s?.average_result || 0;
					}),
				},
			];
		},
	},

	methods: {
		studentState,

		async submitNoteReport() {
			try {
				const { data } = await $api.weeklyReport.updateForGroup(
					this.groupClass?.id,
					this.report?.data?.id,
					{
						group_weekly_report: {
							note: this.note,
						},
					}
				);
				this.$emit('submittedNote', {
					all_student_group_weekly_report_completed:
						data?.all_student_group_weekly_report_completed,
					note: this.note,
				});
				return true;
			} catch (err) {
				const error =
					err?.response?.data?.errors?.join(<br />) ||
					err?.message ||
					'Đã có lỗi xảy ra. Vui lòng thử lại';
				await this.$store.dispatch('setAlertMessage', {
					message: error,
					type: 'danger',
				});
				return false;
			}
		},

		async submitNoteReportForStudent() {
			try {
				const { data } =
					await $api.weeklyReport.submitNoteReportForStudent(
						this.groupClass?.id,
						this.report?.data?.id,
						{
							note: this.student_group_weekly_report_note,
							student_id: this.studentSelected?.id,
						}
					);
				this.$emit('submittedNote', {
					all_student_group_weekly_report_completed:
						data?.all_student_group_weekly_report_completed,
					student_group_weekly_report_note:
						this.student_group_weekly_report_note,
				});
				return true;
			} catch (err) {
				const error =
					err?.response?.data?.errors?.join(<br />) ||
					err?.message ||
					'Đã có lỗi xảy ra. Vui lòng thử lại';
				await this.$store.dispatch('setAlertMessage', {
					message: error,
					type: 'danger',
				});
				return false;
			}
		},

		selectNewStudent(student) {
			this.studentSelected = student;
		},
	},
};
</script>
