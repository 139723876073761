<template>
  <div
    class="
			fixed
			inset-y-0
			right-0
			flex
			side-over
			z-50
			overflow-y-auto
		"
    style="width: 100vw; max-width: 720px"
  >
    <div
      class="h-full w-full flex flex-col bg-white shadow-xl"
      style="min-height: 100vh; height: fit-content"
    >
      <div class="px-6 py-8 bg-blue-50">
        <div class="flex items-start justify-between">
          <div class="flex items-center">
            <svg
              fill="none"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M14.107 1.57558C13.4878 -0.525194 10.5122 -0.525193 9.89301 1.57558L9.74284 2.08505C9.3472 3.42735 7.81403 4.06241 6.58512 3.39302L6.11869 3.13896C4.19538 2.09133 2.09133 4.19539 3.13896 6.1187L3.39302 6.58512C4.06241 7.81403 3.42735 9.3472 2.08505 9.74284L1.57558 9.89301C-0.525194 10.5122 -0.525193 13.4878 1.57558 14.107L2.08505 14.2572C3.42735 14.6528 4.06241 16.186 3.39302 17.4149L3.13896 17.8813C2.09133 19.8046 4.19538 21.9087 6.11869 20.861L6.58512 20.607C7.81403 19.9376 9.3472 20.5727 9.74284 21.915L9.89301 22.4244C10.5122 24.5252 13.4878 24.5252 14.107 22.4244L14.2572 21.915C14.6528 20.5727 16.186 19.9376 17.4149 20.607L17.8813 20.861C19.8046 21.9087 21.9087 19.8046 20.861 17.8813L20.607 17.4149C19.9376 16.186 20.5727 14.6528 21.915 14.2572L22.4244 14.107C24.5252 13.4878 24.5252 10.5122 22.4244 9.89301L21.915 9.74284C20.5727 9.3472 19.9376 7.81403 20.607 6.58512L20.861 6.11869C21.9087 4.19538 19.8046 2.09133 17.8813 3.13896L17.4149 3.39302C16.186 4.06241 14.6528 3.42735 14.2572 2.08505L14.107 1.57558ZM12 16.3932C14.4263 16.3932 16.3932 14.4263 16.3932 12C16.3932 9.57369 14.4263 7.60678 12 7.60678C9.57369 7.60678 7.60678 9.57369 7.60678 12C7.60678 14.4263 9.57369 16.3932 12 16.3932Z"
                fill="#94A3B8"
                fill-rule="evenodd"
              />
            </svg>
            <div class="text-2xl font-bold text-neutral-700 ml-2">
              Chọn lĩnh vực
            </div>
          </div>
          <div class="ml-3 h-7 flex items-center">
            <button
              class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
              type="button"
              @click="$emit('close')"
            >
              <span class="sr-only">Close panel</span>
              <svg
                aria-hidden="true"
                class="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 18L18 6M6 6l12 12"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="mt-6 relative flex-1 px-4 sm:px-6">
        <div>
          <div class="text-neutral-500">
            Cần chọn ít nhất 1 lĩnh vực
          </div>
          <div
            v-if="!isLoading"
            class="grid grid-1 sm:grid-cols-2 gap-6 mt-8 mb-10"
          >
            <div
              v-for="(skill, index) in skills"
              :key="index"
              class="flex items-center gap-4"
              @click.stop="checkChanged(skill.id)"
            >
              <Checkbox
                :value="
									checkBoxSelected.find(
										(id) => skill.id === id
									)
								"
              />
              <span class="text-neutral-700">{{
                  skill.name
                }}</span>
            </div>
          </div>
          <div v-else>
            <Loading/>
          </div>
        </div>
      </div>
      <div v-if="message">
        <AlertUpload
          :message="message"
          type="success"
          @close="message = ''"
        />
      </div>
      <div v-if="error">
        <AlertUpload
          :message="error"
          type="danger"
          @close="error = ''"
        />
      </div>
      <div class="px-6 py-4 border-t border-yellow-100">
        <div class="flex justify-between">
          <div>
            <button
              class="
								button-p
								text-center
								bg-none
								mr-4
								shadow-none
								text-yellow-600
							"
              type="button"
              @click="
								!checkBoxSelected.length
									? selectedAll()
									: removeAll()
							"
            >
              {{
                !checkBoxSelected.length
                  ? 'Chọn tất cả'
                  : 'Bỏ chọn tất cả'
              }}
            </button>
          </div>
          <div>
            <button
              :disabled="!checkBoxSelected.length"
              class="button-p text-center w-32"
              type="button"
              @click="submit"
            >
              Lưu chỉnh sửa
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/elements/checkbox/Checkbox';
import {$api} from '@/services';
import AlertUpload from '@/components/elements/alert/AlertUpload';
import Loading from '@/components/common/Loading';

export default {
  name: 'UpdateSkillUsing',

  components: {Loading, AlertUpload, Checkbox},

  props: {
    selected: {
      type: Array,
      required: true,
    },

    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      checkBoxSelected: [],
      skills: [],
      message: '',
      error: '',
      isLoading: true,
    };
  },

  watch: {
    selected: {
      immediate: true,
      deep: true,
      handler(value) {
        this.checkBoxSelected = (value || []).map((item) => item.id);
      },
    },

    isOpen: {
      immediate: true,
      handler(value) {
        if (value) {
          this.fetchSkills();
        }
      },
    },
  },

  computed: {
    arraySubmit() {
      return this.skills
        .filter((s) => this.checkBoxSelected.includes(s.id))
        .map((item) => item.id);
    },
  },

  methods: {
    async fetchSkills() {
      try {
        this.isLoading = true;
        const {
          data: {data},
        } = await $api.org.fetchSkills();
        this.skills = data || [];
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },

    selectedAll() {
      this.checkBoxSelected = this.skills.map((item) => item.id);
    },

    removeAll() {
      this.checkBoxSelected = [];
    },

    checkChanged(id) {
      const result = this.checkBoxSelected.find((item) => item === id);

      if (result) {
        this.checkBoxSelected = this.checkBoxSelected.filter((item) => {
          return item !== id;
        });
      } else {
        this.checkBoxSelected.push(id);
      }
    },

    async submit() {
      try {
        await $api.org.updateSkills({
          skill_ids: this.arraySubmit,
        });
        this.message = 'Lưu chỉnh sửa thành công';
        this.$emit('refetch');
      } catch (err) {
        this.error = 'Đã có lỗi xảy ra, vui lòng thử lại';
      }
    },
  },
};
</script>
