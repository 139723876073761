
import { defineComponent } from 'vue';

export default defineComponent({
	data() {
		return {
			isAll: true,
		};
	},

	methods: {
		close() {
			this.$emit('close');
		},
	},
});
