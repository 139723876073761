<script setup>
import { $api } from '@/services';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { _GET_DATE_2 } from '@/helper/formatDate';

const route = useRoute();
const loading = ref(false);
const empty = ref(false);
const data = ref([]);
const statistics = ref([]);
const result = ref([]);
const emotion_cooperation_result_by_day = ref([]);
const startDate = ref(route.query.start_date);
const endDate = ref(route.query.end_date);
const chartOptions = ref({
	dataLabels: {
		enabled: false,
	},
	colors: ['#7AD2C6', '#93BF56', '#F8BB54', '#F47C7C', '#E5E7EB'],
	labels: [
		'Đạt 80 - 100%',
		'Đạt 55 - 75%',
		'Đạt 30 - 50%',
		'Đạt 5 - 25%',
		'X',
	],
	legend: {
		show: false,
	},
	tooltip: {
		fillSeriesColor: true,
		y: {
			formatter: function (value) {
				return value + ' mục tiêu';
			},
		},
	},
	stroke: {
		lineCap: 'round',
	},
});
const chartOptions3 = ref({
	colors: ['#9EDF93', '#D0DB54', '#FFE68C', '#FF9D6D', '#FF6967'],
	chart: {
		width: 380,
		type: 'polarArea',
	},
	labels: ['Vui vẻ', 'Bình thường', 'Khó chịu', 'Tức giận', 'Bùng nổ'],
	fill: {
		opacity: 1,
		colors: ['#9EDF93', '#D0DB54', '#FFE68C', '#FF9D6D', '#FF6967'],
	},
	stroke: {
		lineCap: 'round',
	},
	yaxis: {
		show: false,
	},
	tooltip: {
		fillSeriesColor: true,
		y: {
			formatter: function (value) {
				if (!value || value == 'null') {
					return 'Không thực hiện';
				}
				return value + '%';
			},
		},
	},
	legend: {
		show: false,
	},
	dataLabels: {
		enabled: false,
	},
	plotOptions: {
		polarArea: {
			rings: {
				strokeWidth: 0,
			},
			spokes: {
				strokeWidth: 0,
			},
		},
	},
});
const mixChartOptions = ref({
	chart: {
		type: 'line',
		stacked: false,
	},
	dataLabels: {
		enabled: true,
		enabledOnSeries: [0, 1],
		offsetY: -10,
		background: {
			enabled: true,
			foreColor: '#334155',
		},
		formatter: function (val) {
			return val + '%';
		},
	},
	stroke: {
		width: [0.5, 0.5, 4],
	},
	yaxis: [
		{
			opposite: false,
			axisTicks: {
				show: false,
			},
			axisBorder: {
				show: false,
			},
			labels: {
				show: false,
				formatter: function (value) {
					if (!value || value == 'null') {
						return 'Không thực hiện';
					}
					return value + '%';
				},
			},
			title: {
				show: false,
			},
			max: 100,
		},
		{
			opposite: false,
			axisTicks: {
				show: false,
			},
			axisBorder: {
				show: false,
			},
			labels: {
				show: false,
				formatter: function (value) {
					if (!value || value == 'null') {
						return 'Không thực hiện';
					}
					return value + '%';
				},
			},
			title: {
				show: false,
			},
			max: 100,
		},
		{
			opposite: false,
			axisTicks: {
				show: false,
			},
			axisBorder: {
				show: false,
			},
			labels: {
				show: false,
				formatter: function (value) {
					if (!value || value == 'null') {
						return 'Không thực hiện';
					}
					return value + '%';
				},
			},
			title: {
				show: false,
			},
			max: 100,
		},
	],
	tooltip: {
		fixed: {
			enabled: true,
			position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
			offsetY: 30,
			offsetX: 60,
		},
	},
	legend: {
		horizontalAlign: 'left',
		offsetX: 40,
	},
	plotOptions: {
		bar: {
			horizontal: false,
			columnWidth: '20%',
			borderRadius: 4,
		},
	},
	labels: [],
	colors: ['#FFD270', '#DA8F8F', '#6ED0B9'],
	markers: {
		size: 8,
		strokeColors: '#fff',
		strokeWidth: 2,
	},
});
const emotion_cooperation_result_by_day_data = ref([
	{
		name: 'Cảm xúc',
		type: 'column',
		data: [0, 0, 0, 0, 0],
	},
	{
		name: 'Sự tham gia',
		type: 'column',
		data: [0, 0, 0, 0, 0],
	},
	{
		name: 'Kết quả buổi học',
		type: 'line',
		data: [0, 0, 0, 0, 0],
	},
]);
const emotion_cooperation_result_by_day_emotion = ref([]);
const emotion_cooperation_result_by_day_rating = ref([]);
const emotion_cooperation_result_by_day_result = ref([]);
const emotion_cooperation_result_by_day_labels = ref([]);
const mixChart = ref(null);
const radarChartOptions = ref({
	chart: {
		id: 'radarChart',
		type: 'radar',
	},
	title: {
		show: false,
	},
	stroke: {
		width: 0,
	},
	fill: {
		opacity: 0.2,
		colors: ['#65A30D'],
	},
	markers: {
		size: 5,
		hover: {
			size: 10,
		},
		colors: ['#65A30D'],
	},
	dataLabels: {
		enabled: true,
		formatter: function (val) {
			return val + '%';
		},
		style: {
			fontFamily: 'Gilrory',
			fontSize: '20px',
			fontWeight: 'bold',
			colors: ['#344256'],
		},
		background: {
			enabled: false,
		},
	},
	yaxis: {
		show: false,
		logBase: 100,
		tickAmount: 10,
		min: 0,
		max: 100,
		labels: {
			show: false,
		},
	},
	xaxis: {
		categories: [],
		labels: {
			show: true,
			style: {
				fontFamily: 'Gilrory',
				fontSize: '14px',
				fontWeight: 600,
				colors: Array(1000).fill('#344256'),
			},
			formatter: function (val, index) {
				return index?.dataPointIndex + 1;
			},
		},
	},
	tooltip: {
		x: {
			show: true,
			formatter: function (val, object) {
				return `${object?.dataPointIndex + 1}: ${val}`;
			},
			title: {
				formatter: (seriesName) => seriesName,
			},
		},
		y: {
			show: true,
			formatter: function (val) {
				return val + '%';
			},
			title: {
				title: {
					formatter: (seriesName) => seriesName + ':',
				},
			},
		},
	},
});
const series = ref([]);
const series3 = ref([]);
const radarData = ref([]);
const categories = ref([]);
const assessment_results = ref([]);
watch(
	mixChart,
	() => {
		const optimalColumnWidthPercent =
			12 +
			16 /
				(1 +
					30 *
						Math.exp(
							-emotion_cooperation_result_by_day_emotion.value
								?.length / 3
						));

		setTimeout(() => {
			mixChart.value?.updateOptions({
				plotOptions: {
					bar: {
						columnWidth: optimalColumnWidthPercent + '%',
					},
				},
			});
		}, 1000);
	},
	{ immediate: true, deep: true }
);
const formatDate = (dateString, reverse) => {
	const date = new Date(dateString);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	let formattedDate;
	if (reverse) formattedDate = `${day}/${month}/${year}`;
	else formattedDate = `${year}-${month}-${day}`;
	return formattedDate;
};

const fetchPeriodsReport = async () => {
	loading.value = true;
	try {
		emotion_cooperation_result_by_day.value = [];
		emotion_cooperation_result_by_day_emotion.value = [];
		emotion_cooperation_result_by_day_rating.value = [];
		emotion_cooperation_result_by_day_result.value = [];
		emotion_cooperation_result_by_day_labels.value = [];
		emotion_cooperation_result_by_day_data.value = [
			{
				name: 'Kết quả buổi học',
				type: 'line',
				data: [0, 0, 0, 0, 0],
			},
			{
				name: 'Sự tham gia',
				type: 'column',
				data: [0, 0, 0, 0, 0],
			},
			{
				name: 'Cảm xúc',
				type: 'column',
				data: [0, 0, 0, 0, 0],
			},
		];
		const res = await $api.monthlyReport.periodReports(
			route.params.id,
			route.query.start_date,
			route.query.end_date
		);
		if (res) {
			data.value = res.data;
			statistics.value = res.data.data;
			if (
				statistics.value?.goal_count === 0 &&
				data.value?.stats?.attendance === 0
			) {
				empty.value = true;
				loading.value = false;
				return;
			}
			emotion_cooperation_result_by_day.value =
				res.data.stats?.emotion_cooperation_result_by_day;
			assessment_results.value = res.data.skills;
			let a = [];
			let count = 0;
			result.value = res.data.stats?.result;
			result.value.forEach((item) => {
				if (
					item.result === 'Từ chối' ||
					item.result === 'Không có cơ hội' ||
					item.result === 'Không hợp tác'
				) {
					count += item.count;
				} else {
					a.push(item);
				}
			});
			a.push({
				result: 'X',
				count: count,
			});
			series.value = a.map((item) => {
				return item.count;
			});
			let series3Total = 0;
			res.data.stats?.emotion_scale.forEach((item) => {
				return (series3Total += item.count);
			});
			series3.value = res.data.stats?.emotion_scale.map((item) => {
				const r = (item.count * 100) / series3Total;
				return Math.round(r * 10) / 10 || 0;
			});
			emotion_cooperation_result_by_day.value.forEach((item) => {
				emotion_cooperation_result_by_day_emotion.value.push(
					+(+item.emotion_scale / 5) * 100 || null
				);
				emotion_cooperation_result_by_day_rating.value.push(
					+(+item.cooperation_scale / 5) * 100 || null
				);
				emotion_cooperation_result_by_day_result.value.push(
					+item.complete_percent || null
				);
				emotion_cooperation_result_by_day_labels.value.push(
					_GET_DATE_2(item.date, true)
				);
			});
			emotion_cooperation_result_by_day_data.value[1] = {
				name: 'Cảm xúc',
				type: 'column',
				data: emotion_cooperation_result_by_day_emotion.value,
			};
			emotion_cooperation_result_by_day_data.value[0] = {
				name: 'Sự tham gia',
				type: 'column',
				data: emotion_cooperation_result_by_day_rating.value,
			};
			emotion_cooperation_result_by_day_data.value[2] = {
				name: 'Kết quả buổi học',
				type: 'line',
				data: emotion_cooperation_result_by_day_result.value,
			};
			const optimalColumnWidthPercent =
				5 +
				15 /
					(1 +
						30 *
							Math.exp(
								-emotion_cooperation_result_by_day_labels.value
									?.length / 3
							));

			setTimeout(() => {
				mixChart.value?.updateOptions({
					labels: emotion_cooperation_result_by_day_labels.value,
					plotOptions: {
						bar: {
							columnWidth: optimalColumnWidthPercent + '%',
						},
					},
				});
			}, 1000);
			if (data.value.skills.length) {
				radarData.value = [
					{
						name: 'Kết quả',
						data: data.value.skills.map((skill) => {
							return skill?.skill?.skill_percent;
						}),
					},
				];
				radarChartOptions.value.xaxis.categories =
					data.value.skills.map((skill) => {
						return skill?.skill?.name;
					});
				categories.value = data.value.skills.map((skill) => {
					return skill?.skill?.name;
				});
			}
		}
	} catch (e) {
		console.log(e);
	} finally {
		loading.value = false;
	}
};

fetchPeriodsReport();
</script>
<template>
	<div>
		<div class="relative mt-12">
			<div aria-hidden="true" class="absolute inset-0 flex items-center">
				<div class="w-full border-t border-neutral-300" />
			</div>
			<div class="relative flex items-center justify-center">
				<div class="px-2.5 py-1 bg-body text-neutral-600 font-semibold">
					Thống kê chung
				</div>
			</div>
		</div>
		<div class="mt-10">
			<div class="p-6 bg-white shadow-sm rounded-2xl">
				<div class="w-full flex items-center justify-between mb-6">
					<div class="font-semibold text-neutral-600">
						Thống kê chung
					</div>
					<div></div>
				</div>
				<div class="flex flex-nowrap w-full md-down:flex-col">
					<div
						class="
							p-6
							border
							rounded-l-lg
							border-neutral-200
							w-full
							md-down:rounded-t md-down:rounded-b-none
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/abc.png"
								style="height: 32px"
								width="32"
							/>
							{{
								Number(statistics?.skill_count || 0) +
								'/' +
								Number(data?.stats?.total_skill_count || 0)
							}}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Lĩnh vực đã học
							</span>
						</div>
					</div>
					<div
						class="
							p-6
							border-t border-b border-neutral-200
							w-full
							md-down:border-l md-down:border-r md-down:border-b-0
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/light.png"
								style="height: 32px"
								width="32"
							/>
							{{
								Number(statistics?.goal_count || 0) +
								'/' +
								Number(data?.stats?.total_goal_count || 0)
							}}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Mục tiêu đã học
							</span>
						</div>
					</div>
					<div
						class="
							p-6
							border
							rounded-r-lg
							border-neutral-200
							w-full
							md-down:rounded-b md-down:rounded-t-none
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/light.png"
								style="height: 32px"
								width="32"
							/>
							{{ statistics?.completed || 0 }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Mục tiêu đã hoàn thành
							</span>
						</div>
					</div>
					<div
						v-if="false"
						class="
							p-6
							border-l border-t border-b border-neutral-200
							w-full
							md-down:border-r md-down:border-b-0
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/light.png"
								style="height: 32px"
								width="32"
							/>
							{{ statistics?.continued || 0 }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Mục tiêu sẽ tiếp tục
							</span>
						</div>
					</div>
					<div
						v-if="false"
						class="
							p-6
							border
							rounded-r-lg
							border-neutral-200
							w-full
							md-down:rounded-b md-down:rounded-t-none
						"
					>
						<div
							class="
								text-33
								font-bold
								text-neutral-600
								mb-6
								flex flex-nowrap
								items-center
							"
						>
							<img
								alt=""
								class="mr-2"
								height="32"
								src="@/assets/images/icons/light.png"
								style="height: 32px"
								width="32"
							/>
							{{ statistics?.stopped || 0 }}
						</div>
						<div class="flex flex-nowrap items-center">
							<span class="text-neutral-500 text-sm"
								>Mục tiêu sẽ tạm dừng</span
							>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="series3"
			class="mt-6 flex items-stretch gap-6 xl-down:flex-col"
		>
			<div
				class="
					bg-white
					shadow-sm
					rounded-2xl
					overflow-hidden
					w-1/3
					xl-down:w-full
				"
			>
				<div class="font-semibold text-neutral-500 p-6">
					Biều đồ cảm xúc
				</div>
				<div
					class="
						border-t border-orange-50
						flex
						justify-center
						items-center
						p-6
						flex-col
					"
				>
					<div
						class="
							relative
							w-full
							md-down:w-full
							flex
							items-center
							justify-center
						"
					>
						<apexchart
							:options="chartOptions3"
							:series="series3"
							height="220"
							type="donut"
						></apexchart>
						<div
							class="
								text-center
								absolute
								top-1/2
								left-1/2
								transform
							"
							style="transform: translate(-50%, -50%)"
						>
							<div class="text-neutral-500">Cảm xúc</div>
						</div>
					</div>
					<div class="w-full">
						<div
							v-if="series3[0] > 0"
							class="
								flex flex-nowrap
								items-center
								justify-between
								w-full
							"
						>
							<div class="flex flex-nowrap items-center">
								<img
									alt=""
									height="24"
									src="@/assets/images/emoji/happy.png"
									width="24"
								/>
								<div
									class="h-2.5 w-2.5 rounded-full ml-2"
									style="background: #9edf93"
								></div>
								<div class="ml-2 text-base text-neutral-500">
									Vui vẻ
								</div>
							</div>
							<div class="text-base text-neutral-600 font-bold">
								{{ series3[0] }}
								%
							</div>
						</div>
						<div
							v-if="series3[1] > 0"
							class="
								flex flex-nowrap
								items-center
								justify-between
								w-full
								mt-2.5
							"
						>
							<div class="flex flex-nowrap items-center">
								<img
									alt=""
									height="24"
									src="@/assets/images/emoji/5565d66a50b44df9ae37a955bdfb3757.png"
									width="24"
								/>
								<div
									class="h-2.5 w-2.5 rounded-full ml-2"
									style="background: #d0db54"
								></div>
								<div class="ml-2 text-base text-neutral-500">
									Bình thường
								</div>
							</div>
							<div class="text-base text-neutral-600 font-bold">
								{{ series3[1] }}
								%
							</div>
						</div>
						<div
							v-if="series3[2] > 0"
							class="
								flex flex-nowrap
								items-center
								justify-between
								w-full
								mt-2.5
							"
						>
							<div class="flex flex-nowrap items-center">
								<img
									alt=""
									height="24"
									src="@/assets/images/emoji/cf1f643bc1244f5e8e37767bc946d607.png"
									width="24"
								/>
								<div
									class="h-2.5 w-2.5 rounded-full ml-2"
									style="background: #ffe68c"
								></div>
								<div class="ml-2 text-base text-neutral-500">
									Khó chịu
								</div>
							</div>
							<div class="text-base text-neutral-600 font-bold">
								{{ series3[2] }}
								%
							</div>
						</div>
						<div
							v-if="series3[3] > 0"
							class="
								flex flex-nowrap
								items-center
								justify-between
								w-full
								mt-2.5
							"
						>
							<div class="flex flex-nowrap items-center">
								<img
									alt=""
									height="24"
									src="@/assets/images/emoji/5ede936757cd4fbdb6247bad22bf454d.png"
									width="24"
								/>
								<div
									class="h-2.5 w-2.5 rounded-full ml-2"
									style="background: #ff9d6d"
								></div>
								<div class="ml-2 text-base text-neutral-500">
									Tức giận
								</div>
							</div>
							<div class="text-base text-neutral-600 font-bold">
								{{ series3[3] }}
								%
							</div>
						</div>
						<div
							v-if="series3[4] > 0"
							class="
								flex flex-nowrap
								items-center
								justify-between
								w-full
								mt-2.5
							"
						>
							<div class="flex flex-nowrap items-center">
								<img
									alt=""
									height="24"
									src="@/assets/images/emoji/0b356bb507d64b9489e2fbced131f795.png"
									width="24"
								/>
								<div
									class="h-2.5 w-2.5 rounded-full ml-2"
									style="background: #ff6967"
								></div>
								<div class="ml-2 text-base text-neutral-500">
									Bùng nổ
								</div>
							</div>
							<div class="text-base text-neutral-600 font-bold">
								{{ series3[4] }}
								%
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				class="
					bg-white
					shadow-sm
					rounded-2xl
					overflow-hidden
					w-1/3
					xl-down:w-full xl-down:mt-6
				"
			>
				<div class="font-semibold text-neutral-500 p-6">
					Biểu đồ kết quả các mục tiêu
				</div>
				<div
					class="
						flex flex-nowrap
						p-6
						border-t border-orange-50
						items-center
						justify-center
						flex-col
					"
				>
					<div class="relative w-fit md-down:w-full">
						<apexchart
							:options="chartOptions"
							:series="series"
							height="220"
							type="donut"
						></apexchart>
						<div
							class="
								text-center
								absolute
								top-1/2
								left-1/2
								transform
							"
							style="transform: translate(-50%, -50%)"
						>
							<div class="text-4xl font-bold text-neutral-600">
								{{ statistics?.goal_count }}
							</div>
							<div class="text-neutral-500">Mục tiêu</div>
						</div>
					</div>
					<div
						class="flex flex-col w-full justify-center md-down:mt-4"
					>
						<div
							v-if="result[0]?.count > 0"
							class="
								flex flex-nowrap
								items-center
								mb-4
								justify-between
							"
						>
							<div
								class="
									text-sm
									font-semibold
									text-neutral-500
									w-14
									blue
								"
							>
								{{ result[0]?.count }}
								M.T
							</div>
							<span
								class="
									flex
									items-center
									justify-center
									py-0.5
									rounded-full
									text-sm
									font-medium
									text-white
									font-semibold
									whitespace-nowrap
									w-32
									shadow-input
								"
								style="background: #2aa797"
							>
								{{ result[0]?.result }}
							</span>
						</div>
						<div
							v-if="result[1]?.count > 0"
							class="
								flex flex-nowrap
								items-center
								mb-4
								justify-between
							"
						>
							<div
								class="
									text-sm
									font-semibold
									text-neutral-500
									w-14
									font-semibold
								"
							>
								{{ result[1]?.count }}
								M.T
							</div>
							<span
								class="
									flex
									items-center
									justify-center
									py-0.5
									rounded-full
									text-sm
									font-medium
									text-white
									font-semibold
									w-32
									whitespace-nowrap
									shadow-input
								"
								style="background: #65a30d"
							>
								{{ result[1]?.result }}
							</span>
						</div>
						<div
							v-if="result[2]?.count > 0"
							class="
								flex flex-nowrap
								items-center
								mb-4
								justify-between
							"
						>
							<div
								class="
									text-sm
									font-semibold
									text-neutral-500
									w-14
								"
							>
								{{ result[2]?.count }}
								M.T
							</div>
							<span
								class="
									flex
									items-center
									justify-center
									py-0.5
									rounded-full
									text-sm
									font-medium
									text-white
									font-semibold
									w-32
									whitespace-nowrap
									shadow-input
								"
								style="background: #f59e0b"
							>
								{{ result[2]?.result }}
							</span>
						</div>
						<div
							v-if="result[3]?.count > 0"
							class="
								flex flex-nowrap
								items-center
								mb-4
								justify-between
							"
						>
							<div
								class="
									text-sm
									font-semibold
									text-neutral-500
									w-14
									blue
								"
							>
								{{ result[3]?.count }}
								M.T
							</div>
							<span
								class="
									flex
									items-center
									justify-center
									px-3
									py-0.5
									rounded-full
									text-sm
									font-medium
									text-white
									font-semibold
									w-32
									whitespace-nowrap
									shadow-input
								"
								style="background: #f87171"
							>
								{{ result[3]?.result }}
							</span>
						</div>
						<div
							v-if="
								result[4]?.count > 0 ||
								result[5]?.count > 0 ||
								result[6]?.count > 0
							"
							class="
								flex flex-nowrap
								items-center
								mb-4
								justify-between
							"
						>
							<div
								class="
									text-sm
									font-semibold
									text-neutral-500
									w-14
									blue
								"
							>
								{{
									result[4]?.count +
									result[5]?.count +
									result[6]?.count
								}}
								M.T
							</div>
							<span
								class="
									flex
									items-center
									justify-center
									px-3
									py-0.5
									rounded-full
									text-sm
									font-medium
									text-white
									font-semibold
									w-32
									whitespace-nowrap
									shadow-input
								"
								style="background: #9ca3af"
							>
								X
							</span>
						</div>
					</div>
				</div>
			</div>
			<div
				class="
					bg-white
					shadow-sm
					rounded-2xl
					overflow-hidden
					w-1/3
					xl-down:w-full xl-down:mt-6
				"
			>
				<div class="font-semibold text-neutral-500 p-6">Chuyên cần</div>
				<div
					class="
						border-t border-orange-50
						p-6
						flex
						items-center
						justify-center
						flex-col
					"
				>
					<div
						class="text-neutral-600 font-semibold mb-5"
						style="font-size: 72px"
					>
						{{ data?.stats?.attendance }}
					</div>
					<div class="text-neutral-500 mb-5 text-center">
						Số ngày học
					</div>
				</div>
			</div>
		</div>
		<div class="mt-6">
			<div
				class="
					bg-white
					shadow-sm
					rounded-2xl
					overflow-hidden
					mb-10
					pb-5
				"
			>
				<div class="font-semibold text-neutral-500 p-6">
					Mối tương quan giữa kết quả, cảm xúc và sự tham gia của trẻ
					từ {{ formatDate(startDate, true) }} tới
					{{ formatDate(endDate, true) }}
				</div>
				<div
					class="
						p-6
						border-t border-orange-50
						md-down:px-0
						overflow-x-auto
						w-full
					"
				>
					<apexchart
						ref="mixChart"
						:options="mixChartOptions"
						:series="emotion_cooperation_result_by_day_data"
						:style="{
							minWidth: `${
								emotion_cooperation_result_by_day_emotion?.length *
								150
							}px`,
						}"
						height="600"
						style="width: 100%"
						width="100%"
					></apexchart>
				</div>
			</div>
		</div>
		<div
			v-if="radarData.length && radarData[0]?.data.length >= 3"
			class="mt-12"
		>
			<div
				class="
					bg-white
					shadow-sm
					overflow-hidden
					rounded-2xl
					mb-10
					pb-5
				"
			>
				<div class="font-semibold text-neutral-500 p-6">
					Biểu đồ mối tương quan giữa kết quả các lĩnh vực từ
					{{ formatDate(startDate, true) }} tới
					{{ formatDate(endDate, true) }}
				</div>
				<div
					class="
						p-6
						border-t border-orange-50
						md-down:px-0
						overflow-x-auto
						w-full
					"
				>
					<apexchart
						:options="radarChartOptions"
						:series="radarData"
						height="700"
						type="radar"
					></apexchart>
					<div
						class="
							py-4
							flex flex-wrap
							gap-6
							justify-center
							items-center
						"
					>
						<div
							v-for="(c, index) in categories"
							:key="c"
							class="flex items-center flex-nowrap gap-2"
						>
							<div class="font-bold text-lf text-neutral-700">
								{{ index + 1 }}: {{ c }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="relative mt-10">
			<div aria-hidden="true" class="absolute inset-0 flex items-center">
				<div class="w-full border-t border-neutral-300" />
			</div>
			<div class="relative flex items-center justify-center">
				<div class="px-2.5 py-1 bg-body text-neutral-600 font-semibold">
					Kết quả mục tiêu đã học
				</div>
			</div>
		</div>
		<div
			v-for="(s, index) in assessment_results"
			:key="index"
			class="mt-10"
		>
			<div class="font-semibold text-lg text-neutral-600 mb-2">
				{{ index + 1 }}. {{ s.skill?.name }}
			</div>
			<div
				class="shadow-sm sm:rounded-2xl overflow-hidden md-down:hidden"
			>
				<div class="__record__table overflow-x-auto scroll-bar">
					<div
						class="
							overflow-hidden
							sm:rounded-t-2xl
							w-fit
							min-w-full
						"
					>
						<table class="min-w-full divide-y text-neutral-200">
							<thead class="bg-neutral-100 h-16">
								<tr>
									<th
										class="
											px-4
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
											w-16
										"
										scope="col"
									>
										STT
									</th>
									<th
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											w-8/12
											whitespace-nowrap
										"
										scope="col"
										style="width: 350px"
									>
										Mục Tiêu
									</th>
									<th
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
										scope="col"
										style="width: 170px"
									>
										Kết quả
									</th>
									<th
										class="
											px-6
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
										scope="col"
										style="width: 100px"
									>
										ALBUM
									</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(i, gIndex) in s.goals"
									:key="gIndex"
									:class="
										gIndex % 2 === 0
											? 'bg-white'
											: 'bg-neutral-50'
									"
									class="
										cursor-pointer
										text-neutral-700
										hover:bg-orange-50
									"
								>
									<td
										class="
											p-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div class="ml-4">
											{{ gIndex + 1 }}
										</div>
									</td>
									<td
										class="
											px-6
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div>
											<div class="whitespace-normal">
												{{ i.name || i.goal_name }}
											</div>
										</div>
									</td>
									<td
										class="
											px-6
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div>
											<div
												v-if="i?.complete_percent"
												:style="{
													background:
														$filters.tagResult(
															i?.complete_percent
														)?.background,
													color: $filters.tagResult(
														i?.complete_percent
													)?.color,
												}"
												class="
													mt-1
													py-0.5
													px-2.5
													rounded-full
													text-neutral-700
													h-fit
													w-fit
													font-semibold
													text-sm
													shadow-input
												"
											>
												Đạt
												{{ i?.complete_percent || 0 }}%
											</div>
										</div>
									</td>
									<td
										class="
											px-6
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div>
											<svg
												v-if="i?.has_goal_media"
												fill="none"
												height="20"
												viewBox="0 0 20 20"
												width="20"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													clip-rule="evenodd"
													d="M15.002 5H2.50195C1.8116 5 1.25195 5.55964 1.25195 6.25V16.25L4.56001 13.3081C4.77068 13.0974 5.10075 13.0647 5.34864 13.23L8.67232 15.4458L13.31 10.8081C13.5002 10.6179 13.7908 10.5707 14.0315 10.691L16.252 13.125V6.25C16.252 5.55964 15.6923 5 15.002 5ZM2.50195 3.75C1.12124 3.75 0.00195312 4.86929 0.00195312 6.25V16.25C0.00195312 17.6307 1.12124 18.75 2.50195 18.75H15.002C16.3827 18.75 17.502 17.6307 17.502 16.25V6.25C17.502 4.86929 16.3827 3.75 15.002 3.75H2.50195ZM7.50195 9.375C7.50195 10.4105 6.66249 11.25 5.62695 11.25C4.59142 11.25 3.75195 10.4105 3.75195 9.375C3.75195 8.33947 4.59142 7.5 5.62695 7.5C6.66249 7.5 7.50195 8.33947 7.50195 9.375Z"
													fill="#E5A42B"
													fill-rule="evenodd"
												/>
												<path
													clip-rule="evenodd"
													d="M4.5 2.25H18C18.5523 2.25 19 2.69772 19 3.25V14.25C19 14.8023 18.5523 15.25 18 15.25H17.5V16.25H18C19.1046 16.25 20 15.3546 20 14.25V3.25C20 2.14543 19.1046 1.25 18 1.25H4.5C3.39543 1.25 2.5 2.14543 2.5 3.25V3.75H3.5V3.25C3.5 2.69772 3.94772 2.25 4.5 2.25Z"
													fill="#E5A42B"
													fill-rule="evenodd"
												/>
											</svg>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
@import '../../../../../assets/styles/responsive';

.is-MT-show {
	width: 100vw;
	transform: translateX(0);
	transition: all 0.4s ease;

	@include media-breakpoint-up(lg) {
		width: 400px;
	}
}

.is-MT-hide {
	transform: translateX(450px);
	transition: all 0.4s ease;
}

.transition {
	transition: all 0.4s ease;
}

.content-report-is-show {
	@include media-breakpoint-up(lg) {
		padding-right: 448px;
	}
}

.content-report-is-hide {
	@include media-breakpoint-up(lg) {
		padding-right: 40px;
	}
}
</style>
