<template>
	<div class="_create-boarding _modal bg-white">
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<img
					src="@/assets/images/illustrations/success.png"
					alt="Luca Education"
					title="Luca Education"
					height="60"
					width="60"
					v-if="success"
				/>
				<img
					src="@/assets/images/illustrations/warning.png"
					alt="Luca Education"
					title="Luca Education"
					height="60"
					width="60"
					v-else
				/>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-700
					font-semibold
					text-2xl
					mt-5
					text-center
				"
			>
				{{
					success
						? 'Xoá ngày nghỉ thành công'
						: 'Bạn có muốn xoá ngày nghỉ này?'
				}}
			</div>
			<div class="text-center text-neutral-500 mt-2">
				{{
					success
						? 'Ngày nghỉ đã được xoá khỏi dữ liệu của cơ sở'
						: 'Ngày nghỉ sẽ được xoá khỏi dữ liệu của cơ sở'
				}}
			</div>
			<template v-if="!success">
				<div class="w-full mt-5">
					<div class="input-group flex flex-nowrap items-center">
						<div
							class="
								input-group__label
								text-neutral-500
								font-normal
								w-2/5
								uppercase
							"
						>
							Tên ngày nghỉ
						</div>
						<div class="font-bold text-neutral-500 text-right">
							{{ data.title }}
						</div>
					</div>
				</div>
				<div class="w-full mt-5">
					<div class="input-group flex flex-nowrap items-center">
						<div
							class="
								input-group__label
								text-neutral-500
								font-normal
								w-2/5
								uppercase
							"
						>
							ngày bắt đầu
						</div>
						<div class="font-bold text-neutral-500 text-right">
							<span v-if="data.start_date">
								{{ $filters.formatDate2(data.start_date) }}
							</span>
							<span v-else>-</span>
						</div>
					</div>
				</div>
				<div class="w-full mt-5">
					<div class="input-group flex flex-nowrap items-center">
						<div
							class="
								input-group__label
								text-neutral-500
								font-normal
								w-2/5
								uppercase
							"
						>
							Ngày kết thúc
						</div>
						<div class="font-bold text-neutral-500 text-right">
							<span v-if="data.end_date">
								{{ $filters.formatDate2(data.end_date) }}
							</span>
							<span v-else>-</span>
						</div>
					</div>
				</div>
				<div class="w-full mt-5">
					<div class="input-group flex flex-nowrap items-center">
						<div
							class="
								input-group__label
								text-neutral-500
								font-normal
								w-2/5
								uppercase
							"
						>
							Tổng số ngày nghỉ
						</div>
						<div class="font-bold text-neutral-500 text-right">
							<span v-if="data.end_date && data.start_date">
								{{
									$filters.aFromB(
										data.start_date,
										data.end_date
									)
								}}
							</span>
							<span v-else>-</span>
						</div>
					</div>
				</div>
			</template>
		</div>
		<div class="px-12 mb-5 text-sienna-600 text-sm" v-if="error">
			{{ error }}
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-700
				"
				@click="close()"
			>
				{{ success ? 'Đóng' : 'Thoát' }}
			</button>
			<button
				type="button"
				class="button-p text-center whitespace-nowrap"
				v-if="!success"
				@click="submit"
			>
				Xác nhận
			</button>
		</div>
	</div>
</template>

<script>
import { $api } from '@/services';

export default {
	name: 'DeleteHoliday',

	props: {
		data: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			success: false,
			error: '',
		};
	},

	methods: {
		async submit() {
			try {
				await $api.schedule.deleteHoliday(this.data.id);
				await this.$store.dispatch('setAlertMessage', {
					message: 'Xoá ngày nghỉ thành công',
				});
				this.success = true;
			} catch (e) {
				this.error =
					e?.response?.data?.errors ||
					'Đã có lỗi xảy ra. Vui lòng thử lại';
			}
		},

		close() {
			if (this.success) {
				this.$emit('success');
				this.$emit('close');
			} else {
				this.$emit('close');
			}
		},
	},
};
</script>
