
import { Vue, Options } from 'vue-class-component';
import { Switch } from '@headlessui/vue';
import { PropSync } from 'vue-property-decorator';

@Options({
	name: 'SwitchButton',
	components: {
		Switch,
	},
})
export default class SwitchButton extends Vue {
	//----------------------- 🤍 Props 🤍 -----------------------//
	@PropSync('value', { type: Boolean }) valueData!: boolean;
}
