<script setup>
import SwitchButton from '@/components/elements/switchButton/SwitchButton.vue';
import { defineEmits, defineProps, onMounted, ref, watch } from 'vue';
import { $api } from '@/services';
import { useStore } from 'vuex';

const props = defineProps(['goalChanged']);
const emits = defineEmits(['success']);
const store = useStore();
const disable = ref(true);

const state = ref(false);
watch(state, () => {
	disable.value = false;
});

const exit = () => {
	emits('success');
};

const submit = async (data) => {
	try {
		await $api.goal.updateGoalLibrary(props.goalChanged.id, {
			paid_mode: state.value,
		});
		store.dispatch('setAlertMessage', {
			message: 'Chỉnh sửa thư viện tham khảo thành công',
		});
		exit();
	} catch (e) {
		console.log(e);
	}
};

onMounted(() => {
	if (props.goalChanged.paid_mode == true) state.value = true;
});
</script>
<template>
	<div class="fixed inset-y-0 right-0 flex side-over z-50 w-screen md:w-2/5">
		<div class="h-full w-full flex flex-col bg-white shadow-xl">
			<div class="px-6 py-8 bg-blue-50">
				<div class="flex items-start justify-between">
					<div class="flex items-center gap-2">
						<img
							alt=""
							class="w-6 h-6"
							src="@/assets/images/icons/pen.png"
						/>
						<div class="text-2xl font-bold text-neutral-700">
							Quyền truy cập
						</div>
					</div>
					<div class="ml-3 h-7 flex items-center">
						<button
							class="
								rounded-md
								text-neutral-400
								hover:text-neutral-700
							"
							type="button"
							@click="exit"
						>
							<span class="sr-only">Close panel</span>
							<svg
								aria-hidden="true"
								class="h-6 w-6"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M6 18L18 6M6 6l12 12"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
								/>
							</svg>
						</button>
					</div>
				</div>
			</div>
			<div
				class="
					sideover-content
					pt-6
					relative
					flex-1
					px-4
					sm:px-6
					overflow-y-auto
				"
			>
				<div>
					<div class="input-group flex items-center gap-10">
						<div class="text-neutral-700 font-semibold">
							Chế độ trả phí
						</div>
						<SwitchButton v-model:value="state" />
					</div>
				</div>
			</div>
			<div class="px-6 py-4 border-t border-yellow-50">
				<div class="flex justify-end">
					<div>
						<button
							:disabled="disable"
							class="button-p text-center w-32"
							type="button"
							@click="submit()"
						>
							Xác nhận
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
