<template>
	<div
		v-if="
			Object.keys(staffs_map).length > 0 ||
			Object.keys(students_map).length > 0
		"
		class="pt-10 md-down:pt-4 pr-6 w-full -mb-40"
	>
		<div>
			<div v-for="index in 19" :key="index" class="py-6">
				<div class="relative mb-4">
					<div
						class="absolute inset-0 flex items-center"
						aria-hidden="true"
					>
						<div
							class="w-full border-gray-300 border-dashed"
							:class="{ 'border-t': index < 19 }"
						/>
					</div>
					<div class="relative flex justify-start">
						<span
							class="pr-2 bg-body text-sm text-gray-500"
							v-if="index < 19"
						>
							{{ index + 6 }}:00
						</span>
					</div>
				</div>
				<div class="container flex flex-wrap gap-3">
					<div
						@click="$emit('selectedStudent', item)"
						v-for="(item, i) in getSlotByStart(index)"
						:key="i"
						class="md-down:w-full w-fit p-3 rounded-xl border-2"
						:class="
							studentSelected &&
							studentSelected.student_id === item.student_id
								? 'bg-yellow-50 border-yellow-200'
								: item.state === 'cancelled'
								? 'bg-neutral-200 border-neutral-300'
								: item.overlapped
								? 'bg-sienna-50 border-sienna-200'
								: 'bg-white border-neutral-200'
						"
					>
						<div class="flex items-center justify-between">
							<div class="mr-4">
								<div v-if="item.staff_ids">
									<div
										class="flex items-center"
										v-for="staff_id in item.staff_ids"
										:key="staff_id"
									>
										<div>
											<img
												class="
													shadow-sm
													inline-block
													h-6
													w-6
													rounded-full
												"
												:src="
													getStaff(staff_id)
														?.profile_photo?.w200
												"
												alt=""
												v-if="
													getStaff(staff_id)
														?.profile_photo?.w200
												"
											/>
											<span
												v-else
												class="
													inline-flex
													items-center
													justify-center
													h-6
													w-6
													rounded-full
												"
												:style="{
													background:
														getStaff(staff_id)
															?.profile_photo
															?.default?.color,
												}"
											>
												<span
													class="
														font-medium
														leading-none
														text-white text-ls
													"
													>{{
														getStaff(staff_id)
															?.profile_photo
															?.default?.initial
													}}</span
												>
											</span>
										</div>
									</div>
								</div>
							</div>
							<div
								class="
									h-full
									flex
									justify-between
									flex-col
									border-l
									pl-20
									md:pl-4 md:ml-auto
								"
								:class="
									studentSelected &&
									studentSelected.student_id ===
										item.student_id
										? 'border-yellow-200'
										: item.state === 'cancelled'
										? 'bg-neutral-200 border-neutral-300'
										: item.overlapped
										? 'border-sienna-200'
										: 'border-neutral-300'
								"
							>
								<div
									class="
										flex
										items-center
										gap-2
										font-semibold
										text-indigo-600
									"
								>
									<span
										>{{ item.display_start_slot }} -
										{{ item.display_end_slot }}</span
									>
								</div>
							</div>
						</div>
						<div
							class="flex items-center border-t mt-2 pt-2"
							:class="
								studentSelected &&
								studentSelected.student_id === item.student_id
									? 'border-yellow-200'
									: item.state === 'cancelled'
									? 'bg-neutral-200 border-neutral-300'
									: item.overlapped
									? 'border-sienna-200'
									: 'border-neutral-300'
							"
						>
							<div v-if="item.student_id">
								<img
									class="
										shadow-sm
										inline-block
										h-6
										w-6
										rounded-full
									"
									:src="
										getStudent(item.student_id)
											?.profile_photo?.w200
									"
									alt=""
									v-if="
										getStudent(item.student_id)
											?.profile_photo?.w200
									"
								/>
								<span
									v-else
									class="
										inline-flex
										items-center
										justify-center
										h-6
										w-6
										rounded-full
									"
									:style="{
										background: getStudent(item.student_id)
											?.profile_photo?.default?.color,
									}"
								>
									<span
										class="
											font-medium
											leading-none
											text-white text-ls
										"
										>{{
											getStudent(item.student_id)
												?.profile_photo?.default
												?.initial
										}}</span
									>
								</span>
							</div>
							<div
								v-if="item.student_id"
								class="ml-2 font-semibold text-neutral-600"
							>
								{{ getStudent(item.student_id).name }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else class="w-full h-full flex items-center flex-col mt-20">
		<img
			src="@/assets/images/illustrations/not_found.png"
			alt=""
			height="250"
			width="250"
		/>
		<div class="text-neutral-500 text-lg max-w-lg text-center">
			Rất tiếc, chúng tôi không thể tìm thấy kết quả cho tìm kiếm của bạn. aa
		</div>
	</div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'TimeSlotClass',

	props: {
		data: {
			type: Array,
			required: true,
		},
		staffs_map: {
			type: Object,
			required: true,
		},
		students_map: {
			type: Object,
			required: true,
		},
		studentSelected: {
			type: Object,
			required: true,
		},
	},

	methods: {
		getSlotByStart(index) {
			return this.data.filter(
				(item) =>
					item.start_slot >= (index + 6) * 60 &&
					item.start_slot < (index + 7) * 60
			);
		},
		getStaff(staff_id) {
			return this.staffs_map[staff_id]?.[0];
		},
		getStudent(student_id) {
			return this.students_map[student_id]?.[0];
		},
	},
});
</script>
