
import { computed, defineComponent, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { $api } from '@/services';
import { useStore } from 'vuex';
import { goalLibByStudent, useStudent } from '@/features/student/useStudent';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import DeleteConfirm from '@/components/modules/reportTargetFuture/DeleteConfirm.vue';
import EditGoalSideOver from '@/components/elements/editGoalSideOver/EditGoalSideOver.vue';
import TooltipGoalDetail from '@/components/elements/tooltipGoalDetail/TooltipGoalDetail.vue';

export default defineComponent({
	name: 'PlanFutureSlideOver',
	components: {
		TooltipGoalDetail,
		EditGoalSideOver,
		DeleteConfirm,
		DaModalFrame,
	},
	props: {
		isCompleted: {
			type: Boolean,
			default: false,
		},
		isUpdated: {
			type: Boolean,
			default: false,
		},
	},
	setup(props: any, { emit }: any) {
		const route = useRoute();
		const store = useStore();

		const id = computed(() =>
			route.params?.id ? route.params?.id + '' : ''
		);

		//
		const data = ref(null as any);
		const goals = ref(null as any);
		const goals_count = ref(0);
		const skills_count = ref(0);
		const continued_goals_count = ref(0);
		const goals_from_lib_count = ref(0);
		const goals_from_stopped_count = ref(0);
		const new_goals_count = ref(0);

		async function fetchGoalsLib() {
			const res = await goalLibByStudent(id.value);
			if (res?.data) {
				goals.value = res?.data?.skills ?? [];

				if (res?.stats) {
					skills_count.value = res?.stats?.skills_count || 0;
					goals_count.value = res?.stats?.goals_count || 0;
				}
			}
		}

		const student_id = computed(() =>
			route.params?.id ? route.params?.id + '' : ''
		);

		async function deleteGoal(id: string) {
			try {
				await $api.goal.deleteGoalLib(id, student_id.value);
				await fetchGoalsLib();
				await store.dispatch('setAlertMessage', {
					message: 'Đã xoá mục tiêu thành công',
				});
				isSuccess.value = true;
				closeModal();
			} catch (e) {
				await store.dispatch('setAlertMessage', {
					message:
						e?.response?.data?.errors?.join('<br/>') ||
						'Đã có lỗi xảy ra. Vui lòng thử lại',
					type: 'danger',
				});
			}
		}

		watch(
			() => props.isUpdated,
			(value: boolean) => {
				if (value) {
					fetchGoalsLib();
					emit('updated');
				}
			}
		);

		//
		const { goalCompletedByStudent } = useStudent();

		const goals__completed = ref([]);
		const skills_count__completed = ref(0);
		const goals_count__completed = ref(0);

		//
		async function fetchGoals() {
			const res = await goalCompletedByStudent(id.value);
			if (res?.data) {
				goals__completed.value = res?.data.skills;
				skills_count__completed.value = goals__completed.value.length;
				if (goals__completed.value.length > 0) {
					goals__completed.value.forEach((item: any) => {
						goals_count__completed.value += item?.goals.length || 0;
					});
				}
			}
		}

		const modalIsOpen = ref(false);
		const idSelected = ref('');
		const isSuccess = ref(false);
		const isNoRepeat = ref(false);
		const isReteach = ref(false);
		const isNoRepeatReTeach = ref(false);
		const isEditModal = ref(false);

		function deleting(id: string, reteach = false) {
			if (reteach && isNoRepeatReTeach.value) {
				deleteGoal(id);
				closeModal();
				return;
			}
			if (!reteach && isNoRepeat.value) {
				deleteGoal(id);
				closeModal();
				return;
			}
			idSelected.value = id;
			modalIsOpen.value = true;
			isSuccess.value = false;
			isEditModal.value = false;
			isReteach.value = reteach;
		}

		let editData = ref(null as any);
		const tabActive = ref(null as any);

		async function editGoalModal(data: any) {
			console.log(data);
			editData.value = {
				id: data?.id || data?.goal_id,
				name: data?.name || data?.goal_name,
			};
			isEditModal.value = true;
			if (!data?.editable) {
				tabActive.value = 0;
			} else {
				tabActive.value = null;
			}
		}

		function closeModal() {
			modalIsOpen.value = false;
			idSelected.value = '';
			isSuccess.value = false;
			isEditModal.value = false;
		}

		function closeModelParent() {
			if (isReteach.value) {
				isNoRepeatReTeach.value = false;
			} else {
				isNoRepeat.value = false;
			}
			closeModal();
		}

		const errorEdit = ref('');

		async function editSubmit(data: any) {
			try {
				const res = await $api.goal.updateGoal(id.value, data.id, {
					goal: data,
				});
				if (res?.data) {
					closeModal();
					await fetchGoalsLib();
					await store.dispatch('setAlertMessage', {
						message: 'Chỉnh sửa mục tiêu thành công',
					});
					emit('re-fetch');
				}
			} catch (e) {
				errorEdit.value = e?.response?.data?.errors_by_field || '';
			}
		}

		const skills = ref([]);

		async function fetchSkill() {
			try {
				const res = await $api.goal.allSkills();
				if (res?.data) {
					skills.value = res?.data?.data;
				}
			} catch (e) {
				console.log(e);
			}
		}

		function openSubGoal(goal: any) {
			if (goal.sub_goal_count > 0) goal.open = !goal.open;
			else return;
		}

		fetchGoals();
		fetchGoalsLib();
		fetchSkill();

		return {
			id,
			isReteach,
			isNoRepeat,
			isNoRepeatReTeach,
			modalIsOpen,
			idSelected,
			isSuccess,
			errorEdit,
			editData,
			isEditModal,
			skills,
			deleting,
			closeModal,
			closeModelParent,
			editSubmit,
			editGoalModal,
			data,
			goals,
			goals_count,
			skills_count,
			continued_goals_count,
			goals_from_lib_count,
			goals_from_stopped_count,
			new_goals_count,
			goals__completed,
			skills_count__completed,
			goals_count__completed,
			stop,
			deleteGoal,
			tabActive,
			org: computed(() => store.state.profile.current_org),
			openSubGoal,
		};
	},
});
