<template>
	<div
		class="_create-boarding _modal bg-white w-full"
		style="width: 90vw; max-width: 800px"
	>
		<div
			class="
				_modal__content
				md-down:px-4
				px-12
				py-6
				flex flex-col
				items-center
				justify-center
			"
		>
			<div>
				<svg
					width="60"
					height="60"
					viewBox="0 0 60 60"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M29.8034 3.38292C15.2228 3.38292 3.36674 15.3238 3.36674 30.001C3.36674 44.6762 15.2228 56.6171 29.8034 56.6171C44.3839 56.6171 56.2452 44.6762 56.2452 30.001C56.2452 15.3238 44.3839 3.38292 29.8034 3.38292ZM29.8034 60C13.3737 60 0 46.5418 0 30.001C0 13.4582 13.3737 0 29.8034 0C46.2382 0 59.6068 13.4582 59.6068 30.001C59.6068 46.5418 46.2382 60 29.8034 60Z"
						fill="#E37056"
					/>
					<path
						d="M52.5551 30.0008C52.5551 42.6491 42.3697 52.9023 29.8011 52.9023C17.2376 52.9023 7.05225 42.6491 7.05225 30.0008C7.05225 17.3515 17.2376 7.09775 29.8011 7.09775C42.3697 7.09775 52.5551 17.3515 52.5551 30.0008Z"
						fill="#F4C870"
					/>
					<path
						d="M29.7887 36.6177C29.0367 36.6177 28.3899 36.8826 27.8685 37.408C27.3522 37.9313 27.0864 38.5748 27.0864 39.3218C27.0864 40.1721 27.3672 40.8489 27.9237 41.3324C28.4601 41.8028 29.0968 42.041 29.8187 42.041C30.5306 42.041 31.1624 41.7993 31.6938 41.3228C32.2453 40.8323 32.521 40.159 32.521 39.3218C32.521 38.5733 32.2503 37.9278 31.7188 37.4045C31.1874 36.8811 30.5357 36.6177 29.7887 36.6177Z"
						fill="white"
					/>
					<path
						d="M27.7131 31.5493V31.5609C27.8084 32.5698 27.9738 33.3102 28.2045 33.8234C28.4902 34.4387 29.0166 34.7637 29.7285 34.7637C30.4254 34.7637 30.9518 34.4336 31.2576 33.8083C31.5183 33.2748 31.6788 32.5481 31.754 31.5892L32.4107 24.025C32.4859 23.3164 32.521 22.6093 32.521 21.924C32.521 20.7021 32.3606 19.7816 32.0347 19.1083C31.784 18.5845 31.2075 17.9612 29.909 17.9612C29.0718 17.9612 28.3799 18.2463 27.8585 18.8065C27.3471 19.3602 27.0864 20.1253 27.0864 21.0806C27.0864 21.711 27.1316 22.7577 27.2268 24.1951L27.7131 31.5493Z"
						fill="white"
					/>
				</svg>
			</div>
			<div
				class="
					_modal__content__title
					text-neutral-600
					font-semibold
					text-2xl
					mt-5
					text-center
				"
			>
				Bạn có muốn tiếp tục đổi giờ học?
			</div>
			<div class="text-center text-neutral-500 mt-2">
				Giờ học vừa đổi bị trùng
			</div>
			<div class="w-full">
				<div
					class="
						shadow-sm
						sm:rounded-2xl
						overflow-hidden
						mt-10
						w-full
					"
					v-if="dataStaffs.length"
				>
					<div class="__record__table overflow-x-auto scroll-bar">
						<div class="overflow-hidden sm:rounded-t-2xl">
							<div class="divide-y text-neutral-200 w-full">
								<div
									class="
										bg-neutral-100
										flex
										items-center
										py-1
										w-full
									"
								>
									<div
										class="
											py-3
											text-sm
											px-6
											text-neutral-900
											tracking-wider
											whitespace-nowrap
											w-full
										"
									>
										Giáo viên bị trùng buổi dạy
									</div>
								</div>
								<div>
									<div
										v-for="(item, i) in [
											currentSession,
											...dataStaffs,
										]"
										:key="i"
										class="text-neutral-600"
									>
										<div
											class="
												flex
												border-t
												border-gray-100
												border-l-0
												border-r-0
												flex-col
												md:flex-row
												md-down:justify-start
												md-down:items-start
											"
											:class="{
												'border-t-0 bg-sienna-50':
													i === 0,
											}"
										>
											<div
												class="
													md-down:py-2
													px-6
													py-4
													whitespace-nowrap
													text-neutral-600
													flex
													md:w-4/12
												"
											>
												<div
													v-if="item?.group_class"
													class="
														flex
														items-center
														gap-2
													"
												>
													<svg
														width="24"
														height="24"
														viewBox="0 0 24 24"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<g
															clip-path="url(#clip0_22534_44051)"
														>
															<path
																fill-rule="evenodd"
																clip-rule="evenodd"
																d="M7.34111 8.43756C7.34111 9.90549 6.15158 11.1203 4.65835 11.1203C3.16511 11.1203 1.97559 9.88018 1.97559 8.41225C1.97559 6.94433 3.16511 5.72949 4.65835 5.72949C6.12627 5.72949 7.34111 6.96964 7.34111 8.43756ZM6.60714 11.3987C7.46765 11.7278 8.1763 12.3605 8.60656 13.1957C7.8979 13.7525 7.3158 14.4864 7.01209 15.3723C6.86023 15.8025 6.73369 16.2834 6.63245 16.739H0.634203C0.279876 16.739 -0.0238327 16.4352 0.00147638 16.0556C0.0520945 15.2457 0.229258 14.4358 0.507658 13.6766C0.887294 12.6136 1.69718 11.8037 2.70955 11.3987C3.26635 11.7784 3.94969 11.9809 4.65834 11.9809C5.367 11.9809 6.05034 11.7784 6.60714 11.3987ZM23.5136 13.6766C23.134 12.6136 22.3241 11.8037 21.3117 11.3987C20.7296 11.7784 20.0716 11.9809 19.3376 11.9809C18.6037 11.9809 17.9456 11.7784 17.3888 11.3987C16.5283 11.7278 15.8197 12.3605 15.3894 13.1957C16.0981 13.7525 16.6802 14.4864 16.9839 15.3723C17.1357 15.8278 17.2623 16.2834 17.3635 16.739H23.3618C23.7414 16.739 24.0451 16.4352 23.9945 16.0556C23.9692 15.2457 23.792 14.4358 23.5136 13.6766ZM22.0204 8.43756C22.0204 9.90549 20.8309 11.1203 19.3377 11.1203C17.8697 11.1203 16.6549 9.88018 16.6549 8.41225C16.6549 6.94433 17.8444 5.72949 19.3377 5.72949C20.8309 5.72949 22.0204 6.96964 22.0204 8.43756ZM13.9468 13.3729C13.39 13.7525 12.7066 13.955 11.998 13.955C11.2893 13.955 10.606 13.7525 10.0492 13.3729C9.03682 13.7778 8.20162 14.5877 7.82199 15.6507C7.54359 16.41 7.39173 17.2198 7.31581 18.0297C7.2905 18.4094 7.59421 18.7131 7.97384 18.7131H16.0221C16.4018 18.7131 16.7055 18.4094 16.6802 18.0297C16.6295 17.2198 16.4524 16.41 16.174 15.6507C15.7943 14.5877 14.9591 13.7778 13.9468 13.3729ZM14.6808 10.4117C14.6808 11.8796 13.4913 13.0945 11.9981 13.0945C10.5048 13.0945 9.31531 11.9049 9.31531 10.4117C9.31531 8.94378 10.5301 7.72894 11.9981 7.72894C13.466 7.72894 14.6808 8.91847 14.6808 10.4117Z"
																fill="#94A3B8"
															/>
														</g>
														<defs>
															<clipPath
																id="clip0_22534_44051"
															>
																<rect
																	width="24"
																	height="24"
																	fill="white"
																/>
															</clipPath>
														</defs>
													</svg>
													{{
														item?.group_class?.name
													}}
												</div>
												<div
													v-else
													class="flex items-center"
												>
													<div>
														<img
															class="
																shadow-sm
																inline-block
																h-6
																w-6
																rounded-full
															"
															:src="
																item?.student
																	?.profile_photo
																	?.w200
															"
															alt=""
															v-if="
																item?.student
																	?.profile_photo
																	?.w200
															"
														/>
														<span
															v-else
															class="
																inline-flex
																items-center
																justify-center
																h-6
																w-6
																rounded-full
															"
															:style="{
																background:
																	item
																		?.student
																		?.profile_photo
																		?.default
																		?.color,
															}"
														>
															<span
																class="
																	font-medium
																	leading-none
																	text-white
																	text-xs
																"
																>{{
																	item
																		?.student
																		?.profile_photo
																		?.default
																		?.initial
																}}</span
															>
														</span>
													</div>
													<div
														class="
															ml-4
															text-yellow-700
															font-semibold
															whitespace-pre-wrap
														"
													>
														{{ item?.student.name }}
													</div>
												</div>
											</div>
											<div
												class="
													flex flex-col
													gap-2
													md:w-3/12
													md-down:px-6 md-down:py-2
												"
											>
												<div
													@click.stop="
														$router.push(
															`/record/employee/${staff.id}`
														)
													"
													class="flex items-center"
													v-for="staff in item.staffs"
													:key="staff.id"
												>
													<div v-if="staff">
														<img
															class="
																shadow-sm
																inline-block
																h-6
																w-6
																rounded-full
															"
															:src="
																staff
																	?.profile_photo
																	?.w200
															"
															alt=""
															v-if="
																staff
																	?.profile_photo
																	?.w200
															"
														/>
														<span
															v-else
															class="
																inline-flex
																items-center
																justify-center
																h-6
																w-6
																rounded-full
															"
															:style="{
																background:
																	staff
																		?.profile_photo
																		?.default
																		?.color,
															}"
														>
															<span
																class="
																	font-medium
																	leading-none
																	text-white
																	text-xs
																"
																>{{
																	staff
																		?.profile_photo
																		?.default
																		?.initial
																}}</span
															>
														</span>
													</div>
													<div
														v-if="staff"
														class="
															ml-4
															text-blue-600
															font-semibold
														"
													>
														{{ staff.name }}
													</div>
												</div>
											</div>
											<div
												class="
													py-3
													text-right
													px-6
													md:w-5/12
													md-down:px-6 md-down:py-2
												"
												:class="{
													'text-sienna-600 font-semibold':
														i === 0,
												}"
											>
												<span v-if="item.date">{{
													$filters.formatDate2(
														item.date
													)
												}}</span
												>,
												<span
													v-if="
														item.display_start_slot
													"
													>{{
														item.display_start_slot
													}}</span
												><span
													v-if="item.display_end_slot"
												>
													-
													{{
														item.display_end_slot
													}}</span
												>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				_modal__footer
				px-12
				py-6
				bg-neutral-50
				flex flex-nowrap
				items-center
			"
		>
			<button
				type="button"
				class="
					button-p
					text-center
					mr-3
					bg-none
					shadow-none
					text-neutral-500
					hover:text-neutral-600
				"
				@click="$emit('close')"
			>
				Đóng
			</button>
			<button
				type="button"
				class="button-p text-center whitespace-nowrap"
				@click="$emit('submit')"
			>
				Xác nhận
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ErrorOverlap',

	props: {
		dataStaffs: {
			type: Array,
			required: true,
		},
		currentSession: {
			type: Object,
			required: true,
		},
	},
};
</script>
